import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/api/http.service';

@Component({
  selector: 'app-web-forget-password',
  templateUrl: './web-forget-password.component.html',
  styleUrls: ['./web-forget-password.component.css']
})
export class WebForgetPasswordComponent implements OnInit {

  passwordToggleHide:boolean = true
  cPasswordToggleHide:boolean = true
  password:string = ''
  cPassword:string = ''
  constructor(
    private router:Router,
    public toster : ToastrService,
    private http:HttpService
  ) { }

  ngOnInit(): void {
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.hash.split('?')[1]);
    console.log(urlParams.get('token')?.length)
    if(urlParams.get('token')?.length == 0){
      this.router.navigate(['/login'])
    }
    else{
      let token:any = urlParams.get('token')
      localStorage.setItem('token', token)
    }
  }
  updatePassword(){
    if(this.password.length > 0 || this.cPassword.length > 0){
      if(this.password === this.cPassword){
        this.http.changePassword({"password":this.password}).subscribe((res:any)=>{
          if(res['status']){
            this.toster.success('Password updated Successfully', '', { timeOut: 2000 })
            this.router.navigate(['/login'])
            localStorage.removeItem('token')
          }
          else {
            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toster.error(err.error.message, '', { timeOut: 2000 })
        })
      }
      else{
        this.toster.warning("Password doesn't match", '', { timeOut: 2000 })
      }
    }
    else{
      this.toster.warning("Please enter the Password", '', { timeOut: 2000 })
    }
  }

}
