<div class="side_bar_common_css">
    <app-sidenav-bar></app-sidenav-bar>
    <div class="profile_main_div">
        <div class="notificationBar">
            <img *ngIf="role == 1" src = "../../../assets/img/setting.png" (click)="navigate('/settings')" style="width:35px; border-radius: 25px;cursor: pointer;" alt="" srcset="" class="side_nav_bottom_dp">
            <img *ngIf="!isNewNotifications" (click)="toggleNotif()" src="../../../assets/img/NotifiationBell.svg"/>
            <img *ngIf="isNewNotifications" (click)="toggleNotif()" src="../../../assets/img/notificationIcon.svg"/>
            <div class="shwoNotififications" *ngIf="showNotif">
              <div class="d-flex justify-content-end">
                <img style="width: 20px;" (click)="toggleNotif()" src="../../../assets/img/carbon_close-filled.svg"/>
              </div>
              <div *ngIf="notifications.length == 0">
                <div class="notificationHeader">
                  No Notifications Found
                </div>
              </div>
              <div  *ngFor="let notif of notifications" class="notif" (click)="readNotifications(notif)">
                <div class="notificationHeader">
                  {{notif.type.toUpperCase().split('_').join(' ')}}
                </div>
                <div class="notificationContent">
                  {{notif.remarks}}
                </div>
              </div>
            </div>
            <button mat-button [matMenuTriggerFor]="menu" style="padding: 0;">
              <img [src] = "photo" style="width:35px; border-radius: 25px;" alt="" srcset="" class="side_nav_bottom_dp">
            </button>
            <mat-menu #menu="matMenu">
              <div style="color:black;padding:10px;font-weight:700;border-bottom:1px solid #A3C17E;width:100%;max-width:10rem;margin-right: 1rem;">{{username.toUpperCase()}}</div>
              <button mat-menu-item (click)="navigate('/profile')"> <img style="width:25px;margin-right: 10px;" [src]="tPhoto" alt="" > Profile</button>
              <button mat-menu-item (click)="navigate('/login')">  <img style="width:25px;margin-right: 10px;" src="../../../assets/img/logout.svg" alt="" > Logout</button>
            </mat-menu>
        </div>
        <div class="profile_heading">PROFILE SETTING</div>
        <div style="padding: 1rem;">
            <div style="    display: flex;
            
            position: relative;
            max-width: min-content;    margin-bottom: 2rem;">
                <img [src]="src" alt="" style="    width: 150px;
                height: 150px;
                border-radius: 50%;
                border: 1px solid #B3C59E;">

                <img src="../../../assets/img/add_new_dp.svg" alt="" style="position: absolute;
                
                cursor: pointer;" (click)="fileInput_image.click()">
                <img src="../../../assets/img/close.svg" style="position: absolute;right: 0;cursor: pointer;"
                (click)="removeImage()"/>
                <input type="file" accept="image/png,image/jpg" (change)="onImageChange($event)"
                #fileInput_image hidden id="file_inp" />

            </div>
            <div>
                <div class="service_modify_pd_inp_main_div">
                    <div class="service_modify_pd_inp_sub_div">
                        <label for="name">Company Name</label>
                        <input type="text" [(ngModel)]="companyName">
                    </div>
                    <div class="service_modify_pd_inp_sub_div">
                        <label for="name">Mobile Number</label>
                        <input type="number" [(ngModel)]="mobileNo" [disabled]="true" class="input_disable">
                    </div>
                    <div class="service_modify_pd_inp_sub_div">
                        <label for="name">Email</label>
                        <input type="email" [(ngModel)]="email" [disabled]="true" class="input_disable">
                    </div>
                    <div class="service_modify_pd_inp_sub_div">
                        <label for="name">Name</label>
                        <input type="text" [(ngModel)]="name">
                    </div>
                    
                    <div class="service_modify_pd_inp_sub_div">
                        <label for="name">State</label>
                        <ng-select class="multi-select" [items]="allState" bindLabel="name" bindValue="name"
                            [multiple]="false" groupBy="selectedAllGroup" [selectableGroup]="true" name="KOB"
                            placeholder="Select state" (change)="onMaterialGroupChange($event)"
                            [(ngModel)]="selectedState" autocomplete="off">

                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="service_modify_pd_inp_sub_div">
                        <label for="name">Address</label>
                        <input type="text" [(ngModel)]="address" autocomplete="off">
                    </div>
                    <div class="service_modify_pd_inp_sub_div">
                        <label for="name">City</label>
                        <input type="text" [(ngModel)]="city" autocomplete="off">
                    </div>
                </div>
                <div class="license_modify_btn_main_div">
                    <div class="license_modify_pd_btn_div">
                        <div (click)="updateProfileDtls()">UPDATE</div>
                    </div>
                </div>
                <div class="password_update_heading">Change Password</div>
                <div class="password_update_div">
                    <div class="reset_password_content">
                        <div>Current Password</div>
                        <div>
                            <div class="passwordToggler" >
                                <input autocomplete="new-usernamer" [type]="passwordToggleHide?'password':'text'" name="amConfirmPassword" id="" [(ngModel)]="oldPassword" >
                                <img src="../../../assets/img/eye-close-line.svg" alt="" style="cursor: pointer;margin-right: 10px;"
                                *ngIf="passwordToggleHide" (click)="passwordToggleHide = false">
                                <img src="../../../assets/img/eye-2-line.svg" alt="" style="cursor: pointer;margin-right: 10px;"
                                *ngIf="!passwordToggleHide" (click)="passwordToggleHide = true">
                            </div>
                        </div>
                    </div>
                    <div class="reset_password_content">
                        <div>New Password</div>
                        <div>
                            <div class="passwordToggler" >
                                <input autocomplete="new-usernamer" [type]="newpasswordToggleHide?'password':'text'" name="amConfirmPassword" id="" [(ngModel)]="newPassword" >
                                <img src="../../../assets/img/eye-close-line.svg" alt="" style="cursor: pointer;margin-right: 10px;"
                                *ngIf="newpasswordToggleHide" (click)="newpasswordToggleHide = false">
                                <img src="../../../assets/img/eye-2-line.svg" alt="" style="cursor: pointer;margin-right: 10px;"
                                *ngIf="!newpasswordToggleHide" (click)="newpasswordToggleHide = true">
                            </div>
                        </div>
                    </div>
                    <div class="reset_password_content">
                        <div>Confirm Password</div>
                        <div>
                            <div class="passwordToggler" >
                                <input autocomplete="new-usernamer" [type]="conpasswordToggleHide?'password':'text'" name="amConfirmPassword" id="" [(ngModel)]="confirmPassword" >
                                <img src="../../../assets/img/eye-close-line.svg" alt="" style="cursor: pointer;margin-right: 10px;"
                                *ngIf="conpasswordToggleHide" (click)="conpasswordToggleHide = false">
                                <img src="../../../assets/img/eye-2-line.svg" alt="" style="cursor: pointer;margin-right: 10px;"
                                *ngIf="!conpasswordToggleHide" (click)="conpasswordToggleHide = true">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="license_modify_btn_main_div" style="margin-top: 1rem;">
                    <div class="license_modify_pd_btn_div">
                        <div (click)="updatePassword()">CHANGE PASSWORD</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>