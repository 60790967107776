<div class="termsContainer">
    <div class="termsHeader">
        <div (click)="goBack()"><img src="../../../assets/img/back_arrow.svg"/></div>
        <p>Terms and Conditions</p>
    </div>
    <div class="termsBody">
        <div class="termsIntro">
            <h3>Introduction</h3>
            <p>
                This document sets forth the terms and conditions under which you may
                use the myFSSAI mobile Application (the "Application"). By downloading, installing, or
                using the Application, you agree to be bound by these terms and conditions.
            </p>
        </div>
        <div class="termsIntro">
            <h3>License</h3>
            <p>
                Food Safety Works grants you a non-exclusive, non-transferable,
                limited license to use the Application for personal and non-commercial
                purposes. This license does not include the right to
            </p>
            <ul>
                    <li>Modify, adapt, or translate the Application.</li>
                    <li>Reverse engineer, decompile, or disassemble the Application.</li>
                    <li>Create derivative works of the Application.</li>
                    <li>Rent, lease, lend, sell, or distribute the Application.</li>
                    <li>Use the Application to harm or injure others</li>
                    <li>Use the Application in a way that violates any applicable laws or
                        regulations</li>
            </ul>
        </div>
        <div class="termsIntro">
            <h3>Permitted Use</h3>
            <p>
                You may use the Application to
            </p>
            <ul>
                <li>Apply for licenses, renew licenses, and manage your licenses</li>
                <li>View information about licenses, such as license types, fees, and
                    requirements and  Communicate with employees of Food Safety Works</li>
            </ul>
        </div>
        <div class="termsIntro">
            <h3>Prohibited Use</h3>
            <p> In addition to the prohibited uses listed in the section above , you
                may not use the Application to :</p>
            <ul>
                <li>Transmit any content that is unlawful, harmful, threatening,
                    abusive, harassing, defamatory, obscene, vulgar, pornographic, or
                    otherwise objectionable.</li>
                <li>Transmit any content that infringes on the intellectual property
                    rights of others.</li>
                <li>Spam or otherwise flood the Application with unwanted messages.</li>
                <li>Use the Application in a way that disrupts or interferes with the Application's
                    operation.</li>
            </ul>
        </div>
        <div class="termsIntro">
            <h3>Intellectual Property</h3>
            <p>          All rights, title, and interest in and to the Application, including but not
                limited to all copyrights, trademarks, patents, and trade secrets, are
                owned by Food Safety Works. By downloading the Application the user agrees not
                to infringe on Food Safety Work's intellectual property rights :</p>
        </div>
        <div class="termsIntro">
            <h3>Changes to Terms of Use</h3>
            <p> We may update these terms of use from time to time. You will be
                notified of any changes by email or through the Application. If you do not
                agree to the new terms of use, you must stop using the Application.</p>
        </div>
        <div class="termsIntro">
            <h3>Governing Law</h3>
            <p>  These terms of use are governed by the laws of India. Any disputes
                arising out of or in connection with these terms of use will be
                subject to the exclusive jurisdiction of the courts of India..</p>
        </div>
        <div class="termsIntro">
            <h3>Privacy Policy</h3>
            <p>   This Privacy Policy describes how myFSSAI collects, uses, and shares
                your information when you download, install, and use our mobile Application
                (the "Application").</p>
        </div>
        <div class="termsIntro">
            <h3>Information We Collect</h3>
            <p> We collect the following information from you when you use the Application :</p>
            <ul>
                <li>Device information : We collect information about the device you use
                    to access the Application, such as your device type, operating system, and
                    unique device identifier.</li>
                <li>Usage information : We collect information about how you use the Application,
                    such as the pages you visit, the features you use, and the actions you
                    take.</li>
                <li>Log data : We collect log data, which includes information about your
                    use of the Application, such as the date and time of your use, the pages you
                    visit, the features you use, and the errors you encounter.</li>
                <li>Personal information you provide : We may collect personal
                    information from you, such as your name, email address, and phone
                    number, when you create an account, contact us, or provide feedback.</li>
            </ul>
        </div>
        <div class="termsIntro">
            <h3>How We Use Your Information</h3>
            <p> We use the information we collect from you to : </p>
            <ul>
                <li>Provide and improve the Application.</li>
                <li>Communicate with you about the Application.</li>
                <li>Personalize your experience with the Application.</li>
                <li>Provide customer support.</li>
                <li>Fulfill your requests.</li>
                <li>Administer the Application.</li>
                <li>Protect our rights and property.</li>
                <li>Comply with applicable laws and regulations.</li>
            </ul>
        </div>
        <div class="termsIntro">
            <h3>Data Retention</h3>
            <p>   We will retain your information for as long as you use the Application and for
                a reasonable time thereafter. We will also retain Automatically
                Collected information for a reasonable period of time depending on the
                nature of the Application and thereafter may store it in aggregate.</p>
        </div>
    </div>
</div>