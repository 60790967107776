<div class="side_nav_main_div">
    <div>
        <div>
            <div class="side_nav_header_main_div pd_l_1">
                <img src="../../../assets/img/logo.svg" alt="">
                <span class="side_nav_header_txt">myFssai</span>
            </div>
            <div>
                <div style="position: relative;text-align: end;">
                    &nbsp;
                </div>
            </div>
            <div class="side_nav_bar_list">
                <div class="side_nav_bar_ele_main_div pd_l_1" [ngClass]="activeValidater.dashboard?'side_bar_active':''"
                    (click)="navigate('/dashboard')">
                    <div class="side_nav_bar_ele">Dashboard</div>
                </div>
                <!-- <div class="side_nav_bar_ele_main_div pd_l_1 USER_MANAGEMENT_txt_main_div">
                    <div class="USER_MANAGEMENT_txt"> USER MANAGEMENT </div>
                </div> -->
                <div class="side_nav_bar_ele_main_div pd_l_1" [ngClass]="activeValidater.webUser?'side_bar_active':''"
                    (click)="navigate('/webUser')" *ngIf="sideNavActivate.webUser">
                    <div class="side_nav_bar_ele">User Management</div>
                </div>
                <div class="side_nav_bar_ele_main_div pd_l_1" [ngClass]="activeValidater.appUser?'side_bar_active':''"
                    (click)="navigate('/appUser')" *ngIf="sideNavActivate.appUser">
                    <div class="side_nav_bar_ele">App User</div>
                </div>
                <div class="side_nav_bar_ele_main_div pd_l_1" [ngClass]="activeValidater.license?'side_bar_active':''"
                    (click)="navigate('/license')">
                    <div class="side_nav_bar_ele">Licenses</div>
                </div>
                <div class="side_nav_bar_ele_main_div pd_l_1" [ngClass]="activeValidater.bm?'side_bar_active':''"
                (click)="navigate('/bm')" *ngIf="sideNavActivate.bussinessManager">
                <div class="side_nav_bar_ele">Business Managers</div>
                </div>
                <div class="side_nav_bar_ele_main_div pd_l_1" [ngClass]="activeValidater.am?'side_bar_active':''"
                    (click)="navigate('/am')" *ngIf="sideNavActivate.accountManager">
                    <div class="side_nav_bar_ele">Account Managers</div>
                </div>
                <div class="side_nav_bar_ele_main_div pd_l_1" [ngClass]="activeValidater.service?'side_bar_active':''"
                    (click)="navigate('/service')">
                    <div class="side_nav_bar_ele">Service Requests</div>
                </div>
                <div class="side_nav_bar_ele_main_div pd_l_1"  [ngClass]="activeValidater.report?'side_bar_active':''"
                    (click)="navigate('/report')" *ngIf="sideNavActivate.report">
                    <div class="side_nav_bar_ele">Reports</div>
                </div>
                <!-- <div class="side_nav_bar_ele_main_div pd_l_1"  [ngClass]="activeValidater.settings?'side_bar_active':''"
                    (click)="navigate('/settings')" *ngIf="sideNavActivate.settings">
                <div class="side_nav_bar_ele">Settings</div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="side_nav_bottom_main_div">
        <div>
            <!-- <div class="logout_div" (click)="navigate('/login')"><span>Logout</span> <img src="../../../assets/img/logout.svg" alt="" > </div>
            <div style="border-top: 2px solid #e6e9e3;padding-top: 12px;">
                <div class="side_nav_bottom_content_div" style="border: none;" [ngClass]="activeValidater.profile?'side_bar_active':''"
                    (click)="navigate('/profile')">
                    <img [src] = "photo" alt="" srcset="" class="side_nav_bottom_dp">
                    <span class="side_nav_bottom_name">{{username}}</span>
                   
                </div>
            </div> -->
        </div>
    </div>
</div>