<div class="remarksContainer">
    <div class="service_modify_header" style="justify-content: flex-start;">
        <img src="../../../assets/img/back_arrow.svg" alt="" style="cursor: pointer;" (click)="close()">
        <div style="padding-left: 2rem;">
            Status Change Remarks
        </div>
        <div>

        </div>
    </div>
    <div class="service_modify_pd_inp_sub_div" style="padding: 1rem;flex:1;overflow: auto;">
        <label for="name">Remark</label>
        <textarea type="text" name="name" [(ngModel)]="remarks"></textarea>
    </div>
    <div class="service_modify_pd_btn_div" style="padding: 1rem;">
        <div (click)="close()" >Cancel</div>
        <div (click)="save()" >SUBMIT</div>
    </div> 
</div>  

<style>
    .modify_doc_header_div {
    color: #1D1E1C;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    }
    .header_main{
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
    }
</style>