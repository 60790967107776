<div class="ces_main_div">
    <div class="ces_header">
        <span>Modify Enterprise User</span>
        <div class="header_quik_functions">
            <img src="../../../assets/img/reset_icon.svg"  *ngIf="!data.isFromLicense" alt="" (click)="handleResetPassword()">
            <img src="../../../assets/img/remove_icon_bold.svg"  *ngIf="!data.isFromLicense" alt="" (click)="handleDeleteEnterpriseUser()" >
            <img src="../../../assets/img/close.svg" alt="" (click)="close()">
        </div>
    </div>
    <div class="ces_content_main_div">

        <div class="ces_inp_main_div">

            <!-- ------------------------------------------ PERSONAL DETAILS --------------------------------------------------- -->

            <div class="ces_pd_main_div"  *ngIf="this.stepperFlag['personal_dtl']">
                <div class="ces_pd_inp_main_div" style="grid-template-columns: 1fr 1fr 1fr 1fr;">
                    <div class="ces_pd_inp_sub_div">
                        <label for="name">Name</label>
                        <input type="text" name="name" [(ngModel)]="name">
                    </div>
                    <div class="ces_pd_inp_sub_div">
                        <label for="email">Email Address</label>
                        <input type="email" name="email" [(ngModel)]="email">
                    </div>
                    <div class="ces_pd_inp_sub_div">
                        <label for="number">Mobile Number</label>
                        <input type="number" name="mobile" [(ngModel)]="number">
                    </div>
                    <div class="ces_pd_inp_sub_div">
                        <label for="comp_name">Company Name</label>
                        <input type="text" name="comp_name" [(ngModel)]="compName">
                    </div>
                    <div class="ces_pd_inp_sub_div">
                        <label for="city">Address</label>
                        <input type="text" name="city" [(ngModel)]="address">
                    </div>
                    <div class="ces_pd_inp_sub_div">
                        <label for="city">City</label>
                        <input type="text" name="city" [(ngModel)]="city">
                    </div>
                    <div class="ces_pd_inp_sub_div">
                        <label for="address">State</label>
                        <ng-select class="multi-select" [items]="allState" bindLabel="name"  bindValue="name"
                        [multiple]="false" groupBy="selectedAllGroup" [selectableGroup]="true" name="KOB"
                        placeholder="Select state"
                        [(ngModel)]="state">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>                    </div>
                    <div class="ces_pd_inp_sub_div">
                        <label for="assign_Am"> Business Manager</label>
                        <div style="display: flex; justify-content: space-between;background:white;padding: 14px; border-radius: 10px; " (click)="handleAssignment('bm',assign_business_manager?assign_business_manager.id:0,id)">
                            <span>{{assign_business_manager == null ? 'None' :
                                assign_business_manager.name}}</span>
                            <img src="../../../assets/img/arrow_drop_down_ICON.svg" alt="">
                        </div>                   </div>
                    <div class="ces_pd_inp_sub_div" *ngIf="role == '1'">
                        <label for="assign_Am"> Account Manager</label>
                        <div [ngClass]="assign_business_manager == null || assign_business_manager.id == 0 ? '' : 'disabledDropdpown'" style="display: flex; justify-content: space-between;background:white;padding: 14px; border-radius: 10px; " (click)="handleAssignment('am',assign_account_manager?assign_account_manager.id:0,id)" >
                            <span>{{assign_account_manager == null ? 'None' :
                                assign_account_manager.name}}</span>
                            <img *ngIf="assign_business_manager == null || assign_business_manager.id == 0" src="../../../assets/img/arrow_drop_down_ICON.svg" alt="">
                        </div>                       </div>
                    <div class="ces_pd_inp_sub_div">
                        <label for="status">Status</label>
                        <div class="dropdown">
                            <button class="btn dropdown-toggle updateSelect" type="button" data-bs-toggle="dropdown"
                                aria-expanded="false" style="width: 100%;text-align: left;min-height: 48px;">
                                <span *ngIf="txtStatus == ''">&nbsp;</span>
                                <span *ngIf="txtStatus != ''"
                                    [ngClass]="txtStatus == 'active'?'colorGreen':txtStatus == 'inactive'?'colorRed':'colorBlue'">
                                    {{txtStatus}}</span>

                            </button>
                            <ul class="dropdown-menu" style="width: 100%;">
                                <li class="dropdown-item" style="color: #12C058;"
                                    [ngClass]="selectedFilter.active?'filterActiveCss':''"
                                    (click)="filterByStatus('Active')">
                                    Active</li>
                                <li class="dropdown-item" style="color: #D43738;"
                                    [ngClass]="selectedFilter.expired?'filterActiveCss':''"
                                    (click)="filterByStatus('Inactive')">
                                    Inactive</li>
                                <!-- <li class="dropdown-item" style="color: #1968B1;"
                                    [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                                    (click)="filterByStatus('Requested')">
                                    Requested</li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="ces_pd_inp_sub_div" *ngIf="role == '1' || role == '2'">
                        <label for="status">Licenses List</label>
                        <div class="dropdown">
                            <button class="btn dropdown-toggle updateSelect" type="button" data-bs-toggle="dropdown"
                                aria-expanded="false" style="width: 100%;text-align: left;min-height: 48px;">
                                <span style="font-size: 14px;color: grey;" >View Licenses</span>
                            </button>
                            <ul class="dropdown-menu" style="width: 100%;" *ngIf="!listOfLicense" >
                                <span class="dropdown-item" >No Licenses Found</span>
                            </ul>
                            <ul class="dropdown-menu" style="width: 100%;" *ngIf="listOfLicense?listOfLicense.length>0:''" >
                                <li  *ngFor="let data of listOfLicense" class="dropdown-item"
                                    (click)="handleOpenLicense(data.name)">
                                    {{data.name}}</li>
                            </ul>
                        </div>
                    </div>
                  
                </div>

            </div>



        </div>
    </div>
    <div style="flex: 1;">&nbsp;</div>
    <div class="ces_btn_main_div">
        <div class="ces_pd_btn_div_new" (click)="handleAddNewLicense()" *ngIf="role == '1' || role == '2'">
            <div   >Add New License</div>
        </div>
        <div class="" *ngIf="role != '1'">
        </div>
        <div class="ces_pd_btn_div">
            <div (click)="close()">CANCEL</div>
            <div *ngIf="role == '1'" (click)="update()" >UPDATE</div>
            <div *ngIf="role == '2'" (click)="updateAMEnterpriseUser()" >UPDATE</div>
        </div>
    </div>
</div>  