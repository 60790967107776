<div class="modify_doc_main_divSR">
    <div style="justify-self: flex-start;">
        <div class="header_mainSR">
            <img src="../../../assets/img/back_arrow.svg" alt="" style="cursor: pointer;" (click)="close()">
            <span class="modify_doc_header_divSR" *ngIf="isEdit">MODIFY DOCUMENT</span>
            <span class="modify_doc_header_divSR" *ngIf="!isEdit">CREATE DOCUMENT</span>

        </div>
    <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr;
            gap: 20px;min-height: 11rem;padding: 2rem;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">Document Folder</label>
                    <ng-select class="multi-select" [items]="allTypeOfDocs" bindLabel="value" bindValue="ref"
                        [multiple]="false" groupBy="selectedAllGroup" [selectableGroup]="true" name="typeOfLicence"
                        placeholder=" Document Folder" 
                        [(ngModel)]="selectedDoc">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                 <!-- Application -->
        <div *ngIf="selectedDoc == 'application'">
            <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="name">Document Name</label>
                    <input type="text" name="name" [(ngModel)]="docName">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Description</label>
                    <input type="email" name="description" [(ngModel)]="docDes">
                </div>
                <!-- <div class="license_modify_pd_inp_sub_div">
                    <label for="state">Sample Document</label>
                    <ng-select class="multi-select" [items]="allTypeOfDocs" bindLabel="name" bindValue="id"
                        [multiple]="false" groupBy="selectedAllGroup" [selectableGroup]="true" name="typeOfLicence"
                        placeholder="Select Sample Document" 
                        [(ngModel)]="selectedDoc">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div> -->
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">STATUS</label>
                    <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                        [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                        placeholder="Select Document Status" 
                        [(ngModel)]="selectedStatus">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="upload">Upload Documents</label>
                    <div class="license_upload_sub_div">
                        <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                            #fileInput_image hidden id="file_inp_doc" />
                        <div (click)="fileInput_image.click()">
                            Choose file
                        </div>
                        <div>
                            <span *ngIf="fileName == ''">No file chosen</span>
                            <span *ngIf="fileName != ''">{{fileName}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Testing -->
        <div *ngIf="selectedDoc == 'testing'">
            <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="name">Product Name</label>
                    <input type="text" name="name" [(ngModel)]="productName">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Product ID</label>
                    <input type="email" name="id" [(ngModel)]="productId">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="date">Product Date</label>
                    <input type="date" name="date" [(ngModel)]="testingDate">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">STATUS</label>
                    <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                        [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                        placeholder="Select Document Status" 
                        [(ngModel)]="selectedStatus">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="upload">Upload Documents</label>
                    <div class="license_upload_sub_div">
                        <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                            #fileInput_image hidden id="file_inp_doc" />
                        <div (click)="fileInput_image.click()">
                            Choose file
                        </div>
                        <div>
                            <span *ngIf="fileName == ''">No file chosen</span>
                            <span *ngIf="fileName != ''">{{fileName}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <!-- Training -->
         <div *ngIf="selectedDoc == 'training'">
            <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="name">Employee Name</label>
                    <input type="text" name="name" [(ngModel)]="trainingName" >
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Emloyee ID</label>
                    <input type="email" name="id" [(ngModel)]="trainingId" >
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Training Date</label>
                    <input type="date" name="date" [(ngModel)]="trainingDate" >
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">STATUS</label>
                    <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                        [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                        placeholder="Select Document Status" 
                        [(ngModel)]="selectedStatus">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="upload">Upload Documents</label>
                    <div class="license_upload_sub_div">
                        <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                            #fileInput_image hidden id="file_inp_doc" />
                        <div (click)="fileInput_image.click()">
                            Choose file
                        </div>
                        <div>
                            <span *ngIf="fileName == ''">No file chosen</span>
                            <span *ngIf="fileName != ''">{{fileName}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <!-- Health -->
         <div *ngIf="selectedDoc == 'health'">
            <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="name">Employee Name</label>
                    <input type="text" name="name" [(ngModel)]="healthName">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Emloyee ID</label>
                    <input type="email" name="id" [(ngModel)]="healthId">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Report Date</label>
                    <input type="date" name="date" [(ngModel)]="healthDate">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">STATUS</label>
                    <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                        [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                        placeholder="Select Document Status" 
                        [(ngModel)]="selectedStatus">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="upload">Upload Documents</label>
                    <div class="license_upload_sub_div">
                        <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                            #fileInput_image hidden id="file_inp_doc" />
                        <div (click)="fileInput_image.click()">
                            Choose file
                        </div>
                        <div>
                            <span *ngIf="fileName == ''">No file chosen</span>
                            <span *ngIf="fileName != ''">{{fileName}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Calibration -->
        <div *ngIf="selectedDoc == 'calibration'">
            <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="name">Name of Instrument</label>
                    <input type="text" name="name" [(ngModel)]="calibirationName">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Equipment ID</label>
                    <input type="email" name="id" [(ngModel)]="calibirationId">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Calibration Date</label>
                    <input type="date" name="date" [(ngModel)]="calibirationDate">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">STATUS</label>
                    <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                        [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                        placeholder="Select Document Status" 
                        [(ngModel)]="selectedStatus">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="upload">Upload Documents</label>
                    <div class="license_upload_sub_div">
                        <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                            #fileInput_image hidden id="file_inp_doc" />
                        <div (click)="fileInput_image.click()">
                            Choose file
                        </div>
                        <div>
                            <span *ngIf="fileName == ''">No file chosen</span>
                            <span *ngIf="fileName != ''">{{fileName}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Others -->
        <div *ngIf="selectedDoc == 'others'">
            <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="name">Document Name</label>
                    <input type="text" name="name" [(ngModel)]="othersName">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Description</label>
                    <input type="email" name="id"  [(ngModel)]="othersDes">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Expiration Date</label>
                    <input type="date" name="date" [(ngModel)]="othersDate">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">STATUS</label>
                    <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                        [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                        placeholder="Select Document Status" 
                        [(ngModel)]="selectedStatus">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="upload">Upload Documents</label>
                    <div class="license_upload_sub_div">
                        <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                            #fileInput_image hidden id="file_inp_doc" />
                        <div (click)="fileInput_image.click()">
                            Choose file
                        </div>
                        <div>
                            <span *ngIf="fileName == ''">No file chosen</span>
                            <span *ngIf="fileName != ''">{{fileName}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </div>
            </div>
            <div class="service_modify_pd_btn_div" style="padding: 1rem;">
                <div (click)="close()">CANCEL</div>
                <div *ngIf="!isEdit" (click)="save()" >CREATE</div>
                <div *ngIf="isEdit" (click)="update()" >UPDATE</div>
            </div> 
        </div>