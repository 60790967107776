import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/api/http.service';
import { DataService } from 'src/app/services/data-share/data.service';
import { DialogResetPassword } from '../reset-password/reset_password';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  @Output() messageEmitter = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<DialogResetPassword>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http:HttpService,
    private dataShare:DataService,
    private toast:ToastrService
  ) { }
  notifications:any = []
  ngOnInit(): void {
    this.notifications = this.data
  }
  close(){
    this.dialogRef.close(false)
  }
  moveTab(tab:any,to:any='Enterprise'){
    // this.messageEmitter.emit(tab)
    // this.dataShare.prefilter=to
  }
  getDate(data:any){
    const date = new Date(data);
      // Define arrays for month and weekday names
      const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      // Format the date manually
      const weekday = weekdays[date.getUTCDay()];
      const month = months[date.getUTCMonth()];
      const day = date.getUTCDate();
      const year = date.getUTCFullYear();
      const utcDate = new Date(date);
    
      // Convert to IST (UTC + 5:30)
      const istDate = new Date(utcDate.getTime() + (5.5 * 60 * 60 * 1000));
      
      // Get hours and minutes
      let hours = istDate.getUTCHours();
      let minutes:any = istDate.getUTCMinutes();
      
      // Format to AM/PM
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // The hour '0' should be '12'
      
      // Format minutes to 2 digits
      minutes = minutes < 10 ? `0${minutes}` : minutes;

      return`${weekday}, ${month}, ${day} ${year} at ${hours}:${minutes} ${ampm}`;;

  }
  readNotifications(element:any){
    console.log(element)
    this.moveTab('service',element.user_type == 'eu' ? 'Enterprise' : 'Retail')
    this.dataShare.dialogRef(element.service_request_id,element.user_type)
    let data = {
      id: element.id,
      is_read : 1
    }
    this.http.readNotification(data).subscribe((res:any)=>{
      if(res['status']){
        this.dialogRef.close(true)
      }
      else{
        this.toast.error(res['message'],'',{timeOut:2000})
      }
    },(err:any)=>{
      this.toast.error("Oops!, Somrthing went wrong Please try again",'',{timeOut:2000})
    })
  }
}
