<div class="side_bar_common_css">
    <app-sidenav-bar></app-sidenav-bar>
    <div style="flex:1;background-color: #EFEFEF;" *ngIf="role==1">
        <div class="notificationBar">
          <img  *ngIf="role == 1" src = "../../../assets/img/setting.png" (click)="navigate('/settings')" style="width:35px; border-radius: 25px;cursor: pointer;" alt="" srcset="" class="side_nav_bottom_dp">
            <img *ngIf="!isNewNotifications" (click)="toggleNotif()" src="../../../assets/img/NotifiationBell.svg"/>
            <img *ngIf="isNewNotifications" (click)="toggleNotif()" src="../../../assets/img/notificationIcon.svg"/>
            <div class="shwoNotififications" *ngIf="showNotif">
              <div class="d-flex justify-content-end">
                <img style="width: 20px;" (click)="toggleNotif()" src="../../../assets/img/carbon_close-filled.svg"/>
              </div>
              <div *ngIf="notifications.length == 0">
                <div class="notificationHeader">
                  No Notifications Found
                </div>
              </div>
              <div  *ngFor="let notif of notifications" class="notif" (click)="readNotifications(notif)">
                <div class="notificationHeader">
                  {{notif.type.toUpperCase().split('_').join(' ')}}
                </div>
                <div class="notificationContent">
                  {{notif.remarks}}
                </div>
              </div>
            </div>
            <button mat-button [matMenuTriggerFor]="menu" style="padding: 0;">
              <img [src] = "photo" style="width:35px; border-radius: 25px;" alt="" srcset="" class="side_nav_bottom_dp">
            </button>
            <mat-menu #menu="matMenu">
              <div style="color:black;padding:10px;font-weight:700;border-bottom:1px solid #A3C17E;width:100%;max-width:10rem;margin-right: 1rem;">{{username.toUpperCase()}}</div>
              <button mat-menu-item (click)="navigate('/profile')"> <img style="width:25px;margin-right: 10px;" [src]="tPhoto" alt="" > Profile</button>
              <button mat-menu-item (click)="navigate('/login')">  <img style="width:25px;margin-right: 10px;" src="../../../assets/img/logout.svg" alt="" > Logout</button>
            </mat-menu>
        </div>
        <div style="width:100%;height:100%;padding:1rem">
          <app-u0-webuser></app-u0-webuser>
        </div>
    </div>
    <div style="flex:1;background-color: #EFEFEF;" *ngIf="role==3">
      <div class="notificationBar">
        <img  *ngIf="role == 1" src = "../../../assets/img/setting.png" (click)="navigate('/settings')" style="width:35px; border-radius: 25px;cursor: pointer;" alt="" srcset="" class="side_nav_bottom_dp">
          <img *ngIf="!isNewNotifications" (click)="toggleNotif()" src="../../../assets/img/NotifiationBell.svg"/>
          <img *ngIf="isNewNotifications" (click)="toggleNotif()" src="../../../assets/img/notificationIcon.svg"/>
          <div class="shwoNotififications" *ngIf="showNotif">
            <div class="d-flex justify-content-end">
              <img style="width: 20px;" (click)="toggleNotif()" src="../../../assets/img/carbon_close-filled.svg"/>
            </div>
            <div *ngIf="notifications.length == 0">
              <div class="notificationHeader">
                No Notifications Found
              </div>
            </div>
            <div  *ngFor="let notif of notifications" class="notif" (click)="readNotifications(notif)">
              <div class="notificationHeader">
                {{notif.type.toUpperCase().split('_').join(' ')}}
              </div>
              <div class="notificationContent">
                {{notif.remarks}}
              </div>
            </div>
          </div>
          <button mat-button [matMenuTriggerFor]="menu" style="padding: 0;">
            <img [src] = "photo" style="width:35px; border-radius: 25px;" alt="" srcset="" class="side_nav_bottom_dp">
          </button>
          <mat-menu #menu="matMenu">
            <div style="color:black;padding:10px;font-weight:700;border-bottom:1px solid #A3C17E;width:100%;max-width:10rem;margin-right: 1rem;">{{username.toUpperCase()}}</div>
            <button mat-menu-item (click)="navigate('/profile')"> <img style="width:25px;margin-right: 10px;" [src]="tPhoto" alt="" > Profile</button>
            <button mat-menu-item (click)="navigate('/login')">  <img style="width:25px;margin-right: 10px;" src="../../../assets/img/logout.svg" alt="" > Logout</button>
          </mat-menu>
      </div>
        <div style="width:100%;height:100%;padding:1rem">
          <app-u2-webuser></app-u2-webuser>
        </div>
    </div>
    <div style="flex:1;background-color: #EFEFEF;" *ngIf="role==2">
      <div class="notificationBar">
        <img  *ngIf="role == 1" src = "../../../assets/img/setting.png" (click)="navigate('/settings')" style="width:35px; border-radius: 25px;cursor: pointer;" alt="" srcset="" class="side_nav_bottom_dp">
          <img *ngIf="!isNewNotifications" (click)="toggleNotif()" src="../../../assets/img/NotifiationBell.svg"/>
          <img *ngIf="isNewNotifications" (click)="toggleNotif()" src="../../../assets/img/notificationIcon.svg"/>
          <div class="shwoNotififications" *ngIf="showNotif">
            <div class="d-flex justify-content-end">
              <img style="width: 20px;" (click)="toggleNotif()" src="../../../assets/img/carbon_close-filled.svg"/>
            </div>
            <div *ngIf="notifications.length == 0">
              <div class="notificationHeader">
                No Notifications Found
              </div>
            </div>
            <div  *ngFor="let notif of notifications" class="notif" (click)="readNotifications(notif)">
              <div class="notificationHeader">
                {{notif.type.toUpperCase().split('_').join(' ')}}
              </div>
              <div class="notificationContent">
                {{notif.remarks}}
              </div>
            </div>
          </div>
          <button mat-button [matMenuTriggerFor]="menu" style="padding: 0;">
            <img [src] = "photo" style="width:35px; border-radius: 25px;" alt="" srcset="" class="side_nav_bottom_dp">
          </button>
          <mat-menu #menu="matMenu">
            <div style="color:black;padding:10px;font-weight:700;border-bottom:1px solid #A3C17E;width:100%;max-width:10rem;margin-right: 1rem;">{{username.toUpperCase()}}</div>
            <button mat-menu-item (click)="navigate('/profile')"> <img style="width:25px;margin-right: 10px;" [src]="tPhoto" alt="" > Profile</button>
            <button mat-menu-item (click)="navigate('/login')">  <img style="width:25px;margin-right: 10px;" src="../../../assets/img/logout.svg" alt="" > Logout</button>
          </mat-menu>
      </div>
        <div style="width:100%;height:100%;padding:1rem">
          <app-u1-webuser></app-u1-webuser>
        </div>
    </div>
</div>