<div>
    <div class="dash_user_count_main_div" style=" grid-template-columns: 1fr 1fr 1fr 1fr;">
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license')">
            <div>
                <img src="../../../assets/img/total_licenses-icon.svg" alt="">
            </div>
            <div class="dash_user_count_div"   >
                <div>Total Licenses</div>
                <div>{{licenseCount.total_licenses}}</div>
            </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license')">
            <div>
                <img src="../../../assets/img/actice_license.svg" alt="">
            </div>
            <div class="dash_user_count_div"    >
                <div>Active Licenses</div>
                <div>{{licenseCount.active_licenses}}</div>
            </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license')">
            <div>
                <img src="../../../assets/img/expired_license.svg" alt="">
            </div>
            <div class="dash_user_count_div"    >
                <div>Expired Licenses</div>
                <div>{{licenseCount.expired_licenses}}</div>
            </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count"     (click)="moveTab('license')">
            <div>
                <img src="../../../assets/img/requested_license.svg" alt="">
            </div>
            <div class="dash_user_count_div">
                <div>Requested Licenses</div>
                <div>{{licenseCount.requested_licenses}}</div>
            </div>
        </div>
    </div>
    <div class="dash_service_count_main_div">

        <div class="dash_user_count_sub_div u3_license_count"   (click)="moveTab('license')">
            <div>
                <img src="../../../assets/img/expired_license.svg" alt="">
            </div>
            <div class="dash_user_count_div">
                <div>Expired Documents</div>
                <div>{{documentsCount.expired_documents}}</div>
            </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license')">
            <div>
                <img src="../../../assets/img/document_need_to_upload.svg" alt="">
            </div>
            <div class="dash_user_count_div" >
                <div>Documents Need to Upload</div>
                <div>{{documentsCount.documents_need_to_upload}}</div>
            </div>
        </div>
    </div>

    <!-- ---------------------------------DOCUMENTS PIE CHART--------------------------------------------------------- -->
    <!-- <div class="dash_user_end_div">&nbsp;</div>
    <div class="dash_main_header">USER MANAGEMENT</div> -->


    <div class="dash_pie_sub_div">
        <div class="dash_pie_header">
            Documents
        </div>
        <div class="dash_pie_wraper_div" style="display: flex; justify-content: center;">
            <div>
                <apx-chart #chart [series]="totalDocumentData" [chart]="pieChats" [labels]="lableDocument"
                    [dataLabels]="dataLabels" [legend]="pieLegend" [colors]="colorsDocument"></apx-chart>
            </div>
            <div style="width: 184px;    display: flex;
            flex-direction: column;
            justify-content: center;">
                <div class="pie_chart_lengend_label pie_chart_lengend_label_total">
                    <div class="" style="padding-left: 0;">Total Documents</div>
                    <div class="">{{documentsCount.total_documents}}</div>
                </div>
                <div class="pie_chart_lengend_label pie_chart_lengend_label_data ">
                    <div>
                        <div class="active_legend_circle"></div>Active documents
                    </div>
                    <div>{{documentsCount.active_documents}}</div>
                </div>
                <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                    <div>
                        <div class="inactive_legend_circle" style="background-color: #C6372E;"></div>Expired documents
                    </div>
                    <div>{{documentsCount.expired_documents}}</div>
                </div>
                <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                    <div>
                        <div class="inactive_legend_circle" style="background-color: #F0C662;"></div>Documents Need to
                        Upload
                    </div>
                    <div>{{documentsCount.documents_need_to_upload}}</div>
                </div>
            </div>
        </div>
    </div>
    <!-- ---------------------------------Licenses PIE CHART--------------------------------------------------------- -->

    <div class="dash_pie_sub_div">
        <div class="dash_pie_header">
            Total Licenses
        </div>
        <div style="    display: grid;
        grid-template-columns: 1fr 1fr;">
            <div class="dash_pie_wraper_div" style="display: flex; justify-content: center;">
                <div>
                    <apx-chart #chart [series]="totalLicenseData" [chart]="pieChats" [labels]="lableLicense"
                        [dataLabels]="dataLabels" [legend]="pieLegend" [colors]="colorsLicense"></apx-chart>
                </div>
                <div style="    width: 184px;
                display: flex;
                flex-direction: column;
                justify-content: center;">
                    <div class="pie_chart_lengend_label pie_chart_lengend_label_total">
                        <div class="" style="padding-left: 0;">Total Licenses</div>
                        <div class="">{{licenseCount.total_licenses}}</div>
                    </div>
                    <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                        <div>
                            <div class="active_legend_circle" style="background-color: #7CC3B5;"></div>Active Licenses
                        </div>
                        <div>{{licenseCount.active_licenses}}</div>
                    </div>
                    <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                        <div>
                            <div class="inactive_legend_circle" style="background-color: #E0473E;"></div>Expired
                            Licenses
                        </div>
                        <div>{{licenseCount.expired_licenses}}</div>
                    </div>
                    <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                        <div>
                            <div class="inactive_legend_circle" style="background-color: #247DD0;"></div>Requseted
                            Licenses
                        </div>
                        <div>{{licenseCount.requested_licenses}}</div>
                    </div>
                </div>
            </div>
            <div class="dash_pie_wraper_div" style="display: flex; justify-content: center;">
                <div>
                    <apx-chart #chart [series]="totalExpiringLicenseData" [chart]="pieChats"
                        [labels]="lableExpiringLicense" [dataLabels]="dataLabels" [legend]="pieLegend"
                        [colors]="colorsExpiringLicense"></apx-chart>
                </div>
                <div style="width: 184px;    display: flex;
                flex-direction: column;
                justify-content: center;">
                    <div class="pie_chart_lengend_label pie_chart_lengend_label_total">
                        <div class="" style="padding-left: 0;">Expiring Licenses</div>
                        <div class="">&nbsp;</div>
                    </div>
                    <div class="pie_chart_lengend_label pie_chart_lengend_label_data ">
                        <div>
                            <div class="active_legend_circle" style="background-color: #E0473E;"></div>Expiring in less
                            than 30 days
                        </div>
                        <div>{{expiringLicenseCount.expiring_in_less_than_30_days}}</div>
                    </div>
                    <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                        <div>
                            <div class="inactive_legend_circle" style="background-color: #F59E5F;"></div>Expiring in 60
                            days
                        </div>
                        <div>{{expiringLicenseCount.expiring_in_less_than_60_days}}</div>
                    </div>
                    <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                        <div>
                            <div class="inactive_legend_circle" style="background-color: #F5DD5F;"></div>Expiring in 90
                            days
                        </div>
                        <div>{{expiringLicenseCount.expiring_in_less_than_90_days}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="dash_user_end_div">&nbsp;</div>


    <!-- --------------------------------- Services BAR CHART--------------------------------------------------------- -->

    <div>
        <div>
            <div class="dash_sub_heading">SERVICE REQUESTS</div>
            <div class="dash_service_count_main_div">

                <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('service')">
                    <div>
                        <img src="../../../assets/img/total_service_req.svg" alt="">
                    </div>
                    <div class="dash_user_count_div"  >
                        <div>Total Service Requests</div>
                        <div>{{serviceCount.total_service_requests}}</div>
                    </div>
                </div>
                <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('service')">
                    <div>
                        <img src="../../../assets/img/document_need_to_upload.svg" alt="">
                    </div>
                    <div class="dash_user_count_div"  >
                        <div>Pending Service Requests</div>
                        <div>{{serviceCount.pending_service_requests}}</div>
                    </div>
                </div>
            </div>
            <div class="dash_sub_box_main_div" style="margin-top: 1rem;">
                <div class="dash_header_main_div">
                    <div class="box_main_heading">Total Service Requests</div>
                    <div class="dateFilter">
                    <div class="dash_filter_main_div">
                        <div>Month</div>
                        <div>
                            <select name="monthName" id="" [(ngModel)]="selectedServiceMonth"
                                (change)="serviceChatMonthHander($event)">
                                <option value="Jan" >Jan</option>
                                <option value="Feb">Feb</option>
                                <option value="Mar">Mar</option>
                                <option value="Apr">Apr</option>
                                <option value="May">May</option>
                                <option value="Jun">Jun</option>
                                <option value="July">July</option>
                                <option value="Aug">Aug</option>
                                <option value="Sep">Sep</option>
                                <option value="Oct">Oct</option>
                                <option value="Nov">Nov</option>
                                <option value="Dec">Dec</option>
                            </select>
                        </div>
                    </div>
                    <div class="dash_filter_main_div">
                        <div>Year</div>
                        <div>
                          <select name="monthName" id=""[(ngModel)]="currentYear"
                          (change)="serviceChatMonthHander(selectedServiceMonth)">
                            <option *ngFor="let year of yearDropDown" [value]="year">{{year}}</option>
                          </select>
                        </div>
                      </div>
                    </div>


                </div>


                <div style="display: flex;justify-content: center;">
                    <div class="chart_y_axis_header">No. Services</div>
                    <div class="dash_chart_main_div">
                        <div><img src="../../../assets/img/line_verical.svg" alt=""></div>
                        <div id="chart_div" class="dash_chart_sub_div">
                            <div id="chart_su_div">
                                <apx-chart #chart [series]="serviceRequestData" [chart]="chats" [xaxis]="xaxis" [yaxis]="yaxis"
                                    [dataLabels]="dataLabels" [plotOptions]="plotOptions" [legend]="legend"></apx-chart>
                            </div>

                            <img src="../../../assets/img/line_horizontal.svg" alt="" class="horizontal_line">
                            <div class="chart_x_axis_header">Weeks</div>

                        </div>
                    </div>
                </div>
                <div class="box_main_heading" style="margin-top: 4rem;">Service Requests</div>
                <div>
                    <div class="dash_pie_sub_div">
                        <div class="dash_pie_wraper_div" style="display: flex; justify-content: center;">
                            <div>
                                <apx-chart #chart [series]="totalServiceData" [chart]="pieChats" [labels]="lableService"
                                    [dataLabels]="dataLabels" [legend]="pieLegend" [colors]="colorsService"></apx-chart>
                            </div>
                            <div
                                style="width: 700px;display: flex;flex-direction: column;justify-content: center;max-height: 200px;flex-flow: wrap;gap: 8rem;">
                                <div>
                                    <div style=" max-width: 287px;gap: 60px;"
                                        class="pie_chart_lengend_label pie_chart_lengend_label_total">
                                        <div class="" style="padding-left: 0;text-align: left;">Total Service Requests
                                        </div>
                                        <div class="">{{serviceCount.total_service_requests}}</div>
                                    </div>
                                    <div style=" max-width: 287px;gap: 60px;"
                                        class="pie_chart_lengend_label pie_chart_lengend_label_data ">
                                        <div>
                                            <div class="active_legend_circle" style="background-color: #7CC3B5;"></div>Renew License
                                        </div>
                                        <div>{{serviceCount['Renew license']}}</div>
                                    </div>
                                    <div style=" max-width: 287px;gap: 60px;"
                                        class="pie_chart_lengend_label pie_chart_lengend_label_data">
                                        <div>
                                            <div class="inactive_legend_circle" style="background-color: #9FC4FB;">
                                            </div>Modify License
                                        </div>
                                        <div>{{serviceCount['Modify license']}}</div>
                                    </div>
                                    <div style=" max-width: 287px;gap: 60px;"
                                        class="pie_chart_lengend_label pie_chart_lengend_label_data">
                                        <div>
                                            <div class="inactive_legend_circle" style="background-color: #F66D44;">
                                            </div>File Annual Returns
                                        </div>
                                        <div>{{serviceCount['File Annual Returns']}}</div>
                                    </div>
                                    <div style=" max-width: 287px;gap: 60px;"
                                        class="pie_chart_lengend_label pie_chart_lengend_label_data">
                                        <div>
                                            <div class="inactive_legend_circle" style="background-color: #FEAE65;">
                                            </div>Health check
                                        </div>
                                        <div>{{serviceCount['Health Check']}}</div>
                                    </div>
                                    <div style=" max-width: 287px;gap: 60px;"
                                        class="pie_chart_lengend_label pie_chart_lengend_label_data">
                                        <div>
                                            <div class="inactive_legend_circle" style="background-color: #E6F69D;">
                                            </div>Upload Test Reports
                                        </div>
                                        <div>{{serviceCount['Upload Test Reports']}}</div>
                                    </div>
                                </div>
                                <div style="padding-top: 24px;">
                                    <div style=" max-width: 287px;gap: 60px;"
                                        class="pie_chart_lengend_label pie_chart_lengend_label_data">
                                        <div>
                                            <div class="inactive_legend_circle" style="background-color: #AADEA7;">
                                            </div>
                                            Product Testing
                                        </div>
                                        <div>{{serviceCount['Product Testing']}}</div>
                                    </div>
                                    <div style=" max-width: 287px;gap: 60px;"
                                        class="pie_chart_lengend_label pie_chart_lengend_label_data">
                                        <div>
                                            <div class="inactive_legend_circle" style="background-color: #64C2A6;">
                                            </div>
                                            Water Testing
                                        </div>
                                        <div>{{serviceCount['Water Testing']}}</div>
                                    </div>
                                    <div style=" max-width: 287px;gap: 60px;"
                                        class="pie_chart_lengend_label pie_chart_lengend_label_data">
                                        <div>
                                            <div class="inactive_legend_circle" style="background-color: #2D87BB;">
                                            </div>
                                            Pest Control
                                        </div>
                                        <div>{{serviceCount['Pest Control']}}</div>
                                    </div>
                                    <div style=" max-width: 287px;gap: 60px;"
                                        class="pie_chart_lengend_label pie_chart_lengend_label_data">
                                        <div>
                                            <div class="inactive_legend_circle" style="background-color: #6AAD41;">
                                            </div>
                                            Equipment Callibration
                                        </div>
                                        <div>{{serviceCount['Equipment Callibration']}}</div>
                                    </div>
                                    <div style=" max-width: 287px;gap: 60px;"
									class="pie_chart_lengend_label pie_chart_lengend_label_data">
									<div>
										<div class="inactive_legend_circle" style="background-color: #6AAD41;">
										</div>
										Training
									</div>
									<div>{{serviceCount['Training']}}</div>
								</div>
								<div style=" max-width: 287px;gap: 60px;"
									class="pie_chart_lengend_label pie_chart_lengend_label_data">
									<div>
										<div class="inactive_legend_circle" style="background-color: #6AAD41;">
										</div>
										Internal Audit
									</div>
									<div>{{serviceCount['Internal Audit']}}</div>
								</div>
								<div style=" max-width: 287px;gap: 60px;"
									class="pie_chart_lengend_label pie_chart_lengend_label_data">
									<div>
										<div class="inactive_legend_circle" style="background-color: #6AAD41;">
										</div>
										Third-party Audits
									</div>
									<div>{{serviceCount['Third-party Audits']}}</div>
								</div>
								<div style=" max-width: 287px;gap: 60px;"
									class="pie_chart_lengend_label pie_chart_lengend_label_data">
									<div>
										<div class="inactive_legend_circle" style="background-color: #6AAD41;">
										</div>
										New license
									</div>
									<div>{{serviceCount['New license']}}</div>
								</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>