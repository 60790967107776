<div>
  <div class="u2_user_management">
    <label>USER MANAGEMENT</label>
    <div class="u2_management_outer_container">
      <!-- <div class="u2_management_inner_container"> -->
      <div class="dash_user_count_main_div">
        <div class="dash_user_count_sub_div_sa accManager" (click)="moveTab('am')" >
          <div class="dash_user_header">
            <img src="../../../assets/img/account_manager-icon.svg" alt="">
            <div>Account Manager</div>
          </div>
          <div class="dash_user_body">
              <div class="dash_user_status_counts">
                <div>
                  Total Account Managers
                </div>
                <div class="dash_users_statusCount">{{accountManagerCount.total_account_manager}}</div>
              </div>
              <div class="dash_user_status_counts">
                <div>
                <img src="../../../assets/img/pajamas_status-green.svg"/>
                Active Users
                </div>
                <div class="dash_users_statusCount">{{accountManagerCount.total_active_account_manager}}</div>
              </div>
              <div class="dash_user_status_counts">
                <div>
                  <img src="../../../assets/img/pajamas_status-red.svg"/>
                  In Active Users              </div>
                <div class="dash_users_statusCount">{{accountManagerCount.total_inactive_account_manager}}</div>
              </div>
              <div class="dash_user_status_counts">
                <div>
                  <img src="../../../assets/img/pajamas_status-red.svg"/>
                  UnAssigned Users              </div>
                <div class="dash_users_statusCount">{{accountManagerCount.total_unassigned_account_manager}}</div>
              </div>
          </div>
        <!-- </div> -->
        </div>
        <div class="dash_user_count_sub_div_sa bussManager" (click)="moveTab('bm')">
          <div class="dash_user_header">
            <img src="../../../assets/img/business_manager-icon.svg" alt="">
            <div>Business Manager</div>
          </div>
          <div class="dash_user_body">
              <div class="dash_user_status_counts">
                <div>
                  Total Business Managers
                </div>
                <div class="dash_users_statusCount">{{businessManagerCount.total_business_manager}}</div>
              </div>
              <div class="dash_user_status_counts">
                <div>
                <img src="../../../assets/img/pajamas_status-green.svg"/>
                Active Users
                </div>
                <div class="dash_users_statusCount">{{businessManagerCount.total_active_business_manager}}</div>
              </div>
              <div class="dash_user_status_counts">
                <div>
                  <img src="../../../assets/img/pajamas_status-red.svg"/>
                  In Active Users              </div>
                <div class="dash_users_statusCount">{{businessManagerCount.total_inactive_business_manager}}</div>
              </div>
              <div class="dash_user_status_counts">
                <div>
                  <img src="../../../assets/img/pajamas_status-red.svg"/>
                  UnAssigned Users              </div>
                <div class="dash_users_statusCount">{{businessManagerCount.total_unassigned_business_manager}}</div>
              </div>
          </div>
        </div>
        <div class="dash_user_count_sub_div_sa enterUser" (click)="moveTab('webUser')">
          <div class="dash_user_header">
            <img src="../../../assets/img/enterpriser-icon.svg" alt="">
            <div>Enterprise User</div>
          </div>
          <div class="dash_user_body">
              <div class="dash_user_status_counts">
                <div>
                  Total Enterprise Users
                </div>
                <div class="dash_users_statusCount">{{enterpriserCount.total_enterprise_user}}</div>
              </div>
              <div class="dash_user_status_counts">
                <div>
                <img src="../../../assets/img/pajamas_status-green.svg"/>
                Active Users
                </div>
                <div class="dash_users_statusCount">{{enterpriserCount.total_active_enterprise_user}}</div>
              </div>
              <div class="dash_user_status_counts">
                <div>
                  <img src="../../../assets/img/pajamas_status-red.svg"/>
                  In Active Users              </div>
                <div class="dash_users_statusCount">{{enterpriserCount.total_inactive_enterprise_user}}</div>
              </div>
              <div class="dash_user_status_counts">
                <div>
                  <img src="../../../assets/img/pajamas_status-red.svg"/>
                  UnAssigned Users           
                 </div>
                <div class="dash_users_statusCount">{{enterpriserCount.total_unassigned_enterprise_user}}</div>
              </div>
          </div>
        </div>
        <div class="dash_user_count_sub_div_sa retailUser" (click)="moveTab('webUser','Retail')">
          <div class="dash_user_header">
            <img src="../../../assets/img/retail_user-icon.svg" alt="">
            <div>Retail User</div>
          </div>
          <div class="dash_user_body">
              <div class="dash_user_status_counts">
                <div>
                  Total Retail Users
                </div>
                <div class="dash_users_statusCount">{{retailUserCount.total_retail_user}}</div>
              </div>
              <div class="dash_user_status_counts">
                <div>
                <img src="../../../assets/img/pajamas_status-green.svg"/>
                Active Users
                </div>
                <div class="dash_users_statusCount">{{retailUserCount.total_active_retail_user}}</div>
              </div>
              <div class="dash_user_status_counts">
                <div>
                  <img src="../../../assets/img/pajamas_status-red.svg"/>
                  In Active Users              </div>
                <div class="dash_users_statusCount">{{retailUserCount.total_inactive_retail_user}}</div>
              </div>
              <div class="dash_user_status_counts">
                <div>
                  <img src="../../../assets/img/pajamas_status-red.svg"/>
                  UnAssigned Users              </div>
                <div class="dash_users_statusCount">{{retailUserCount.total_unassigned_retail_user}}</div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>



    <!-- ---------------------------------Super Admin License --------------------------------------------------------- -->
    <div class="dash_user_end_div">&nbsp;</div>
    <div class="dash_main_header_sa">LICENSE DETAILS</div>
    <div class="dash_main_header_sa">ENTERPRISE</div>
    <div class="dash_pie_main_div_sa" >
      <div class="dash_user_count_main_div" style=" grid-template-columns: 1fr 1fr 1fr;" >
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Enterprise','')">
            <div>
                <img src="../../../assets/img/total_licenses-icon.svg" alt="">
            </div>
            <div class="dash_user_count_div">
                <div>Total Licenses</div>
                <div>{{allLicenseCount.enterprise_user_data?.total_licenses}}</div>
            </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Enterprise','Active')">
            <div>
                <img src="../../../assets/img/actice_license.svg" alt="">
            </div>
            <div class="dash_user_count_div">
                <div>Active Licenses</div>
                <div>{{allLicenseCount.enterprise_user_data?.active_licenses}}</div>
            </div>
        </div>
        <!-- <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Enterprise','Expired')">
            <div>
                <img src="../../../assets/img/expired_license.svg" alt="">
            </div>
            <div class="dash_user_count_div">
                <div>Expired Licenses</div>
                <div>{{allLicenseCount.enterprise_user_data?.expired_licenses}}</div>
            </div>
        </div> -->
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Enterprise','Requested')">
            <div>
                <img src="../../../assets/img/requested_license.svg" alt="">
            </div>
            <div class="dash_user_count_div">
                <div>Requested Licenses</div>
                <div>{{allLicenseCount.enterprise_user_data?.requested_licenses}}</div>
            </div>
        </div>
      </div>
      <div class="dash_user_count_main_div" style=" grid-template-columns: 1fr 1fr 1fr 1fr; margin-top: 1rem;" >
          <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Enterprise','90')">
            <div>
                <img src="../../../assets/img/expired_license.svg" alt="">
            </div>
            <div class="dash_user_count_div">
                <div>Expiring in 90 Days</div>
                <div>{{allLicenseCount.enterprise_user_data?.expiring_in_less_than_90_days}}</div>
            </div>
        </div>
          <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Enterprise','60')">
            <div>
                <img src="../../../assets/img/expired_license.svg" alt="">
            </div>
            <div class="dash_user_count_div">
                <div>Expiring in 60 Days</div>
                <div>{{allLicenseCount.enterprise_user_data?.expiring_in_less_than_60_days}}</div>
            </div>
        </div>
          <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Enterprise','30')">
            <div>
                <img src="../../../assets/img/expired_license.svg" alt="">
            </div>
            <div class="dash_user_count_div">
                <div>Expiring in 30 Days</div>
                <div>{{allLicenseCount.enterprise_user_data?.expiring_in_less_than_30_days}}</div>
            </div>
        </div>
          <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Enterprise','Expired')">
            <div>
                <img src="../../../assets/img/expired_license.svg" alt="">
            </div>
            <div class="dash_user_count_div">
                <div>Expired Licenses</div>
                <div>{{allLicenseCount.enterprise_user_data?.expired_licenses}}</div>
            </div>
        </div>
      </div>
      <div class="dash_main_header_sa">RETAIL</div>
      <div class="dash_user_count_main_div" style=" grid-template-columns: 1fr 1fr 1fr;" >
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Retail','')">
            <div>
                <img src="../../../assets/img/total_licenses-icon.svg" alt="">
            </div>
            <div class="dash_user_count_div">
                <div>Total Licenses</div>
                <div>{{allLicenseCount.retail_user_data?.total_licenses}}</div>
            </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Retail','Active')">
            <div>
                <img src="../../../assets/img/actice_license.svg" alt="">
            </div>
            <div class="dash_user_count_div">
                <div>Active Licenses</div>
                <div>{{allLicenseCount.retail_user_data?.active_licenses}}</div>
            </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Retail','Requested')">
            <div>
                <img src="../../../assets/img/requested_license.svg" alt="">
            </div>
            <div class="dash_user_count_div">
                <div>Requested Licenses</div>
                <div>{{allLicenseCount.retail_user_data?.requested_licenses}}</div>
            </div>
        </div>
      </div>
      <div class="dash_user_count_main_div" style=" grid-template-columns: 1fr 1fr 1fr 1fr;margin-top: 1rem;" >
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Retail','90')">
          <div>
              <img src="../../../assets/img/expired_license.svg" alt="">
          </div>
          <div class="dash_user_count_div">
              <div>Expiring in 90 Days</div>
              <div>{{allLicenseCount.retail_user_data?.expiring_in_less_than_90_days}}</div>
          </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Retail','60')">
          <div>
              <img src="../../../assets/img/expired_license.svg" alt="">
          </div>
          <div class="dash_user_count_div">
              <div>Expiring in 60 Days</div>
              <div>{{allLicenseCount.retail_user_data?.expiring_in_less_than_60_days}}</div>
          </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Retail','30')">
        <div>
            <img src="../../../assets/img/expired_license.svg" alt="">
        </div>
        <div class="dash_user_count_div">
            <div>Expiring in 30 Days</div>
            <div>{{allLicenseCount.retail_user_data?.expiring_in_less_than_30_days}}</div>
        </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Retail','Expired')">
          <div>
              <img src="../../../assets/img/expired_license.svg" alt="">
          </div>
          <div class="dash_user_count_div">
              <div>Expired Licenses</div>
              <div>{{allLicenseCount.retail_user_data?.expired_licenses}}</div>
          </div>
        </div>
      </div>
      <!-- TOTAL BM PIE CHART -->
      <div class="dash_pie_sub_div">
        <div class="dash_pie_header">
          Total Licenses
        </div>
        <div class="dash_pie_charts">
          <div class="dash_pie_wraper_div">
            <div >
              <apx-chart #chart [series]="totalLicenseData" [chart]="pieCharts" [labels]="labelLicense" [dataLabels]="dataLabels"
                [legend]="pieLegend" [colors]="totalLicensecolors"></apx-chart>
            </div>
            <div class="dash_piecharts_label">
              <div class="pie_chart_lengend_label pie_chart_lengend_label_total" >
                <div class="" style="padding: 0;">Total Licenses</div>
                <div class="">{{allLicenseCount.total_licenses}}</div>
              </div>
              <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div><div class="active_legend_circle"></div>Active Licenses</div>
                <div>{{allLicenseCount.active_licenses}}</div>
              </div>
              <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div><div class="inactive_legend_circle"></div>Expired Licenses</div>
                <div>{{allLicenseCount.expired_licenses}}</div>
              </div>
            </div>
          </div>
          <div class="dash_pie_wraper_div">
            <div >
              <apx-chart #chart [series]="expiringLicenseData" [chart]="pieCharts" [labels]="labelExpLicense" [dataLabels]="dataLabels"
                [legend]="pieLegend" [colors]="expLicenseColors"></apx-chart>
            </div>
            <div class="dash_piecharts_label">
              <div class="pie_chart_lengend_label pie_chart_lengend_label_total" >
                <div class="" style="padding: 0;">Expiring Licenses</div>
              </div>
              <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div><div class="expLicense_legend_circle expLicense_30days"></div>Expiring in less than 30 days</div>
                <div>{{expiringLicenseCount.expiring_in_less_than_30_days}}</div>
              </div>
              <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div><div class="expLicense_legend_circle expLicense_60days"></div>Expiring in 60 days</div>
                <div>{{expiringLicenseCount.expiring_in_less_than_60_days}}</div>
              </div>
              <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div><div class="expLicense_legend_circle expLicense_90days"></div>Expiring in 90 days</div>
                <div>{{expiringLicenseCount.expiring_in_less_than_90_days}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="dash_bar_wrapper_div">
          <div style="display: flex;justify-content: center;">
            <div class="chart_y_axis_header">No. Licenses</div>
            <div class="dash_chart_main_div">
              <div><img src="../../../assets/img/line_verical.svg" alt=""></div>
              <div id="chart_div" class="dash_chart_sub_div">
                <apx-chart #chart [series]="licenseRequestData" [chart]="barCharts"  [xaxis]="xaxisLebels" [dataLabels]="dataLabels"
                  [plotOptions]="plotOptions" [legend]="legend"></apx-chart>
                <img src="../../../assets/img/line_horizontal.svg" alt="" class="horizontal_line">
                <div class="chart_x_axis_header">Days</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dash_user_end_div">&nbsp;</div>
    
    <!-- --------------------------------- Services BAR CHART--------------------------------------------------------- -->

    <div>
      <div>
        <div class="dash_sub_heading">SERVICE REQUESTS</div>
        <div class="dash_main_header_sa">ENTERPRISE</div>
        <div class="dash_user_count_main_div" style=" grid-template-columns: 1fr 1fr;"  (click)="moveTab('service','Enterprise')">
          <div class="dash_user_count_sub_div u3_license_count">
              <div>
                  <img src="../../../assets/img/total_service_requests.svg" alt="">
              </div>
              <div class="dash_user_count_div">
                  <div>Total Service Requests</div>
                  <div>{{serviceRequestCount.eu_total_service_requests}}</div>
              </div>
          </div>
          <div class="dash_user_count_sub_div u3_license_count">
              <div>
                  <img src="../../../assets/img/pending_service_requests.svg" alt="">
              </div>
              <div class="dash_user_count_div">
                  <div>Pending Service Requests</div>
                  <div>{{serviceRequestCount.eu_pending_service_requests}}</div>
              </div>
          </div>
        </div>
        <div class="dash_main_header_sa">RETAIL</div>
        <div class="dash_user_count_main_div" style=" grid-template-columns: 1fr 1fr;"  (click)="moveTab('service','Retail')">
          <div class="dash_user_count_sub_div u3_license_count">
              <div>
                  <img src="../../../assets/img/total_service_requests.svg" alt="">
              </div>
              <div class="dash_user_count_div">
                  <div>Total Service Requests</div>
                  <div>{{serviceRequestCount.ru_total_service_requests}}</div>
              </div>
          </div>
          <div class="dash_user_count_sub_div u3_license_count">
              <div>
                  <img src="../../../assets/img/pending_service_requests.svg" alt="">
              </div>
              <div class="dash_user_count_div">
                  <div>Pending Service Requests</div>
                  <div>{{serviceRequestCount.ru_pending_service_requests}}</div>
              </div>
          </div>
        </div>
        <div class="dash_sub_box_main_div">
          <div class="dash_header_main_div">
            <div class="box_main_heading">Total Service Requests</div>
            <div class="dateFilter">
              <div class="dash_filter_main_div">
                <div>Month</div>
                <div>
                  <select name="monthName" id=""[(ngModel)]="selectedServiceMonth"
                  (change)="serviceRequestMonthHander($event)">
                    <option value="Jan">Jan</option>
                    <option value="Feb">Feb</option>
                    <option value="Mar">Mar</option>
                    <option value="Apr">Apr</option>
                    <option value="May">May</option>
                    <option value="Jun">Jun</option>
                    <option value="Jul">Jul</option>
                    <option value="Aug">Aug</option>
                    <option value="Sep">Sep</option>
                    <option value="Oct">Oct</option>
                    <option value="Nov">Nov</option>
                    <option value="Dec">Dec</option>
                  </select>
                </div>
              </div>
              <div class="dash_filter_main_div">
                <div>Year</div>
                <div>
                  <select name="monthName" id=""[(ngModel)]="currentYear"
                  (change)="serviceRequestMonthHander($event)">
                    <option *ngFor="let year of yearDropDown" [value]="year">{{year}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="dash_bar_wrapper_div">
            <div style="display: flex;justify-content: center;">
              <div class="chart_y_axis_header">No. Services</div>
              <div class="dash_chart_main_div">
                <div><img src="../../../assets/img/line_verical.svg" alt=""></div>
                <div id="chart_div" class="dash_chart_sub_div">
                  <apx-chart #chart [series]="serviceRequestData" [chart]="barCharts" [xaxis]="xaxis"
                    [dataLabels]="dataLabels" [plotOptions]="plotOptions" [legend]="legend"></apx-chart>

                  <img src="../../../assets/img/line_horizontal.svg" alt="" class="horizontal_line">
                  <div class="chart_x_axis_header">Weeks</div>

                </div>
              </div>
            </div>
          </div>

          <div class="dash_pie_sub_div">
            <div class="dash_pie_wraper_div">
              <div >
                <apx-chart #chart [series]="totalServiceRequestData" [chart]="pieCharts" [labels]="labelServiceRequest" [dataLabels]="dataLabels"
                  [legend]="pieLegend" [colors]="serviceRequestColors"></apx-chart>
              </div>
              <div style="display: flex;">
                <div>
                <div class="pie_chart_lengend_label pie_chart_lengend_label_total" >
                  <div class="" style="padding: 0;">Total Service Requests</div>
                  <div style="margin-left: 20px;" class="">{{serviceRequestCount.total_service_requests}}</div>
                </div>
                <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                  <div><div class="legend_circle renew_license"></div>Renew Licences</div>
                  <div>{{serviceRequestCount['Renew license']}}</div>
                </div>
                <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                  <div><div class="legend_circle modify_license"></div>Modify Licences</div>
                  <div>{{serviceRequestCount['Modify license']}}</div>
                </div>
                <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                  <div><div class="legend_circle file_annual_returns"></div>File Annual Returns</div>
                  <div>{{serviceRequestCount['File Annual Returns']}}</div>
                </div>
                <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                  <div><div class="legend_circle product_testing"></div>Product Testing</div>
                  <div>{{serviceRequestCount['Product Testing']}}</div>
                </div>
               
                <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                  <div><div class="legend_circle water_testing"></div>Water Testing</div>
                  <div>{{serviceRequestCount['Water Testing']}}</div>
                </div>
                <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                  <div><div class="legend_circle pest_testing"></div>Pest Control</div>
                  <div>{{serviceRequestCount['Pest Control']}}</div>
                </div>
                <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                  <div><div class="legend_circle health_check"></div>Health Check</div>
                  <div>{{serviceRequestCount['Health Check']}}</div>
                </div>
              </div>
              <div style="padding-left: 40px; margin-top: 30px;">
                <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                  <div><div class="legend_circle equipment_callibration"></div>Equipment Callibration</div>
                  <div>{{serviceRequestCount['Equipment Callibration']}}</div>
                </div>
                <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                  <div><div class="legend_circle upload_test_reports"></div>Upload Test Reports</div>
                  <div>{{serviceRequestCount['Upload Test Reports']}}</div>
                </div>
                <div 
                class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div>
                  <div class="inactive_legend_circle" style="background-color: #6AAD41;">
                  </div>
                  Training
                </div>
                <div>{{serviceRequestCount['Training']}}</div>
              </div>
              <div 
                class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div>
                  <div class="inactive_legend_circle" style="background-color: #6AAD41;">
                  </div>
                  Internal Audit
                </div>
                <div>{{serviceRequestCount['Internal Audit']}}</div>
              </div>
              <div 
                class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div>
                  <div class="inactive_legend_circle" style="background-color: #6AAD41;">
                  </div>
                  Third-party Audits
                </div>
                <div>{{serviceRequestCount['Third-party Audits']}}</div>
              </div>
              <div 
                class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div>
                  <div class="inactive_legend_circle" style="background-color: #6AAD41;">
                  </div>
                  New license
                </div>
                <div>{{serviceRequestCount['New license']}}</div>
              </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>