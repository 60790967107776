<div class="side_bar_common_css">
    <app-sidenav-bar></app-sidenav-bar>
    <div style="width: 100%;">
        <div class="notificationBar">
            <img *ngIf="roleId == 1" src = "../../../assets/img/setting.png" (click)="navigate('/settings')" style="width:35px; border-radius: 25px;cursor: pointer;" alt="" srcset="" class="side_nav_bottom_dp">
            <img *ngIf="!isNewNotifications" (click)="toggleNotif()" src="../../../assets/img/NotifiationBell.svg"/>
            <img *ngIf="isNewNotifications" (click)="toggleNotif()" src="../../../assets/img/notificationIcon.svg"/>
            <div class="shwoNotififications" *ngIf="showNotif">
              <div class="d-flex justify-content-end">
                <img style="width: 20px;" (click)="toggleNotif()" src="../../../assets/img/carbon_close-filled.svg"/>
              </div>
              <div *ngIf="notifications.length == 0">
                <div class="notificationHeader">
                  No Notifications Found
                </div>
              </div>
              <div  *ngFor="let notif of notifications" class="notif" (click)="readNotifications(notif)">
                <div class="notificationHeader">
                  {{notif.type.toUpperCase().split('_').join(' ')}}
                </div>
                <div class="notificationContent">
                  {{notif.remarks}}
                </div>
              </div>
            </div>
            <button mat-button [matMenuTriggerFor]="menu" style="padding: 0;">
                <img [src] = "photo" style="width:35px; border-radius: 25px;" alt="" srcset="" class="side_nav_bottom_dp">
              </button>
              <mat-menu #menu="matMenu">
                <div style="color:black;padding:10px;font-weight:700;border-bottom:1px solid #A3C17E;width:100%;max-width:10rem;margin-right: 1rem;">{{username.toUpperCase()}}</div>
                <button mat-menu-item (click)="navigate('/profile')"> <img style="width:25px;margin-right: 10px;" [src]="tPhoto" alt="" > Profile</button>
                <button mat-menu-item (click)="navigate('/login')">  <img style="width:25px;margin-right: 10px;" src="../../../assets/img/logout.svg" alt="" > Logout</button>
              </mat-menu>
        </div>
    <div class="service_main_div" style="padding: 1rem;background-color:#EFEFEF" *ngIf="roleId!=3 && roleId!=1 && roleId!=2;">
        <div class="header">SERVICE REQUSETS</div>
        <div class="service_content_main_div">
            <div>
                <div class="service_search_main_div">
                    <div class="service_search_sub_div">
                        <div class="search_icon_inp_div">
                            <img src="../../../assets/img/search_icon.svg" alt="" class="search_icon_inp_icon">
                            <input type="text" class="search_icon_inp" placeholder="Search"
                                (keyup)="applyFilter($event)" autocomplete="off">
                        </div>
                        <div class="dropdown">
                            <button class="btn filter_btn" type="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <img src="../../../assets/img/../img/filter_icon.svg" alt="">Filters
                            </button>
                            <ul class="dropdown-menu">
                                <li class="dropdown-item" style="color: #1968B1;"
                                    [ngClass]="selectedFilter.all?'filterActiveCss':''" (click)="filterByStatus('All')">
                                    All</li>
                                <li class="dropdown-item" style="color: #1968B1;"
                                    [ngClass]="selectedFilter.Submitted?'filterActiveCss':''"
                                    (click)="filterByStatus('Submitted')">
                                    Submitted</li>
                                <li class="dropdown-item" style="color: #1968B1;"
                                    [ngClass]="selectedFilter.Approved?'filterActiveCss':''"
                                    (click)="filterByStatus('Approved')">
                                    Approved</li>
                                <li class="dropdown-item" style="color: #1968B1;"
                                    [ngClass]="selectedFilter.Accepted?'filterActiveCss':''"
                                    (click)="filterByStatus('Accepted')">
                                    Accepted</li>
                                <li class="dropdown-item" style="color: #1968B1;"
                                    [ngClass]="selectedFilter.Pending_Payment?'filterActiveCss':''"
                                    (click)="filterByStatus('Pending_Payment')">
                                    Pending Payment</li>
                                <li class="dropdown-item" style="color: #1968B1;"
                                    [ngClass]="selectedFilter.In_Progress?'filterActiveCss':''"
                                    (click)="filterByStatus('In_Progress')">
                                    In Progress</li>
                                <li class="dropdown-item" style="color: #1968B1;"
                                    [ngClass]="selectedFilter.Pending_Documentation?'filterActiveCss':''"
                                    (click)="filterByStatus('Pending_Documentation')">
                                    Pending Documentation</li>
                                <li class="dropdown-item" style="color: #1968B1;"
                                    [ngClass]="selectedFilter.Completed?'filterActiveCss':''"
                                    (click)="filterByStatus('Completed')">
                                    Completed</li>
                                <li class="dropdown-item" style="color: #1968B1;"
                                    [ngClass]="selectedFilter.Rejected?'filterActiveCss':''"
                                    (click)="filterByStatus('Rejected')">
                                    Rejected</li>
                                <li class="dropdown-item" style="color: #1968B1;"
                                    [ngClass]="selectedFilter.Cancelled?'filterActiveCss':''"
                                    (click)="filterByStatus('Cancelled')">
                                    Cancelled</li>
                            </ul>
                        </div>
                    </div>
                    <div class="service_table_main_div">
                        <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8"
                            style="width: 100%; position: relative;">
    
                            <!-- Sl NO -->
                            <ng-container matColumnDef="sl_no">
                                <th class="" mat-header-cell *matHeaderCellDef> ID. </th>
                                <td class="" mat-cell *matCellDef="let element; let i = index"  (click)="modifyAppUser(element.id)" style="color: #7893ec;cursor: pointer;"> {{element.id}} </td>
                            </ng-container>
                            <!-- Request Name  -->
                            <ng-container matColumnDef="services">
                                <!-- <th class="common_table_data" mat-header-cell *matHeaderCellDef> Service Request Name</th> -->
                                <th class="common_table_data"  mat-header-cell *matHeaderCellDef>
                                    <div style="display: flex;flex-direction: column;height: 5rem;justify-content: space-between;padding:10px 0;min-width: 10rem;" ><p mat-sort-header>Service Request Name</p>
                                        <div class="dropdown">
                                            <button class="btn btnn  multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img *ngIf="!EUNameFilter" src="../../../assets/img/../img/Group.svg" alt="" >{{EUNameFilter?EUNameFilter:'All'}}
                                                <img  style="width:20px;" *ngIf="EUNameFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt="" (click)="EuApplyFilter('','name',1)">
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li class="dropdown-item" style="color: #5F6370;"
                                                    [ngClass]="selectedFilter.all?'':''" *ngFor="let status of EUNameDropdown" (click)="EuApplyFilter(status,'name',0)">
                                                    {{status}}</li>
                                            </ul>
                                        </div>
                                    </div>    
                                </th>
                                <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.services}} </td>
                            </ng-container>
                            <!-- License Number  -->
                            <ng-container matColumnDef="fssai_regd_no">
                                <!-- <th class="common_table_data" mat-header-cell *matHeaderCellDef>License Number</th> -->
                                <th class="common_table_data"  mat-header-cell *matHeaderCellDef>
                                    <div style="display: flex;flex-direction: column;height: 5rem;justify-content: space-between;padding:10px 0;" ><p mat-sort-header>License Number</p>
                                        <div class="dropdown">
                                            <button class="btn btnn  multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img *ngIf="!EULicenseFilter" src="../../../assets/img/../img/Group.svg" alt="" >{{EULicenseFilter?EULicenseFilter:'All'}}
                                                <img  style="width:20px;" *ngIf="EULicenseFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt="" (click)="EuApplyFilter('','license',1)">
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li class="dropdown-item" style="color: #5F6370;"
                                                    [ngClass]="selectedFilter.all?'':''" *ngFor="let status of EULicenseDropdown" (click)="EuApplyFilter(status,'license',0)">
                                                    {{status}}</li>
                                            </ul>
                                        </div>
                                    </div>    
                                </th>  
                                <td class="common_table_data" mat-cell *matCellDef="let element" (click)="openLicense(element.fssai_regd_no,'enterprise')" style="color: #7893ec;cursor: pointer;"> {{element.fssai_regd_no}} </td>
                            </ng-container>
                           
                            <ng-container matColumnDef="am">
                                <th class="common_table_data"  mat-header-cell *matHeaderCellDef>
                                    <div style="display: flex;flex-direction: column;height: 5rem;justify-content: space-between;padding:10px 0;" ><p mat-sort-header>Account Manager</p>
                                        <div class="dropdown">
                                            <button class="btn btnn  multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img *ngIf="!EUamFilter" src="../../../assets/img/../img/Group.svg" alt="" >{{EUamFilter?EUamFilter:'All'}}
                                                <img  style="width:20px;" *ngIf="EUamFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt="" (click)="EuApplyFilter('','am',1)">
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li class="dropdown-item" style="color: #5F6370;"
                                                    [ngClass]="selectedFilter.all?'':''" *ngFor="let status of EUamDropdown" (click)="EuApplyFilter(status,'am',0)">
                                                    {{status}}</li>
                                            </ul>
                                        </div>
                                    </div>    
                                </th>                    
                             <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.account_manager}} </td>
                            </ng-container>
                            <!-- Company name  -->
                            <ng-container matColumnDef="business_name">
                                <!-- <th class="common_table_data" mat-header-cell *matHeaderCellDef> Company name</th> -->
                                <th class="common_table_data"  mat-header-cell *matHeaderCellDef>
                                    <div style="display: flex;flex-direction: column;height: 5rem;justify-content: space-between;padding:10px 0;" ><p mat-sort-header>Company Name</p>
                                        <div class="dropdown">
                                            <button class="btn btnn  multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img *ngIf="!EUCompanyFilter" src="../../../assets/img/../img/Group.svg" alt="" >{{EUCompanyFilter?EUCompanyFilter:'All'}}
                                                <img  style="width:20px;" *ngIf="EUCompanyFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt="" (click)="EuApplyFilter('','company',1)">
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li class="dropdown-item" style="color: #5F6370;"
                                                    [ngClass]="selectedFilter.all?'':''" *ngFor="let status of EUCompanyDropdown" (click)="EuApplyFilter(status,'company',0)">
                                                    {{status}}</li>
                                            </ul>
                                        </div>
                                    </div>    
                                </th> 
                                <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.business_name}} </td>
                            </ng-container>
    
                            <!-- Address -->
                            <ng-container matColumnDef="state">
                                <!-- <th class="common_table_data" mat-header-cell *matHeaderCellDef> State -->
                                <!-- </th> -->
                                <th class="common_table_data"  mat-header-cell *matHeaderCellDef>
                                    <div style="display: flex;flex-direction: column;height: 5rem;justify-content: space-between;padding:10px 0;" ><p mat-sort-header>State</p>
                                        <div class="dropdown">
                                            <button class="btn btnn  multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img *ngIf="!EUAddressFilter" src="../../../assets/img/../img/Group.svg" alt="" >{{EUAddressFilter?EUAddressFilter:'All'}}
                                                <img  style="width:20px;" *ngIf="EUAddressFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt="" (click)="EuApplyFilter('','address',1)">
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li class="dropdown-item" style="color: #5F6370;"
                                                    [ngClass]="selectedFilter.all?'':''" *ngFor="let status of EUAddressDropdown" (click)="EuApplyFilter(status,'address',0)">
                                                    {{status}}</li>
                                            </ul>
                                        </div>
                                    </div>    
                                </th> 
                                <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.state}} </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="date">
                            <!-- <th class="common_table_data" mat-header-cell *matHeaderCellDef> Date</th> -->
                            <th class="common_table_data"  mat-header-cell *matHeaderCellDef>
                                <div style="display: flex;flex-direction: column;height: 5rem;justify-content: space-between;padding:10px 0;min-width: 5rem;" ><p mat-sort-header>Created Date</p>
                                    <div class="dropdown">
                                        <button class="btn btnn  multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img *ngIf="!EUDateFilter" src="../../../assets/img/../img/Group.svg" alt="" >{{EUDateFilter?EUDateFilter:'All'}}
                                            <img  style="width:20px;" *ngIf="EUDateFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt="" (click)="EuApplyFilter('','date',1)">
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li class="dropdown-item" style="color: #5F6370;"
                                                [ngClass]="selectedFilter.all?'':''" *ngFor="let status of EUDateDropdown" (click)="EuApplyFilter(status,'date',0)">
                                                {{status}}</li>
                                        </ul>
                                    </div>
                                </div>    
                            </th> 
                            <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.date}} </td>
                        </ng-container>
                            <!-- Status -->
                            <ng-container matColumnDef="status">
                                <!-- <th class="common_table_data" mat-header-cell *matHeaderCellDef>Status</th> -->
                                <th class="common_table_data"  mat-header-cell *matHeaderCellDef>
                                    <div style="display: flex;flex-direction: column;height: 5rem;justify-content: space-between;padding:10px 0;min-width: 5rem;" ><p mat-sort-header>Status</p>
                                        <div class="dropdown">
                                            <button class="btn btnn  multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img *ngIf="!EUStatusFilter" src="../../../assets/img/../img/Group.svg" alt="" >{{EUStatusFilter?EUStatusFilter:'All'}}
                                                <img  style="width:20px;" *ngIf="EUStatusFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt="" (click)="EuApplyFilter('','status',1)">
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li class="dropdown-item" style="color: #5F6370;"
                                                    [ngClass]="selectedFilter.all?'':''" *ngFor="let status of EUStatusDropdown" (click)="EuApplyFilter(status,'status',0)">
                                                    {{status}}</li>
                                            </ul>
                                        </div>
                                    </div>    
                                </th> 
                                <td class="common_table_data" mat-cell *matCellDef="let element"><span
                                        [ngClass]="element.status == 'Active'?'colorGreen':element.status == 'Expired'?'colorRed':'colorBlue'">
                                        {{element.status}}</span> </td>
                            </ng-container>


                            <!-- Progress -->
                            <!-- <ng-container matColumnDef="progress">
                                <th class="common_table_data" mat-header-cell *matHeaderCellDef> Progress</th>
                                <td class="common_table_data" mat-cell *matCellDef="let element">
                                    Null
                                </td>
                            </ng-container> -->

                            
                            <!-- Modify -->
                            <ng-container matColumnDef="modify">
                                <th class="common_table_data" mat-header-cell *matHeaderCellDef> Modify</th>
                                <td class="common_table_data" mat-cell *matCellDef="let element">
                                    <img src="../../../assets/img/edit_icon.svg" alt="" (click)="modifyAppUser(element.id)" style="cursor: pointer;" >
                                    <!-- <img src="../../../assets/img/tabler_dots.svg" alt="" style="cursor: pointer;"
                                        (click)="modifyAppUser(element.id)"> -->
                                </td>
                            </ng-container>
    
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100%;height:100%;padding:1rem;background-color: #EFEFEF;">
        <app-u2-service-request *ngIf="roleId==3" style="width: 100%;"></app-u2-service-request>
        <app-u0-service-request *ngIf="roleId==1" style="width: 100%;"></app-u0-service-request>
        <app-u1-service-request *ngIf="roleId==2" style="width: 100%;"></app-u1-service-request>
    </div>
    </div>
</div>