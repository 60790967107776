import { map } from 'rxjs';
import { Component, Inject, OnInit,ViewChild,ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/api/http.service';
import { DataService } from 'src/app/services/data-share/data.service';
import {MatSort} from '@angular/material/sort';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import * as  download from 'downloadjs';


import { AES, enc } from 'crypto-js';
import { NgSelectConfig } from '@ng-select/ng-select';
import { DialogDeleteCofirm } from '../common_dialog/delete-confirm/delete_confirm_dialog';
import { Router } from '@angular/router';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { DialogEnterpriseModify, DialogRetailModify } from '../web-user/web-user.component';
import { DialogServiceRequestModify, DialogueAddRemark, DialogueAuditReport } from '../service-request/service-request.component';
import { NotificationsComponent } from '../common_dialog/notifications/notifications.component';







const ELEMENT_DATA: any[] = [
  {
    position: 1,
    name: "Hydrogen",
    weight: 1.0079,
    symbol: "H",
    status: "Expired",
    documents:"tester.aaa"
  },
  {
    position: 1,
    name: "Hydrogen",
    weight: 1.0079,
    symbol: "H",
    status: "Expired",
    documents:"tester.aaa"
  },
  {
    position: 1,
    name: "Hydrogen",
    weight: 1.0079,
    symbol: "H",
    status: "Expired",
    documents:"tester.aaa"
  },
  {
    position: 1,
    name: "Hydrogen",
    weight: 1.0079,
    symbol: "H",
    status: "Expired",
    documents:"tester.aaa"
  },
  {
    position: 1,
    name: "Hydrogen",
    weight: 1.0079,
    symbol: "H",
    status: "Expired",
    documents:"tester.aaa"
  },
  {
    position: 1,
    name: "Hydrogen",
    weight: 1.0079,
    symbol: "H",
    status: "Expired",
    documents:"tester.aaa"
  },
  {
    position: 1,
    name: "Hydrogen",
    weight: 1.0079,
    symbol: "H",
    status: "Expired",
    documents:"tester.aaa"
  },
  {
    position: 1,
    name: "Hydrogen",
    weight: 1.0079,
    symbol: "H",
    status: "Expired",
    documents:"tester.aaa"
  },
  {
    position: 2,
    name: "Helium",
    weight: 4.0026,
    symbol: "He",
    status: "Active",
    documents:"tester.aaa"
  },
  {
    position: 3,
    name: "Lithium",
    weight: 6.941,
    symbol: "Li",
    status: "Active"
  },
  {
    position: 4,
    name: "Beryllium",
    weight: 9.0122,
    symbol: "Be",
    status: "Active"
  },
  {
    position: 5,
    name: "Boron",
    weight: 10.811,
    symbol: "B",
    status: "Active"
  },
  {
    position: 6,
    name: "Carbon",
    weight: 12.0107,
    symbol: "C",
    status: "Expired"
  },
  {
    position: 7,
    name: "Nitrogen",
    weight: 14.0067,
    symbol: "N",
    status: "Expired"
  },
  {
    position: 8,
    name: "Oxygen",
    weight: 15.9994,
    symbol: "O",
    status: "Active"
  },
  {
    position: 9,
    name: "Fluorine",
    weight: 18.9984,
    symbol: "F",
    status: "Expired"
  },
  {
    position: 10,
    name: "Neon",
    weight: 20.1797,
    symbol: "Ne",
    status: "Active"
  },
  {
    position: 1,
    name: "Hydrogen",
    weight: 1.0079,
    symbol: "H",
    status: "Expired"
  },
  {
    position: 2,
    name: "Helium",
    weight: 4.0026,
    symbol: "He",
    status: "Active"
  },
  {
    position: 3,
    name: "Lithium",
    weight: 6.941,
    symbol: "Li",
    status: "Active"
  },
  {
    position: 4,
    name: "Beryllium",
    weight: 9.0122,
    symbol: "Be",
    status: "Expired"
  },
  {
    position: 5,
    name: "Boron",
    weight: 10.811,
    symbol: "B",
    status: "Active"
  },
  {
    position: 6,
    name: "Carbon",
    weight: 12.0107,
    symbol: "C",
    status: "Expired"
  }
];
const emailRegex =new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, "gm");



@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.css']
})
export class LicensesComponent implements OnInit {
  constructor(
    private dataShare: DataService,
    private http:HttpService,
    private toast:ToastrService,
    private dialog :MatDialog,
    private router :Router,
  ) { }

  role: any
  notifications:any=[]
  isNewNotifications:boolean = false
  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      const roleId = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
      this.role = roleId
      console.log(this.role, roleId)
    }
    this.getNotifications()
    setTimeout(async ()=>{
      this.photo = await localStorage.getItem('photo')
      this.username = await localStorage.getItem('username')
    },0)
  }
  username:any = 'User'
  photo: any = '../../../assets/img/default_dp.png'
  tPhoto: any = '../../../assets/img/default_dp.png'
  navigate(route: string) {
    if (this.router.url == route) return
    else this.router.navigate([route])
  }
  getNotifications(){
    this.http.getNotifications(37).subscribe((res:any)=>{
      if(res['status']){
        this.notifications = res['result']
        const newNotf = this.notifications.filter((data:any) =>  data.is_read === false)
        console.log(this.notifications,newNotf)
        if(newNotf.length > 0)
          this.isNewNotifications = true
        else
          this.isNewNotifications = false
      }
      else{
        this.notifications = []
        this.isNewNotifications = false
      }
    },(err:any)=>{
      this.toast.error("Oops!, Somrthing went wrong Please try again",'',{timeOut:2000})
    })
  }
  @Output() messageEmitter = new EventEmitter<any>();
  moveTab(tab:any,to:any='Enterprise'){
    this.messageEmitter.emit(tab)
    this.dataShare.prefilter=to
  }
  readNotifications(element:any){
    this.moveTab('service',element.type == 'eu' ? 'Enterprise' : 'Retail')
    this.dataShare.dialogRef(element.service_request_id,element.type)
    let data = {
      id: element.id,
      is_read : 1
    }
    this.http.readNotification(data).subscribe((res:any)=>{
      if(res['status']){
        this.getNotifications()
      }
      else{
        this.toast.error(res['message'],'',{timeOut:2000})
      }
    },(err:any)=>{
      this.toast.error("Oops!, Somrthing went wrong Please try again",'',{timeOut:2000})
    })
  }
  showNotif:boolean = false
  toggleNotif(){
    this.dialog.open(NotificationsComponent,{
      data : this.notifications,
      // position: {
      //   top: `5rem`,
      //   right: `25rem`,
      // },
    }).afterClosed().subscribe((res)=>{
      if(res){
        this.getNotifications()
      }
    }) 
  }
}

// Super Admin Licenses Component
// Super Admin Licenses Component
// Super Admin Licenses Component

@Component({
  selector: 'app-u0-licenses',
  templateUrl: './u0_licenses.html',
  styleUrls: ['./licenses.component.css']
})
export class U0LicensesComponent implements OnInit {
  @ViewChild(MatSort) sort = new MatSort();

  constructor(
    public dialog: MatDialog,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dataShare:DataService
  ) { }

  ngOnInit(): void {
    const my_date = new Date();

    // Add 3 months to today's date
    new Date(my_date.setMonth(my_date.getMonth() + 3));
    if(this.dataShare.prefilter=='Retail'){
      this.getAllRetailLicense()
      this.dataShare.prefilter = 'Retail'
      this.enterpriseActiveFlag = false
      this.enter = false
      this.retail= true
      this.selectedType = 'retail'
    }
    else if(this.dataShare.prefilter=='Enterprise'){
      this.getAllEnterpriseLicense()
      this.dataShare.prefilter = 'Retail'
      this.enterpriseActiveFlag = true
      this.enter = true
      this.retail = false
      this.selectedType = 'enterprise'

    }
    this.dataShare.prefilter = 'Retail'
    this.clearRUFilter()
    this.clearEUFilter()
   
  }
  displayedColumns: string[] = ['sl_no', 'registration_no','user_name', 'business_name', 'state', 'status','view_license','modify','serviceReq'];
  dataEnterprise: any[] = []
  dataSource: any = new MatTableDataSource(this.dataEnterprise);
  displayedColumnsArchive: string[] = ['sl_no', 'registration_no','user_name', 'business_name', 'state', 'status','view_license','modify','serviceReq'];
  enterpriseActiveFlag: boolean = false
  selectedFilter: any = {
    all: true,
    active: false,
    expired: false,
    requested: false
  }
  uploadDoc:any = false
  archive:any = false
  fileData:any;
  enter:any=false;
  selectedFilterDropdown:any;
  retail:any=true
  displayedRetailColumns: string[] = ['sl_no', 'registration_no','user_name', 'business_name', 'state', 'status', 'view_license', 'modify','serviceReq'];
  dataRetail: any[] = []
  dataRetailSource: any = new MatTableDataSource(this.dataRetail);

  displayedDocsColumns: string[] = ['sl_no', 'doc_name', 'view', 'modify'];
  dataDocs: any[] = []
  dataDocsSource: any = new MatTableDataSource(this.dataDocs);

  displayedArchiveColumns: string[] = ['sl_no', 'licenseType','registration_no','user_name', 'business_name', 'state', 'status', 'view_license', 'modify'];
  dataArchive: any[] = []
  dataArchiveSource: any = new MatTableDataSource(this.dataArchive);
  selectedRetailFilter: any = {
    all: true,
    active: false,
    expired: false,
    requested: false
  }
  RUCityDropdown:any[]=[]
  selectedType='retail'
  RUBussinessDropdown:any[]=[]
  RULicenseDropdown:any[]=[]
  RUCompanyDropdown:any[]=[]
  RUStatusDropdown:any[]=[]
  RUUserNameDropdown:any[]=[]
  RUCityFilter:any='';
  RUUserNameFilter:any='';
  RULicenseFilter:any='';
  RUCompanyFilter:any='';
  RUBussinessFilter:any='';
  RUStatusFilter:any='';
  filterEudata:any;
  filterRudata:any;
  filterArchivedata:any;
  EUCityDropdown:any[]=[]
  ArchiveCityDropdown:any[]=[]
  EUBussinessDropdown:any[]=[]
  ArchiveBussinessDropdown:any[]=[]
  EULicenseDropdown:any[]=[]
  ArchiveLicenseDropdown:any[]=[]
  EUUserNameDropdown:any[]=[]
  ArchiveUserNameDropdown:any[]=[]
  EUCompanyDropdown:any[]=[]
  ArchiveCompanyDropdown:any[]=[]
  EUStatusDropdown:any[]=[]
  ArchiveStatusDropdown:any[]=[]
  EUCityFilter:any='';
  ArchiveCityFilter:any='';
  EUUserNameFilter:any='';
  ArchiveUserNameFilter:any='';
  EULicenseFilter:any='';
  ArchiveLicenseFilter:any='';
  EUCompanyFilter:any='';
  ArchiveCompanyFilter:any='';
  EUBussinessFilter:any='';
  ArchiveBussinessFilter:any='';
  EUStatusFilter:any='';
  ArchiveStatusFilter:any='';
  filterByStatus(order: any) {
    switch (order) {
      case 'All':
        this.selectedFilter = {
          all: true,
          active: false,
          expired: false,
          requested: false
        }
        break;
      case 'Active':
        this.selectedFilter = {
          all: false,
          active: true,
          expired: false,
          requested: false
        }
        break;
      case 'Expired':
        this.selectedFilter = {
          all: false,
          active: false,
          expired: true,
          requested: false
        }
        break;
      case 'Requested':
        this.selectedFilter = {
          all: false,
          active: false,
          expired: false,
          requested: true
        }
        break;
    }
    this.filterByStatusDataHandel(order)
  }
  uploadDocBtn(){
    const dialogRef = this.dialog.open(DialogAddDocument, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res)
        this.getDocs()
    })
  }
  getDocs(){
    this.httpSv.getSampleDocuments().subscribe((res: any) => {
      if (res['status']) {
        this.dataDocsSource.data = res['results']
      } else {
        this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      // this.toster.error(err.error.message, '', { timeOut: 2000 })
      if(err.error.message=='no data found')
      this.dataDocsSource.data = []
    })
  }
  deleteDocument(id:any){
    var heading='Are you sure you want to Delete the Document?'
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: heading,
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res){
          this.httpSv.deleteDocument(id).subscribe((res: any) => {
            if (res['status']) {
              this.toster.success('Document deleted successfully', '', { timeOut: 2000 })
              this.getDocs()
            } else {
              this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
            }
          }, (err: any) => {
            this.toster.error(err.error.message, '', { timeOut: 2000 })
          })
        }
      })
  }
   
  editSampleDocument(id:any){
    const dialogRef = this.dialog.open(DialogAddDocument, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        id
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res)
        this.getDocs()
    })
  }
  filterByStatusDataHandel(order: any) {
    if (order == 'All')
      this.dataSource.data = this.dataEnterprise
    else {
      const tempData = this.dataEnterprise.filter((ele: any) => ele.status.toLowerCase() == order.toLowerCase())
      this.dataSource.data = tempData
    }
  }
  getAllEnterpriseLicense()
  {
    this.httpSv.getEnterpriseLicense().subscribe((res: any) => {
      if (res['status']) {
        this.dataEnterprise = res['results']
        this.filterEudata = res['results']
        this.dataEnterprise.map((ele: any) => {
          if (ele.certificate_validity == 'Valid') ele.certificate_validity = 'Active'
          else if (ele.certificate_validity == 'Request Sent') ele.certificate_validity = 'Requested'
          return ele
        })
        this.createEUDropDown(this.dataEnterprise)
        this.dataSource = new MatTableDataSource(this.dataEnterprise)
        this.dataSource.sort = this.sort;
        this.filterByRetailStatus(!this.dataShare.preStatusFilter?'All':this.dataShare.preStatusFilter)
        // this.dataShare.preStatusFilter='All'

      } else {
        this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
      if(err.error.message=='no data found')
      this.dataSource.data = []
    })
  }
  applyFilter(event: Event) {
    if(this.selectedType == 'beu'){
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    else if(this.selectedType == 'retail'){
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataRetailSource.filter = filterValue.trim().toLowerCase();
    }
    else if(this.selectedType == 'archive'){
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataArchiveSource.filter = filterValue.trim().toLowerCase();
    }
  }
  createEUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state && !this.EUCityDropdown.includes(data.state.toLowerCase()))
        this.EUCityDropdown.push(data.state.toLowerCase())
      if(data.business_name && !this.EUBussinessDropdown.includes(data.business_name))
        this.EUBussinessDropdown.push(data.business_name)
      if(data.status && !this.EUStatusDropdown.includes(data.status))
        this.EUStatusDropdown.push(data.status)
      if(data.registration_no && !this.EULicenseDropdown.includes(data.registration_no))
        this.EULicenseDropdown.push(data.registration_no)
      if(data.business_name && !this.EUCompanyDropdown.includes(data.business_name))
        this.EUCompanyDropdown.push(data.business_name)
      if(data.user_name.user_name && !this.EUUserNameDropdown.includes(data.user_name.user_name))
        this.EUUserNameDropdown.push(data.user_name.user_name)
    })
  }
  EuApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='license'){
      this.EULicenseFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUBussinessFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUBussinessFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          console.log('comin')
          console.log(this.EUStatusFilter)
          filterData.map((data:any)=>{
            console.log(this.EUStatusFilter,data.status)

                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.registration_no && apiData.registration_no.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSource.data = this.filterEudata

    }
    if(from=='company'){
      this.EUCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EULicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EULicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.business_name && apiData.business_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSource.data = this.filterEudata

    }
    if(from=='city'){
      this.EUCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EULicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EULicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSource.data = this.filterEudata

    }
    if(from=='status'){
      this.EUStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EULicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EULicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
 
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSource.data = this.filterEudata

    }
    if(from=='uname'){
      this.EUUserNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EULicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EULicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
 
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.user_name.user_name && apiData.user_name.user_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSource.data = this.filterEudata

    }
  }
  clearEUFilter()
  {
    this.EUStatusFilter='';
    this.EUBussinessFilter='';
    this.EUCityFilter='';
    this.EUUserNameFilter='';
    this.dataSource.data = this.dataEnterprise

  }
  getArchive(){
    this.httpSv.getArchive().subscribe((res: any) => {
      if (res['status']) {
        this.dataArchive  = res['results']
        this.filterArchivedata  = res['results']
        this.dataArchive.map((ele: any) => {
          if (ele.certificate_validity == 'Valid') ele.certificate_validity = 'Active'
          else if (ele.certificate_validity == 'Request Sent') ele.certificate_validity = 'Requested'
          return ele
        })
        this.dataArchiveSource = new MatTableDataSource(this.dataArchive)
        this.dataArchiveSource.sort = this.sort
        this.createArchiveDropDown(this.dataArchive)
      }
    })
  }
  createArchiveDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state && !this.ArchiveCityDropdown.includes(data.state.toLowerCase()))
        this.ArchiveCityDropdown.push(data.state.toLowerCase())
      if(data.business_name && !this.ArchiveBussinessDropdown.includes(data.business_name))
        this.ArchiveBussinessDropdown.push(data.business_name)
      if(data.status && !this.ArchiveStatusDropdown.includes(data.status))
        this.ArchiveStatusDropdown.push(data.status)
      if(data.registration_no && !this.ArchiveLicenseDropdown.includes(data.registration_no))
        this.ArchiveLicenseDropdown.push(data.registration_no)
      if(data.business_name && !this.ArchiveCompanyDropdown.includes(data.business_name))
        this.ArchiveCompanyDropdown.push(data.business_name)
      if(data.user_name.user_name && !this.ArchiveUserNameDropdown.includes(data.user_name.user_name))
        this.ArchiveUserNameDropdown.push(data.user_name.user_name)
    })
  }
  ArchiveApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='license'){
      this.ArchiveLicenseFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataArchive;
        if(this.ArchiveCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.ArchiveCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.ArchiveCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.ArchiveUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveBussinessFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.ArchiveBussinessFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveStatusFilter.length>0){
          console.log('comin')
          console.log(this.ArchiveStatusFilter)
          filterData.map((data:any)=>{
            console.log(this.ArchiveStatusFilter,data.status)

                if(data.status && data.status.toLowerCase()==this.ArchiveStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterArchivedata.map((apiData:any)=>{
              if(apiData.registration_no && apiData.registration_no.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterArchivedata = result
      this.dataArchiveSource.data = this.filterArchivedata

    }
    if(from=='company'){
      this.ArchiveCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataArchive;
        if(this.ArchiveLicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.ArchiveLicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.ArchiveUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.ArchiveCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.ArchiveStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterArchivedata.map((apiData:any)=>{
              if(apiData.business_name && apiData.business_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterArchivedata = result
      this.dataArchiveSource.data = this.filterArchivedata

    }
    if(from=='city'){
      this.ArchiveCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataArchive;
        if(this.ArchiveLicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.ArchiveLicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.ArchiveUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.ArchiveCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.ArchiveStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterArchivedata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterArchivedata = result
      this.dataArchiveSource.data = this.filterArchivedata

    }
    if(from=='status'){
      this.ArchiveStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataArchive;
        if(this.ArchiveLicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.ArchiveLicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.ArchiveUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.ArchiveCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.ArchiveCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
 
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterArchivedata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterArchivedata = result
      this.dataArchiveSource.data = this.filterArchivedata

    }
    if(from=='uname'){
      this.ArchiveUserNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataArchive;
        if(this.ArchiveLicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.ArchiveLicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.ArchiveStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.ArchiveCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.ArchiveCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.ArchiveCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
 
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterArchivedata.map((apiData:any)=>{
              if(apiData.user_name.user_name && apiData.user_name.user_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterArchivedata = result
      this.dataArchiveSource.data = this.filterArchivedata

    }
  }
  clearArchiveFilter()
  {
    this.ArchiveStatusFilter='';
    this.ArchiveBussinessFilter='';
    this.ArchiveCityFilter='';
    this.ArchiveUserNameFilter='';
    this.dataSource.data = this.dataArchive

  }
  modifyAppUser(licensesNo: any,type:any) {
    const dialogRef = this.dialog.open(DialogAppLicenseModify, {
      hasBackdrop: true,
      disableClose: true,
      maxWidth: '90vw',
      data:
      {
        licensesNo,
        type
       }}
    )
    dialogRef.afterClosed().subscribe((res:any)=>{
      if(res.type!='retail' && res.status)
      this.getAllEnterpriseLicense()
      if(res.type=='retail' && res.status)
      this.getAllRetailLicense()
    })
  }
  getUserDetails(id:any,from:any){
    if(from == 'eu'){
      const dialogRef = this.dialog.open(DialogEnterpriseModify, {
        hasBackdrop: true,
        disableClose: true,
        data:{
        isModify:true,
        id:id,
        isFromLicense : true
        }
      })
    }
    else{
      const dialogRef = this.dialog.open(DialogRetailModify, {
        hasBackdrop: true,
        disableClose: true,
        data: {
          id,
          isFromLicense : true
        }
      })
    }

  }
  viewLicence(url: any,element:any) {
    let fileType = url.split('.')
    fileType = fileType[fileType.length - 1]
    const dialogRef = this.dialog.open(DialogAppLicenseView, {
      hasBackdrop: true,
      // disableClose:true
      data: {
        type: fileType,
        src: url,
        element:element
      }
    })
  }
  handleSwicthWebUser(type: any) {

    this.selectedRetailFilter = {
      all: true,
      active: false,
      expired: false,
      requested: false
    }
    this.selectedFilter = {
      all: true,
      active: false,
      expired: false,
      requested: false
    }
    if (type === 'beu') {
        this.uploadDoc=false
        this.archive=false
        this.enter=true
        this.retail=false
        this.selectedType=type
      if (!this.enterpriseActiveFlag) this.enterpriseActiveFlag = true
      this.getAllEnterpriseLicense()
    }
    else if (type === 'retail') {
      this.uploadDoc=false
      this.archive=false
      this.retail=true
      this.enter=false
      this.selectedType=type
      if (this.enterpriseActiveFlag) this.enterpriseActiveFlag = false
      this.getAllRetailLicense()
    }
    else if( type == 'docs'){
      this.enterpriseActiveFlag = false
      this.uploadDoc=true
      this.archive=false
      this.retail=false
      this.enter=false
      this.getDocs()
    }
    else {
      this.enterpriseActiveFlag = false
      this.uploadDoc=false
      this.archive=true
      this.retail=false
      this.selectedType=type
      this.enter=false
      this.getArchive()
    }
    this.selectedFilterDropdown ='All'
  }



  makeArchive(element:any,from:any){
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to move this license to Archive?'
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res){
        let data = {
          'is_archived' : 1
        }
        this.httpSv.archiveLicense(element.id,data).subscribe((res:any)=>{
          if(res['status']){
            if(from == 'enterprise')
              this.getAllEnterpriseLicense()
            else
              this.getAllRetailLicense()
            }
            else {
              this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
            }
        })
      } 
    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  makeRestore(element:any){
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to Restore this License?'
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res){
        let data = {
          'is_archived' : 0
        }
        this.httpSv.archiveLicense(element.id,data).subscribe((res:any)=>{
          if(res['status'])
            this.getArchive()
          else {
            this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
          }
        })
      } 
    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  deleteLicense(element:any){
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to Delete this License?'
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res){
        this.httpSv.deleteLicense(element.id).subscribe((res:any)=>{
          if(res['status'])
            this.getArchive()
          else {
            this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
          }
        })
      } 
    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  addRequest(element:any){
    this.dialog.open(DialogueAddServiceRequest,{
      disableClose:true,
      hasBackdrop: true,
      data:element
    })
  }
  filterByRetailStatus(order: any) {
    if(order == '30')
      this.selectedFilterDropdown='Expiring in less than 30 days';
    else if(order == '60')
      this.selectedFilterDropdown='Expiring in 60 days';
    else if(order == '90')
      this.selectedFilterDropdown='Expiring in 90 days';
    else
      this.selectedFilterDropdown=order;
    switch (order) {
      case 'All':
        this.selectedRetailFilter = {
          all: true,
          active: false,
          expired: false,
          requested: false
        }
        this.selectedFilter = {
          all: true,
          active: false,
          expired: false,
          requested: false
        }
        break;
      case 'Active':
        this.selectedRetailFilter = {
          all: false,
          active: true,
          expired: false,
          requested: false
        }
        this.selectedFilter = {
          all: false,
          active: true,
          expired: false,
          requested: false
        }
        break;
      case 'Expired':
        this.selectedRetailFilter = {
          all: false,
          active: false,
          expired: true,
          requested: false
        }
        this.selectedFilter = {
          all: false,
          active: false,
          expired: true,
          requested: false
        }
        break;
      case 'Requested':
        this.selectedRetailFilter = {
          all: false,
          active: false,
          expired: false,
          requested: true
        }
        this.selectedFilter = {
          all: false,
          active: false,
          expired: false,
          requested: true
        }
        break;
    }
    console.log(this.selectedRetailFilter)
    if(this.selectedType=='retail')
    this.filterByStatusRetailDataHandel(order)
    else if(this.selectedType=='archive')
      this.filterByStatusArchiveDataHandel(order)
    else
    this.filterByStatusEnterpriseDataHandel(order)
  }
  filterByStatusArchiveDataHandel(order: any) {
    if (order == 'All'){
      this.dataArchiveSource = this.dataArchive
    }
    else if(order == '30' || order == '60' || order == '90'){

      if(order == '30'){
        const tempData = this.dataArchive.filter((ele: any) => ele.expiring_in_30_days == true)
        this.dataArchiveSource = tempData
      } 
      else if(order == '60'){
        const tempData = this.dataArchive.filter((ele: any) => ele.expiring_in_60_days == true)
        this.dataArchiveSource = tempData
      } 
      else{
        const tempData = this.dataArchive.filter((ele: any) => ele.expiring_in_90_days == true)
        this.dataArchiveSource = tempData
      } 
    }
    else {
      const tempData = this.dataArchive.filter((ele: any) => ele.status == order)
      this.dataArchiveSource = tempData
    }
  }
  filterByStatusRetailDataHandel(order: any) {
    if (order == 'All'){
      this.dataRetailSource = this.dataRetail
    }
    else if(order == '30' || order == '60' || order == '90'){

      if(order == '30'){
        const tempData = this.dataRetail.filter((ele: any) => ele.expiring_in_30_days == true)
        this.dataRetailSource = tempData
      } 
      else if(order == '60'){
        const tempData = this.dataRetail.filter((ele: any) => ele.expiring_in_60_days == true)
        this.dataRetailSource = tempData
      } 
      else{
        const tempData = this.dataRetail.filter((ele: any) => ele.expiring_in_90_days == true)
        this.dataRetailSource = tempData
      } 
    }
    else {
      const tempData = this.dataRetail.filter((ele: any) => ele.status == order)
      this.dataRetailSource = tempData
    }
  }
  filterByStatusEnterpriseDataHandel(order:any)
  {
    if (order == 'All')
    this.dataSource.data = this.dataEnterprise
    else if(order == '30' || order == '60' || order == '90'){
      if(order == '30'){
        const tempData = this.dataEnterprise.filter((ele: any) => ele.expiring_in_30_days == true)
        this.dataSource.data = tempData
      } 
      else if(order == '60'){
        const tempData = this.dataEnterprise.filter((ele: any) => ele.expiring_in_60_days == true)
        this.dataSource.data = tempData
      } 
      else{
        const tempData = this.dataEnterprise.filter((ele: any) => ele.expiring_in_90_days == true)
        this.dataSource.data = tempData
      } 
    }
  else {
    let statuss:any;

    const tempData = this.dataEnterprise.filter((ele: any) => ele.status == order)
    this.dataSource.data = tempData
  }
  }
  applyRetailFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataRetailSource.filter = filterValue.trim().toLowerCase();
  }
  applyDocFilter(event :Event){

  }

  getAllRetailLicense() {
    this.httpSv.getRetailAllLicense().subscribe((res: any) => {
      if (res['status']) {
        this.dataRetail = res['results']
        this.filterRudata = res['results']
        this.dataRetail.map((ele: any) => {
          if (ele.certificate_validity == 'Valid') ele.certificate_validity = 'Active'
          else if (ele.certificate_validity == 'Request Sent') ele.certificate_validity = 'Requested'
          return ele
        })
        this.createRUDropDown(this.dataRetail)
        this.dataRetailSource = new MatTableDataSource(this.dataRetail)
        this.dataRetailSource.sort = this.sort
        this.filterByRetailStatus(!this.dataShare.preStatusFilter?'All':this.dataShare.preStatusFilter)
        this.dataShare.preStatusFilter='All'
      } else {
        this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      if(err.error.message=='no data found')
      this.dataRetailSource.data = []
      if(err.error.message!='no data found')
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  createRUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state && !this.RUCityDropdown.includes(data.state.toLowerCase()))
        this.RUCityDropdown.push(data.state.toLowerCase())
      if(data.business_name && !this.RUBussinessDropdown.includes(data.business_name))
        this.RUBussinessDropdown.push(data.business_name)
      if(data.status && !this.RUStatusDropdown.includes(data.status))
        this.RUStatusDropdown.push(data.status)
      if(data.registration_no && !this.RULicenseDropdown.includes(data.registration_no))
        this.RULicenseDropdown.push(data.registration_no)
      if(data.business_name && !this.RUCompanyDropdown.includes(data.business_name))
        this.RUCompanyDropdown.push(data.business_name)
      if(data.user_name.user_name && !this.RUUserNameDropdown.includes(data.user_name.user_name))
        this.RUUserNameDropdown.push(data.user_name.user_name)
    })
  }
  RuApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='license'){
      this.RULicenseFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_account_manager && data.assign_account_manager.name.toLowerCase()==this.EUStatusFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.registration_no && apiData.registration_no.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataRetailSource.data = this.filterRudata

    }
    if(from=='company'){
      this.RUCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.EULicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EULicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.business_name && apiData.business_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataRetailSource.data = this.filterRudata

    }
    if(from=='city'){
      this.RUCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RULicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RULicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataRetailSource.data = this.filterRudata

    }
    if(from=='status'){
      this.RUStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RULicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RULicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
 
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataRetailSource.data = this.filterRudata

    }
    if(from=='uname'){
      this.RUUserNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RULicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RULicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
 
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.user_name.user_name && apiData.user_name.user_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataRetailSource.data = this.filterRudata

    }
  }
  clearRUFilter()
  {
    this.RUStatusFilter='';
    this.RUBussinessFilter='';
    this.RUCityFilter='';
    this.RUUserNameFilter='';
    this.dataRetailSource.data = this.dataRetail

  }





}


// Account Manager Licenses Component
// Account Manager Licenses Component
// Account Manager Licenses Component

@Component({
  selector: 'app-u1-licenses',
  templateUrl: './u1_licenses.html',
  styleUrls: ['./licenses.component.css']
})
export class U1LicensesComponent implements OnInit {
  @ViewChild(MatSort) sort = new MatSort();

  EUCityDropdown: any=[];
  EUStatusDropdown: any=[];
  EUCompanyDropdown: any=[];
  EUNameDropdown: any=[];
  EUDateDropdown: any=[];
  EUUserNameDropdown: any=[];
  EUNameFilter: any='';
  EUUserNameFilter: any='';
  dataEU: any;
  EUCompanyFilter: any='';
  EUCityFilter: any='';
  EUDateFilter: any='';
  EUFilter: any='';
  EUStatusFilter: any='';
  filterEUdata: any;
  dataSourceEU: any;
  RUCityDropdown:any =[];
  RUStatusDropdown: any=[];
  RUCompanyDropdown: any=[];
  RUUserNameDropdown: any=[];
  RUNameDropdown: any=[];
  RUDateDropdown: any=[];
  RUNameFilter: any='';
  RUUserNameFilter: any='';
  selectedFilterDropdown = 'All'
  RUCompanyFilter: any='';
  RUCityFilter: any='';
  RUDateFilter: any='';
  RUFilter: any='';
  RUStatusFilter: any='';
  filterRUdata: any;

  constructor(
    public dialog: MatDialog,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dataShare: DataService
  ) { }

  ngOnInit(): void {

    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      const roleId = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
      this.role = roleId
    }
    if(this.dataShare.prefilter=='Retail'){
      this.getAllRetailLicense()
      this.enterpriseActiveFlag = false

    }
    if(this.dataShare.prefilter=='Enterprise'){
      this.getAllEnterpriseLicense()
      this.enterpriseActiveFlag = true
    }
    if(this.enterpriseActiveFlag) {
      this.dataShare.selectedTable == 'eu';
    } else {
      this.dataShare.selectedTable == 'ru';
    }
    this.dataShare.prefilter = 'Retail'

  }

  role: any

  displayedColumns: string[] = ['sl_no', 'registration_no','user_name', 'business_name', 'state', 'status','bm','view_license','serviceReq'];
  dataEnterprise: any[] = []
  dataSource: any = new MatTableDataSource(this.dataEnterprise);
  enterpriseActiveFlag: boolean = false
  selectedFilter: any = {
    all: true,
    active: false,
    expired: false,
    requested: false
  }
  filterByStatus(order: any) {
    switch (order) {
      case 'All':
        this.selectedFilter = {
          all: true,
          active: false,
          expired: false,
          requested: false
        }
        break;
      case 'Active':
        this.selectedFilter = {
          all: false,
          active: true,
          expired: false,
          requested: false
        }
        break;
      case 'Expired':
        this.selectedFilter = {
          all: false,
          active: false,
          expired: true,
          requested: false
        }
        break;
      case 'Requested':
        this.selectedFilter = {
          all: false,
          active: false,
          expired: false,
          requested: true
        }
        break;
    }
    this.filterByStatusDataHandle(order)
  }
  addRequest(element:any){
    this.dialog.open(DialogueAddServiceRequest,{
      disableClose:true,
      hasBackdrop: true,
      data:element
    })
  }
  filterByStatusDataHandle(order: any) {
    if (order == 'All')
      this.dataSource.data = this.dataEnterprise
    else {
      const tempData = this.dataEnterprise.filter((ele: any) => ele.status == order)
      this.dataSource.data = tempData
    }
  }
  getAllEnterpriseLicense()
  {
    this.httpSv.getAMEnterpriseLicense().subscribe((res: any) => {
      if (res['status']) {
        this.dataEnterprise = res['results']
        this.dataEnterprise.map((ele: any) => {
          if(ele.status)
            ele.status=ele.status
          if (ele.certificate_validity == 'Valid') ele.certificate_validity = 'Active'
          else if (ele.certificate_validity == 'Request Sent') ele.certificate_validity = 'Requested'
          return ele
        })
        this.dataSource.data = this.dataEnterprise
        this.dataSource.sort = this.sort
        this.filterEUdata = this.dataEnterprise
        this.createEUDropDown(this.dataEnterprise)
        this.filterByRetailStatus(this.dataShare.preStatusFilter)
        this.dataShare.preStatusFilter='All'

      } else {
        this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
      this.dataSource.data = []
    })
  }
  createEUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state &&!this.EUCityDropdown.includes(data.state.toLowerCase()))
        this.EUCityDropdown.push(data.state.toLowerCase())
      if(data.status && !this.EUStatusDropdown.includes(data.status))
        this.EUStatusDropdown.push(data.status)
      if(data.business_name && !this.EUCompanyDropdown.includes(data.business_name))
        this.EUCompanyDropdown.push(data.business_name)
      if(data.registration_no && !this.EUNameDropdown.includes(data.registration_no))
        this.EUNameDropdown.push(data.registration_no)
      if(data.date && !this.EUDateDropdown.includes(data.date))
        this.EUDateDropdown.push(data.date)
      if(data.user_name.user_name && !this.EUUserNameDropdown.includes(data.user_name.user_name))
        this.EUUserNameDropdown.push(data.user_name.user_name)
    })
  }
   EUApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='name'){
      this.EUNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUCompanyFilter.length>0){
          console.log("comaany")
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          console.log("city")

          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          console.log("date")

          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUFilter.length>0){
          console.log("eu")

          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          console.log("status")

          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        console.log(filterData)
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.registration_no && apiData.registration_no.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
    if(from=='company'){
      this.EUCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.business_name && apiData.business_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
    if(from=='city'){
      this.EUCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
    if(from=='date'){
      this.EUDateFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.date && apiData.date.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
    if(from=='EU'){
      this.EUFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.assign_business_manager && apiData.assign_business_manager.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
    if(from=='status'){
      this.EUStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.EUFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
    if(from=='uname'){
      this.EUUserNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.EUFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.user_name.user_name && apiData.user_name.user_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
  }
  applyFilterr(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getUserDetails(id:any,from:any){
    if(from == 'eu'){
      const dialogRef = this.dialog.open(DialogEnterpriseModify, {
        hasBackdrop: true,
        disableClose: true,
        data:{
        isModify:true,
        id:id,
        isFromLicense : true
        }
      })
    }
    else{
      const dialogRef = this.dialog.open(DialogRetailModify, {
        hasBackdrop: true,
        disableClose: true,
        data: {
          id,
          isFromLicense : true
        }
      })
    }

  }
  modifyLicenseAM(licensesNo: any,type:any) {
    const dialogRef = this.dialog.open(DialogAppLicenseModify, {
      hasBackdrop: true,
      disableClose: true,
      maxWidth: '90vw',
      data:
      {
        role: this.role,
        licensesNo,
        type
       }}
    ).afterClosed().subscribe((res)=>{
      if(res['status']){
        if(type == 'eu')
            this.getAllEnterpriseLicense()
        else  
          this.getAllRetailLicense()
      }
    })
  }
  
  viewLicence(url: any) {
    let fileType = url.split('.')
    fileType = fileType[fileType.length - 1]
    const dialogRef = this.dialog.open(DialogAppLicenseView, {
      hasBackdrop: true,
      // disableClose:true
      data: {
        type: fileType,
        src: url
      }
    })
  }

  handleSwicthWebUser(type: any) {
    this.selectedRetailFilter = {
      all: true,
      active: false,
      expired: false,
      requested: false
    }
    this.selectedFilter = {
      all: true,
      active: false,
      expired: false,
      requested: false
    }
    this.dataShare.selectedTable = type;
    this.selectedFilterDropdown = 'All'
    if (type === 'eu') {
      if (!this.enterpriseActiveFlag) this.enterpriseActiveFlag = true
      this.getAllEnterpriseLicense()
    }
    else if (type === 'ru') {
      if (this.enterpriseActiveFlag) this.enterpriseActiveFlag = false
      this.getAllRetailLicense()
    }
  }


  displayedRetailColumns: string[] = ['sl_no', 'registration_no','user_name', 'business_name', 'state', 'status','view_license','serviceReq'];
  dataRetail: any[] = []
  dataRetailSource: any = new MatTableDataSource(this.dataRetail);
  selectedRetailFilter: any = {
    all: true,
    active: false,
    expired: false,
    requested: false
  }

  filterByRetailStatus(order: any) {
    if(order == '30')
      this.selectedFilterDropdown='Expiring in less than 30 days';
    else if(order == '60')
      this.selectedFilterDropdown='Expiring in 60 days';
    else if(order == '90')
      this.selectedFilterDropdown='Expiring in 90 days';
    else
      this.selectedFilterDropdown=order;
    console.log(this.selectedFilterDropdown)
    switch (order) {
      case 'All':
        this.selectedRetailFilter = {
          all: true,
          active: false,
          expired: false,
          requested: false
        }
        this.selectedFilter = {
          all: true,
          active: false,
          expired: false,
          requested: false
        }
        break;
      case 'Active':
        this.selectedRetailFilter = {
          all: false,
          active: true,
          expired: false,
          requested: false
        }
        this.selectedFilter = {
          all: false,
          active: true,
          expired: false,
          requested: false
        }
        break;
      case 'Expired':
        this.selectedRetailFilter = {
          all: false,
          active: false,
          expired: true,
          requested: false
        }
        this.selectedFilter = {
          all: false,
          active: false,
          expired: true,
          requested: false
        }
        break;
      case 'Requested':
        this.selectedRetailFilter = {
          all: false,
          active: false,
          expired: false,
          requested: true
        }
        break;
    }
    if(!this.enterpriseActiveFlag)
    this.filterByStatusRetailDataHandle(order)
    else
    this.filterByStatusEnterpriseDataHandle(order)
  }

  filterByStatusRetailDataHandle(order: any) {
    if (order == 'All')
      this.dataRetailSource.data = this.dataRetail
      else if(order == '30' || order == '60' || order == '90'){

        if(order == '30'){
          const tempData = this.dataRetail.filter((ele: any) => ele.expiring_in_30_days == true)
          this.dataRetailSource.data = tempData
        } 
        else if(order == '60'){
          const tempData = this.dataRetail.filter((ele: any) => ele.expiring_in_60_days == true)
          this.dataRetailSource.data = tempData
        } 
        else{
          const tempData = this.dataRetail.filter((ele: any) => ele.expiring_in_90_days == true)
          this.dataRetailSource.data = tempData
        } 
      }
    else {
      const tempData = this.dataRetail.filter((ele: any) => ele.status == order)
      this.dataRetailSource.data = tempData
    }
  }

  filterByStatusEnterpriseDataHandle(order:any)
  {
    if (order == 'All')
    this.dataSource.data = this.dataEnterprise
    else if(order == '30' || order == '60' || order == '90'){
      if(order == '30'){
        const tempData = this.dataEnterprise.filter((ele: any) => ele.expiring_in_30_days == true)
        this.dataSource.data = tempData
      } 
      else if(order == '60'){
        const tempData = this.dataEnterprise.filter((ele: any) => ele.expiring_in_60_days == true)
        this.dataSource.data= tempData
      } 
      else{
        const tempData = this.dataEnterprise.filter((ele: any) => ele.expiring_in_90_days == true)
        this.dataSource.data = tempData
      } 
    }
  else {
    this.dataEnterprise.map((ele)=>{
      console.log(ele.status)
    })
    const tempData = this.dataEnterprise.filter((ele: any) => ele.status == order)
    this.dataSource.data = tempData
  }
  }

  applyRetailFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataRetailSource.filter = filterValue.trim().toLowerCase();
  }

  getAllRetailLicense() {
    this.httpSv.getAMRetailAllLicense().subscribe((res: any) => {
      if (res['status']) {
        this.dataRetail = res['results']
        this.dataRetail.map((ele: any) => {
          if (ele.certificate_validity == 'Valid') ele.certificate_validity = 'Active'
          else if (ele.certificate_validity == 'Request Sent') ele.certificate_validity = 'Requested'
          return ele
        })
        this.dataRetailSource.data = this.dataRetail
        this.dataRetailSource.sort = this.sort
        this.filterRUdata = this.dataRetail
        this.createRUDropDown(this.dataRetail)
        this.filterByRetailStatus(this.dataShare.preStatusFilter)
        this.dataShare.preStatusFilter='All'
      } else {
        this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.dataRetailSource.data = []
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  createRUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state &&!this.RUCityDropdown.includes(data.state.toLowerCase()))
        this.RUCityDropdown.push(data.state.toLowerCase())
      if(data.status && !this.RUStatusDropdown.includes(data.status))
        this.RUStatusDropdown.push(data.status)
      if(data.business_name && !this.RUCompanyDropdown.includes(data.business_name))
        this.RUCompanyDropdown.push(data.business_name)
      if(data.registration_no && !this.RUNameDropdown.includes(data.registration_no))
        this.RUNameDropdown.push(data.registration_no)
      if(data.date && !this.RUDateDropdown.includes(data.date))
        this.RUDateDropdown.push(data.date)
      if(data.user_name.user_name && !this.RUUserNameDropdown.includes(data.user_name.user_name))
        this.RUUserNameDropdown.push(data.user_name.user_name)
    })
  }
   RUApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='name'){
      this.RUNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.registration_no && apiData.registration_no.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
    if(from=='company'){
      this.RUCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.business_name && apiData.business_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
    if(from=='city'){
      this.RUCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
    if(from=='date'){
      this.RUDateFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.date && apiData.date.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
    if(from=='RU'){
      this.RUFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.assign_business_manager && apiData.assign_business_manager.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
    if(from=='status'){
      this.RUStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.RUFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
    if(from=='uname'){
      this.RUUserNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.RUFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.user_name.user_name && apiData.user_name.user_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
  }

}



// Business End User
// Business End User
// Business End User

@Component({
  selector: 'app-u3-licenses',
  templateUrl: './u3_licenses.html',
  styleUrls: ['./licenses.component.css']
})
export class U3LicensesComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private httpSv: HttpService,
    private toster: ToastrService
  ) { }

  ngOnInit(): void {
    this.getAllLicense()
  }
  @ViewChild(MatSort) sort = new MatSort();

  RUCityDropdown:any[]=[]
  selectedType='beu'
  RUBussinessDropdown:any[]=[]
  RULicenseDropdown:any[]=[]
  RUCompanyDropdown:any[]=[]
  RUUserNameDropdown:any[]=[]
  RUStatusDropdown:any[]=[]
  RUCityFilter:any='';
  RULicenseFilter:any='';
  RUUserNameFilter:any='';
  RUCompanyFilter:any='';
  RUBussinessFilter:any='';
  RUStatusFilter:any='';
  filterEudata:any;
  filterRudata:any;
  EUCityDropdown:any[]=[]
  EUBussinessDropdown:any[]=[]
  EULicenseDropdown:any[]=[]
  EUCompanyDropdown:any[]=[]
  EUUserNameDropdown:any[]=[]
  EUStatusDropdown:any[]=[]
  EUCityFilter:any='';
  EULicenseFilter:any='';
  EUCompanyFilter:any='';
  EUUserNameFilter:any='';
  EUBussinessFilter:any='';
  EUStatusFilter:any='';
  apiData: any;

  dataEnterprise: any;
  displayedColumns: string[] = ['sl_no', 'registration_no','user_name', 'business_name', 'state', 'status', 'view_license','serviceReq'];
  data: any[] = []
  dataSource: any = new MatTableDataSource(this.data);
  cesActiveFlag: boolean = true
  selectedFilter: any = {
    all: true,
    active: false,
    expired: false,
    requested: false
  }
  filterByStatus(order: any) {
    switch (order) {
      case 'All':
        this.selectedFilter = {
          all: true,
          active: false,
          expired: false,
          requested: false
        }
        break;
      case 'Active':
        this.selectedFilter = {
          all: false,
          active: true,
          expired: false,
          requested: false
        }
        break;
      case 'Expired':
        this.selectedFilter = {
          all: false,
          active: false,
          expired: true,
          requested: false
        }
        break;
      case 'Requested':
        this.selectedFilter = {
          all: false,
          active: false,
          expired: false,
          requested: true
        }
        break;
    }
    this.filterByStatusDataHandel(order)
  }

  filterByStatusDataHandel(order: any) {
    if (order == 'All')
      this.dataSource.data = this.data
    else {
      const tempData = this.data.filter((ele: any) => ele.status == order)
      this.dataSource.data = tempData
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  addRequest(element:any){
    const dialogRef = this.dialog.open(DialogueAddServiceRequest,{
      disableClose:true,
      hasBackdrop: true,
      data:element
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.ngOnInit()
      }
    })
  }
  modifyAppUser(licensesNo: any, id: any) {
    const dialogRef = this.dialog.open(DialogAppLicenseModify, {
      hasBackdrop: true,
      disableClose: true,
      maxWidth: '90vw',
      data: {
        licensesNo,
        id
      }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.ngOnInit()
      }
    })
  }
  getAllLicense() {
    this.httpSv.getAllLicenses().subscribe((res: any) => {
      if (res['status']) {
        this.data = res['results']
        this.data.map((ele: any) => {
          if (ele.certificate_validity == 'Valid') ele.certificate_validity = 'Active'
          else if (ele.certificate_validity == 'Request Sent') ele.certificate_validity = 'Requested'
          return ele
        })
        this.dataSource.data = this.data
        this.dataSource.sort = this.sort
        this.apiData = this.data
        this.dataEnterprise = this.data
        this.createEUDropDown(this.data)
      }
      else {

      }
    }, (err: any) => {
      if(err.error.message != 'no data found')
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  createEUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state && !this.EUCityDropdown.includes(data.state.toLowerCase()))
        this.EUCityDropdown.push(data.state.toLowerCase())
      if(data.business_name && !this.EUBussinessDropdown.includes(data.business_name))
        this.EUBussinessDropdown.push(data.business_name)
      if(data.status && !this.EUStatusDropdown.includes(data.status))
        this.EUStatusDropdown.push(data.status)
      if(data.registration_no && !this.EULicenseDropdown.includes(data.registration_no))
        this.EULicenseDropdown.push(data.registration_no)
      if(data.business_name && !this.EUCompanyDropdown.includes(data.business_name))
        this.EUCompanyDropdown.push(data.business_name)
      if(data.user_name.user_name && !this.EUUserNameDropdown.includes(data.user_name.user_name))
        this.EUUserNameDropdown.push(data.user_name.user_name)
    })
  }
  EuApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='license'){
      this.EULicenseFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUBussinessFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUBussinessFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          console.log('comin')
          console.log(this.EUStatusFilter)
          filterData.map((data:any)=>{
            console.log(this.EUStatusFilter,data.status)

                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.registration_no && apiData.registration_no.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSource.data = this.filterEudata

    }
    if(from=='company'){
      this.EUCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EULicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EULicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.business_name && apiData.business_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSource.data = this.filterEudata

    }
    if(from=='city'){
      this.EUCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EULicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EULicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSource.data = this.filterEudata

    }
    if(from=='status'){
      this.EUStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EULicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EULicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
 
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSource.data = this.filterEudata

    }
    if(from=='uname'){
      this.EUUserNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EULicenseFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EULicenseFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
 
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.user_name.user_name && apiData.user_name.user_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSource.data = this.filterEudata

    }
  }
  handleAddNewLicense()
  {
    const dialogRef = this.dialog.open(DialogAppLicenseModify, {
      hasBackdrop: true,
      disableClose: true,
      maxWidth: '90vw',
      data:
      {
        isAddNew:true,
        type:'enterprise',
        id: 1
      }
    }
    ).afterClosed().subscribe((res:any)=>{
      if(res){
        this.ngOnInit()
      }
    })
  }
  viewLicence(url: any) {
    let fileType = url.split('.')
    fileType = fileType[fileType.length - 1]
    const dialogRef = this.dialog.open(DialogAppLicenseView, {
      hasBackdrop: true,
      // disableClose:true
      data: {
        type: fileType,
        src: url,
        // element:element
      }
    })
  }
}




//--------------------------------------------license_view_dialog------------------------------------------------------
//--------------------------------------------license_view_dialog------------------------------------------------------

@Component({
  selector: 'app-license-view-dialog',
  templateUrl: './view_licenses.html',
})
export class DialogAppLicenseView implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogAppLicenseView>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient
  ) { }
  ngOnInit(): void {
    console.log(this.data)
    this.pdfFlag = this.data.type == 'pdf'
    this.src = this.data.src
  }
  pdfFlag: boolean = false
  src: any = '../../assets/img/Kairos_Animal_Farm_Building_trust.pdf'
  zoomScale: any = 'page-height'

  close() {
    this.dialogRef.close(false)
  }

  async download(){
    if(this.pdfFlag){
      this.http.get(this.src, { responseType: 'blob' }).subscribe(
        (blob: Blob) => {
          saveAs(blob,'lic_' + this.data.element.registration_no + '.' + this.data.type);
        },
        error => {
          console.error('Download failed', error);
        }
      );
    }else{
      saveAs(this.src);
    }
  }
}





//--------------------------------------------license_modify_dialog------------------------------------------------------
//--------------------------------------------license_modify_dialog------------------------------------------------------
//--------------------------------------------license_modify_dialog------------------------------------------------------
//--------------------------------------------license_modify_dialog------------------------------------------------------
//--------------------------------------------license_modify_dialog------------------------------------------------------

@Component({
  selector: 'app-license-modify-dialog',
  templateUrl: './modify_license.html',
  styleUrls: ['./licenses.component.css']
})
export class DialogAppLicenseModify implements OnInit {
  role: string = '';
  licenseData:any;
  constructor(
    public dialogRef: MatDialogRef<DialogAppLicenseModify>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataShare: DataService,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dialog: MatDialog,
    private config: NgSelectConfig,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.config.bindValue = "harsga" ;
   }
   userIdBeu:any
   tempStatus :string = ''
   max_file_size : any;
   total_storage_limit : any;
   userStorage:any;
  ngOnInit(): void {
    this.max_file_size = this.dataShare.individualFileSize
    this.total_storage_limit = this.dataShare.totalRetailSize
    this.userStorage = this.dataShare.userStorage
    this.userIdBeu = localStorage.getItem("user_id")
    console.log(this.data, this.data.licensesNo)
    const roleIdEncodedd = localStorage.getItem('role')
    if (roleIdEncodedd) {
      const roleId = AES.decrypt(roleIdEncodedd, this.dataShare.secretKey).toString(enc.Utf8);
      this.roleId = roleId
    }
    this.getBusinessNature()
    this.role=this.data.role
    this.dataShare.allStatesOfIndia.forEach((ele: any, index: any) => {
      this.allState.push({ id: index + 1, name: ele })
    });
    this.allTypeOfLicence = this.dataShare.allTypeOfLicences

      this.httpSv.getAllKob().subscribe((res: any) => {
        if (res['status']) {
          this.allKOB = res['result']
        }
        else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      })
      console.log(this.roleId)
    if(this.roleId==3 && !this.data.isAddNew )
    {
      console.log(this.data.licenseDetails.application_document_details)
      this.licenseData=this.data.licenseDetails.fssai_license_details[0];
      this.applicationData=this.data.licenseDetails.application_document_details[0];
      this.testingData=this.data.licenseDetails.testing_document_details[0];
      this.trainingData=this.data.licenseDetails.training_document_details[0];
      this.healthData=this.data.licenseDetails.health_document_details[0];
      this.calibrationData=this.data.licenseDetails.calibration_document_details[0];
      this.otherData=this.data.licenseDetails.others_document_details[0];
      this.srData=this.data.licenseDetails.service_requests_details[0];
      this.srDataSource=new MatTableDataSource(this.data.licenseDetails.service_requests_details)
      this.applicationDataSource= new MatTableDataSource(this.data.licenseDetails.application_document_details)
      this.testingDataSource=new MatTableDataSource(this.data.licenseDetails.testing_document_details)
      this.trainingDataSource=new MatTableDataSource(this.data.licenseDetails.training_document_details)
      this.healthDataSource=new MatTableDataSource(this.data.licenseDetails.health_document_details)
      this.calibrationDataSource=new MatTableDataSource(this.data.licenseDetails.calibration_document_details)
      this.otherDataSource=new MatTableDataSource(this.data.licenseDetails.others_document_details)
      this.businessName=this.licenseData.business_name;
      this.emailId=this.licenseData.email;
      this.mobileNo=this.licenseData.mobile;
      this.selectedBusinessNature = this.licenseData.business_nature
      this.city=this.licenseData.address;
      this.address=this.licenseData.address;
      this.currentTurnover = this.licenseData.current_turn_over
      this.expectedTurnover = this.licenseData.expected_turn_over
      this.pinCode=this.licenseData.pincode;
      this.selectedState=this.licenseData.state;
      this.selectedCity=this.licenseData.city;
      this.licensesNo=this.licenseData.registration_no;
      this.licensesNo=this.licenseData.registration_no;
      this.selectedKOBBusiness=this.licenseData.business_kind_ids?.split(',').map(Number);
      this.licensesExpire=this.licenseData.validity_date;
      this.selectedLicenceType=this.licenseData.license_type;
      this.licensesUrl=this.licenseData.documents;
      this.userId = this.licenseData.user_id
      // this.txtStatus=this.licenseData.certificate_validity=='Valid'?'Active': this.licenseData.certificate_validity=='Request Sent'?'Requested':this.licenseData.status;
      this.txtStatus=this.licenseData.status
      this.tempStatus=this.licenseData.status
    }
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      const roleId = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
      this.role = roleId
      if (roleId == '1' || roleId == '3')
        {
          if(this.data.isAddNew){
            this.addNew=true
            this.getCompanyIncorporation()
            this.getPremiseAddress()
            this.getBusinessNature()
          }
          else{
            if(roleId=='1')
            this.getAllsupLicenseDtls(this.data.licensesNo,this.data.type)
            this.tempLicenseNo=this.data.licensesNo
            console.log(this.data,this.tempLicenseNo)
          }
        }
      else if (roleId == '2'){
        if(!this.data.isAddNew){
          this.tempLicenseNo=this.data.licensesNo
          this.getAllAMLicenseDtls(this.data.licensesNo, this.data.type)
        }
        else{
          this.addNew=true
          this.getCompanyIncorporation()
          this.getPremiseAddress()
          this.getBusinessNature()
        }
      }
      else if (roleId == '5' && !this.data.isAddNew){
        this.getLicenceseDtls(this.data.licensesNo)
        this.tempLicenseNo=this.data.licensesNo
      }
      else if (roleId == '5' && this.data.isAddNew){
        this.addNew=true
        this.getCompanyIncorporation()
        this.getPremiseAddress()
        this.getBusinessNature()
      }

    }
    else {
      this.toster.error('Opps! something went wrong', '', { timeOut: 2000 })
    }
  }
  changeLicense(data:any){
    this.type = data;
    if(data == 'existing')
      this.addNew = false
    else  
      this.addNew = true
  }
  openTerms(){
    const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
      hasBackdrop: true,
      disableClose: true,
      maxWidth: '80vw',
      minHeight:'80vh'
    })
  }
  getDate(data:any){
    const date = new Date(data);
      // Define arrays for month and weekday names
      const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      // Format the date manually
      const weekday = weekdays[date.getUTCDay()];
      const month = months[date.getUTCMonth()];
      const day = date.getUTCDate();
      const year = date.getUTCFullYear();
      const utcDate = new Date(date);
    
      // Convert to IST (UTC + 5:30)
      const istDate = new Date(utcDate.getTime() + (5.5 * 60 * 60 * 1000));
      
      // Get hours and minutes
      let hours = istDate.getUTCHours();
      let minutes:any = istDate.getUTCMinutes();
      
      // Format to AM/PM
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // The hour '0' should be '12'
      
      // Format minutes to 2 digits
      minutes = minutes < 10 ? `0${minutes}` : minutes;

      return`${weekday}, ${month}, ${day} ${year} at ${hours}:${minutes} ${ampm}`;;

  }
  changeDate(){
    if(this.licensesExpire.length >= 7){
      let expiryDate = new Date(this.licensesExpire);
      let todayDate = new Date();
      if(todayDate > expiryDate)
        this.tempStatus = 'Expired'
      else if(this.tempStatus == 'Expired' && todayDate < expiryDate)
        this.tempStatus = 'Active'
    }
  }
  keydown(event:any){
      // Check if the pressed key is a digit
      if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        return;
      }
      const isDigit = /^\d$/.test(event.key);
      // If not a digit, prevent the input
      if (!isDigit) {
        event.preventDefault();
      }
  }
  getPincode(event:any){
    let value=event.target.value;
    if(value.length==6)  {
      this.pinCode = value
        this.httpSv.getpincode(value).subscribe((res:any)=>{
      if(res['status']){
        this.selectedState=res['data'].state
        this.selectedCity = res['data'].district
      }
      else{
        this.toster.error("Please enter a valid pincode",'',{timeOut:2000})
        this.selectedState=''
        this.selectedCity = ''
      }
    },()=>{
        this.selectedState=''
        this.selectedCity = ''
        this.toster.error("Please enter a valid pincode",'',{timeOut:2000})
    })
  }
  }
  selectedTab: string = 'LICENSE DETAILS'
  handleTabChange(tab: string) {
    if (this.selectedTab == tab) return
    this.selectedTab = tab
  }
  type:any='new'
  roleId:any;
  addNew:boolean=false;
  licenseType:string='';
  modifyId:any;
  userId:any;
  name: string = 'temp'
  businessName: string = ''
  emailId: string = ''
  mobileNo: string = ''
  address: string = ''
  city: string = ''
  pinCode: string = ''
  licensesNo: string = ''
  licensesExpire: string = ''
  licensesStatus: string = ''
  allState: any[] = []
  selectedState: any
  licenseStatus: any
  licensesUrl: any
  tempLicenseNo: any = ''
  currentTurnover: any
  expectedTurnover: any
  allCompIncorpType: any[] = []
  allPremiseAddress: any[] = []
  allBusinessNature: any[] = []

  selectedPremiseAddress: any
  selectedCompIncorp: any
  selectedBusinessNature: any

  setLicense(val:any)
  {
    this.licenseType=val
  }
  onMaterialGroupChange(type: any) {
    if(type=='state')
    {
      console.log(this.selectedState)
    }
    if(type=='kob'){
      console.log(this.selectedKOBBusiness)
    }
  }
  defaultBindingsList = [
    { value: 1, label: 'Vilnius' },
    { value: 2, label: 'Kaunas' },
    { value: 3, label: 'Pavilnys', disabled: true }
];
selectedCity:any = '';

  allKOB: any[] = []
  selectedKOBBusiness: any = []
  allTypeOfLicence: any[] = []
  selectedLicenceType: any = []



  selectedFilter: any = {
    active: false,
    expired: false,
    requested: false
  }
  txtStatus: string = ''
  filterByStatus(order: any) {

    switch (order) {

      case 'Active':
        this.selectedFilter = {
          active: true,
          expired: false,
          requested: false
        }
        this.txtStatus = 'Active'
        break;
      case 'Expired':
        this.selectedFilter = {
          active: false,
          expired: true,
          requested: false
        }
        this.txtStatus = 'Expired'
        break;
      case 'Requested':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: true
        }
        this.txtStatus = 'Requested'
        break;
    }

  }
  getStateId(name:any)
  {
    let flag:any;
    this.dataShare.allStatesOfIndia.forEach((ele: any, index: any) => {
      if(name.localeCompare(ele)==0)
      {
        flag= index+1;
      }
    });
    return flag;
  }
  close() {
    console.log(this.selectedState)
    this.dialogRef.close(false)
  }
  fileName: string = ''
  fileData: any = ''
  onImageChange(event: any) {
    let file: File = event.target.files[0];
    let currentSize = (event.target.files[0].size)/1000000 
    if(currentSize > this.max_file_size)
    {
      this.toster.error("Maximum file size is "+ this.max_file_size+" MB",'', { timeOut: 2000 })
      return;
    }
    if(this.data.type == 'retail' || this.data.type == 'ru'){
      if(currentSize+(this.userStorage)/1000  > this.total_storage_limit ){
        this.toster.error("You have Exceeded your free storage limit, Upgrade to the myFssai Enterprise Edition to add more. Contact myfssai@foodsafetyworks.com to upgrade to the myFssai Enterprise Edition",'', { timeOut: 4000 })
        return;
      }
    }
    this.fileData = file
    this.fileName = file.name
    let reader: FileReader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e: any) => {
      // this.src = e.target.result;
    };
    const fileDom = document.getElementById('file_inp') as HTMLInputElement
    if (fileDom) {
      fileDom.value = ''
    }
  }
  addSupportingDocs(state:any)
  {
    if(state=='CALIBRATION')
      state='calibiration'
    const dialogRef = this.dialog.open(DialogAppLicenseDocModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        licensesNo: this.tempLicenseNo,
        data:'',
        userId: this.data.id?this.data.id:this.userId,
        userType: this.data.type?this.data.type:this.data.userType,
        type:state.toLowerCase(),
        addNew:true
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res == 'close')
        this.close()
      if(res){
        if(this.role=='1')
        this.getAllsupLicenseDtls(this.tempLicenseNo, this.data.type)
        else
        this.getAllBMLicenseDtls(this.tempLicenseNo, this.data.type)
      }
    })
  }

  addLicenseToast(){
    this.toster.info('Please! First create a new license from License Details tab to add supporting docs.', '', { timeOut: 3000 })
  }

  getCompanyIncorporation(){
    this.httpSv.getCompanyIncorporation().subscribe((res:any) => {
      if(res['status']){
        res['result'].forEach((compIncorp:any) => {
          this.allCompIncorpType = [...this.allCompIncorpType, compIncorp];
        })
      }
    }), ((err: any) => {
      this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
    })
  }

  getBusinessNature(){
    this.httpSv.getBusinessNature().subscribe((res:any) => {
      if(res['status']){
        res['result'].forEach((business_nature:any) => {
          this.allBusinessNature = [...this.allBusinessNature, business_nature];
        })
      }
    }), ((err: any) => {
      this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
    })
  }

  getPremiseAddress(){
    this.httpSv.getPremiseAddress().subscribe((res:any) => {
      if(res['status']){
        res['result'].forEach((address:any) => {
          this.allPremiseAddress = [...this.allPremiseAddress, address];
        })
      }
    }), ((err: any) => {
      this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
    })
  }



  addNewLicenseDtls(){
    this.txtStatus = this.tempStatus
    if(this.pinCode.length<6){
      this.toster.error("Pincode has to be 6 digits",'',{timeOut:2000})
      return
    }
    if(this.selectedCity.length == 0){
      this.toster.error("Please enter the Pincode",'',{timeOut:2000})
      return
    }
    let data = new FormData()
    data.append('city', this.selectedCity)

    let id:any = localStorage.getItem('user_id')
    if(this.role == '5')
      data.append('user_id', id)
    else
      data.append('user_id', this.data.id)
    if(this.role == '1' || this.role == '2')
    {
      if(this.type == 'new'){
        // if(this.name && this.name != ''){
          data.append('name', this.name)
          if (this.businessName && this.businessName != '') {
            data.append('business_name', this.businessName)
            if (this.emailId && this.emailId != '') {
              if (this.emailId.match(emailRegex)) {
                data.append('email', this.emailId)
                if (this.mobileNo && this.mobileNo != '') {
                  if (this.mobileNo.toString().length == 10) {
                    data.append('mobile', this.mobileNo)
                    if (this.address && this.address != '') {
                      data.append('address', this.address)
                      if (this.pinCode && this.pinCode != '') {
                        data.append('pincode', this.pinCode)
                        if (this.selectedState && this.selectedState != '') {
                          data.append('state', this.selectedState )
                          if (this.currentTurnover && this.currentTurnover != '') {
                            data.append('current_turn_over', this.currentTurnover )
                            if (this.expectedTurnover && this.expectedTurnover != '') {
                              data.append('expected_turn_over', this.expectedTurnover )
                              if (this.selectedCompIncorp && this.selectedCompIncorp != '') {
                                data.append('company_incorporation', this.selectedCompIncorp )
                                if (this.selectedPremiseAddress && this.selectedPremiseAddress != '') {
                                  data.append('premise_address', this.selectedPremiseAddress )
                                  if (this.selectedBusinessNature && this.selectedBusinessNature != '') {
                                    data.append('business_nature', this.selectedBusinessNature )  
                                    if (this.selectedLicenceType && this.selectedLicenceType != '') {
                                      data.append('license_type', this.selectedLicenceType)
                                      if (this.fileData || this.licensesUrl) {
                                        if(this.fileData)
                                        data.append('documents', this.fileData)
                                        else
                                        data.append('documents', this.licensesUrl)
                                        if (this.tcFlag) {

                                          this.httpSv.createNewLicenseDtls(data, this.role, this.data.type)?.subscribe((res: any) => {
                                            if (res['status']) {
                                              
                                              this.tempLicenseNo = res.registration_no
                                              this.toster.success('You have been created new License successfully. You can add additional details for this license', '', { timeOut: 2000 })
                                              var temp={
                                                type:this.data.type,
                                                status:false
                                              }
                                              this.dialogRef.close(true)
                                            } else {
                                              this.toster.error('Details not added.', '', { timeOut: 2000 })
                                              var temp={
                                                type:this.data.type,
                                                status:false
                                              }
                                              this.dialogRef.close(temp)
                                            }
                                          }, ((err: any) => {
                                            this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                                            this.dialogRef.close(false)
                                          }))
                                        } else {
                                          this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
                                        }
                                      } else {
                                        this.toster.error('Please uplaod the image of license', '', { timeOut: 2000 })
                                      }
                                    } else {
                                      this.toster.error('Please select type of license', '', { timeOut: 2000 })
                                    }
                                  } else {
                                    this.toster.error('Please select business nature', '', { timeOut: 2000 })
                                  }
                                } else {
                                  this.toster.error('Please Select Premise Address Document Type', '', { timeOut: 2000 })
                                }
                              } else {
                                this.toster.error('Please select company incorporation type', '', { timeOut: 2000 })
                              }
                            } else {
                              this.toster.error('Please enter expected turnover', '', { timeOut: 2000 })
                            }
                          } else {
                              this.toster.error('Please enter current turnover', '', { timeOut: 2000 })
                            }
                        } else {
                          this.toster.error('Please enter a valid Pincode', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please enter pincode', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter address', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter email id', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter name of business', '', { timeOut: 2000 })
          }
      }
      else if(this.type=='existing')
      {
        if (this.selectedBusinessNature && this.selectedBusinessNature != '') {
          data.append('business_nature', this.selectedBusinessNature )  
        }
        else{
          this.toster.error('Please select business nature', '', { timeOut: 2000 })
        }
          if (this.businessName && this.businessName != '') {
            data.append('business_name', this.businessName)
            if (this.emailId && this.emailId != '') {
              if (this.emailId.match(emailRegex)) {
                data.append('email', this.emailId)
                if (this.mobileNo && this.mobileNo != '') {
                  if (this.mobileNo.toString().length == 10) {
                    data.append('mobile', this.mobileNo)
                    if (this.address && this.address != '') {
                      data.append('address', this.address)
                      if (this.pinCode && this.pinCode != '') {
                        data.append('pincode', this.pinCode)
                        if (this.selectedState && this.selectedState != '') {
                          data.append('state', this.selectedState )
                          if (this.licensesNo && this.licensesNo != '') {
                            data.append('registration_no', this.licensesNo)
                            if (this.selectedKOBBusiness.length != 0) {
                              this.selectedKOBBusiness.forEach((ele: any, index: any) => {
                                data.append(`business_kind_ids[${index}]`, ele)
                              })
                              if (this.licensesExpire && this.licensesExpire != '') {
                                data.append('validity_date', this.licensesExpire)
                                if (this.selectedLicenceType && this.selectedLicenceType != '') {
                                  data.append('license_type', this.selectedLicenceType)
                                  // if (this.txtStatus && this.txtStatus != '') {
                                    data.append('certificate_validity', 'Valid' )
                                    if (this.fileData || this.licensesUrl) {
                                      if(this.fileData)
                                      data.append('documents', this.fileData)
                                      else
                                      data.append('documents', this.licensesUrl)
                                    if(this.currentTurnover)
                                      data.append('current_turn_over', this.currentTurnover)
                                    if(this.expectedTurnover)
                                      data.append('expected_turn_over', this.expectedTurnover)

                                    if (this.tcFlag) {
                                        this.httpSv.addExistingLicense(data,this.role,this.data.type)?.subscribe((res: any) => {
                                          if (res['status']) {
                                            this.toster.success('Details have been Added Successfuly.', '', { timeOut: 2000 })
                                            this.tempLicenseNo = res.registration_no
                                            // this.toster.success('You have been created new License successfully. You can add additional details for this license', '', { timeOut: 2000 })
                                            var temp={
                                              type:this.data.type,
                                              status:false
                                            }       
                                            this.dialogRef.close(true)
                                          } else {
                                            this.toster.error('Details not updated.', '', { timeOut: 2000 })
                                            this.dialogRef.close(false)
                                          }
                                        }, ((err: any) => {
                                          this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                                          this.dialogRef.close(false)
                                        }))
                                      } else {
                                        this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
                                      }
                                    } else {
                                      this.toster.error('Please uplaod the image of license', '', { timeOut: 2000 })
                                    }
                                  // } else {
                                  //   this.toster.error('Please select the status', '', { timeOut: 2000 })
                                  // }
                                }
                                else {
                                  this.toster.error('Please select type of license', '', { timeOut: 2000 })
                                }
                              }
                              else {
                                this.toster.error('Please enter validity date', '', { timeOut: 2000 })
                              }
                            }
                            else {
                              this.toster.error('Please select kind of business', '', { timeOut: 2000 })
                            }
                          } else {
                            this.toster.error('Please enter license number', '', { timeOut: 2000 })
                          }
                        } else {
                          this.toster.error('Please select state', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please enter pincode', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter address', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter email id', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter name of business', '', { timeOut: 2000 })
          }
      }
  
    }
    if(this.role == '3'){
    if(this.type == 'new'){
      // if(this.name && this.name != ''){
        data.append('name', this.name)
        if (this.businessName && this.businessName != '') {
          data.append('business_name', this.businessName)
          if (this.emailId && this.emailId != '') {
            if (this.emailId.match(emailRegex)) {
              data.append('email', this.emailId)
              if (this.mobileNo && this.mobileNo != '') {
                if (this.mobileNo.toString().length == 10) {
                  data.append('mobile', this.mobileNo)
                  if (this.address && this.address != '') {
                    data.append('address', this.address)
                    if (this.pinCode && this.pinCode != '') {
                      data.append('pincode', this.pinCode)
                      if (this.selectedState && this.selectedState != '') {
                        data.append('state', this.selectedState )
                        if (this.currentTurnover && this.currentTurnover != '') {
                          data.append('current_turn_over', this.currentTurnover )
                          if (this.expectedTurnover && this.expectedTurnover != '') {
                            data.append('expected_turn_over', this.expectedTurnover )
                            if (this.selectedCompIncorp && this.selectedCompIncorp != '') {
                              data.append('company_incorporation', this.selectedCompIncorp )
                              if (this.selectedPremiseAddress && this.selectedPremiseAddress != '') {
                                data.append('premise_address', this.selectedPremiseAddress )
                                if (this.selectedBusinessNature && this.selectedBusinessNature != '') {
                                  data.append('business_nature', this.selectedBusinessNature )  
                                  if (this.selectedLicenceType && this.selectedLicenceType != '') {
                                    data.append('license_type', this.selectedLicenceType)
                                    if (this.fileData || this.licensesUrl) {
                                      if(this.fileData)
                                      data.append('documents', this.fileData)
                                      else
                                      data.append('documents', this.licensesUrl)
                                      if (this.tcFlag) {

                                        this.httpSv.createNewLicenseDtls(data, this.role, this.data.type)?.subscribe((res: any) => {
                                          if (res['status']) {
                                            
                                            this.tempLicenseNo = res.registration_no
                                            this.toster.success('You have been created new License successfully. You can add additional details for this license', '', { timeOut: 2000 })
                                            var temp={
                                              type:this.data.type,
                                              status:false
                                            }
                                            this.dialogRef.close(true)
                                          } else {
                                            this.toster.error('Details not added.', '', { timeOut: 2000 })
                                            var temp={
                                              type:this.data.type,
                                              status:false
                                            }
                                            this.dialogRef.close(temp)
                                          }
                                        }, ((err: any) => {
                                          this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                                          this.dialogRef.close(false)
                                        }))
                                      } else {
                                        this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
                                      }
                                    } else {
                                      this.toster.error('Please uplaod the image of license', '', { timeOut: 2000 })
                                    }
                                  } else {
                                    this.toster.error('Please select type of license', '', { timeOut: 2000 })
                                  }
                                } else {
                                  this.toster.error('Please select business nature', '', { timeOut: 2000 })
                                }
                              } else {
                                this.toster.error('Please Select Premise Address Document Type', '', { timeOut: 2000 })
                              }
                            } else {
                              this.toster.error('Please select company incorporation type', '', { timeOut: 2000 })
                            }
                          } else {
                            this.toster.error('Please enter expected turnover', '', { timeOut: 2000 })
                          }
                        } else {
                            this.toster.error('Please enter current turnover', '', { timeOut: 2000 })
                          }
                      } else {
                        this.toster.error('Please enter a valid Pincode', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter pincode', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter address', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter email id', '', { timeOut: 2000 })
          }
        } else {
          this.toster.error('Please enter name of business', '', { timeOut: 2000 })
        }
      }
      else if(this.type=='existing')
      {
        console.log(this.data)
          if (this.businessName && this.businessName != '') {
            data.append('business_name', this.businessName)
            if (this.emailId && this.emailId != '') {
              if (this.emailId.match(emailRegex)) {
                data.append('email', this.emailId)
                if (this.mobileNo && this.mobileNo != '') {
                  if (this.mobileNo.toString().length == 10) {
                    data.append('mobile', this.mobileNo)
                    if (this.address && this.address != '') {
                      data.append('address', this.address)
                      if (this.pinCode && this.pinCode != '') {
                        data.append('pincode', this.pinCode)
                        if (this.selectedState && this.selectedState != '') {
                          data.append('state', this.selectedState )
                          if (this.licensesNo && this.licensesNo != '') {
                            data.append('registration_no', this.licensesNo)
                            if (this.selectedKOBBusiness.length != 0) {
                              this.selectedKOBBusiness.forEach((ele: any, index: any) => {
                                data.append(`business_kind_ids[${index}]`, ele)
                              })
                              if (this.licensesExpire && this.licensesExpire != '') {
                                data.append('validity_date', this.licensesExpire)
                                if (this.selectedLicenceType && this.selectedLicenceType != '') {
                                  data.append('license_type', this.selectedLicenceType)
                                  // if (this.txtStatus && this.txtStatus != '') {
                                    data.append('certificate_validity', 'Valid' )
                                    if (this.fileData || this.licensesUrl) {
                                      if(this.fileData)
                                      data.append('documents', this.fileData)
                                      else
                                      data.append('documents', this.licensesUrl)
                                    if (this.tcFlag) {
                                        this.httpSv.addExistingLicense(data,this.role,this.data.type)?.subscribe((res: any) => {
                                          if (res['status']) {
                                            this.toster.success('Details have been Added Successfuly.', '', { timeOut: 2000 })
                                            this.tempLicenseNo = res.registration_no
                                            this.dialogRef.close(true)

                                            // this.toster.success('You have been created new License successfully. You can add additional details for this license', '', { timeOut: 2000 })
                                            var temp={
                                              type:this.data.type,
                                              status:false
                                            }                                          } else {
                                            this.toster.error('Details not updated.', '', { timeOut: 2000 })
                                            this.dialogRef.close(false)
                                          }
                                        }, ((err: any) => {
                                          this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                                          this.dialogRef.close(false)
                                        }))
                                      } else {
                                        this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
                                      }
                                    } else {
                                      this.toster.error('Please uplaod the image of license', '', { timeOut: 2000 })
                                    }
                                  // } else {
                                  //   this.toster.error('Please select the status', '', { timeOut: 2000 })
                                  // }
                                }
                                else {
                                  this.toster.error('Please select type of license', '', { timeOut: 2000 })
                                }
                              }
                              else {
                                this.toster.error('Please enter validity date', '', { timeOut: 2000 })
                              }
                            }
                            else {
                              this.toster.error('Please select kind of business', '', { timeOut: 2000 })
                            }
                          } else {
                            this.toster.error('Please enter license number', '', { timeOut: 2000 })
                          }
                        } else {
                          this.toster.error('Please select state', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please enter pincode', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter address', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter email id', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter name of business', '', { timeOut: 2000 })
          }
      }
  
    }
    if(this.role == '5'){
      if(this.type=='new')
      { 
        // if(this.name && this.name != ''){
          data.append('name', this.name)
          if (this.businessName && this.businessName != '') {
            data.append('business_name', this.businessName)
            if (this.emailId && this.emailId != '') {
              if (this.emailId.match(emailRegex)) {
                data.append('email', this.emailId)
                if (this.mobileNo && this.mobileNo != '') {
                  if (this.mobileNo.toString().length == 10) {
                    data.append('mobile', this.mobileNo)
                    if (this.address && this.address != '') {
                      data.append('address', this.address)
                      if (this.pinCode && this.pinCode != '') {
                        data.append('pincode', this.pinCode)
                        if (this.selectedState && this.selectedState != '') {
                          data.append('state', this.selectedState )
                          if (this.currentTurnover && this.currentTurnover != '') {
                            data.append('current_turn_over', this.currentTurnover )
                            if (this.expectedTurnover && this.expectedTurnover != '') {
                              data.append('expected_turn_over', this.expectedTurnover )
                              if (this.selectedCompIncorp && this.selectedCompIncorp != '') {
                                data.append('company_incorporation', this.selectedCompIncorp )
                                if (this.selectedPremiseAddress && this.selectedPremiseAddress != '') {
                                  data.append('premise_address', this.selectedPremiseAddress )
                                  if (this.selectedBusinessNature && this.selectedBusinessNature != '') {
                                    data.append('business_nature', this.selectedBusinessNature )  
                                    if (this.selectedLicenceType && this.selectedLicenceType != '') {
                                      data.append('license_type', this.selectedLicenceType)
                                      if (this.fileData || this.licensesUrl) {
                                        if(this.fileData)
                                        data.append('documents', this.fileData)
                                        else
                                        data.append('documents', this.licensesUrl)
                                        if (this.tcFlag) {
                                          this.httpSv.createNewLicenseDtls(data, this.role, this.data.type)?.subscribe((res: any) => {
                                            if (res['status']) {
                                              
                                              this.tempLicenseNo = res.registration_no
                                              this.toster.success('You have been created new License successfully. You can add additional details for this license', '', { timeOut: 4000 })
                                              var temp={
                                                type:this.data.type,
                                                status:false
                                              }
                                              this.dialogRef.close(true)
                                            } else {
                                              this.toster.error('Details not added.', '', { timeOut: 2000 })
                                              var temp={
                                                type:this.data.type,
                                                status:false
                                              }
                                              this.dialogRef.close(temp)
                                            }
                                          }, ((err: any) => {
                                            this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                                            this.dialogRef.close(false)
                                          }))
                                        } else {
                                          this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
                                        }
                                      } else {
                                        this.toster.error('Please upload the  the Premise Address document', '', { timeOut: 2000 })
                                      }
                                    } else {
                                      this.toster.error('Please select type of license', '', { timeOut: 2000 })
                                    }
                                  } else {
                                    this.toster.error('Please select business nature', '', { timeOut: 2000 })
                                  }
                                } else {
                                  this.toster.error('Please Select Premise Address Document Type', '', { timeOut: 2000 })
                                }
                              } else {
                                this.toster.error('Please select company incorporation type', '', { timeOut: 2000 })
                              }
                            } else {
                              this.toster.error('Please enter expected turnover', '', { timeOut: 2000 })
                            }
                          } else {
                              this.toster.error('Please enter current turnover', '', { timeOut: 2000 })
                            }
                        } else {
                          this.toster.error('Please enter a valid Pincode', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please enter pincode', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter address', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter email id', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter name of business', '', { timeOut: 2000 })
          }
        // } else {
        //   this.toster.error('Please enter the name', '', { timeOut: 2000 })
        // }
      }
      else if(this.type=='existing')
      {  
          if (this.businessName && this.businessName != '') {
            data.append('business_name', this.businessName)
            if (this.emailId && this.emailId != '') {
              if (this.emailId.match(emailRegex)) {
                data.append('email', this.emailId)
                if (this.mobileNo && this.mobileNo != '') {
                  if (this.mobileNo.toString().length == 10) {
                    data.append('mobile', this.mobileNo)
                    if (this.address && this.address != '') {
                      data.append('address', this.address)
                      if (this.pinCode && this.pinCode != '') {
                        data.append('pincode', this.pinCode)
                        if (this.selectedState && this.selectedState != '') {
                          data.append('state', this.selectedState )
                          if (this.licensesNo && this.licensesNo != '') {
                            data.append('registration_no', this.licensesNo)
                            if (this.selectedKOBBusiness.length != 0) {
                              this.selectedKOBBusiness.forEach((ele: any, index: any) => {
                                data.append(`business_kind_ids[${index}]`, ele)
                              })
                              if (this.licensesExpire && this.licensesExpire != '') {
                                data.append('validity_date', this.licensesExpire)
                                if (this.selectedLicenceType && this.selectedLicenceType != '') {
                                  data.append('license_type', this.selectedLicenceType)
                                  // if (this.txtStatus && this.txtStatus != '') {
                                    data.append('certificate_validity', 'Valid' )
                                    if (this.fileData || this.licensesUrl) {
                                      if(this.fileData)
                                      data.append('documents', this.fileData)
                                      else
                                      data.append('documents', this.licensesUrl)
                                    if (this.tcFlag) {
                                        this.httpSv.addExistingLicense(data,this.role)?.subscribe((res: any) => {
                                          if (res['status']) {
                                            this.toster.success('Details have been Added Successfuly.', '', { timeOut: 2000 })
                                            this.tempLicenseNo = res.registration_no
                                            this.dialogRef.close(true)
                                            var temp={
                                              type:this.data.type,
                                              status:false
                                            }                                          } else {
                                            this.toster.error('Details not updated.', '', { timeOut: 2000 })
                                            this.dialogRef.close(false)
                                          }
                                        }, ((err: any) => {
                                          this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                                          this.dialogRef.close(false)
                                        }))
                                      } else {
                                        this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
                                      }
                                    } else {
                                      this.toster.error('Please uplaod the image of license', '', { timeOut: 2000 })
                                    }
                                  // } else {
                                  //   this.toster.error('Please select the status', '', { timeOut: 2000 })
                                  // }
                                }
                                else {
                                  this.toster.error('Please select type of license', '', { timeOut: 2000 })
                                }
                              }
                              else {
                                this.toster.error('Please enter validity date', '', { timeOut: 2000 })
                              }
                            }
                            else {
                              this.toster.error('Please select kind of business', '', { timeOut: 2000 })
                            }
                          } else {
                            this.toster.error('Please enter license number', '', { timeOut: 2000 })
                          }
                        } else {
                          this.toster.error('Please select state', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please enter pincode', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter address', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter email id', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter name of business', '', { timeOut: 2000 })
          }
      }
    }
  }


  updatePersonalDtls() {
    this.txtStatus = this.tempStatus
    if(this.pinCode.length<6){
      this.toster.error("Pincode has to be 6 digits",'',{timeOut:2000})
      return
    }
    if(!this.currentTurnover || this.currentTurnover.length<=0){
      this.toster.error("Please enter current turn over",'',{timeOut:2000})
      return
    }
    if(!this.expectedTurnover || this.expectedTurnover.length<=0){
      this.toster.error("Please enter expected turn over",'',{timeOut:2000})
      return
    }
    if(!this.selectedBusinessNature || this.selectedBusinessNature.length <= 0){
      this.toster.error("Please select the Nature of bussiness",'',{timeOut:2000})
      return
    }
    if(!this.selectedCity || this.selectedCity.length <= 0){
      this.toster.error("Please enter the Pincode",'',{timeOut:2000})
      return
    }
    let data = new FormData()
    data.append('current_turn_over', this.currentTurnover)
    data.append('city', this.selectedCity)
    data.append('expected_turn_over', this.expectedTurnover)
    data.append('business_nature', this.selectedBusinessNature)
    if (this.role == '5') {
      if (this.businessName && this.businessName != '') {
        data.append('business_name', this.businessName)
        if (this.emailId && this.emailId != '') {
          if (this.emailId.match(emailRegex)) {
            data.append('email', this.emailId)
            if (this.mobileNo && this.mobileNo != '') {
              if (this.mobileNo.toString().length == 10) {
                data.append('mobile', this.mobileNo)
                if (this.address && this.address != '') {
                  data.append('address', this.address)
                  if (this.pinCode && this.pinCode != '') {
                    data.append('pincode', this.pinCode)
                    if (this.selectedState && this.selectedState != '') {
                      data.append('state', this.selectedState )
                      if (this.licensesNo && this.licensesNo != '') {
                        data.append('registration_no', this.licensesNo)
                        if (this.selectedKOBBusiness.length != 0) {
                          this.selectedKOBBusiness.forEach((ele: any, index: any) => {
                            data.append(`business_kind_ids[${index}]`, ele)
                          })
                          if (this.licensesExpire && this.licensesExpire != '') {
                            data.append('validity_date', this.licensesExpire)
                            if (this.selectedLicenceType && this.selectedLicenceType != '') {
                              data.append('license_type', this.selectedLicenceType)
                              if (this.txtStatus && this.txtStatus != '') {
                                data.append('certificate_validity', this.txtStatus == 'Active' ? 'Valid' : this.txtStatus == 'Requested' ? 'Request Sent' : this.txtStatus)
                                if (this.fileData || this.licensesUrl) {
                                  if(this.fileData)
                                  data.append('documents', this.fileData)
                                  else
                                  data.append('documents', this.licensesUrl)
                                if (this.tcFlag) {
                                    this.httpSv.updateLiciencePersonalDtls(this.data.id, data,this.role)?.subscribe((res: any) => {
                                      if (res['status']) {
                                        this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                                        this.ngOnInit()
                                        this.dialogRef.close(true)
                                      } else {
                                        this.toster.error('Details not updated.', '', { timeOut: 2000 })
                                        this.dialogRef.close(false)
                                      }
                                    }, ((err: any) => {
                                      this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                                      this.dialogRef.close(false)
                                    }))
                                  } else {
                                    this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
                                  }
                                } else {
                                  this.toster.error('Please upload the image of license', '', { timeOut: 2000 })
                                }
                              } else {
                                this.toster.error('Please select the status', '', { timeOut: 2000 })
                              }
                            }
                            else {
                              this.toster.error('Please select type of license', '', { timeOut: 2000 })
                            }
                          }
                          else {
                            this.toster.error('Please enter validity date', '', { timeOut: 2000 })
                          }
                        }
                        else {
                          this.toster.error('Please select kind of business', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please enter license number', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter a valid Pincode', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter pincode', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter address', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
          }
        } else {
          this.toster.error('Please enter email id', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter name of business', '', { timeOut: 2000 })
      }
    }
    else if (this.role == '3') {
      data.append('business_kind_ids', this.selectedBusinessNature)
      if (this.businessName && this.businessName != '') {
        data.append('business_name', this.businessName)
        if (this.emailId && this.emailId != '') {
          if (this.emailId.match(emailRegex)) {
            data.append('email', this.emailId)
            if (this.mobileNo && this.mobileNo != '') {
              if (this.mobileNo.toString().length == 10) {
                data.append('mobile', this.mobileNo)
                if (this.address && this.address != '') {
                  data.append('address', this.address)
                  if (this.pinCode && this.pinCode != '') {
                    data.append('pincode', this.pinCode)
                    if (this.selectedState && this.selectedState != '') {
                      data.append('state', this.selectedState )
                      if (this.licensesNo && this.licensesNo != '') {
                        data.append('registration_no', this.licensesNo)
                        if (this.selectedKOBBusiness && this.selectedKOBBusiness.length != 0) {
                          this.selectedKOBBusiness.forEach((ele: any, index: any) => {
                            data.append(`business_kind_ids[${index}]`, ele)
                          })
                          if (this.licensesExpire && this.licensesExpire != '') {
                            data.append('validity_date', this.licensesExpire)
                            if (this.selectedLicenceType && this.selectedLicenceType != '') {
                              data.append('license_type', this.selectedLicenceType)
                              // if (this.txtStatus && this.txtStatus != '') {
                              //   data.append('certificate_validity', this.txtStatus == 'Active' ? 'Valid' : this.txtStatus == 'Requested' ? 'Request Sent' : this.txtStatus)
                              if (this.fileData || this.licensesUrl) {
                                if(this.fileData)
                                data.append('documents', this.fileData)
                                else
                                data.append('documents', this.licensesUrl)
                                  data.append('status', this.txtStatus)
                                  if (this.tcFlag) {
                                    let t=JSON.stringify(data)
                                    data.forEach((value,key) => {
                                      console.log(key+" "+value)
                                    });
                                    this.httpSv.updateLiciencePersonalDtls(this.data.licId ? this.data.licId : this.data.licenseDetails.fssai_license_details[0].id, data,this.role,this.data.userType)?.subscribe((res: any) => {
                                      if (res['status']) {
                                        this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                                        this.ngOnInit()
                                        this.dialogRef.close(true)
                                      } else {
                                        this.toster.error('Details not updated.', '', { timeOut: 2000 })
                                        this.dialogRef.close(false)
                                      }
                                    }, ((err: any) => {
                                      this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                                      this.dialogRef.close(false)
                                    }))
                                  } else {
                                    this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
                                  }
                                } else {
                                  this.toster.error('Please uplaod the image of license', '', { timeOut: 2000 })
                                }
                              // } else {
                              //   this.toster.error('Please select the status', '', { timeOut: 2000 })
                              // }
                            }
                            else {
                              this.toster.error('Please select type of license', '', { timeOut: 2000 })
                            }
                          }
                          else {
                            this.toster.error('Please enter validity date', '', { timeOut: 2000 })
                          }
                        }
                        else {
                          this.toster.error('Please select kind of business', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please enter license number', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter a valid Pincode', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter pincode', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter address', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
          }
        } else {
          this.toster.error('Please enter email id', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter name of business', '', { timeOut: 2000 })
      }

    }
    else if (this.role == '1') {
      if (this.businessName && this.businessName != '') {
        data.append('business_name', this.businessName)
        if (this.emailId && this.emailId != '') {
          if (this.emailId.match(emailRegex)) {
            data.append('email', this.emailId)
            if (this.mobileNo && this.mobileNo != '') {
              if (this.mobileNo.toString().length == 10) {
                data.append('mobile', this.mobileNo)
                if (this.address && this.address != '') {
                  data.append('address', this.address)
                  if (this.pinCode && this.pinCode != '') {
                    data.append('pincode', this.pinCode)
                    if (this.selectedState && this.selectedState != '') {
                      data.append('state', this.selectedState )
                      if (this.licensesNo && this.licensesNo != '') {
                        data.append('registration_no', this.licensesNo)
                        if (this.selectedKOBBusiness.length != 0) {
                          this.selectedKOBBusiness.forEach((ele: any, index: any) => {
                            data.append(`business_kind_ids[${index}]`, ele)
                          })
                          if (this.licensesExpire && this.licensesExpire != '') {
                            data.append('validity_date', this.licensesExpire)
                            if (this.selectedLicenceType && this.selectedLicenceType != '') {
                              data.append('license_type', this.selectedLicenceType)
                              // if (this.txtStatus && this.txtStatus != '') {
                              //   data.append('certificate_validity', this.txtStatus == 'Active' ? 'Valid' : this.txtStatus == 'Requested' ? 'Request Sent' : this.txtStatus)
                                if (this.fileData || this.licensesUrl) {
                                  if(this.fileData)
                                  data.append('documents', this.fileData)
                                  else
                                  data.append('documents', this.licensesUrl)
                                  data.append('status',this.txtStatus)
                                  if (this.tcFlag) {
                                    let t=JSON.stringify(data)

                                    this.httpSv.updateLiciencePersonalDtls(this.modifyId, data,this.role,this.data.type)?.subscribe((res: any) => {
                                      if (res['status']) {
                                        this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                                        var temp={
                                          type:this.data.type,
                                          status:true
                                        }
                                        this.dialogRef.close(temp)
                                      } else {
                                        this.toster.error('Details not updated.', '', { timeOut: 2000 })
                                        var temp={
                                          type:this.data.type,
                                          status:false
                                        }
                                        this.dialogRef.close(temp)
                                      }
                                    }, ((err: any) => {
                                      this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                                      this.dialogRef.close(false)
                                    }))
                                  } else {
                                    this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
                                  }
                                } else {
                                  this.toster.error('Please uplaod the image of license', '', { timeOut: 2000 })
                                }
                              // } else {
                              //   this.toster.error('Please select the status', '', { timeOut: 2000 })
                              // }
                            }
                            else {
                              this.toster.error('Please select type of license', '', { timeOut: 2000 })
                            }
                          }
                          else {
                            this.toster.error('Please enter validity date', '', { timeOut: 2000 })
                          }
                        }
                        else {
                          this.toster.error('Please select kind of business', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please enter license number', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter a valid Pincode', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter pincode', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter address', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
          }
        } else {
          this.toster.error('Please enter email id', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter name of business', '', { timeOut: 2000 })
      }

    } else if(this.role == '2'){
      if (this.businessName && this.businessName != '') {
        data.append('business_name', this.businessName)
        if (this.emailId && this.emailId != '') {
          if (this.emailId.match(emailRegex)) {
            data.append('email', this.emailId)
            if (this.mobileNo && this.mobileNo != '') {
              if (this.mobileNo.toString().length == 10) {
                data.append('mobile', this.mobileNo)
                if (this.address && this.address != '') {
                  data.append('address', this.address)
                  if (this.pinCode && this.pinCode != '') {
                    data.append('pincode', this.pinCode)
                    if (this.selectedState && this.selectedState != '') {
                      data.append('state', this.selectedState)
                      if (this.licensesNo && this.licensesNo != '') {
                        data.append('registration_no', this.licensesNo)
                        if (this.selectedKOBBusiness.length != 0) {
                          this.selectedKOBBusiness.forEach((ele: any, index: any) => {
                            data.append(`business_kind_ids[${index}]`, ele)
                          })
                          if (this.licensesExpire && this.licensesExpire != '') {
                            data.append('validity_date', this.licensesExpire)
                            if (this.selectedLicenceType && this.selectedLicenceType != '') {
                              data.append('license_type', this.selectedLicenceType)
                              if (this.txtStatus && this.txtStatus != '') {
                                data.append('certificate_validity', this.txtStatus == 'Active' ? 'Valid' : this.txtStatus == 'Requested' ? 'Request Sent' : this.txtStatus)
                                if (this.fileData || this.licensesUrl) {
                                  if(this.fileData)
                                  data.append('documents', this.fileData)
                                  else
                                  data.append('documents', this.licensesUrl)
                                  data.append('status',this.txtStatus)
                                  if (this.tcFlag) {
                                    let t=JSON.stringify(data)

                                    this.httpSv.updateAMLiciencePersonalDtls(this.modifyId, data,this.role,this.data.type)?.subscribe((res: any) => {
                                      if (res['status']) {
                                        this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                                        var temp={
                                          type:this.data.type,
                                          status:true
                                        }
                                        this.dialogRef.close(temp)
                                      } else {
                                        this.toster.error('Details not updated.', '', { timeOut: 2000 })
                                        var temp={
                                          type:this.data.type,
                                          status:false
                                        }
                                        this.dialogRef.close(temp)
                                      }
                                    }, ((err: any) => {
                                      this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                                      this.dialogRef.close(false)
                                    }))
                                  } else {
                                    this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
                                  }
                                } else {
                                  this.toster.error('Please uplaod the image of license', '', { timeOut: 2000 })
                                }
                              } else {
                                this.toster.error('Please select the status', '', { timeOut: 2000 })
                              }
                            }
                            else {
                              this.toster.error('Please select type of license', '', { timeOut: 2000 })
                            }
                          }
                          else {
                            this.toster.error('Please enter validity date', '', { timeOut: 2000 })
                          }
                        }
                        else {
                          this.toster.error('Please select kind of business', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please enter license number', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter a valid Pincode', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter pincode', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter address', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
          }
        } else {
          this.toster.error('Please enter email id', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter name of business', '', { timeOut: 2000 })
      }

    }
  }


  //############    Application  ############
  applicationDisplayedColumns: string[] = ['sl_no', 'doc_name', 'description', 'view_doc', 'status','sr'];
  applicationData: any[] = []
  applicationDataSource: any =new MatTableDataSource() 
  tcFlag: boolean = false


  //############    Testing  ############
  testingDisplayedColumns: string[] = ['sl_no', 'product_name', 'product_id', 'report_date', 'view_doc', 'status','sr'];
  testingData: any[] = []
  testingDataSource: any =new MatTableDataSource() 

  //############    Training  ############
  trainingDisplayedColumns: string[] = ['sl_no', 'emp_name', 'emp_id', 'training_date', 'view_doc', 'status','sr'];
  trainingData: any[] = []
  trainingDataSource: any =new MatTableDataSource() 


  //############    Health  ############
  healthDisplayedColumns: string[] = ['sl_no', 'emp_name', 'emp_id', 'report_date', 'view_doc', 'status','sr'];
  healthData: any[] = []
  healthDataSource: any =new MatTableDataSource() 


  //############    Calibration  ############
  calibrationDisplayedColumns: string[] = ['sl_no', 'instrument_name', 'equipment_id', 'calibration_date', 'view_doc', 'status','sr'];
  calibrationData: any[] = []
  calibrationDataSource: any=new MatTableDataSource() 


  //############    Others  ############
  otherDisplayedColumns: string[] = ['sl_no', 'doc_name', 'description', 'expiry_date', 'view_doc', 'status','sr'];
  otherData: any[] = []
  otherDataSource: any=new MatTableDataSource() 


//############    AUDITS  ############
auditsDisplayedColumns: string[] = ['sl_no', 'doc_name', 'description', 'view_doc', 'status','sr'];
auditsData: any[] = []
auditsDataSource: any =new MatTableDataSource() 

  
  //############    Service Request  ############
  srDisplayedColumns: string[] = ['sl_no',
    'id',
    'services',
    'fssai_regd_no',
    'business_name',
    'state',
    'am',
    'date',
    'updateddate',
    'status'];
  srData: any[] = []
  srDataSource: any=new MatTableDataSource() 




  modifyAppUser(id:any){
    console.log( this.data.type)
    const dialogRef = this.dialog.open(DialogServiceRequestModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        id,
        type : this.data.type == 'Retail' ? 'ru' : 'eu',
        isSR : true
      },
    }).afterClosed().subscribe(()=>{
      this.ngOnInit()
    })
  }







  viewLicence(url: any = this.licensesUrl) {
    let fileType = url.split('.')
    fileType = fileType[fileType.length - 1]
    const dialogRef = this.dialog.open(DialogAppLicenseView, {
      hasBackdrop: true,
      // disableClose:true
      data: {
        type: fileType,
        src: url
      }
    })
  }
  getLicenceseDtls(licNo: any) {
    this.httpSv.getLicensesDtls(licNo).subscribe((res: any) => {
      if (res['status']) {
        //License Dtls
        const liDtl = res['results'][0].fssai_license_details[0]
        this.businessName = liDtl.business_name
        this.emailId = liDtl.email
        this.mobileNo = liDtl.mobile
        this.address = liDtl.address
        this.dataShare.userStorage = liDtl.size ? liDtl.size : 0
        this.currentTurnover = liDtl.current_turn_over
        this.expectedTurnover = liDtl.expected_turn_over
        this.selectedBusinessNature = liDtl.business_nature
        this.pinCode = liDtl.pincode
        this.licensesNo = liDtl.registration_no
        if (liDtl.business_kind_ids)
          this.selectedKOBBusiness = liDtl.business_kind_ids.split(',').map((ele: any) => +ele)
        this.licensesExpire = liDtl.validity_date
        this.selectedLicenceType = liDtl.license_type
        // this.txtStatus = liDtl.certificate_validity == 'Valid' ? 'Active' : liDtl.certificate_validity == 'Requested Sent' ? '' : liDtl.certificate_validity
        this.txtStatus = liDtl.status
        this.tempStatus = liDtl.status
        this.licensesUrl = liDtl.documents
        const reIndex = this.allState.findIndex((ele: any) => ele.name == liDtl.state)
        console.log(reIndex)
        // if (reIndex != -1)
          this.selectedState = liDtl.state
        console.log(this.selectedState)

        //Application Dtls
        if (res['results'][0].application_document_details.length > 0) {
          this.applicationData = res['results'][0].application_document_details
          this.applicationDataSource.data = this.applicationData
        }

        //Testing Dtls
        if (res['results'][0].testing_document_details.length > 0) {
          this.testingData = res['results'][0].testing_document_details
          this.testingDataSource.data = this.testingData
        }

        //Training Dtls
        if (res['results'][0].training_document_details.length > 0) {
          this.trainingData = res['results'][0].training_document_details
          this.trainingDataSource.data = this.trainingData
        }

        //Health Dtls
        if (res['results'][0].health_document_details.length > 0) {
          this.healthData = res['results'][0].health_document_details
          this.healthDataSource.data = this.healthData
        }

        //Calibration Dtls
        if (res['results'][0].calibration_document_details.length > 0) {
          this.calibrationData = res['results'][0].calibration_document_details
          this.calibrationDataSource.data = this.calibrationData
        }

        //Others Dtls
        if (res['results'][0].others_document_details.length > 0) {
          this.otherData = res['results'][0].others_document_details
          this.otherDataSource.data = this.otherData
        }
        //AUDITS Dtls
        if (res['results'][0].audit_document_details.length > 0) {
          this.auditsData = res['results'][0].audit_document_details
          this.auditsDataSource.data = this.auditsData
        }

      }
      else {

      }
    }, (err: any) => {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
    })
  }

  getAllBMLicenseDtls(licNo:any,type:any){
    // console.log(type,'aaaaaaaaaaaaaaaaaaaaaa')
    if(type=='retail')
    {
      this.httpSv.getOneBMRetailLicense(licNo).subscribe((res: any) => {
        if (res['status']) {
          //License Dtls
          const liDtl = res['results'][0].fssai_license_details[0]
          this.businessName = liDtl.business_name
          this.userId = liDtl.user_id
          this.emailId = liDtl.email
          this.dataShare.userStorage = liDtl.size
          this.modifyId=liDtl.id
          this.currentTurnover = liDtl.current_turn_over
          this.expectedTurnover = liDtl.expected_turn_over
          this.mobileNo = liDtl.mobile
          this.address = liDtl.address
          this.pinCode = liDtl.pincode
          this.licensesNo = liDtl.registration_no
          if (liDtl.business_kind_ids)
            this.selectedKOBBusiness = liDtl.business_kind_ids.split(',').map((ele: any) => +ele)
          this.licensesExpire = liDtl.validity_date
          this.selectedLicenceType = liDtl.license_type
          // this.txtStatus = liDtl.certificate_validity == 'Valid' ? 'Active' : liDtl.certificate_validity == 'Requested Sent' ? '' : liDtl.certificate_validity
          this.txtStatus = liDtl.status
          this.tempStatus = liDtl.status
          this.licensesUrl = liDtl.documents
          const reIndex = this.allState.findIndex((ele: any) => ele.name == liDtl.state)
          console.log(reIndex)
          // if (reIndex != -1)
            this.selectedState =liDtl.state
          console.log(this.selectedState)

          //Application Dtls
          if (res['results'][0].application_document_details.length > 0) {
            this.applicationData = res['results'][0].application_document_details
            this.applicationDataSource.data = this.applicationData
            // this.applicationDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Testing Dtls
          if (res['results'][0].testing_document_details.length > 0) {
            this.testingData = res['results'][0].testing_document_details
            this.testingDataSource.data = this.testingData
            // this.testingDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Training Dtls
          if (res['results'][0].training_document_details.length > 0) {
            this.trainingData = res['results'][0].training_document_details
            this.trainingDataSource.data = this.trainingData
            // this.trainingDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Health Dtls
          if (res['results'][0].health_document_details.length > 0) {
            this.healthData = res['results'][0].health_document_details
            this.healthDataSource.data = this.healthData
            // this.healthDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Calibration Dtls
          if (res['results'][0].calibration_document_details.length > 0) {
            this.calibrationData = res['results'][0].calibration_document_details
            this.calibrationDataSource.data = this.calibrationData
            // this.calibrationDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Others Dtls
          if (res['results'][0].others_document_details.length > 0) {
            this.otherData = res['results'][0].others_document_details
            this.otherDataSource.data = this.otherData
            // this.otherDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

        }
        else {

        }
      }, (err: any) => {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      })
    }
    else{
      this.httpSv.getOneBMEnterpiseLicense(licNo).subscribe((res: any) => {
        if (res['status']) {
          //License Dtls
          const liDtl = res['results'][0].fssai_license_details[0]
          this.businessName = liDtl.business_name
          this.modifyId=liDtl.id
          this.emailId = liDtl.email
          this.userId=liDtl.user_id
          this.mobileNo = liDtl.mobile
          this.currentTurnover = liDtl.current_turn_over
          this.expectedTurnover = liDtl.expected_turn_over
          this.address = liDtl.address
          this.pinCode = liDtl.pincode
          this.licensesNo = liDtl.registration_no
          if (liDtl.business_kind_ids)
            this.selectedKOBBusiness = liDtl.business_kind_ids.split(',').map((ele: any) => +ele)
          this.licensesExpire = liDtl.validity_date
          this.selectedLicenceType = liDtl.license_type
          // this.txtStatus = liDtl.certificate_validity == 'Valid' ? 'Active' : liDtl.certificate_validity == 'Requested Sent' ? '' : liDtl.certificate_validity
          this.txtStatus = liDtl.status
          this.tempStatus = liDtl.status
          this.licensesUrl = liDtl.documents
          const reIndex = this.allState.findIndex((ele: any) => ele.name == liDtl.state)
          console.log(reIndex)
          // if (reIndex != -1)
            this.selectedState = liDtl.state
          console.log(this.selectedState)

          //Application Dtls
          if (res['results'][0].application_document_details.length > 0) {
            this.applicationData = res['results'][0].application_document_details
            this.applicationDataSource.data = this.applicationData
            // this.applicationDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Testing Dtls
          if (res['results'][0].testing_document_details.length > 0) {
            this.testingData = res['results'][0].testing_document_details
            this.testingDataSource.data = this.testingData
            // this.testingDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Training Dtls
          if (res['results'][0].training_document_details.length > 0) {
            this.trainingData = res['results'][0].training_document_details
            this.trainingDataSource.data = this.trainingData
            // this.trainingDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Health Dtls
          if (res['results'][0].health_document_details.length > 0) {
            this.healthData = res['results'][0].health_document_details
            this.healthDataSource.data = this.healthData
            // this.healthDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Calibration Dtls
          if (res['results'][0].calibration_document_details.length > 0) {
            this.calibrationData = res['results'][0].calibration_document_details
            this.calibrationDataSource.data = this.calibrationData
            // this.calibrationDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Others Dtls
          if (res['results'][0].others_document_details.length > 0) {
            this.otherData = res['results'][0].others_document_details
            this.otherDataSource.data = this.otherData

          }

        }
        else {

        }
      }, (err: any) => {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      })
    }
  }
  getAllsupLicenseDtls(licNo: any,type:any) {
    if(type=='retail')
    {
      this.httpSv.getOneRetailLicense(licNo).subscribe((res: any) => {
        if (res['status']) {
          //License Dtls
          const liDtl = res['results'][0].fssai_license_details[0]
          this.businessName = liDtl.business_name
          this.userId = liDtl.user_id
          this.emailId = liDtl.email
          this.currentTurnover = liDtl.current_turn_over
          this.expectedTurnover = liDtl.expected_turn_over
          this.modifyId=liDtl.id
          this.selectedBusinessNature = liDtl.business_nature
          this.currentTurnover = liDtl.current_turn_over
          this.expectedTurnover = liDtl.expected_turn_over
          this.mobileNo = liDtl.mobile
          this.address = liDtl.address
          this.pinCode = liDtl.pincode
          this.licensesNo = liDtl.registration_no
          this.dataShare.userStorage = liDtl.size ? liDtl.size : 0
          this.userStorage = liDtl.size ? liDtl.size : 0
          if (liDtl.business_kind_ids)
            this.selectedKOBBusiness = liDtl.business_kind_ids.split(',').map((ele: any) => +ele)
          this.licensesExpire = liDtl.validity_date
          this.selectedLicenceType = liDtl.license_type
          // this.txtStatus = liDtl.certificate_validity == 'Valid' ? 'Active' : liDtl.certificate_validity == 'Requested Sent' ? '' : liDtl.certificate_validity
          this.txtStatus = liDtl.status
          this.tempStatus = liDtl.status
          this.licensesUrl = liDtl.documents
          const reIndex = this.allState.findIndex((ele: any) => ele.name == liDtl.state)
          console.log(reIndex)
          // if (reIndex != -1)
            this.selectedState =liDtl.state
            this.selectedCity =liDtl.city
          console.log(this.selectedState)

          //Application Dtls
          if (res['results'][0].application_document_details.length > 0) {
            this.applicationData = res['results'][0].application_document_details
            this.applicationDataSource.data = this.applicationData
            // this.applicationDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Testing Dtls
          if (res['results'][0].testing_document_details.length > 0) {
            this.testingData = res['results'][0].testing_document_details
            this.testingDataSource.data = this.testingData
            // this.testingDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Training Dtls
          if (res['results'][0].training_document_details.length > 0) {
            this.trainingData = res['results'][0].training_document_details
            this.trainingDataSource.data = this.trainingData
            // this.trainingDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Health Dtls
          if (res['results'][0].health_document_details.length > 0) {
            this.healthData = res['results'][0].health_document_details
            this.healthDataSource.data = this.healthData
            // this.healthDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Calibration Dtls
          if (res['results'][0].calibration_document_details.length > 0) {
            this.calibrationData = res['results'][0].calibration_document_details
            this.calibrationDataSource.data = this.calibrationData
            // this.calibrationDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Others Dtls
          if (res['results'][0].others_document_details.length > 0) {
            this.otherData = res['results'][0].others_document_details
            this.otherDataSource.data = this.otherData
            // this.otherDataSource = new MatTableDataSource(ELEMENT_DATA)

          }
          if (res['results'][0].service_requests_details.length > 0) {
            this.srData = res['results'][0].service_requests_details
            this.srDataSource.data = this.srData
            // this.otherDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

        }
        else {

        }
      }, (err: any) => {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      })
    }
    else{
      this.httpSv.getOneEnterpiseLicense(licNo).subscribe((res: any) => {
        if (res['status']) {
          //License Dtls
          const liDtl = res['results'][0].fssai_license_details[0]
          this.businessName = liDtl.business_name
          this.modifyId=liDtl.id
          this.emailId = liDtl.email
          this.userId=liDtl.user_id
          this.mobileNo = liDtl.mobile
          this.currentTurnover = liDtl.current_turn_over
          this.expectedTurnover = liDtl.expected_turn_over
          this.selectedBusinessNature = liDtl.business_nature
          this.address = liDtl.address
          this.pinCode = liDtl.pincode
          this.licensesNo = liDtl.registration_no
          if (liDtl.business_kind_ids)
            this.selectedKOBBusiness = liDtl.business_kind_ids.split(',').map((ele: any) => +ele)
          this.licensesExpire = liDtl.validity_date
          this.selectedLicenceType = liDtl.license_type
          // this.txtStatus = liDtl.certificate_validity == 'Valid' ? 'Active' : liDtl.certificate_validity == 'Requested Sent' ? '' : liDtl.certificate_validity
          this.txtStatus = liDtl.status
          this.tempStatus = liDtl.status
          this.licensesUrl = liDtl.documents
          const reIndex = this.allState.findIndex((ele: any) => ele.name == liDtl.state)
          console.log(reIndex)
          // if (reIndex != -1)
            this.selectedState = liDtl.state
            this.selectedCity = liDtl.city
          console.log(this.selectedState)

          //Application Dtls
          if (res['results'][0].application_document_details.length > 0) {
            this.applicationData = res['results'][0].application_document_details
            this.applicationDataSource.data = this.applicationData
            // this.applicationDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Testing Dtls
          if (res['results'][0].testing_document_details.length > 0) {
            this.testingData = res['results'][0].testing_document_details
            this.testingDataSource.data = this.testingData
            // this.testingDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Training Dtls
          if (res['results'][0].training_document_details.length > 0) {
            this.trainingData = res['results'][0].training_document_details
            console.log(this.trainingData,'aaa')

            this.trainingDataSource.data = this.trainingData
            // this.trainingDataSource = new MatTableDataSource(ELEMENT_DATA)

          }if (res['results'][0].service_requests_details.length > 0) {
            this.srData = res['results'][0].service_requests_details
            this.srDataSource.data = this.srData
            // this.otherDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Health Dtls
          if (res['results'][0].health_document_details.length > 0) {
            this.healthData = res['results'][0].health_document_details
            this.healthDataSource.data = this.healthData
            // this.healthDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Calibration Dtls
          if (res['results'][0].calibration_document_details.length > 0) {
            this.calibrationData = res['results'][0].calibration_document_details
            console.log(this.calibrationData,'aaa')
            this.calibrationDataSource.data = this.calibrationData
            // this.calibrationDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Others Dtls
          if (res['results'][0].others_document_details.length > 0) {
            this.otherData = res['results'][0].others_document_details
            this.otherDataSource.data = this.otherData

          }

        }
        else {

        }
      }, (err: any) => {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      })
    }
  }

  getAllAMLicenseDtls(licNo: any,type:any) {
    if(type=='ru' || type == 'retail')
    {
      this.httpSv.getAMOneRetailLicense(licNo).subscribe((res: any) => {
        if (res['status']) {
          //License Dtls
          const liDtl = res['results'][0].fssai_license_details[0]
          this.businessName = liDtl.business_name
          this.emailId = liDtl.email
          this.modifyId=liDtl.id
          this.dataShare.userStorage = liDtl.size ? liDtl.size : 0
          this.userStorage = liDtl.size ? liDtl.size : 0
          this.currentTurnover = liDtl.current_turn_over
          this.expectedTurnover = liDtl.expected_turn_over
          this.mobileNo = liDtl.mobile
          this.address = liDtl.address
          this.selectedBusinessNature = liDtl.business_nature
          this.pinCode = liDtl.pincode
          this.licensesNo = liDtl.registration_no
          this.userId = liDtl.user_id
          console.log("aaaaaaaa",this.userId)
          if (liDtl.business_kind_ids)
            this.selectedKOBBusiness = liDtl.business_kind_ids.split(',').map((ele: any) => +ele)
          this.licensesExpire = liDtl.validity_date
          this.selectedLicenceType = liDtl.license_type
          // this.txtStatus = liDtl.certificate_validity == 'Valid' ? 'Active' : liDtl.certificate_validity == 'Requested Sent' ? '' : liDtl.certificate_validity
          this.txtStatus = liDtl.status
          this.tempStatus = liDtl.status

          this.licensesUrl = liDtl.documents
          this.selectedState = liDtl.state
          this.selectedCity = liDtl.city

          //Application Dtls
          if (res['results'][0].application_document_details.length > 0) {
            this.applicationData = res['results'][0].application_document_details
            this.applicationDataSource.data = this.applicationData
            // this.applicationDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Testing Dtls
          if (res['results'][0].testing_document_details.length > 0) {
            this.testingData = res['results'][0].testing_document_details
            this.testingDataSource.data = this.testingData
            // this.testingDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Training Dtls
          if (res['results'][0].training_document_details.length > 0) {
            this.trainingData = res['results'][0].training_document_details
            this.trainingDataSource.data = this.trainingData
            // this.trainingDataSource = new MatTableDataSource(ELEMENT_DATA)

          }
          if (res['results'][0].service_requests_details.length > 0) {
            this.srData = res['results'][0].service_requests_details
            this.srDataSource.data = this.srData
            // this.otherDataSource = new MatTableDataSource(ELEMENT_DATA)

          }
          //Health Dtls
          if (res['results'][0].health_document_details.length > 0) {
            this.healthData = res['results'][0].health_document_details
            this.healthDataSource.data = this.healthData
            // this.healthDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Calibration Dtls
          if (res['results'][0].calibration_document_details.length > 0) {
            this.calibrationData = res['results'][0].calibration_document_details
            this.calibrationDataSource.data = this.calibrationData
            // this.calibrationDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Others Dtls
          if (res['results'][0].others_document_details.length > 0) {
            this.otherData = res['results'][0].others_document_details
            this.otherDataSource.data = this.otherData
            // this.otherDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

        }
        else {

        }
      }, (err: any) => {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      })
    }
    else{
      this.httpSv.getAMOneEnterpriseLicense(licNo).subscribe((res: any) => {
        if (res['status']) {
          //License Dtls
          const liDtl = res['results'][0].fssai_license_details[0]
          this.businessName = liDtl.business_name
          this.modifyId=liDtl.id
          this.emailId = liDtl.email
          this.currentTurnover = liDtl.current_turn_over
          this.expectedTurnover = liDtl.expected_turn_over
          this.mobileNo = liDtl.mobile
          this.selectedBusinessNature = liDtl.business_nature
          this.address = liDtl.address
          this.pinCode = liDtl.pincode
          this.userId = liDtl.user_id
          console.log("aaaaaaaa",this.userId)
          this.licensesNo = liDtl.registration_no
          if (liDtl.business_kind_ids)
            this.selectedKOBBusiness = liDtl.business_kind_ids.split(',').map((ele: any) => +ele)
          this.licensesExpire = liDtl.validity_date
          this.selectedLicenceType = liDtl.license_type
          // this.txtStatus = liDtl.certificate_validity == 'Valid' ? 'Active' : liDtl.certificate_validity == 'Requested Sent' ? '' : liDtl.certificate_validity
          this.txtStatus = liDtl.status
          this.tempStatus = liDtl.status

          this.licensesUrl = liDtl.documents
          this.selectedState = liDtl.state
          this.selectedCity = liDtl.city

          //Application Dtls
          if (res['results'][0].application_document_details.length > 0) {
            this.applicationData = res['results'][0].application_document_details
            this.applicationDataSource.data = this.applicationData
            // this.applicationDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Testing Dtls
          if (res['results'][0].testing_document_details.length > 0) {
            this.testingData = res['results'][0].testing_document_details
            this.testingDataSource.data = this.testingData
            // this.testingDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Training Dtls
          if (res['results'][0].training_document_details.length > 0) {
            this.trainingData = res['results'][0].training_document_details
            this.trainingDataSource.data = this.trainingData
            // this.trainingDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Health Dtls
          if (res['results'][0].health_document_details.length > 0) {
            this.healthData = res['results'][0].health_document_details
            this.healthDataSource.data = this.healthData
            // this.healthDataSource = new MatTableDataSource(ELEMENT_DATA)

          }
          if (res['results'][0].service_requests_details.length > 0) {
            this.srData = res['results'][0].service_requests_details
            this.srDataSource.data = this.srData
            // this.otherDataSource = new MatTableDataSource(ELEMENT_DATA)

          }
          //Calibration Dtls
          if (res['results'][0].calibration_document_details.length > 0) {
            this.calibrationData = res['results'][0].calibration_document_details
            this.calibrationDataSource.data = this.calibrationData
            // this.calibrationDataSource = new MatTableDataSource(ELEMENT_DATA)

          }

          //Others Dtls
          if (res['results'][0].others_document_details.length > 0) {
            this.otherData = res['results'][0].others_document_details
            this.otherDataSource.data = this.otherData

          }

        }
        else {

        }
      }, (err: any) => {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      })
    }
  }

  updateDoc(type: any, data: any) {
    const dialogRef = this.dialog.open(DialogAppLicenseDocModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        licensesNo: this.licensesNo,
        data,
        type,
        userType: this.data.type
      }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result == 'close')
        this.close()
      if (result){
        if(this.role == '1'){
          this.getAllsupLicenseDtls(this.data.licensesNo?this.data.licensesNo:this.tempLicenseNo, this.data.type)
        } else if(this.role == '2'){
          this.getAllAMLicenseDtls(this.data.licensesNo, this.data.type)
        } else if(this.role == '5'){
          this.getLicenceseDtls(this.licensesNo)
        }
      }
    })
  }
}


//--------------------------------------------document_modify_dialog------------------------------------------------------
//--------------------------------------------document_modify_dialog------------------------------------------------------
//--------------------------------------------document_modify_dialog------------------------------------------------------
//--------------------------------------------document_modify_dialog------------------------------------------------------
//--------------------------------------------document_modify_dialog------------------------------------------------------

@Component({
  selector: 'app-license-doc-modify-dialog',
  templateUrl: './document_modify_dialog.html',
  styleUrls: ['./licenses.component.css']
})
export class DialogAppLicenseDocModify implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogAppLicenseDocModify>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataShare: DataService,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dialog: MatDialog,
    private router: Router
  ) { }
  role: any
  allTypeOfDocs:any[]=[]
  selectedDoc:any;
  tempStatus :string = ''
  max_file_size :any;
  originalStatus:any ;
  total_storage_limit:any;
  allStatus:any= [ 
    {name:'Requested',value:'Requested'},
    {name:'Uploaded',value:'Uploaded'},
    {name:'Resubmit',value:'Resubmit'},
    {name:'Verified',value:'Verified'},
    {name:'Valid',value:'Valid'},
    {name:'Expired',value:'Expired'},
  ]
  selectedStatus:any = 'Uploaded'
  userStorage:any;
  remarks:any;
  imageUrl:any;
  ngOnInit(): void {
    this.max_file_size = this.dataShare.individualFileSize
    this.total_storage_limit = this.dataShare.totalRetailSize
    this.userStorage = this.dataShare.userStorage
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      this.role = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
    }
    else {
      this.toster.error('Opps! something went wrong', '', { timeOut: 2000 })
    }
    this.httpSv.getSampleDocuments().subscribe((res: any) => {
      if (res['status']) {
        this.allTypeOfDocs = res['results']
      }
      else {
        this.toster.error('Oops! something went wrong.', '', { timeOut: 2000 })
      }
    }, ((err: any) => {
      // this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
    }))
    const temp = this.data.data
    console.log(temp,'suppoettin docs',temp.status,'s')
    this.imageUrl = temp.documents?temp.documents : '';
    this.originalStatus =  temp.status;
    this.remarks = temp.remarks
    this.recentRepo = temp.last_three_status ? temp.last_three_status : []
    switch (this.data.type) {
      case 'application':
        this.type.application = true
        this.docName = temp.document_name
        this.selectedDoc = temp.sample_doc_id
        this.docDes = temp.description
        this.selectedStatus = temp.status ? temp.status : 'Uploaded'
        this.typeTitle = 'COMPANY DETAILS'
        break;
      case 'testing':
        this.type.testing = true
        this.productName = temp.product_name
        this.productId = temp.product_id
        this.testingDate = temp.report_date
        this.selectedStatus = temp.status ? temp.status : 'Uploaded'
        this.typeTitle = 'TESTING'
        break;
      case 'training':
        this.type.training = true
        this.trainingName = temp.employee_name
        this.trainingId = temp.employee_id
        this.trainingDate = temp.training_date
        this.selectedStatus = temp.status ? temp.status : 'Uploaded'
        this.typeTitle = 'TRAINING'
        break;
      case 'health':
        this.type.health = true
        this.healthName = temp.employee_name
        this.healthId = temp.employee_id
        this.healthDate = temp.report_date
        this.typeTitle = 'HEALTH'
        this.selectedStatus = temp.status ? temp.status : 'Uploaded'
        break;
      case 'calibiration':
        this.type.calibiration = true
        this.calibirationName = temp.instrument_name
        this.calibirationId = temp.equipment_id
        this.calibirationDate = temp.calibration_date
        this.selectedStatus = temp.status ? temp.status : 'Uploaded'
        this.typeTitle = 'CALIBRATION'
        break;
      case 'others':
        this.type.other = true
        this.othersName = temp.document_name
        this.othersDes = temp.description
        this.othersDate = temp.expiration_date
        this.selectedStatus = temp.status ? temp.status : 'Uploaded'
        this.typeTitle = 'OTHERS'
        break;
      case 'audits':
        this.type.audits = true
        this.docName = temp.document_name
        this.selectedDoc = temp.sample_doc_id
        this.docDes = temp.description
        this.selectedStatus = temp.status ? temp.status : 'Uploaded'
        this.typeTitle = 'AUDITS'
        break;

    }
    console.log(this.data)
  }
  recentRepo:any = [];
  typeTitle: string = ''
  type: any = {
    application: false,
    testing: false,
    training: false,
    health: false,
    calibiration: false,
    other: false

  }
  docName: string = ''
  docDes: string = ''

  productName: string = ''
  productId: string = ''
  testingDate: any

  trainingName: string = ''
  trainingId: string = ''
  trainingDate: any

  healthName: string = ''
  healthId: string = ''
  healthDate: any

  calibirationName: string = ''
  calibirationId: string = ''
  calibirationDate: any

  othersName: string = ''
  othersDes: string = ''
  othersDate: any

  auditsName: string = ''
  auditsDes: string = ''
  auditsDate: any


  fileName: string = ''
  fileData: any = ''

  tcFlag: boolean = true

  addNew: any = this.data.addNew

  onImageChange(event: any) {
    let file: File = event.target.files[0];
    let currentSize = (event.target.files[0].size)/1000000 
    if(currentSize > this.max_file_size)
    {
      this.toster.error("Maximum file size is "+ this.max_file_size+" MB",'', { timeOut: 2000 })
      return;
    }
    if(this.data.userType == 'retail' || this.data.userType == 'ru'){
      if(currentSize+(this.userStorage)/1000  > this.total_storage_limit ){
        this.toster.error("You have Exceeded your free storage limit, Upgrade to the myFssai Enterprise Edition to add more. Contact myfssai@foodsafetyworks.com to upgrade to the myFssai Enterprise Edition",'', { timeOut: 4000 })
        return;
      }
    }
    this.fileData = file
    this.fileName = file.name
    let reader: FileReader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e: any) => {
      // this.src = e.target.result;
    };
    const fileDom = document.getElementById('file_inp_doc') as HTMLInputElement
    if (fileDom) {
      fileDom.value = ''
    }
  }
  getAudit(){
    console.log(this.data.data.id,this.data.type,this.data)
    this.httpSv.getLicenseDocsAudit(this.data.data.id,this.data.type == 'testing' ? 'test' : this.data.type ).subscribe((res:any)=>{
      if(res['status']){
      this.dialog.open(DialogueAuditReport, {
        hasBackdrop: true,
        disableClose: true,
        data : {
          data : res['results'],
          from : 'license',
          type : this.data.type
        }
      })
    }
    else {
      this.toster.error('No Data Found', '', { timeOut: 2000 })
      // this.dialogRef.close(false)
    }
  }, ((err: any) => {
    this.toster.error('No Data Found', '', { timeOut: 2000 })
    // this.dialogRef.close(false)
  }))
}
  getDate(data:any){
    const date = new Date(data);
      // Define arrays for month and weekday names
      const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      // Format the date manually
      const weekday = weekdays[date.getUTCDay()];
      const month = months[date.getUTCMonth()];
      const day = date.getUTCDate();
      const year = date.getUTCFullYear();
      const utcDate = new Date(date);
    
      // Convert to IST (UTC + 5:30)
      const istDate = new Date(utcDate.getTime() + (5.5 * 60 * 60 * 1000));
      
      // Get hours and minutes
      let hours = istDate.getUTCHours();
      let minutes:any = istDate.getUTCMinutes();
      
      // Format to AM/PM
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // The hour '0' should be '12'
      
      // Format minutes to 2 digits
      minutes = minutes < 10 ? `0${minutes}` : minutes;

      return`${weekday}, ${month}, ${day} ${year} at ${hours}:${minutes} ${ampm}`;;

  }
  openTerms(){
    const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
      hasBackdrop: true,
      disableClose: true,
      maxWidth: '80vw',
      minHeight:'80vh'
    })
  }
  viewLicence( url:any = this.imageUrl){
    let fileType = url.split('.')
    fileType = fileType[fileType.length - 1]
    const dialogRef = this.dialog.open(DialogAppLicenseView, {
      hasBackdrop: true,
      // disableClose:true
      data: {
        type: fileType,
        src: url
      }
    })
  }
  addNewDtls() {
    if(this.role == '1') {
      if (this.type.application) this.addApplicationDtls()
      else if (this.type.testing) this.addTestingDtls()
      else if (this.type.training) this.addTrainingDtls()
      else if (this.type.health) this.addHealthDtls()
      else if (this.type.calibiration) this.addCalibrationDtls()
      else if (this.type.other) this.addOthersDtls()
    }
    if(this.role == '2') {
      if (this.type.application) this.addApplicationDtls()
      else if (this.type.testing) this.addTestingDtls()
      else if (this.type.training) this.addTrainingDtls()
      else if (this.type.health) this.addHealthDtls()
      else if (this.type.calibiration) this.addCalibrationDtls()
      else if (this.type.other) this.addOthersDtls()
    }
    else if(this.role == '3') {
      // console.log("aaaaaaaaa")
      if (this.type.application) this.addApplicationDtls()
      else if (this.type.testing) this.addTestingDtls()
      else if (this.type.training) this.addTrainingDtls()
      else if (this.type.health) this.addHealthDtls()
      else if (this.type.calibiration) this.addCalibrationDtls()
      else if (this.type.other) this.addOthersDtls()
    }
    else if(this.role == '5') {
      // console.log("aaaaaaaaa")
      if (this.type.application) this.addApplicationDtls()
      else if (this.type.testing) this.addTestingDtls()
      else if (this.type.training) this.addTrainingDtls()
      else if (this.type.health) this.addHealthDtls()
      else if (this.type.calibiration) this.addCalibrationDtls()
      else if (this.type.other) this.addOthersDtls()
    }
  }

  addApplicationDtls(){
    const data = new FormData()
    if( this.selectedStatus.length <= 0){
      this.toster.error('Please select the Status', '', { timeOut: 2000 })
      return
    }
    let id:any = localStorage.getItem('user_id')
    // data.append('user_id', id)
    if (this.docName && this.docName != '') {
      if (this.docDes && this.docDes != '') {
        if (this.fileData && this.fileData != '') {
          if (this.tcFlag) {
            data.append('document_name', this.docName)
            data.append('description', this.docDes)
            data.append('documents', this.fileData)
            data.append('status', this.selectedStatus)
            data.append('fssai_regd_no', this.data.licensesNo)
            data.append('user_id', this.data.userId ? this.data.userId : id)
            if(this.selectedDoc)       
            data.append('sample_doc_id', this.selectedDoc)
            this.httpSv.addLicenseApplicationDtls(this.role, data, this.data.userType)?.subscribe((res: any) => {
              if (res['status']) {
                this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                this.dialogRef.close(true)
              }
              else {data
                this.toster.error('Details not updated.', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }
            }, ((err: any) => {
              this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
              this.dialogRef.close(false)
            }))
          } else { this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 }) }
        } else { this.toster.error('Please upload the document', '', { timeOut: 2000 }) }
      } else { this.toster.error('Please enter the document description', '', { timeOut: 2000 }) }
    } else { this.toster.error('Please enter the document name', '', { timeOut: 2000 }) }
  }

  addTestingDtls(){
    const data = new FormData()
    if( this.selectedStatus.length <= 0){
      this.toster.error('Please select the Status', '', { timeOut: 2000 })
      return
    }
    let id:any = localStorage.getItem('user_id')
    // data.append('user_id', id)
    if (this.productName && this.productName != '') {
      if (this.productId && this.productId != '') {
        if (this.testingDate && this.testingDate != '') {
          if (this.fileData && this.fileData != '') {
            if (this.tcFlag) {
              data.append('product_name', this.productName)
              data.append('product_id', this.productId)
              data.append('report_date', this.testingDate)
              data.append('documents', this.fileData)
              data.append('status', this.selectedStatus)
              data.append('fssai_regd_no', this.data.licensesNo)
              data.append('user_id', this.data.userId ? this.data.userId : id)
              this.httpSv.addLicenseTestingDtls(this.role, data, this.data.userType)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else { this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 }) }
          } else { this.toster.error('Please upload the document', '', { timeOut: 2000 }) }
        } else { this.toster.error('Please enter the date', '', { timeOut: 2000 }) }
      } else { this.toster.error('Please enter the product id', '', { timeOut: 2000 }) }
    } else { this.toster.error('Please enter the product name', '', { timeOut: 2000 }) }
  }

  addTrainingDtls(){
    const data = new FormData()
    if( this.selectedStatus.length <= 0){
      this.toster.error('Please select the Status', '', { timeOut: 2000 })
      return
    }
    let id:any = localStorage.getItem('user_id')
    // data.append('user_id', id)
    if (this.trainingName && this.trainingName != '') {
      if (this.trainingId && this.trainingId != '') {
        if (this.trainingDate && this.trainingDate != '') {
          if (this.fileData && this.fileData != '') {
            if (this.tcFlag) {
              data.append('employee_name', this.trainingName)
              data.append('employee_id', this.trainingId)
              data.append('training_date', this.trainingDate)
              data.append('documents', this.fileData)
              data.append('status', this.selectedStatus)
              data.append('fssai_regd_no', this.data.licensesNo)
              data.append('user_id', this.data.userId ? this.data.userId : id)
              this.httpSv.addLicenseTrainingDtls(this.role, data, this.data.userType)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else { this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 }) }
          } else { this.toster.error('Please upload the document', '', { timeOut: 2000 }) }
        } else { this.toster.error('Please enter the training date', '', { timeOut: 2000 }) }
      } else { this.toster.error('Please enter the employee id', '', { timeOut: 2000 }) }
    } else { this.toster.error('Please enter the employee name', '', { timeOut: 2000 }) }
  }

  addHealthDtls(){
    if( this.selectedStatus.length <= 0){
      this.toster.error('Please select the Status', '', { timeOut: 2000 })
      return
    }
    const data = new FormData()
    let id:any = localStorage.getItem('user_id')
    // data.append('user_id', id)
    if (this.healthName && this.healthName != '') {
      if (this.healthId && this.healthId != '') {
        if (this.healthDate && this.healthDate != '') {
          if (this.fileData && this.fileData != '') {
            if (this.tcFlag) {
              data.append('employee_name', this.healthName)
              data.append('employee_id', this.healthId)
              data.append('report_date', this.healthDate)
              data.append('documents', this.fileData)
              data.append('status', this.selectedStatus)
              data.append('fssai_regd_no', this.data.licensesNo)
              data.append('user_id', this.data.userId ? this.data.userId : id)
              this.httpSv.addLicenseHealthDtls(this.role, data, this.data.userType)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else { this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 }) }
          } else { this.toster.error('Please upload the document', '', { timeOut: 2000 }) }
        } else { this.toster.error('Please enter the report date', '', { timeOut: 2000 }) }
      } else { this.toster.error('Please enter the employee id', '', { timeOut: 2000 }) }
    } else { this.toster.error('Please enter the employee name', '', { timeOut: 2000 }) }
  }

  addCalibrationDtls(){
    const data = new FormData()
    if( this.selectedStatus.length <= 0){
      this.toster.error('Please select the Status', '', { timeOut: 2000 })
      return
    }
    let id:any = localStorage.getItem('user_id')
    // data.append('user_id', id)
    if (this.calibirationName && this.calibirationName != '') {
      if (this.calibirationId && this.calibirationId != '') {
        if (this.calibirationDate && this.calibirationDate != '') {
          if (this.fileData && this.fileData != '') {
            if (this.tcFlag) {
              data.append('instrument_name', this.calibirationName)
              data.append('equipment_id', this.calibirationId)
              data.append('calibration_date', this.calibirationDate)
              data.append('documents', this.fileData)
              data.append('documents', this.fileData)
              data.append('fssai_regd_no', this.data.licensesNo)
              data.append('user_id', this.data.userId ? this.data.userId : id)
              this.httpSv.addLicenseCalibrationDtls(this.role, data, this.data.userType)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else { this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 }) }
          } else { this.toster.error('Please upload the document', '', { timeOut: 2000 }) }
        } else { this.toster.error('Please enter the callibration date', '', { timeOut: 2000 }) }
      } else { this.toster.error('Please enter the instrument id', '', { timeOut: 2000 }) }
    } else { this.toster.error('Please enter the instrument name', '', { timeOut: 2000 }) }
  }
  
  addOthersDtls(){
    const data = new FormData()
    if( this.selectedStatus.length <= 0){
      this.toster.error('Please select the Status', '', { timeOut: 2000 })
      return
    }
    let id:any = localStorage.getItem('user_id')
    // data.append('user_id', id)
    if (this.othersName && this.othersName != '') {
      if (this.othersDes && this.othersDes != '') {
        if (this.othersDate && this.othersDate != '') {
          if (this.fileData && this.fileData != '') {
            if (this.tcFlag) {
              data.append('document_name', this.othersName)
              data.append('description', this.othersDes)
              data.append('expiration_date', this.othersDate)
              data.append('documents', this.fileData)
              data.append('status', this.selectedStatus)
              data.append('fssai_regd_no', this.data.licensesNo)
              data.append('user_id', this.data.userId ? this.data.userId : id)
              this.httpSv.addLicenseOthersDtls(this.role, data, this.data.userType)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else { this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 }) }
          } else { this.toster.error('Please upload the document', '', { timeOut: 2000 }) }
        } else { this.toster.error('Please enter the expiration date', '', { timeOut: 2000 }) }
      } else { this.toster.error('Please enter the description', '', { timeOut: 2000 }) }
    } else { this.toster.error('Please enter the document name', '', { timeOut: 2000 }) }
  }


  updateDtls() {
    if (this.role == '5') {
      if (this.type.application) this.updateApplicationDtls()
      else if (this.type.testing) this.updateTestingDtls()
      else if (this.type.training) this.updateTrainingDtls()
      else if (this.type.health) this.updateHealthDtls()
      else if (this.type.calibiration) this.updateCalibirationDtls()
      else if (this.type.other) this.updateOthersDtls()
    }
    else if (this.role == '3') {
      if (this.type.application) this.updateApplicationDtls()
      else if (this.type.testing) this.updateTestingDtls()
      else if (this.type.training) this.updateTrainingDtls()
      else if (this.type.health) this.updateHealthDtls()
      else if (this.type.calibiration) this.updateCalibirationDtls()
      else if (this.type.other) this.updateOthersDtls()
    }
    else if (this.role == '1') {
      if (this.type.application) this.updateApplicationDtls()
      else if (this.type.testing) this.updateTestingDtls()
      else if (this.type.training) this.updateTrainingDtls()
      else if (this.type.health) this.updateHealthDtls()
      else if (this.type.calibiration) this.updateCalibirationDtls()
      else if (this.type.other) this.updateOthersDtls()
    }
    else if(this.role == '2') {
      if (this.type.application) this.updateApplicationDtls()
      else if (this.type.testing) this.updateTestingDtls()
      else if (this.type.training) this.updateTrainingDtls()
      else if (this.type.health) this.updateHealthDtls()
      else if (this.type.calibiration) this.updateCalibirationDtls()
      else if (this.type.other) this.updateOthersDtls()
    }

  }
  async updateApplicationDtls() {
    const data = new FormData()
    if( this.selectedStatus.length <= 0){
      this.toster.error('Please select the Status', '', { timeOut: 2000 })
      return
    }
    data.append('status',this.selectedStatus)
    if (this.docName && this.docName != '') {
      data.append('document_name', this.docName)
      if (this.docDes && this.docDes != '') {
        data.append('description', this.docDes)
        if (this.fileData && this.fileData != '') {
          data.append('documents', this.fileData)
          }
          if (this.tcFlag) {
            data.append('fssai_regd_no', this.data.licensesNo)
            data.append('user_id', this.data.data.user_id)
            if(this.selectedDoc)
              data.append('sample_doc_id', this.selectedDoc)
            if ((this.selectedStatus == 'Resubmit' && this.originalStatus != 'Resubmit') || (this.selectedStatus == 'Verified' && this.originalStatus != 'Verified')) {
              const dialogRef = this.dialog.open(DialogueAddRemark, {});
              const result = await dialogRef.afterClosed().toPromise(); 
          
              if (result && result['status']) {
                data.append('remarks', result['data']);
              }  
              else{
                return;
              }
            }
            this.httpSv.updateLicienceApplicationDtls(this.data.data.id, data,this.role, this.data.userType)?.subscribe((res: any) => {
              if (res['status']) {
                this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                this.dialogRef.close(true)
              }
              else {
                this.toster.error('Details not updated.', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }
            }, ((err: any) => {
              this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
              this.dialogRef.close(false)
            }))
          } else {
            this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
          }
        // } else {
        //   this.toster.error('Please upload the document', '', { timeOut: 2000 })
        // }
      } else {
        this.toster.error('Please enter the document description', '', { timeOut: 2000 })
      }
    } else {
      this.toster.error('Please enter the document name', '', { timeOut: 2000 })
    }
  }
  async updateTestingDtls() {
    const data = new FormData()
    if( this.selectedStatus.length <= 0){
      this.toster.error('Please select the Status', '', { timeOut: 2000 })
      return
    }
    data.append('status',this.selectedStatus)
    if (this.productName && this.productName != '') {
      if (this.productId && this.productId != '') {
        if (this.testingDate && this.testingDate != '') {
          if (this.fileData && this.fileData != '') {
            data.append('documents', this.fileData)
            }            
            if (this.tcFlag) {
              data.append('product_name', this.productName)
              data.append('product_id', this.productId)
              data.append('report_date', this.testingDate)
              data.append('fssai_regd_no', this.data.licensesNo)
              data.append('user_id', this.data.data.user_id)
              if ((this.selectedStatus == 'Resubmit' && this.originalStatus != 'Resubmit') || (this.selectedStatus == 'Verified' && this.originalStatus != 'Verified')) {
                const dialogRef = this.dialog.open(DialogueAddRemark, {});
                const result = await dialogRef.afterClosed().toPromise(); 
                console.log(result,'result')
                if (result && result['status']) {
                  data.append('remarks', result['data']);
                }
                else{
                  return;
                }
              }
              this.httpSv.updateLicienceTestingDtls(this.data.data.id, data,this.role, this.data.userType)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else {
              this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
            }
          // } else {
          //   this.toster.error('Please upload the document', '', { timeOut: 2000 })
          // }
        } else {
          this.toster.error('Please enter the date', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter the product id', '', { timeOut: 2000 })
      }
    } else {
      this.toster.error('Please enter the product name', '', { timeOut: 2000 })
    }
  }

  async updateTrainingDtls() {
    const data = new FormData()
    if( this.selectedStatus.length <= 0){
      this.toster.error('Please select the Status', '', { timeOut: 2000 })
      return
    }
    data.append('status',this.selectedStatus)
    if (this.trainingName && this.trainingName != '') {
      if (this.trainingId && this.trainingId != '') {
        if (this.trainingDate && this.trainingDate != '') {
          if (this.fileData && this.fileData != '') {
            data.append('documents', this.fileData)
            }
            if (this.tcFlag) {
              data.append('employee_name', this.trainingName)
              data.append('employee_id', this.trainingId)
              data.append('training_date', this.trainingDate)
              // data.append('documents', this.fileData)
              data.append('fssai_regd_no', this.data.licensesNo)
              data.append('user_id', this.data.data.user_id)
              if ((this.selectedStatus == 'Resubmit' && this.originalStatus != 'Resubmit') || (this.selectedStatus == 'Verified' && this.originalStatus != 'Verified')) {
                const dialogRef = this.dialog.open(DialogueAddRemark, {});
                const result = await dialogRef.afterClosed().toPromise(); 
            
                if (result && result['status']) {
                  data.append('remarks', result['data']);
                }
                else{
                  return;
                }
              }
              this.httpSv.updateLicienceTrainingDtls(this.data.data.id, data,this.role, this.data.userType)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else {
              this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
            }
          // } else {
          //   this.toster.error('Please upload the document', '', { timeOut: 2000 })
          // }
        } else {
          this.toster.error('Please enter the training date', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter the employee id', '', { timeOut: 2000 })
      }
    } else {
      this.toster.error('Please enter the employee name', '', { timeOut: 2000 })
    }
  }
  async updateHealthDtls() {
    const data = new FormData()
    if( this.selectedStatus.length <= 0){
      this.toster.error('Please select the Status', '', { timeOut: 2000 })
      return
    }
    data.append('status',this.selectedStatus)
    if (this.healthName && this.healthName != '') {
      if (this.healthId && this.healthId != '') {

        if (this.healthDate && this.healthDate != '') {
          if (this.fileData && this.fileData != '') {
            data.append('documents', this.fileData)
            }
            if (this.tcFlag) {
              data.append('employee_name', this.healthName)
              data.append('employee_id', this.healthId)
              data.append('report_date', this.healthDate)
              // data.append('documents', this.fileData)
              data.append('fssai_regd_no', this.data.licensesNo)
              data.append('user_id', this.data.data.user_id)
              if ((this.selectedStatus == 'Resubmit' && this.originalStatus != 'Resubmit') || (this.selectedStatus == 'Verified' && this.originalStatus != 'Verified')) {
                const dialogRef = this.dialog.open(DialogueAddRemark, {});
                const result = await dialogRef.afterClosed().toPromise(); 
            
                if (result && result['status']) {
                  data.append('remarks', result['data']);
                }
                else{
                  return;
                }
              }
              this.httpSv.updateLicienceHealthDtls(this.data.data.id, data,this.role, this.data.userType)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else {
              this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
            }
          // } else {
          //   this.toster.error('Please upload the document', '', { timeOut: 2000 })
          // }
        } else {
          this.toster.error('Please enter the report date', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter the employee id', '', { timeOut: 2000 })
      }
    } else {
      this.toster.error('Please enter the employee name', '', { timeOut: 2000 })
    }
  }
  async updateCalibirationDtls() {
    const data = new FormData()
    if( this.selectedStatus.length <= 0){
      this.toster.error('Please select the Status', '', { timeOut: 2000 })
      return
    }
    data.append('status',this.selectedStatus)
    if (this.calibirationName && this.calibirationName != '') {
      if (this.calibirationId && this.calibirationId != '') {
        if (this.calibirationDate && this.calibirationDate != '') {
          if (this.fileData && this.fileData != '') {
            data.append('documents', this.fileData)
            }
            if (this.tcFlag) {
              data.append('instrument_name', this.calibirationName)
              data.append('equipment_id', this.calibirationId)
              data.append('calibration_date', this.calibirationDate)
              // data.append('documents', this.fileData)
              data.append('fssai_regd_no', this.data.licensesNo)
              data.append('user_id', this.data.data.user_id)
              if ((this.selectedStatus == 'Resubmit' && this.originalStatus != 'Resubmit') || (this.selectedStatus == 'Verified' && this.originalStatus != 'Verified')) {
                const dialogRef = this.dialog.open(DialogueAddRemark, {});
                const result = await dialogRef.afterClosed().toPromise(); 
            
                if (result && result['status']) {
                  data.append('remarks', result['data']);
                }
                else{
                  return;
                }
              }
              this.httpSv.updateLicienceCalibirationDtls(this.data.data.id, data,this.role, this.data.userType)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else {
              this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
            }
          // } else {
          //   this.toster.error('Please upload the document', '', { timeOut: 2000 })
          // }
        } else {
          this.toster.error('Please enter the callibration date', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter the instrument id', '', { timeOut: 2000 })
      }
    } else {
      this.toster.error('Please enter the instrument name', '', { timeOut: 2000 })
    }
  }
  async updateOthersDtls() {
    const data = new FormData()
    if( this.selectedStatus.length <= 0){
      this.toster.error('Please select the Status', '', { timeOut: 2000 })
      return
    }
    data.append('status',this.selectedStatus)
    if (this.othersName && this.othersName != '') {
      if (this.othersDes && this.othersDes != '') {
        if (this.othersDate && this.othersDate != '') {
          if (this.fileData && this.fileData != '') {
            data.append('documents', this.fileData)
            }
            if (this.tcFlag) {
              data.append('document_name', this.othersName)
              data.append('description', this.othersDes)
              data.append('expiration_date', this.othersDate)
              // data.append('documents', this.fileData)
              data.append('fssai_regd_no', this.data.licensesNo)
              data.append('user_id', this.data.data.user_id)
              if ((this.selectedStatus == 'Resubmit' && this.originalStatus != 'Resubmit') || (this.selectedStatus == 'Verified' && this.originalStatus != 'Verified')) {
                const dialogRef = this.dialog.open(DialogueAddRemark, {});
                const result = await dialogRef.afterClosed().toPromise(); 
            
                if (result && result['status']) {
                  data.append('remarks', result['data']);
                }
                else{
                  return;
                }
              }
              this.httpSv.updateLicienceOthersDtls(this.data.data.id, data,this.role, this.data.userType)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else {
              this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
            }
          // } else {
          //   this.toster.error('Please upload the document', '', { timeOut: 2000 })
          // }
        } else {
          this.toster.error('Please enter the expiration date', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter the description', '', { timeOut: 2000 })
      }
    } else {
      this.toster.error('Please enter the document name', '', { timeOut: 2000 })
    }
  }














  close() {
    this.dialogRef.close(false)
  }
}


@Component({
  selector: 'app-u2-licenses',
  templateUrl: './u2_licenses.html',
  styleUrls: ['./licenses.component.css']
})
export class U2LicensesComponent implements OnInit {
  @ViewChild(MatSort) sort = new MatSort();

  EUCompanyDropdown: any=[];
  EUNameDropdown: any=[];
  EUDateDropdown: any=[];
  EUUserNameDropdown: any=[];
  EUNameFilter: any='';
  EUUserNameFilter: any='';
  EUCompanyFilter: any='';
  EUDateFilter: any='';
  EUFilter: any='';
  filterEUdata: any;
  RUCompanyDropdown: any=[];
  RUUserNameDropdown: any=[];
  RUNameDropdown: any=[];
  RUDateDropdown: any=[];
  selectedFilterDropdown = 'All'
  RUNameFilter: any='';
  RUUserNameFilter: any='';
  RUCompanyFilter: any='';
  RUDateFilter: any='';
  RUFilter: any='';
  filterRUdata: any;
  constructor(
    private dataShare: DataService,
    public dialog: MatDialog,
    private httpSv: HttpService,
    private toster: ToastrService
  ) { }
  role:any;
  licenseDetails:any;
  apiData:any;
  displayedColumns: string[] = ['sl_no', 'registration_no','user_name', 'business_name', 'state', 'status','view_license','serviceReq'];
  data: any[] = []
  dataSource: any = new MatTableDataSource();
  enterpriseActiveFlag: boolean = true
  displayedRetailColumns: string[] = ['sl_no', 'registration_no', 'business_name', 'state', 'status', 'view_license', 'modify','serviceReq'];
  dataRetail: any[] = []
  dataRetailSource: any = new MatTableDataSource(this.dataRetail);
  selectedRetailFilter: any = {
    all: true,
    active: false,
    expired: false,
    requested: false
  }

  selectedFilter: any = {
    all: true,
    active: false,
    expired: false,
    requested: false
  }
  RUCityDropdown:any[]=[]
  RUBussinessDropdown:any[]=[]
  RUStatusDropdown:any[]=[]
  RUCityFilter:any='';
  RUBussinessFilter:any='';
  RUStatusFilter:any='';
  EUCityDropdown:any[]=[]
  EUBussinessDropdown:any[]=[]
  EUStatusDropdown:any[]=[]
  EUCityFilter:any='';
  EUBussinessFilter:any='';
  EUStatusFilter:any='';
  dataEnterprise: any[] = []
 
  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      this.role = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
    }
    else {
      // this.toster.error('Opps! something went wrong', '', { timeOut: 2000 })
    }
    this.getAllEnterpriseLicense()
  }
  applyRetailFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataRetailSource.filter = filterValue.trim().toLowerCase();
  }
  filterByRetailStatus(order: any) {
    if(order == '30')
      this.selectedFilterDropdown='Expiring in less than 30 days';
    else if(order == '60')
      this.selectedFilterDropdown='Expiring in 60 days';
    else if(order == '90')
      this.selectedFilterDropdown='Expiring in 90 days';
    else
      this.selectedFilterDropdown=order;
    switch (order) {
      case 'All':
        this.selectedRetailFilter = {
          all: true,
          active: false,
          expired: false,
          requested: false
        }
        this.selectedFilter = {
          all: true,
          active: false,
          expired: false,
          requested: false
        }
        break;
      case 'Active':
        this.selectedRetailFilter = {
          all: false,
          active: true,
          expired: false,
          requested: false
        }
        this.selectedFilter = {
          all: false,
          active: true,
          expired: false,
          requested: false
        }
        break;
      case 'Expired':
        this.selectedRetailFilter = {
          all: false,
          active: false,
          expired: true,
          requested: false
        }
        this.selectedFilter = {
          all: false,
          active: false,
          expired: true,
          requested: false
        }
        break;
      case 'Requested':
        this.selectedRetailFilter = {
          all: false,
          active: false,
          expired: false,
          requested: true
        }
        this.selectedFilter = {
          all: false,
          active: false,
          expired: false,
          requested: true
        }
        break;
    }
    if(!this.enterpriseActiveFlag)
    this.filterByStatusRetailDataHandle(order)
    else
    this.filterByStatusEnterpriseDataHandle(order)
  }
  addRequest(element:any){
    this.dialog.open(DialogueAddServiceRequest,{
      disableClose:true,
      hasBackdrop: true,
      data:element
    })
  }
  filterByStatusRetailDataHandle(order: any) {
    if (order == 'All')
      this.dataRetailSource.data = this.dataRetail
      else if(order == '30' || order == '60' || order == '90'){

        if(order == '30'){
          const tempData = this.dataRetail.filter((ele: any) => ele.expiring_in_30_days == true)
          this.dataRetailSource.data = tempData
        } 
        else if(order == '60'){
          const tempData = this.dataRetail.filter((ele: any) => ele.expiring_in_60_days == true)
          this.dataRetailSource.data = tempData
        } 
        else{
          const tempData = this.dataRetail.filter((ele: any) => ele.expiring_in_90_days == true)
          this.dataRetailSource.data = tempData
        } 
      }
    else {
      const tempData = this.dataRetail.filter((ele: any) => ele.status.toLowerCase() == order.toLowerCase())
      this.dataRetailSource.data = tempData
    }
  }
  handleSwicthWebUser(type: any) {
    this.dataShare.selectedTable = type;
  
    if (type === 'beu') {
      if (!this.enterpriseActiveFlag) this.enterpriseActiveFlag = true
      this.getAllEnterpriseLicense()
    }
    else if (type === 'retail') {
      if (this.enterpriseActiveFlag) this.enterpriseActiveFlag = false
      this.getAllRetailLicense()
    }
  }
  getAllEnterpriseLicense()
  {
    this.httpSv.getEnterpriseLicenseBm().subscribe((res: any) => {
      if (res['status']) {
        this.dataEnterprise = res['results']
        this.filterEUdata = res['results']
        this.dataEnterprise.map((ele: any) => {
          if (ele.certificate_validity == 'Valid') ele.certificate_validity = 'Active'
          else if (ele.certificate_validity == 'Request Sent') ele.certificate_validity = 'Requested'
          return ele
        })
        this.createEUDropDown(this.dataEnterprise)
        this.dataSource.data = this.dataEnterprise
        this.dataSource.sort = this.sort
        this.filterByRetailStatus(this.dataShare.preStatusFilter)
        this.dataShare.preStatusFilter='All'
      } else {
        this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      if(err.error.message!='No users found.')
      this.toster.error(err.error.message, '', { timeOut: 2000 })
      if(err.error.message=='No users found.')
      this.dataSource.data = []
    })
  }
  createEUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state &&!this.EUCityDropdown.includes(data.state.toLowerCase()))
        this.EUCityDropdown.push(data.state.toLowerCase())
      if(data.status && !this.EUStatusDropdown.includes(data.status))
        this.EUStatusDropdown.push(data.status)
      if(data.business_name && !this.EUCompanyDropdown.includes(data.business_name))
        this.EUCompanyDropdown.push(data.business_name)
      if(data.registration_no && !this.EUNameDropdown.includes(data.registration_no))
        this.EUNameDropdown.push(data.registration_no)
      if(data.date && !this.EUDateDropdown.includes(data.date))
        this.EUDateDropdown.push(data.date)
      if(data.user_name.user_name && !this.EUUserNameDropdown.includes(data.user_name.user_name))
        this.EUUserNameDropdown.push(data.user_name.user_name)
    })
  }
   EUApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='name'){
      this.EUNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.registration_no && apiData.registration_no.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
    if(from=='company'){
      this.EUCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.business_name && apiData.business_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
    if(from=='city'){
      this.EUCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
         if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
    if(from=='date'){
      this.EUDateFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.date && apiData.date.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
    if(from=='EU'){
      this.EUFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.assign_business_manager && apiData.assign_business_manager.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
    if(from=='status'){
      this.EUStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.EUFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
    if(from=='uname'){
      this.EUUserNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.EUFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.user_name.user_name && apiData.user_name.user_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSource.data = this.filterEUdata

    }
  }
  filterByStatusEnterpriseDataHandle(order:any)
  {
    if (order == 'All')
    this.dataSource.data = this.dataEnterprise
    else if(order == '30' || order == '60' || order == '90'){

      if(order == '30'){
        const tempData = this.dataEnterprise.filter((ele: any) => ele.expiring_in_30_days == true)
        this.dataSource.data = tempData
      } 
      else if(order == '60'){
        const tempData = this.dataEnterprise.filter((ele: any) => ele.expiring_in_60_days == true)
        this.dataSource.data = tempData
      } 
      else{
        const tempData = this.dataEnterprise.filter((ele: any) => ele.expiring_in_90_days == true)
        this.dataSource.data= tempData
      } 
    }
  else {
    this.dataEnterprise.map((ele)=>{
      console.log(ele.status)
    })
    const tempData = this.dataEnterprise.filter((ele: any) => ele.status == order)
    this.dataSource.data = tempData
  }
  }
  getUserDetails(id:any,from:any){
    if(from == 'eu'){
      const dialogRef = this.dialog.open(DialogEnterpriseModify, {
        hasBackdrop: true,
        disableClose: true,
        data:{
        isModify:true,
        id:id,
        isFromLicense : true
        }
      })
    }
    else{
      const dialogRef = this.dialog.open(DialogRetailModify, {
        hasBackdrop: true,
        disableClose: true,
        data: {
          id,
          isFromLicense : true
        }
      })
    }

  }
  getAllRetailLicense() {
    this.httpSv.getRetailLicenseBm().subscribe((res: any) => {
      if (res['status']) {
        this.dataRetail = res['results']
        this.filterRUdata = res['results']
        this.dataRetail.map((ele: any) => {
          if (ele.certificate_validity == 'Valid') ele.certificate_validity = 'Active'
          else if (ele.certificate_validity == 'Request Sent') ele.certificate_validity = 'Requested'
          return ele
        })
        this.createRUDropDown(this.dataRetail)
        this.dataRetailSource = new MatTableDataSource(this.dataRetail)
        this.dataRetailSource.sort = this.sort

      } else {
        this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      if(err.error.message=='No users found.')
      this.dataRetailSource.data = []
      if(err.error.message!='No users found.')
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  createRUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state &&!this.RUCityDropdown.includes(data.state.toLowerCase()))
        this.RUCityDropdown.push(data.state.toLowerCase())
      if(data.status && !this.RUStatusDropdown.includes(data.status))
        this.RUStatusDropdown.push(data.status)
      if(data.business_name && !this.RUCompanyDropdown.includes(data.business_name))
        this.RUCompanyDropdown.push(data.business_name)
      if(data.registration_no && !this.RUNameDropdown.includes(data.registration_no))
        this.RUNameDropdown.push(data.registration_no)
      if(data.date && !this.RUDateDropdown.includes(data.date))
        this.RUDateDropdown.push(data.date)
      if(data.user_name.user_name && !this.RUUserNameDropdown.includes(data.user_name.user_name))
        this.RUUserNameDropdown.push(data.user_name.user_name)
    })
  }
   RUApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='name'){
      this.RUNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.registration_no && apiData.registration_no.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
    if(from=='company'){
      this.RUCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.business_name && apiData.business_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
    if(from=='city'){
      this.RUCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
    if(from=='date'){
      this.RUDateFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RUFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.date && apiData.date.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
    if(from=='RU'){
      this.RUFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.assign_business_manager && apiData.assign_business_manager.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
    if(from=='status'){
      this.RUStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUUserNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.user_name.user_name && data.user_name.user_name.toLowerCase()==this.RUUserNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.RUFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
    if(from=='uname'){
      this.RUUserNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.registration_no && data.registration_no.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.business_name && data.business_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.RUFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRUdata.map((apiData:any)=>{
              if(apiData.user_name.user_name && apiData.user_name.user_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRUdata = result
      this.dataRetailSource.data = this.filterRUdata

    }
  }

  clearEUFilter()
  {
    this.EUStatusFilter='';
    this.EUBussinessFilter='';
    this.EUCityFilter='';
    this.dataSource.data = this.dataEnterprise

  }
  filterByStatus(order: any) {
    switch (order) {
      case 'All':
        this.selectedFilter = {
          all: true,
          active: false,
          expired: false,
          requested: false
        }
        break;
      case 'Active':
        this.selectedFilter = {
          all: false,
          active: true,
          expired: false,
          requested: false
        }
        break;
      case 'Expired':
        this.selectedFilter = {
          all: false,
          active: false,
          expired: true,
          requested: false
        }
        break;
      case 'Requested':
        this.selectedFilter = {
          all: false,
          active: false,
          expired: false,
          requested: true
        }
        break;
    }
    this.filterByStatusDataHandel(order)
  }

  clearRUFilter()
  {
    this.RUStatusFilter='';
    this.RUBussinessFilter='';
    this.RUCityFilter='';
    this.dataRetailSource.data = this.dataRetail

  }
  getLicensesData()
  {
    this.httpSv.getAllBMLicensesList().subscribe((res:any)=>{
      if(res['status'])
      {
        this.apiData=res['results'];
        this.apiData.map((ele: any) => {
         
          if (ele.certificate_validity == 'Valid') ele.certificate_validity = 'Active'
          else if (ele.certificate_validity == 'Request Sent') ele.certificate_validity = 'Requested'

          if(ele.certificate_validity)
          ele.certificate_validity=ele.certificate_validity.toLowerCase()
          return ele
        })
        this.dataSource = new MatTableDataSource(this.apiData);   
        // this.apiData=this.dataSource;
      }
      else{
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    },(err:any)=>{
      if(err.error.message=='No FSSAI documents found for these users.')
      this.dataSource = new MatTableDataSource();
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  filterByStatusDataHandel(order: any) {
    console.log(this.apiData)
    if (order == 'All')
      this.dataSource = new MatTableDataSource(this.apiData)
    else {
      const tempData = this.apiData.filter((ele: any) => ele.status.toLowerCase() == order.toLowerCase())
      this.dataSource = new MatTableDataSource(tempData)
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  viewLicence(url: any) {
    let fileType = url.split('.')
    fileType = fileType[fileType.length - 1]
    const dialogRef = this.dialog.open(DialogAppLicenseView, {
      hasBackdrop: true,
      // disableClose:true
      data: {
        type: fileType,
        src: url
      }
    })
  }
    modifyAppUser(licensesNo: any, id: any,element:any) {
     this.httpSv.getBMLicenseDetails(licensesNo,id).subscribe((res:any)=>{
      if(res['status'])
      {
        // licensesNo: this.tempLicenseNo,
        // data:'',
        // userId: this.data.id?this.data.id:this.userId,
        // userType: this.data.type,
        // type:state.toLowerCase(),
      this.licenseDetails=res['results'][0];
      const dialogRef = this.dialog.open(DialogAppLicenseModify, {
        hasBackdrop: true,
        disableClose: true,
        maxWidth: '90vw',
        data: {
          licensesNo,
          licenseDetails:this.licenseDetails,
          userType:id,
          role:this.role,
          addNew:false,
          type:id,
          licId:element.id
        }
      }).afterClosed().subscribe((res:any)=>{
      if(res)
        this.ngOnInit()
      })}
      else{
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    },(err:any)=>{
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
}






@Component({
  selector: 'app-license-modify-dialog',
  templateUrl: './dialogue.addDocument.component.html',
  styleUrls: ['./licenses.component.css']
})
export class DialogAddDocument implements OnInit {
  role: string = '';
  licenseData:any;
  documentName:any='';
  fileData:any;
  fileName:any=''
  constructor(
    public dialogRef: MatDialogRef<DialogAddDocument>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataShare: DataService,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dialog: MatDialog,
    private config: NgSelectConfig
  ) {
    this.config.bindValue = "harsga" ;
   }


  ngOnInit(): void {
    if(this.data.id){
        this.httpSv.getDocumentById(this.data.id).subscribe((res:any)=>{
          if(res['status']){
            this.documentName = res['results'][0]['name']
          } else{
            this.toster.error("Something went wrong",'',{timeOut:2000})
          }
        },(err:any)=>{
          this.toster.error("Something went wrong",'',{timeOut:2000})
        })
      }
  }
  onImageChange(event: any) {
    const fsize = event.target.files[0].size;
    const fileSize = Math.round((fsize / 1024));
    const name = event.target.files[0].name;
    console.log(fileSize)
    if (fileSize > 10000) {
      this.toster.error('File Size should be less than 10Mb', '', { timeOut: 2000 })
      return
    }
    let file: File = event.target.files[0];
    let reader: FileReader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.fileData = file
    reader.onload = (e: any) => {
      // this.src = e.target.result;
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
          this.fileName = target.files[0].name
      }    };
    const fileDom = document.getElementById('file_inp') as HTMLInputElement
    if (fileDom) {
      fileDom.value = ''
    }
  }
  close(){
    this.dialogRef.close(false)
  }
  save(){
    
    if(!this.data.id){
      if(this.documentName.length>0){
      if(this.fileData){
        const data = new FormData()
        data.append("name", this.documentName)
        data.append("documents", this.fileData)
        this.httpSv.uploadSampleDoc(data).subscribe((res:any)=>{
          if(res['status']){
            this.toster.success("Document uploaded successfully",'',{timeOut:2000})
            this.dialogRef.close(true)
          }
          else{
            this.toster.error("Something went wrong",'',{timeOut:2000})
          }
        },(err:any)=>{
          this.toster.error("Something went wrong",'',{timeOut:2000})
        })
      }
      else{
        this.toster.error("Please Upload the Document",'',{timeOut:2000})
      }
    }else{
      this.toster.error("Please enter the name of the document",'',{timeOut:2000})
    }
  }
  else{
    if(this.documentName.length>0){
        const data = new FormData()
        data.append("name", this.documentName)
        if(this.fileData)
        data.append("documents", this.fileData)
        this.httpSv.updateSampleDoc(data,this.data.id).subscribe((res:any)=>{
          if(res['status']){
            this.toster.success("Document uploaded successfully",'',{timeOut:2000})
            this.dialogRef.close(true)
          }
          else{
            this.toster.error("Something went wrong",'',{timeOut:2000})
          }
        },(err:any)=>{
          this.toster.error("Something went wrong",'',{timeOut:2000})
        })
    }else{
      this.toster.error("Please enter the name of the document",'',{timeOut:2000})
    }
  }
    
  }
}








@Component({
  selector: 'app-license-modify-dialog',
  templateUrl: './dialogue.addServiceRequest.component.html',
  styleUrls: ['./licenses.component.css']
})
export class DialogueAddServiceRequest implements OnInit {
  role: any = '';
  licenseData:any;
  documentName:any='';
  fileData:any;
  notes:any = ''
  fileName:any=''
  constructor(
    public dialogRef: MatDialogRef<DialogAddDocument>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataShare: DataService,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dialog: MatDialog,
    private config: NgSelectConfig
  ) {
    this.config.bindValue = "harsga" ;
   }

   serviceReqName:any = null;
   companyName:any;
   licenseNumber:any;
   allTypeOfService:any = []
   ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      this.role = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
    }
    this.companyName = this.data.business_name
    this.licenseNumber = this.data.registration_no
    this.httpSv.getAllTypeOfService().subscribe(
      (res: any) => {
        if (res['status']) {
          if (res['result'].length > 0){
            res['result'].forEach((service:any) => {
              this.allTypeOfService = [...this.allTypeOfService, service];
            })
            this.allTypeOfService.push({id: 13, name: "New license"})
          } else this.allTypeOfService = [{ name: 'N/A' }];
        } else {
        }
      },
      (err: any) => {
        this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
      }
    );
   }

   close(){
    this.dialogRef.close(false)
   }
   save(){
    if(!this.serviceReqName){
        this.toster.error('Please select Service Request type', '', { timeOut: 2000 });
        return;
      }
    let data:any = {
      user_id : this.data.user_name.id,
      services:this.serviceReqName,
      fssai_regd_no:this.licenseNumber,
      notes: this.notes
    }
    this.httpSv.beuRaiseServiceRequest(data).subscribe((res:any)=>{
      if(res['status']){
        this.dialogRef.close(true)
        this.toster.success('Service request raised successfully','',{timeOut:2000})
      }
      else{
        this.toster.error('Something went wrong. Please try again','',{timeOut:2000})
      }
    },(err:any)=>{
      this.toster.error('Something went wrong. Please try again','',{timeOut:2000})
    })
   }
  }