<div class="mainDiv" [ngClass]="!pdfFlag ? 'scroll':'' ">
    <div style="text-align: right;text-align: right;
    position: sticky; z-index: 111;
    top: 0;right:0">
    <img src="../../../assets/img/download.svg" alt=""
    style="width: 40px;height: 40px;cursor: pointer;margin-right: 10px;" (click)="download()"> 
    <img src="../../../assets/img/close-svgrepo-com.svg" alt=""
            style="width: 40px;height: 40px;cursor: pointer;" (click)="close()">
        </div>
    <div>
        <div *ngIf="pdfFlag" >
            <pdf-viewer [src]="src" [render-text]="true" [original-size]="true" [show-all]="true"
                [external-link-target]="'blank'" [rotation]="0" [fit-to-page]="false" [autoresize]="true"
                [show-borders]="true" [zoom-scale]="zoomScale" [zoom]="0" class="pdf-viewer"></pdf-viewer>
        </div>
        <div *ngIf="!pdfFlag" class="imageViewer" style="text-align: center;">
            <img  style="width:100%" [src]="src" alt="">
        </div>

    </div>
</div>







<style>
    .mainDiv {
        background: #ebebeb;
        padding: 1rem;
        border-radius: 16px;
        width: 73vw;
        max-height: 75vh;
    }
    .mainDiv::-webkit-scrollbar {
        
    }
    .scroll{
        overflow-y: scroll;
    }

    .pdf-viewer {
        width: 70vw;
        height: 60vh;
    }

    :host ::ng-deep .ng2-pdf-viewer-container::-webkit-scrollbar {
        /* display: none; */
    }
</style>