import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}
  //-----------------------------------Common APIs-------------------------------------

  // login Api
  login(data: any) {
    return this.http.post(environment.baseUrl + 'dashboard/login/', data);
  }
  getAllKob() {
    return this.http.get(environment.baseUrl + 'businessKind/');
  }
  upgradeUser(id:any,data:any,from:any)
  {
    if(from == 'ru')
      return this.http.put(
        environment.baseUrl + 'superadmin/retail/users/upgrade/' + id + '/',data);
    else 
      return this.http.put(
      environment.baseUrl + 'superadmin/enterprise/users/downgrade/' + id + '/',data);
  }
  setStorageLimit(data:any)
  {
    return this.http.post(
      environment.baseUrl + 'superadmin/storage/info/',data);
  }
  setRetailLicenseLimit(data:any)
  {
    return this.http.post(
      environment.baseUrl + 'superadmin/ru/license/limit/',data);
  }
  getRetailLicenseLimit() {
    return this.http.get(environment.baseUrl + 'superadmin/ru/license/limit/');
  }
  getStorageLimit(){
    return this.http.get(
      environment.baseUrl + 'superadmin/storage/info/');
  }
  deleteUser(roleId: any, id: any, type:any = null) {
    if (roleId == 1) {
      if (type == 'enterprise')
        return this.http.delete(
          environment.baseUrl + 'superadmin/enterprise/users/delete/' + id + '/'
        );
      else if ((type = 'retail'))
        return this.http.delete(
          environment.baseUrl + 'superadmin/retail/users/delete/' + id + '/'
        );
    } else if (roleId == 3) {
      if (type == 'enterprise')
        return this.http.delete(
          environment.baseUrl + 'bm/enterprise/users/delete/' + id + '/'
        );
      else if ((type = 'retail'))
        return this.http.delete(
          environment.baseUrl + 'bm/retail/users/delete/' + id + '/'
        );
    } else if(roleId == '2') {
      if(type == 'eu'){
        return this.http.delete(environment.baseUrl+'am/enterprise/users/delete/'+id+'/')
      }else if(type == 'ces'){
        return this.http.delete(environment.baseUrl+'am/retail/users/delete/'+id+'/')
      } else if(type == 'bm'){
        return this.http.delete(environment.baseUrl+'am/bm/users/delete/'+id+'/')
      }
    }
    return;
  }
  resetPassword(id: any, roleId: any, data: any, type: any) {
    if (roleId == '1')
      return this.http.post(
        environment.baseUrl + 'superadmin/users/reset_password/' + id + '/',
        data
      );
    else if (roleId == '3')
      if(type=='eu')
        return this.http.post(
          environment.baseUrl + 'bm/enterprise/users/reset_password/' + id + '/',
          data
        );
      else
      return this.http.post(
        environment.baseUrl + 'bm/retail/users/reset_password/' + id + '/',
        data
      );

    else if (roleId == '2'){
      if(type == 'eu'){
        return this.http.post(environment.baseUrl+'am/enterprise/users/reset_password/' + id + '/', data)
      } else if(type == 'ces'){
        return this.http.post(environment.baseUrl+'am/retail/users/reset_password/' + id + '/', data)
      } else if(type == 'bm'){
        return this.http.post(environment.baseUrl+'am/bm/users/reset_password/' + id + '/', data)
      }
    }
    return;
  }

  //-----------------------------------Superadmin APIs-------------------------------------
  //WEB USER APIS
  //add ces
  createNewCes(data: any, roleId: any) {
    if (roleId == 1)
      return this.http.post(
        environment.baseUrl + 'superadmin/ces/users/create/',
        data
      );
    else if (roleId == 3)
      return this.http.post(
        environment.baseUrl + 'bm/enterprise/users/create/',
        data
      );
    return;
  }
  getAllCESUserList() {
    return this.http.get(environment.baseUrl + 'superadmin/ces/users/views/');
  }
  getAllCesList() {
    return this.http.get(environment.baseUrl + 'superadmin/ces/users/list/');
  }

  //add bm
  createNewBM(data: any) {
    return this.http.post(
      environment.baseUrl + 'superadmin/bm/users/create/',
      data
    );
  }
  // getAllBmUserList() {
  //   return this.http.get(environment.baseUrl + 'superadmin/ces/users/views/')
  // }
  getAllBmList(roleId:any) {
    if(roleId == 1)
      return this.http.get(environment.baseUrl + 'superadmin/bm/users/list/dropdown/');
    else
      return this.http.get(environment.baseUrl + 'am/bm/users/list/');
  }
  getAllBm() {
    return this.http.get(environment.baseUrl + 'superadmin/bm/users/list/');
  }
  getBmDetails(id: any) {
    return this.http.get(
      environment.baseUrl + 'superadmin/bm/users/detail/' + id + '/'
    );
  }
  getAMDetails(id: any) {
    return this.http.get(
      environment.baseUrl + 'superadmin/am/users/detail/' + id + '/'
    );
  }
  updateBmDetails(id: any, data: any) {
    return this.http.put(
      environment.baseUrl + 'superadmin/bm/users/edit/' + id + '/',
      data
    );
  }
  updateAMDetails(id: any, data: any) {
    return this.http.put(
      environment.baseUrl + 'superadmin/am/users/edit/' + id + '/',
      data
    );
  }
  deleteBM(id: any) {
    return this.http.delete(
      environment.baseUrl + 'superadmin/bm/users/delete/' + id + '/'
    );
  }
  getAllAmList() {
    return this.http.get(
      environment.baseUrl + 'superadmin/am/users/list/dropdown/'
    );
  }
  getAllAm() {
    return this.http.get(environment.baseUrl + 'superadmin/am/users/list/');
  }
  CreateNewAmUser(data: any) {
    console.log(data);
    return this.http.post(
      environment.baseUrl + 'superadmin/am/users/create/',
      data
    );
  }
  deleteAM(id: any) {
    return this.http.delete(
      environment.baseUrl + 'superadmin/am/users/delete/' + id + '/'
    );
  }
  getEnterpriseLicense() {
    return this.http.get(
      environment.baseUrl + 'superadmin/eu/fssai/licenses/list/'
    );
  }

  getAllRetailUserList() {
    return this.http.get(environment.baseUrl + 'superadmin/retail/users/list/');
  }
  getRetailUserDtls(id: any, roleId: any,from:any=null) {
    if (roleId == 1)
      return this.http.get(
        environment.baseUrl + 'superadmin/retail/users/list/' + id + '/'
      );
    else if (roleId == 3)
      if(from=='enterprise')
      return this.http.get(
        environment.baseUrl + 'bm/enterprise/users/list/' + id + '/'
      );
      else
      return this.http.get(
        environment.baseUrl + 'bm/retail/users/list/' + id + '/'
      );
    return;
  }
  getEnterpriseUserDtls(id: any, roleId: any) {
    if (roleId == 1)
      return this.http.get(
        environment.baseUrl + 'superadmin/enterprise/users/list/' + id + '/'
      );
    else if (roleId == 3)
      return this.http.get(
        environment.baseUrl + 'bm/enterprise/users/list/' + id + '/'
      );
    else if (roleId == '2'){
      return this.http.get(environment.baseUrl+'am/enterprise/users/list/'+id+'/')
    }
    return;
  }
  updateEntripriseUser(id: any, data: any) {
    return this.http.put(
      environment.baseUrl + 'superadmin/enterprise/users/list/' + id + '/',
      data
    );
  }
  getRetailAllLicense() {
    return this.http.get(
      environment.baseUrl + 'superadmin/ru/fssai/licenses/list/'
    );
  }
  getOneRetailLicense(licNo: any) {
    return this.http.get(
      environment.baseUrl +
        'superadmin/ru/fssai/licenses/details/' +
        licNo +
        '/'
    );
  }
  getOneBMRetailLicense(licNo:any){
    return this.http.get(
      environment.baseUrl +
        'bm/ru/fssai/licenses/details/' +
        licNo +
        '/'
    );
  }
  getOneEnterpiseLicense(licNo: any) {
    return this.http.get(
      environment.baseUrl +
        'superadmin/eu/fssai/licenses/details/' +
        licNo +
        '/'
    );
  }
  getOneBMEnterpiseLicense(licNo:any){
    return this.http.get(
      environment.baseUrl +
        'bm/eu/fssai/licenses/details/' +
        licNo +
        '/'
    );
  }
  createRetailUser(data: any) {
    return this.http.post(
      environment.baseUrl + 'superadmin/retail/users/create/',
      data
    );
  }
  createEnterpriseUser(data: any) {
    return this.http.post(
      environment.baseUrl + 'superadmin/enterprise/users/create/',
      data
    );
  }
  getAllEnterpriseUser() {
    return this.http.get(
      environment.baseUrl + 'superadmin/enterprise/users/list/'
    );
  }
  assignBMToEU(data: any) {
    return this.http.post(
      environment.baseUrl + 'superadmin/assign-eu-to-bm/',
      data
    );
  }
  assignBMToRU(data:any)
  {
    return this.http.post(
      environment.baseUrl + 'superadmin/assign-am-to-ru/',
      data
    );
  }
  assignAMToEU(data: any) {
    return this.http.post(
      environment.baseUrl + 'superadmin/assign-eu-to-am/',
      data
    );
  }
  getAllLicensesDrowpDwonEnterprise() {
    return this.http.get(
      environment.baseUrl + 'superadmin/eu/fssai/licenseNo/dropdown/'
    );
  }
  getAllLicensesDrowpDwonRetail() {
    return this.http.get(
      environment.baseUrl + 'superadmin/ru/fssai/licenseNo/dropdown/'
    );
  }
  deleteEnterpriseServcieRequest(id: any, data: any) {
    return this.http.delete(
      environment.baseUrl + 'superadmin/eu/service/requests/delete/' + id + '/'
    );
  }
  deleteRetailServcieRequest(id: any, data: any) {
    return this.http.delete(
      environment.baseUrl + 'superadmin/ru/service/requests/delete/' + id + '/'
    );
  }

  getPremiseAddress(){
    return this.http.get(environment.baseUrl + 'premiseAddress/')
  }

  getCompanyIncorporation(){
    return this.http.get(environment.baseUrl + 'companyIncorporation/')
  }

  getBusinessNature(){
    return this.http.get(environment.baseUrl + 'businessNature/')
  }

  createNewLicenseDtls(data: any, roleId:any, type:any){
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if(roleId == '1' || roleId == '2'){
      if(type == 'enterprise'){
        return this.http.post(environment.baseUrl + 'superadmin/eu/fssai/licenses/create/', data, options)
      } else if(type == 'retail'){
        return this.http.post(environment.baseUrl + 'superadmin/ru/fssai/licenses/create/', data, options)
      } else 
      return;
    }
    else if(roleId == '3'){
      if(type == 'enterprise'){
        return this.http.post(environment.baseUrl + 'bm/eu/fssai/licenses/create/', data, options)
      } else if(type == 'retail'){
        return this.http.post(environment.baseUrl + 'bm/ru/fssai/licenses/create/', data, options)
      } else 
      return;
    }
    else if(roleId=='5'){
      if(type == 'enterprise'){
        return this.http.post(environment.baseUrl + 'beu/fssai/licenses/create/', data, options)
      }     
    } 
      return;
    
  }

  addLicenseApplicationDtls(roleId: any, data: any, type: any){
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    console.log(roleId,data,type)
    const options = { headers: headers };
    if(roleId == '1'){
      if(type == 'enterprise' || type == 'eu' ){
        return this.http.post(environment.baseUrl + 'superadmin/eu/application/documents/create/', data, options)
      } else if(type == 'retail' || type == 'ru'){
        return this.http.post(environment.baseUrl + 'superadmin/ru/application/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '3'){
      console.log("else ",type)
      if(type == 'enterprise' || type == 'eu' ){
        return this.http.post(environment.baseUrl + 'bm/eu/application/documents/create/', data, options)
      } else if(type == 'retail' || type == 'ru'){
        return this.http.post(environment.baseUrl + 'bm/ru/application/documents/create/', data, options)
      }
      return
    }  
    else if(roleId == '2'){
      console.log("else ",type)
      if(type == 'eu'){
        return this.http.post(environment.baseUrl + 'am/eu/application/documents/create/', data, options)
      } else if(type == 'ru'){
        return this.http.post(environment.baseUrl + 'am/ru/application/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '5'){
      if(true){
        return this.http.post(environment.baseUrl + 'beu/application/documents/create/', data, options)
      } 
      return
    }
    return
  }

  addLicenseTestingDtls(roleId: any, data: any, type: any){
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if(roleId == '1'){
      if(type == 'enterprise' || type == 'eu'){
        return this.http.post(environment.baseUrl + 'superadmin/eu/test/documents/create/', data, options)
      } else if(type == 'retail' || type == 'ru'){
        return this.http.post(environment.baseUrl + 'superadmin/ru/test/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '3'){
      if(type == 'enterprise' || type == 'eu'){
        return this.http.post(environment.baseUrl + 'bm/eu/test/documents/create/', data, options)
      } else if(type == 'retail' || type == 'ru'){
        return this.http.post(environment.baseUrl + 'bm/ru/test/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '2'){
      if(type == 'eu'){
        return this.http.post(environment.baseUrl + 'am/eu/test/documents/create/', data, options)
      } else if(type == 'ru'){
        return this.http.post(environment.baseUrl + 'am/ru/test/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '5'){
        return this.http.post(environment.baseUrl + 'beu/test/documents/create/', data, options)
    }
    return
  }

  addLicenseTrainingDtls(roleId: any, data: any, type: any){
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if(roleId == '1'){
      if(type == 'enterprise' || type == 'eu'){
        return this.http.post(environment.baseUrl + 'superadmin/eu/training/documents/create/', data, options)
      } else if(type == 'retail' || type == 'ru'){
        return this.http.post(environment.baseUrl + 'superadmin/ru/training/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '3'){
      if(type == 'enterprise' || type == 'eu'){
        return this.http.post(environment.baseUrl + 'bm/eu/training/documents/create/', data, options)
      } else if(type == 'retail' || type == 'ru'){
        return this.http.post(environment.baseUrl + 'bm/ru/training/documents/create/', data, options)
      }
    }
    else if(roleId == '2'){
      if(type == 'eu'){
        return this.http.post(environment.baseUrl + 'am/eu/training/documents/create/', data, options)
      } else if(type == 'ru'){
        return this.http.post(environment.baseUrl + 'am/ru/training/documents/create/', data, options)
      }
    }
      else if(roleId == '5'){       
          return this.http.post(environment.baseUrl + 'beu/training/documents/create/', data, options)
      }
      return
  }

  addLicenseHealthDtls(roleId: any, data: any, type: any){
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if(roleId == '1'){
      if(type == 'enterprise' || type == 'eu'){
        return this.http.post(environment.baseUrl + 'superadmin/eu/health/documents/create/', data, options)
      } else if(type == 'retail' || type == 'ru'){
        return this.http.post(environment.baseUrl + 'superadmin/ru/health/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '3'){
      if(type == 'enterprise' || type == 'eu'){
        return this.http.post(environment.baseUrl + 'bm/eu/health/documents/create/', data, options)
      } else if(type == 'retail' || type == 'ru'){
        return this.http.post(environment.baseUrl + 'bm/ru/health/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '2'){
      if(type == 'eu'){
        return this.http.post(environment.baseUrl + 'am/eu/health/documents/create/', data, options)
      } else if(type == 'ru'){
        return this.http.post(environment.baseUrl + 'am/ru/health/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '5'){
        return this.http.post(environment.baseUrl + 'beu/health/documents/create/', data, options)
    }
    return
  }

  addLicenseCalibrationDtls(roleId: any, data: any, type: any){
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if(roleId == '1'){
      if(type == 'enterprise' || type == 'eu'){
        return this.http.post(environment.baseUrl + 'superadmin/eu/calibration/documents/create/', data, options)
      } else if(type == 'retail' || type == 'ru'){
        return this.http.post(environment.baseUrl + 'superadmin/ru/calibration/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '3'){
      if(type == 'enterprise' || type == 'eu'){
        return this.http.post(environment.baseUrl + 'bm/eu/calibration/documents/create/', data, options)
      } else if(type == 'retail' || type == 'ru'){
        return this.http.post(environment.baseUrl + 'bm/ru/calibration/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '2'){
      if(type == 'eu'){
        return this.http.post(environment.baseUrl + 'am/eu/calibration/documents/create/', data, options)
      } else if(type == 'ru'){
        return this.http.post(environment.baseUrl + 'am/ru/calibration/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '5'){
        return this.http.post(environment.baseUrl + 'beu/calibration/documents/create/', data, options)
    }
    return
  }

  addLicenseOthersDtls(roleId: any, data: any, type: any){
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if(roleId == '1'){
      if(type == 'enterprise' || type == 'eu'){
        return this.http.post(environment.baseUrl + 'superadmin/eu/others/documents/create/', data, options)
      } else if(type == 'retail' || type == 'ru'){
        return this.http.post(environment.baseUrl + 'superadmin/ru/others/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '3'){
      if(type == 'enterprise' || type == 'eu'){
        return this.http.post(environment.baseUrl + 'bm/eu/others/documents/create/', data, options)
      } else if(type == 'retail' || type == 'ru'){
        return this.http.post(environment.baseUrl + 'bm/ru/others/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '2'){
      if(type == 'eu'){
        return this.http.post(environment.baseUrl + 'bm/eu/others/documents/create/', data, options)
      } else if(type == 'ru'){
        return this.http.post(environment.baseUrl + 'bm/ru/others/documents/create/', data, options)
      }
      return
    }
    else if(roleId == '5'){
        return this.http.post(environment.baseUrl + 'beu/others/documents/create/', data, options)
    }
    return
  }

  // SuperAdmin Dashboard

  getAccManagerCount() {
    return this.http.get(
      environment.baseUrl + 'superadmin/dashboard/am/users/'
    );
  }

  getBusinessManagerCount() {
    return this.http.get(
      environment.baseUrl + 'superadmin/dashboard/bm/users/'
    );
  }

  getEnterpriserCount() {
    return this.http.get(
      environment.baseUrl + 'superadmin/dashboard/enterprise/users/'
    );
  }

  getRetailUserCount() {
    return this.http.get(
      environment.baseUrl + 'superadmin/dashboard/retail/users/'
    );
  }

  getAllLicenseCount() {
    return this.http.get(
      environment.baseUrl + 'superadmin/dashboard/fssai/license/'
    );
  }

  getExpiringLicenseCount() {
    return this.http.get(
      environment.baseUrl + 'superadmin/dashboard/fssai/expiring/license/'
    );
  }
  getSAExpiringLicenseCount() {
    return this.http.get(
      environment.baseUrl + 'superadmin/dashboard/fssai/license/request/solved/'
    );
  } 
  getAMExpiringLicenseCountDashboard() {
    return this.http.get(
      environment.baseUrl + 'am/dashboard/fssai/license/request/solved/'
    );
  } 
  getBMExpiringLicenseCount() {
    return this.http.get(
      environment.baseUrl + 'bm/dashboard/eu/fssai/license/request/solved/'
    );
  }
  getServiceRequestByMonth(month: any,year:any) {
    return this.http.get(
      environment.baseUrl +
        'superadmin/dashboard/service/requests/' +
        month +
        '/' + year + "/"
    );
  }

  getServiceRequestCount() {
    return this.http.get(
      environment.baseUrl + 'beu/dashboard/service/requests/'
    );
  }
  getSAServiceRequestCount() {
    return this.http.get(
      environment.baseUrl + 'superadmin/dashboard/service/requests/'
    );
  }

  assignBMToAM(data: any) {
    return this.http.post(
      environment.baseUrl + 'superadmin/assign-bm-to-am/',
      data
    );
  }
  //------------------------------------------------BEU APIs-------------------------------------
  getAllLicenses() {
    return this.http.get(environment.baseUrl + 'beu/fssai/licenses/list/');
  }
  getLicensesDtls(licensesNo: any) {
    return this.http.get(
      environment.baseUrl + 'beu/fssai/licenses/details/' + licensesNo + '/'
    );
  }

  updateLiciencePersonalDtls(
    licenceNo: any,
    data: any,
    roleId: any,
    type = 'enterprise'
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if (roleId == 5)
      return this.http.put(
        environment.baseUrl + `beu/fssai/licenses/edit/${licenceNo}/`,
        data,
        options
      );
    else if (roleId == 3)
      if(type=='enterprise')
        return this.http.put(
          environment.baseUrl + `bm/eu/fssai/licenses/edit/${licenceNo}/`,
          data,
          options
        );
      else
        return this.http.put(
          environment.baseUrl + `bm/ru/fssai/licenses/edit/${licenceNo}/`,
          data,
          options
        );
    
    else if (roleId == 1) {
      if (type == 'enterprise')
        return this.http.put(
          environment.baseUrl +
            `superadmin/eu/fssai/licenses/edit/${licenceNo}/`,
          data,
          options
        );
      else
        return this.http.put(
          environment.baseUrl +
            `superadmin/ru/fssai/licenses/edit/${licenceNo}/`,
          data,
          options
        );
    }
    return;
  }
  updateLicienceApplicationDtls(id: any, data: any, roleId: any, userType:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    console.log(id,roleId,data,userType)
    const options = { headers: headers };
    if (roleId == 5)
      return this.http.put(
        environment.baseUrl + `beu/application/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 3)
      return this.http.put(
        environment.baseUrl + `bm/eu/application/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 1 && userType == "enterprise" || userType == "eu")
      return this.http.put(
        environment.baseUrl +
          `superadmin/eu/application/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 1 && userType == "retail" || userType == "ru")
        return this.http.put(
         	environment.baseUrl + `superadmin/ru/application/documents/edit/${id}/`,
          	data,
          	options
        )
    else if (roleId == 2 && userType == "eu")
		return this.http.put(
        	environment.baseUrl + `am/eu/application/documents/edit/${id}/`,
			data,
			options
		)
    else if (roleId == 2 && userType == "ru")
		return this.http.put(
			environment.baseUrl + `am/ru/application/documents/edit/${id}/`,
			data,
			options
		)
    return;
  }
  updateLicienceTestingDtls(id: any, data: any, roleId: any, userType:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if (roleId == 5)
      return this.http.put(
        environment.baseUrl + `beu/test/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 3)
      return this.http.put(
        environment.baseUrl + `bm/eu/test/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 1 && userType == "enterprise" || userType == "eu")
      return this.http.put(
        environment.baseUrl + `superadmin/eu/test/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 1 && userType == "retail" || userType == "ru")
        return this.http.put(
         	environment.baseUrl + `superadmin/ru/test/documents/edit/${id}/`,
          	data,
          	options
        );
    else if (roleId == 2 && userType == "eu")
		return this.http.put(
        	environment.baseUrl + `am/eu/test/documents/edit/${id}/`,
			data, 
			options
		);
    else if (roleId == 2 && userType == "ru")
		return this.http.put(
			environment.baseUrl + `am/ru/test/documents/edit/${id}/`,
			data,
			options
		);
    return;
  }
  updateLicienceTrainingDtls(id: any, data: any, roleId: any, userType:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if (roleId == 5)
      return this.http.put(
        environment.baseUrl + `beu/training/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 3)
      return this.http.put(
        environment.baseUrl + `bm/eu/training/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 1 && userType == "enterprise" || userType == "eu" )
      return this.http.put(
        environment.baseUrl + `superadmin/eu/training/documents/edit/${id}/`,
        data,
        options
      );
	else if (roleId == 1 && userType == "retail" || userType == "ru")
        return this.http.put(
         	environment.baseUrl + `superadmin/ru/trainig/documents/edit/${id}/`,
          	data,
          	options
        );
    else if (roleId == 2 && userType == "eu")
		return this.http.put(
        	environment.baseUrl + `am/eu/training/documents/edit/${id}/`,
			data, 
			options
		);
    else if (roleId == 2 && userType == "ru")
		return this.http.put(
			environment.baseUrl + `am/eu/training/documents/edit/${id}/`,
			data,
			options
		);
    return;
  }
  updateLicienceHealthDtls(id: any, data: any, roleId: any, userType:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if (roleId == 5)
      return this.http.put(
        environment.baseUrl + `beu/health/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 3)
      return this.http.put(
        environment.baseUrl + `bm/eu/health/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 1 && userType == "enterprise" || userType == "eu")
      return this.http.put(
        environment.baseUrl + `superadmin/eu/health/documents/edit/${id}/`,
        data,
        options
      );
	  else if (roleId == 1 && userType == "retail" || userType == "ru")
	  return this.http.put(
		   environment.baseUrl + `superadmin/ru/health/documents/edit/${id}/`,
			data,
			options
	  );
  else if (roleId == 2 && userType == "eu")
	  return this.http.put(
		  environment.baseUrl + `am/eu/health/documents/edit/${id}/`, 
		  data,
		  options
	  );
  else if (roleId == 2 && userType == "ru")
	  return this.http.put(
		  environment.baseUrl + `am/ru/health/documents/edit/${id}/`,
		  data,
		  options
	  );
    return;
  }

  updateRetailUser(id:any,role:any,data:any)
  {
    if (role == 1)
    return this.http.put(
      environment.baseUrl + 'superadmin/retail/users/list/' + id + '/'
    ,data);
  else if (role == 3)
    return this.http.get(
      environment.baseUrl + 'bm/enterprise/users/list/' + id + '/'
    );
  return;
  }

  updateLicienceCalibirationDtls(id: any, data: any, roleId: any, userType:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if (roleId == 5)
      return this.http.put(
        environment.baseUrl + `beu/calibration/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 3)
      return this.http.put(
        environment.baseUrl + `bm/eu/calibration/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 1 && userType == "enterprise" || userType == "eu")
      return this.http.put(
        environment.baseUrl + `superadmin/eu/calibration/documents/edit/${id}/`,
        data,
        options
      );
	  else if (roleId == 1 && userType == "retail" || userType == "ru")
	  return this.http.put(
		   environment.baseUrl + `superadmin/ru/calibration/documents/edit/${id}/`,
			data,
			options
	  );
  else if (roleId == 2 && userType == "eu")
	  return this.http.put(
		  environment.baseUrl + `am/eu/calibration/documents/edit/${id}/`, 
		  data, 
		  options
	  );
  else if (roleId == 2 && userType == "ru")
	  return this.http.put(
		  environment.baseUrl + `am/ru/calibration/documents/edit/${id}/`,
		  data,
		  options
	  );
    return;
  }
  updateLicienceOthersDtls(id: any, data: any, roleId: any, userType:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if (roleId == 5)
      return this.http.put(
        environment.baseUrl + `beu/others/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 3)
      return this.http.put(
        environment.baseUrl + `bm/eu/others/documents/edit/${id}/`,
        data,
        options
      );
    else if (roleId == 1 && userType == "enterprise" || userType == "eu")
      return this.http.put(
        environment.baseUrl + `superadmin/eu/others/documents/edit/${id}/`,
        data,
        options
      ); 
	else if (roleId == 1 && userType == "retail" || userType == "ru")
	  return this.http.put(
		   environment.baseUrl + `superadmin/ru/others/documents/edit/${id}/`,
			data,
			options
	  );
  	else if (roleId == 2 && userType == "eu")
	  return this.http.put(
		  environment.baseUrl + `am/eu/others/documents/edit/${id}/`, 
		  data, 
		  options
	  );
  	else if (roleId == 2 && userType == "ru")
	  return this.http.put(
		  environment.baseUrl + `am/ru/others/documents/edit/${id}/`,
		  data,
		  options
	  );
    return;
  }

  getDasLicenseCount() {
    return this.http.get(environment.baseUrl + 'beu/dashboard/fssai/license/');
  }
  getDasDocumentsCount() {
    return this.http.get(
      environment.baseUrl + 'beu/dashboard/fssai/supporting/documents/'
    );
  }
  getDasExpiringLicenseCount() {
    return this.http.get(
      environment.baseUrl + 'beu/dashboard/fssai/expiring/license/'
    );
  }
  getDasServiceRequestCount() {
    return this.http.get(
      environment.baseUrl + 'beu/dashboard/service/requests/'
    );
  }
  getDasServiceRequestWeekly(month: any,year:any) {
    return this.http.get(
      environment.baseUrl + 'beu/dashboard/service/requests/' + month + '/' + year + "/"
    );
  }

  getAllServicesRequest(roleId: any) {
    console.log(roleId);
    if (roleId == 1)
      return this.http.get(
        environment.baseUrl + 'superadmin/eu/service/requests/list/'
      );
    else if (roleId == 3)
      return this.http.get(
        environment.baseUrl + 'bm/eu/service/requests/list/'
      );
    else if (roleId == 5){
      return this.http.get(environment.baseUrl + 'beu/service/requests/list/');
    } else if (roleId == 2) {
      return this.http.get(environment.baseUrl + 'am/eu/service/requests/list/');
    }
    return;
  }
  getServicesRequestEnterpriseDts(id: any, roleId: any) {
    if (roleId == 1)
      return this.http.get(
        environment.baseUrl +
          'superadmin/eu/service/requests/detail/' +
          id +
          '/'
      );
    else if (roleId == 3)
      return this.http.get(
        environment.baseUrl + 'bm/eu/service/requests/detail/' + id + '/'
      );
    else if(roleId == 2)
        return this.http.get(environment.baseUrl + 'am/eu/service/requests/detail/' + id + '/')
    else if(roleId==5)
    return this.http.get(environment.baseUrl + 'beu/service/requests/detail/'+id+'/')
    return;
  }
  getServicesRequestretailDts(id: any, roleId: any) {
    if (roleId == 1)
      return this.http.get(
        environment.baseUrl +
          'superadmin/ru/service/requests/detail/' +
          id +
          '/'
      );
    else if(roleId == 2)
        return this.http.get(environment.baseUrl + 'am/ru/service/requests/detail/' + id + '/')
    // else if(roleId==3)
    // return this.http.get(environment.baseUrl + 'bm/eu/service/requests/detail/'+id+'/')
    // else if(roleId==0)
    // return this.http.get(environment.baseUrl + 'bm/eu/service/requests/detail/'+id+'/')
    return;
  }
  updateServiceRequest(id: any, data: any) {
    return this.http.put(
      environment.baseUrl + 'beu/service/requests/edit/' + id + '/',
      data
    );
  }
  deleteServiceRequest(id: any) {
    return this.http.delete(
      environment.baseUrl + 'beu/service/requests/delete/' + id + '/'
    );
  }

  getAllRUServiceRequest(roleId: any) {
    if(roleId == '1'){
      return this.http.get(
        environment.baseUrl + 'superadmin/ru/service/requests/list/'
      );
    } else if (roleId == '2'){
      return this.http.get(environment.baseUrl + 'am/ru/service/requests/list/');
    }
    return;
  }

  updateEUServiceRequest(id: any, data: any) {
    return this.http.put(
      environment.baseUrl + 'superadmin/eu/service/requests/edit/' + id + '/',
      data
    );
  }

  updateRUServiceRequest(id: any, data: any) {
    return this.http.put(
      environment.baseUrl + 'superadmin/ru/service/requests/edit/' + id + '/',
      data
    );
  }

  getProfileDtls() {
    return this.http.get(environment.baseUrl + 'beu/profile/details/');
  }
  updateProfileDtls(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    return this.http.put(
      environment.baseUrl + 'beu/profile/update/',
      data,
      options
    );
  }
  updatePassword(data: any) {
    return this.http.post(
      environment.baseUrl + 'beu/profile/change_pass/',
      data
    );
  }
  getAllTypeOfService() {
    return this.http.get(environment.baseUrl + 'services/');
  }

  // -----------------------------BUSSINESS MANAGER _------------------------------------------

  getAllUsrManagementList() {
    return this.http.get(environment.baseUrl + 'bm/enterprise/users/list/');
  }
  getAllBMLicensesList() {
    return this.http.get(environment.baseUrl + 'bm/eu/fssai/licenses/list/');
  }
  getUserStatusData() {
    return this.http.get(environment.baseUrl + 'bm/dashboard/eu/users/');
  }
  getLicenseStatus() {
    return this.http.get(
      environment.baseUrl + 'bm/dashboard/eu/fssai/license/'
    );
  }
  getExpiringLicenseStatus() {
    return this.http.get(
      environment.baseUrl + 'bm/dashboard/eu/fssai/expiring/license/'
    );
  }
  getServiceChatMonthHander(data: any,year:any) {
    return this.http.get(
      environment.baseUrl + 'bm/dashboard/eu/service/requests/' + data + '/' + year + "/"
    );
  }
  getServiceRequests() {
    return this.http.get(
      environment.baseUrl + 'bm/dashboard/eu/service/requests/'
    );
  }
  getBMLicenseDetails(id: any,from:any) {
    if(from=='enterprise')
      return this.http.get(
        environment.baseUrl + 'bm/eu/fssai/licenses/details/' + id + '/'
      );
    else
      return this.http.get(
        environment.baseUrl + 'bm/ru/fssai/licenses/details/' + id + '/'
      );
  }
  UpdateCes(data:any,id:any)
  {
    return this.http.put(
      environment.baseUrl + 'bm/enterprise/users/edit/' + id + '/',data
    );
  }
  handleAcceptRejectStatus(body: any, id: any) {
    return this.http.put(
      environment.baseUrl + 'bm/eu/service/requests/status/update/' + id + '/',
      body
    );
  }




  // ------------------------------------ Account Manager APIs ---------------------------------------

  
  getAMBusinessManagerCount(){
    return this.http.get(environment.baseUrl+'am/dashboard/bm/users/')
  }

  getAMEnterpriserCount(){
    return this.http.get(environment.baseUrl+'am/dashboard/eu/users/')
  }

  getAMRetailUserCount(){
    return this.http.get(environment.baseUrl+'am/dashboard/ru/users/')
  }

  getAMAllLicenseCount(){
    return this.http.get(environment.baseUrl+'am/dashboard/fssai/license/')
  }

  getAMExpiringLicenseCount(){
    return this.http.get(environment.baseUrl+'am/dashboard/fssai/expiring/license/')
  }

  getAMServiceRequestCount(){
    return this.http.get(environment.baseUrl+'am/dashboard/service/requests/')
  }

  getAMServiceRequestByMonth(month: any) {
    return this.http.get(environment.baseUrl + 'am/dashboard/service/requests/' + month + '/');
  }
  


  getAMEnterpriseUserList(){
    return this.http.get(environment.baseUrl+'am/enterprise/users/list/');
  }

  getAMCESUserList(){
    return this.http.get(environment.baseUrl+'am/retail/users/list/');
  }

  getBusinessManagerList(){
    return this.http.get(environment.baseUrl+'am/bm/users/list/')
  }

  getAMRetailUserDtls(id: any){
    return this.http.get(environment.baseUrl+'am/retail/users/list/'+id+'/')
  }

  getAMBusinessManagerDtls(id: any){
    return this.http.get(environment.baseUrl+'am/bm/users/detail/'+id+'/')
  }

  createAMEnterpriseUser(data: any) {
    return this.http.post(environment.baseUrl+'am/enterprise/users/create/',  data);
  }

  createAMRetailUser(data: any) {
    return this.http.post(environment.baseUrl+'am/retail/users/create/',  data);
  }

  createAMNewBM(data: any) {
    return this.http.post(environment.baseUrl+'am/bm/users/create/',  data);
  }

  getAMEnterpriseLicense(){
    return this.http.get(environment.baseUrl + 'am/eu/fssai/licenses/list/' );
  }

  updateAMLiciencePersonalDtls(licenceNo: any, data: any, roleId: any, type: any){
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if(type == 'eu' || type == 'enterprise'){
      return this.http.put(
        environment.baseUrl + `am/eu/fssai/licenses/edit/${licenceNo}/`,
        data,
        options
      );
    } else if(type == 'ru' || type == 'retail'){
      return this.http.put(
        environment.baseUrl + `am/ru/fssai/licenses/edit/${licenceNo}/`,
        data,
        options
      );
    }
    return;
  }




  getAMOneRetailLicense(licNo: any) {
    return this.http.get( environment.baseUrl + 'am/ru/fssai/licenses/details/' + licNo +'/' );
  }

  getAMOneEnterpriseLicense(licNo: any) {
    return this.http.get( environment.baseUrl + 'am/eu/fssai/licenses/details/' + licNo + '/' );
  }
  


  getAMRetailAllLicense(){
    return this.http.get(environment.baseUrl + 'am/ru/fssai/licenses/list/' );
  }

  updateAMEntripriseUser(id: any, data: any){
    return this.http.put(environment.baseUrl+'am/enterprise/users/edit/'+id+'/', data)
  }

  updateAMRetailUser(id: any, data: any){
    return this.http.put(environment.baseUrl+'am/retail/users/edit/'+id+'/', data)
  }

  updateAMBusinesseManager(id: any, data: any){
    return this.http.put(environment.baseUrl+'am/bm/users/edit/'+id+'/', data)
  }



  updateAMEUServiceRequest(id: any, data: any) {
    return this.http.put(
      environment.baseUrl + 'am/eu/service/requests/edit/' + id + '/',
      data
    );
  }



  updateAMRUServiceRequest(id: any, data: any) {
    return this.http.put(
      environment.baseUrl + 'am/ru/service/requests/edit/' + id + '/',
      data
    );
  }

  getAMAllLicensesDropdown(id: any, type: any) {
    if(type == 'eu'){
      return this.http.get(environment.baseUrl+ 'am/eu/fssai/licenseNo/dropdown/')
    } else if(type == 'ru'){
      return this.http.get(environment.baseUrl+ 'am/ru/fssai/licenseNo/dropdown/')
    }
    return;
  }

  deleteAMServiceRequest(id:any, type:any) {
    if(type == 'eu'){
      return this.http.delete(environment.baseUrl+'am/eu/service/requests/delete/'+id+'/')
    } else if(type == 'ru'){
      return this.http.delete(environment.baseUrl+'am/ru/service/requests/delete/'+id+'/')
    }
    return;
  }
  getBMAllRetailUsers(){
    return this.http.get( environment.baseUrl + 'bm/retail/users/list/' );
  }
  createNewRetail(data:any,role:any){
    return this.http.post(environment.baseUrl+'bm/retail/users/create/',  data);
  }
  UpdateRetailBM(data:any,id:any){
    return this.http.put(
      environment.baseUrl + 'bm/retail/users/edit/' + id + '/',
      data
    );
  }
  getEnterpriseLicenseBm()
  {
    return this.http.get(environment.baseUrl +'bm/eu/fssai/licenses/list/' );
  }
  getRetailLicenseBm()
  {
    return this.http.get(environment.baseUrl + 'bm/ru/fssai/licenses/list/' );
  }
  getpincode(data:any){
    return this.http.get( environment.baseUrl + 'superadmin/pincode/check/' + data + '/' );
  }
  getServiceRequestReport(roleId:any,pageSize:any,pageIndex:any,filter:any){
    if(roleId==1)
      return this.http.get( environment.baseUrl + 'superadmin/service/requests/reports/list/?page_size='+pageSize+'&user_type='+filter+'&page_no='+pageIndex );
    else if(roleId==2)
      return this.http.get( environment.baseUrl + 'am/service/requests/reports/list/?page_size='+pageSize+'&user_type='+filter+'&page_no='+pageIndex );
    else if(roleId==3)
      return this.http.get( environment.baseUrl + 'bm/service/requests/reports/list/?page_size='+pageSize+'&user_type='+filter+'&page_no='+pageIndex );
    else
      return this.http.get( environment.baseUrl + 'beu/service/requests/reports/list/?page_size='+pageSize+'&user_type='+filter+'&page_no='+pageIndex );
  }
  getServiceRequestCountReport(){
    return this.http.get( environment.baseUrl + 'superadmin/service/requests/reports/count/' );
  }
  getServiceRequestReportDate(from:any,to:any){
    return this.http.get( environment.baseUrl + 'superadmin/service/requests/reports/filter/?from_date='+from+'&to_date='+to );
  }
  getLicenseReport(roleId:any,pageSize:any,pageIndex:any,filter:any){
    if(roleId==1)
      return this.http.get( environment.baseUrl + 'superadmin/licenses/reports/list/?page_size='+pageSize+'&user_type='+filter+'&page_no='+pageIndex );
    else if(roleId==2)
      return this.http.get( environment.baseUrl + 'am/licenses/reports/list/?page_size='+pageSize+'&user_type='+filter+'&page_no='+pageIndex );
    else if(roleId==3)
      return this.http.get( environment.baseUrl + 'bm/licenses/reports/list/?page_size='+pageSize+'&user_type='+filter+'&page_no='+pageIndex );
    else 
      return this.http.get( environment.baseUrl + 'beu/licenses/reports/list/?page_size='+pageSize+'&user_type='+filter+'&page_no='+pageIndex );
 }
  getLicenseReportCount(){
    return this.http.get( environment.baseUrl + 'superadmin/licenses/reports/count/' );
  }
  getLicenseReportCountDate(from:any,to:any){
    return this.http.get( environment.baseUrl + 'superadmin/licenses/reports/filter/?from_date='+from+'&to_date='+to );
  }
  getUserReport(roleId:any,pageSize:any,pageIndex:any,filter:any){
    if(roleId==1)
      return this.http.get( environment.baseUrl + 'superadmin/users/reports/list/?page_size='+pageSize+'&user_type='+filter+'&page_no='+pageIndex );
    else if(roleId==2)
      return this.http.get( environment.baseUrl + 'am/users/reports/list/?page_size='+pageSize+'&user_type='+filter+'&page_no='+pageIndex );
    else if(roleId==3)
      return this.http.get( environment.baseUrl + 'bm/users/reports/list/?page_size='+pageSize+'&user_type='+filter+'&page_no='+pageIndex );
    else
      return this.http.get( environment.baseUrl + 'beu/users/reports/list/?page_size='+pageSize+'&user_type='+filter+'&page_no='+pageIndex );
  }
  getUserCount(){
    return this.http.get( environment.baseUrl + 'superadmin/users/reports/count/' );
  }
  getUserReportCountDate(from:any,to:any){
    return this.http.get( environment.baseUrl + 'superadmin/users/reports/filter/?from_date='+from+'&to_date='+to );
  }
  getSampleDocuments(){
    return this.http.get( environment.baseUrl + 'superadmin/sample/document/view/' );
  }
  uploadSampleDoc(data:any){
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    return this.http.post( environment.baseUrl + 'superadmin/sample/document/upload/',data );

  }
  deleteDocument(id:any){
    return this.http.delete(
      environment.baseUrl + 'superadmin/sample/document/delete/' + id + '/'
    );
  }
  getDocumentById(id:any){
    return this.http.get(environment.baseUrl+'superadmin/sample/document/detail/'+id+'/')
  }
  updateSampleDoc(data:any,id:any){
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    return this.http.put( environment.baseUrl + 'superadmin/sample/document/edit/'+id+'/',data );

  }
  getUserDataCount(roleId:any){
    if(roleId==1)
      return this.http.get( environment.baseUrl + 'superadmin/users/reports/count/' );
    else if(roleId==2)
      return this.http.get( environment.baseUrl + 'am/users/reports/count/' );
    else if(roleId==3)
      return this.http.get( environment.baseUrl + 'bm/users/reports/count/' );
    else
      return this.http.get( environment.baseUrl + 'beu/users/reports/count/' );
    }
  getLicenseDataCount(roleId:any){
    if(roleId==1)
      return this.http.get( environment.baseUrl + 'superadmin/licenses/reports/count/' );
    else if(roleId==2)
      return this.http.get( environment.baseUrl + 'am/licenses/reports/count/' );
    else if(roleId==3)
      return this.http.get( environment.baseUrl + 'bm/licenses/reports/count/' );
    else
      return this.http.get( environment.baseUrl + 'beu/licenses/reports/count/' );
    }
  getServiceDataCount(roleId:any){
    if(roleId==1)
      return this.http.get( environment.baseUrl + 'superadmin/service/requests/reports/count/' );
    else if(roleId==2)
      return this.http.get( environment.baseUrl + 'am/service/requests/reports/count/' );
    else if(roleId==3)
      return this.http.get( environment.baseUrl + 'bm/service/requests/reports/count/' );
    else
      return this.http.get( environment.baseUrl + 'beu/service/requests/reports/count/' );
  }

  addExistingLicense(data:any,roleId:any,type:any = 'enterprise'){
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data;boundary=----', // Set the content type as needed
    });
    const options = { headers: headers };
    if(roleId == 5)
      return this.http.post( environment.baseUrl + 'beu/fssai/existing/licenses/upload/',data );
    else if(roleId == 1){
      if(type == 'enterprise')
        return this.http.post( environment.baseUrl + 'superadmin/eu/fssai/existing/licenses/upload/',data );
      else
        return this.http.post( environment.baseUrl + 'superadmin/ru/fssai/existing/licenses/upload/',data );
    }
    else if(roleId == 2){
      if(type == 'enterprise')
        return this.http.post( environment.baseUrl + 'am/eu/fssai/existing/licenses/upload/',data );
      else
        return this.http.post( environment.baseUrl + 'am/ru/fssai/existing/licenses/upload/',data );
    }
    else if(roleId == 3){
        return this.http.post( environment.baseUrl + 'bm/eu/fssai/existing/licenses/upload/',data );
      }
    return
  }

  beuRaiseServiceRequest(data:any){
    return this.http.post( environment.baseUrl + 'superadmin/service/requests/create/',data );
  }
  changeUserStatus(id:any,data:any){
    return this.http.post( environment.baseUrl + 'superadmin/users/status/change/'+id + '/', data );
  }
  getArchive(){
    return this.http.get( environment.baseUrl+'superadmin/fssai/licenses/archived/records/')
  }
  archiveLicense(id:any,data:any){
    return this.http.post( environment.baseUrl + 'superadmin/fssai/licenses/archived/'+id + '/',data);
  }
  deleteLicense(id:any){
    return this.http.delete( environment.baseUrl + 'superadmin/fssai/licenses/delete/'+id + '/');
  }
  forgetPassword(data:any){
    return this.http.post( environment.baseUrl + 'dashboard/forgot/password/',data);
  }
  changePassword(data:any){
    return this.http.post( environment.baseUrl + 'dashboard/reset/password/',data);
  }
  getCity(pinCode:any){
    return this.http.get("https://api.data.gov.in/resource/6176ee09-3d56-4a3b-8115-21841576b2f6?api-key=579b464db66ec23bdd000001cdd3946e44ce4aad7209ff7b23ac571b&format=json&filters%5Bpincode%5D="+pinCode)
  }
  getServiceDocs(id:any){
    return this.http.get(environment.baseUrl + 'superadmin/service/requests/document/view/'+id+'/' )
  }
  editDocData(data:any,id:any){
    return this.http.put( environment.baseUrl + 'superadmin/service/requests/document/edit/'+id+'/',data );
  }
  addDocData(data:any){
    return this.http.post( environment.baseUrl + 'superadmin/service/requests/document/upload/',data);
  }
  getAuditReport(id:any){
    return this.http.get(environment.baseUrl + 'superadmin/service/requests/audit/detail/'+id+'/' );
  }
  getNotifications(id:any){
    return this.http.get(environment.baseUrl + 'common/notifications/view/?user_id='+id+'&type=sr' );
  }
  readNotification(data:any){
    return this.http.put( environment.baseUrl + 'common/notifications/update/',data );
  }
  getLicenseDocsAudit(id:any,type:any){
    return this.http.get( environment.baseUrl + 'superadmin/supporting/documents/audit/detail/'+id +'/'+ type+'/');
  }
}
