<div class="side_bar_common_css">
    <app-sidenav-bar></app-sidenav-bar>
    <div class="app_user_main_div">
        <div class="notificationBar">
            <img *ngIf="role == 1" src = "../../../assets/img/setting.png" (click)="navigate('/settings')" style="width:35px; border-radius: 25px;cursor: pointer;" alt="" srcset="" class="side_nav_bottom_dp">
            <img *ngIf="!isNewNotifications" (click)="toggleNotif()" src="../../../assets/img/NotifiationBell.svg"/>
            <img *ngIf="isNewNotifications" (click)="toggleNotif()" src="../../../assets/img/notificationIcon.svg"/>
            <div class="shwoNotififications" *ngIf="showNotif">
              <div class="d-flex justify-content-end">
                <img style="width: 20px;" (click)="toggleNotif()" src="../../../assets/img/carbon_close-filled.svg"/>
              </div>
              <div *ngIf="notifications.length == 0">
                <div class="notificationHeader">
                  No Notifications Found
                </div>
              </div>
              <div  *ngFor="let notif of notifications" class="notif" (click)="readNotifications(notif)">
                <div class="notificationHeader">
                  {{notif.type.toUpperCase().split('_').join(' ')}}
                </div>
                <div class="notificationContent">
                  {{notif.remarks}}
                </div>
              </div>
            </div>
            <button mat-button [matMenuTriggerFor]="menu" style="padding: 0;">
                <img [src] = "photo" style="width:35px; border-radius: 25px;" alt="" srcset="" class="side_nav_bottom_dp">
              </button>
              <mat-menu #menu="matMenu">
                <div style="color:black;padding:10px;font-weight:700;border-bottom:1px solid #A3C17E;width:100%;max-width:10rem;margin-right: 1rem;">{{username.toUpperCase()}}</div>
                <button mat-menu-item (click)="navigate('/profile')"> <img style="width:25px;margin-right: 10px;" [src]="tPhoto" alt="" > Profile</button>
                <button mat-menu-item (click)="navigate('/login')">  <img style="width:25px;margin-right: 10px;" src="../../../assets/img/logout.svg" alt="" > Logout</button>
              </mat-menu>
        </div>
        <div class="header_main_div">
            <div class="header">ACCOUNT MANAGERS</div>
            <div class="adding_btn_main_div">
                <button (click)="addNewUser()"><img src="../../../assets/img/plus_icon.svg" alt=""><span>ADD ACCOUNT MANAGER</span></button>
            </div>

        </div>
        <div class="app_user_content_main_div">
            <div>
            <div class="license_search_main_div">
            <div class="license_search_sub_div">
                <div class="search_icon_inp_div">
                    <img src="../../../assets/img/search_icon.svg" alt="" class="search_icon_inp_icon">
                    <input type="text" class="search_icon_inp" placeholder="Search" (keyup)="applyFilter($event)"
                        autocomplete="off">
                </div>
                <div class="dropdown">
                    <button class="btn filter_btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="../../../assets/img/../img/filter_icon.svg" alt="">Filters
                    </button>
                    <ul class="dropdown-menu">
                        <li class="dropdown-item" style="color: #5F6370;"
                            [ngClass]="selectedFilter.all?'filterActiveCss':''" (click)="filterByStatus('All')">
                            All</li>
                        <li class="dropdown-item" style="color: #12C058;"
                            [ngClass]="selectedFilter.active?'filterActiveCss':''"
                            (click)="filterByStatus('active')">
                            Active</li>
                        <li class="dropdown-item" style="color: #D43738;"
                            [ngClass]="selectedFilter.expired?'filterActiveCss':''"
                            (click)="filterByStatus('inactive')">
                            In Active</li>
                        <li class="dropdown-item" style="color: #1968B1;"
                            [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                            (click)="filterByStatus('unassigned')">
                            Un Assigned</li>
                    </ul>
                </div>
                <div class="filterApplied" (click)="filterByStatus('All')" *ngIf="selectedFilterDropdown!='All'">{{selectedFilterDropdown.toUpperCase()}} <img style="width: 15px;height: 15px;margin-left: 20px;" src="../../../assets/img/xmark.svg"/></div>

            </div>
            </div>
            </div>

            <div class="app_user_table_main_div">
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
                    style="width: 100%; position: relative;">

                    <!-- Sl NO -->
                    <ng-container matColumnDef="sl_no">
                        <th class="" mat-header-cell *matHeaderCellDef> SN. </th>
                        <td class="" mat-cell *matCellDef="let element; let i = index"> {{i+1}} </td>
                    </ng-container>

                    <!-- User Name  -->
                    <ng-container matColumnDef="name">
                        <th class="common_table_data"  mat-header-cell *matHeaderCellDef>
                            <div style="display: flex;flex-direction: column;height: 5rem;justify-content: space-between;padding:10px 0;" ><p mat-sort-header>Account Manager Name</p>
                                <div class="dropdown">
                                    <button class="btn btnn  multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img *ngIf="!EUNameFilter" src="../../../assets/img/../img/Group.svg" alt="" >{{EUNameFilter?EUNameFilter:'All'}}
                                        <img  style="width:20px;" *ngIf="EUNameFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt="" (click)="EuApplyFilter('','name',1)">
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li class="dropdown-item" style="color: #5F6370;"
                                            [ngClass]="selectedFilter.all?'':''" *ngFor="let status of EUNameDropdown" (click)="EuApplyFilter(status,'name',0)">
                                            {{status}}</li>
                                    </ul>
                                </div>
                            </div>    
                        </th>
                        <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

            

                    <!-- City -->
                    <ng-container matColumnDef="address">
                        <th class="common_table_data"  mat-header-cell *matHeaderCellDef>
                            <div style="display: flex;flex-direction: column;height: 5rem;justify-content: space-between;padding:10px 0;" ><p mat-sort-header>City</p>
                                <div class="dropdown">
                                    <button class="btn btnn  multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img *ngIf="!EUCityFilter" src="../../../assets/img/../img/Group.svg" alt="" >{{EUCityFilter?EUCityFilter:'All'}}
                                        <img  style="width:20px;" *ngIf="EUCityFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt="" (click)="EuApplyFilter('','city',1)">
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li class="dropdown-item" style="color: #5F6370;"
                                            [ngClass]="selectedFilter.all?'':''" *ngFor="let status of EUCityDropdown" (click)="EuApplyFilter(status,'city',0)">
                                            {{status}}</li>
                                    </ul>
                                </div>
                            </div>    
                        </th>
                        <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.city}} </td>
                    </ng-container>


                    <!-- Emial Address -->
                    <ng-container matColumnDef="email">
                        <th class="common_table_data"  mat-header-cell *matHeaderCellDef>
                            <div style="display: flex;flex-direction: column;height: 5rem;justify-content: space-between;padding:10px 0;" ><p mat-sort-header>Email Address</p>
                                <div class="dropdown">
                                    <button class="btn btnn  multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img *ngIf="!EUEmailFilter" src="../../../assets/img/../img/Group.svg" alt="" >{{EUEmailFilter?EUEmailFilter:'All'}}
                                        <img  style="width:20px;" *ngIf="EUEmailFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt="" (click)="EuApplyFilter('','email',1)">
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li class="dropdown-item" style="color: #5F6370;"
                                            [ngClass]="selectedFilter.all?'':''" *ngFor="let status of EUEmailDropdown" (click)="EuApplyFilter(status,'email',0)">
                                            {{status}}</li>
                                    </ul>
                                </div>
                            </div>    
                        </th>                        <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <!-- Status -->
                    <ng-container matColumnDef="status">
                        <th class="common_table_data"  mat-header-cell *matHeaderCellDef>
                            <div style="display: flex;flex-direction: column;height: 5rem;justify-content: space-between;padding:10px 0;" ><p mat-sort-header>Status</p>
                                <div class="dropdown">
                                    <button class="btn btnn  multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img *ngIf="!EUStatusFilter" src="../../../assets/img/../img/Group.svg" alt="" >{{EUStatusFilter?EUStatusFilter:'All'}}
                                        <img  style="width:20px;" *ngIf="EUStatusFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt="" (click)="EuApplyFilter('','status',1)">
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li class="dropdown-item" style="color: #5F6370;"
                                            [ngClass]="selectedFilter.all?'':''" *ngFor="let status of EUStatusDropdown" (click)="EuApplyFilter(status,'status',0)">
                                            {{status}}</li>
                                    </ul>
                                </div>
                            </div>    
                        </th>                       <td class="common_table_data" mat-cell *matCellDef="let element"> <span
                        [ngClass]="element.status == 'Active'?'colorGreen':element.status == 'Inactive'?'colorRed':'colorBlue'">
                        {{element.status}}</span></td>
                   </ng-container>


                     <!-- Reset Password -->
                     <ng-container matColumnDef="reset_password">
                        <th class="common_table_data" mat-header-cell *matHeaderCellDef> Reset Password</th>
                        <td class="common_table_data" mat-cell *matCellDef="let element"> <img
                                src="../../../assets/img/reset_icon.svg" alt="" srcset=""
                                (click)="resetPassword(element.id)" style="cursor: pointer;"> </td>
                    </ng-container>



                    <!-- Modify -->
                    <ng-container matColumnDef="modify">
                        <th class="common_table_data" mat-header-cell *matHeaderCellDef> Modify</th>
                        <td class="common_table_data" mat-cell *matCellDef="let element">
                            <div style="display: flex;">
                                <img src="../../../assets/img/remove_icon.svg" alt="" style="cursor: pointer;"
                                    (click)="handelDeleteAM(element.id)">
                                <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                                <img src="../../../assets/img/edit_icon.svg" alt="" style="cursor: pointer;" (click)="handleModifyAM(element.id)" >
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>

    </div>
</div>