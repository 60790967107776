import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { WebUserComponent } from './components/web-user/web-user.component';
import { AppUsersComponent } from './components/app-users/app-users.component';
import { LicensesComponent } from './components/licenses/licenses.component';
import { ServiceRequestComponent } from './components/service-request/service-request.component';
import { AuthGuard } from './auth-gard/auth.guard';
import { ProfileComponent } from './components/profile/profile.component';
import { BussinessManagerComponent } from './components/bussiness-manager/bussiness-manager.component';
import { AccountManagerComponent } from './components/account-manager/account-manager.component';
import { ReportsComponent } from './components/reports/reports.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { SettingsComponent } from './components/settings/settings.component';
import { DialogResetPassword } from './components/common_dialog/reset-password/reset_password';
import { WebForgetPasswordComponent } from './components/common_dialog/web-forget-password/web-forget-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo:'login', pathMatch: 'full',
  },
  {
    path: 'login',
    component:LoginComponent
  },
  {
    path: 'dashboard',
    component:DashboardComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'webUser',
    component:WebUserComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'appUser',
    component:AppUsersComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'license',
    component:LicensesComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'service',
    component:ServiceRequestComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'profile',
    component:ProfileComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'bm',
    component:BussinessManagerComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'am',
    component:AccountManagerComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'report',
    component:ReportsComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'terms&conditions',
    component:TermsAndConditionsComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'settings',
    component:SettingsComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'forgot_pass',
    component:WebForgetPasswordComponent,
  },
  {
    path: '**',
    component:LoginComponent,
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule],
  providers:[{provide: LocationStrategy, useClass: HashLocationStrategy}],
})
export class AppRoutingModule { }
