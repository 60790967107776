<div class="assginment_main_div">
    <img src="../../../assets/img/carbon_close-filled.svg" alt="" class="close_icon" (click)="close()">
    <div class="web_user_search_sub_div">
       View Licenses
    </div>
    <div>
        <div class="dropdwon_main_div" *ngIf="listLicenses.length>0" >
            <div *ngFor="let item of listLicenses" class="dropdown_assgin_ele"
                [ngClass]="item.id == id?'dropdown_assgin_ele_selected':''" (click)="changeAssignment(item.id)">
                <span>{{item.name}}</span>
            </div>
        </div>
        <div class="dropdwon_main_div" *ngIf="listLicenses.length==0" >
        <span style="padding-left: 1rem;">No Licenses</span>
        </div>

    </div>
</div>


<style>
    ::ng-deep.ng-select-container {
        border: none !important;
        padding: 0 !important;
        background-color: #FFFFFF !important;
        min-height: 48px !important;

    }
    .web_user_search_sub_div{
        color: #1D1E1C;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.84px;
        background: #F4F4F4;
        padding: 1rem 1rem;
        border-radius: 8px 0 0 0;
    }
    .assginment_main_div {
        border-radius: 8px;
        background: var(--color-base-white, #FFF);
        box-shadow: 0px 8px 16px 0px rgba(67, 74, 84, 0.08);
        padding-bottom: 1rem;
        position: relative;
    }

    .dropdwon_main_div {
        overflow-y: scroll;
        margin-top: 20px;
        max-height: 300px;
    }

    .dropdwon_main_div::-webkit-scrollbar {
        width: 4px;
        height: 50px;
        border-radius: 4px;
        opacity: 0.6;
        padding: 1rem 1rem;
    }

    /* Track  */
    .dropdwon_main_div::-webkit-scrollbar-track {
        background: #EAEAEA;
        width: 8px;
        box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.09) inset;
    }

    /* Handle */
    .dropdwon_main_div::-webkit-scrollbar-thumb {
        background: #6C913D;
        background: #6C913D 0% 0% no-repeat padding-box;
        width: 4px;
        height: 100px;
        border-radius: 4px;
        opacity: 0.6;
    }

    .dropdown_assgin_ele {
        padding: 0rem 1rem;
        min-height: 2.5rem;
        display: flex;
        align-items: center;
        margin-top: 3px;
        cursor: pointer;
    }

    .dropdown_assgin_ele_selected {
        background: #DCEEC5;
    }

    .close_icon {
        background: #ffffff;
        position: absolute;
        border-radius: 50%;
        right: -16px;
        top: -11px;
        width: 25px;
        cursor: pointer;

    }
</style>