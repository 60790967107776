import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AES, enc } from 'crypto-js';
import { HttpService } from 'src/app/services/api/http.service';
import { DataService } from 'src/app/services/data-share/data.service';

@Component({
  selector: 'app-sidenav-bar',
  templateUrl: './sidenav-bar.component.html',
  styleUrls: ['./sidenav-bar.component.css']
})
export class SidenavBarComponent implements OnInit {

  constructor(
    private router: Router,
    private dataShare: DataService,
    private httpSv: HttpService
  ) { }
  username: string = 'User'
  photo: string = '../../../assets/img/default_dp.png'
   roleId:any;
  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      this.roleId = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
      console.log(this.roleId)
      switch(this.roleId){
        case '1':
          this.sideNavActivate = {
            dashboard: true,
            webUser: true,
            appUser: false,
            accountManager:true,
            bussinessManager:true,
            license: true,
            service: true,
            report:true,
            settings: true
          }
          break;
          case '2':
            this.sideNavActivate = {
              dashboard: true,
              webUser: true,
              appUser: false,
              accountManager:false,
              bussinessManager:false,
              license: true,
              service: true,
              report:true,
              settings: false
            }
            break;
          case '3':
            this.sideNavActivate = {
              dashboard: true,
              webUser: true,
              appUser: false,
              accountManager:false,
              bussinessManager:false,
              license: true,
              service: true,
              report:true,
              settings: false
            }
            break;
            case '4':
              this.sideNavActivate = {
                dashboard: true,
                webUser: false,
                appUser: false,
                accountManager:false,
                bussinessManager:false,
                license: true,
                service: true,
                report:true,
                settings: false
              }
              break
            case '5':
              this.sideNavActivate = {
                dashboard: true,
                webUser: false,
                appUser: false,
                accountManager:false,
                bussinessManager:false,
                license: true,
                service: true,
                report:true,
                settings: false
              }
              break
      }
    }
    this.httpSv.getProfileDtls().subscribe((res: any) => {
      console.log('aaa')
      if (res['status']) {
        const temp = res['results']
        localStorage.setItem('user_id',temp.id)
        if (temp.name) this.username = temp.name
        if (temp.photo) this.photo = temp.photo
        localStorage.setItem('photo',this.photo)
        localStorage.setItem('username',this.username)

      }
    })
    this.httpSv.getStorageLimit().subscribe((res: any) => {
      if (res['status']) {
        this.dataShare.totalRetailSize = res['results'][0].upload_size_limit
        this.dataShare.individualFileSize = res['results'][0].storage_size_limit  
      }    
    })
    this.httpSv.getRetailLicenseLimit().subscribe((res:any)=>{
      if(res['status']){
        this.dataShare.retailLicenseCount = res['results'][0].ru_license_limit
      }
    })
    this.updateactiveValidater()
  }
  // ngAfterViewInit(): void {
  //   //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
  //   //Add 'implements AfterViewInit' to the class.
  //   console.log(this.dataShare.profileDtls)
  //   if(this.dataShare.profileDtls.name) this.username = this.dataShare.profileDtls.name
  //   if(this.dataShare.profileDtls.photo) this.photo = this.dataShare.profileDtls.photo
  // }
  sideNavActivate: any = {
    dashboard: true,
    webUser: true,
    appUser: false,
    license: true,
    accountManager:false,
    bussinessManager:false,
    service: true,
    profile: true,
    report:false
  }
  activeValidater: any = {
    dashboard: false,
    webUser: false,
    appUser: false,
    license: false,
    accountManager:false,
    bussinessManager:false,
    service: false,
    profile: false,
    report:false
  }
  updateactiveValidater() {
    if (this.router.url == '/dashboard')
      this.activeValidater.dashboard = true
    else if (this.router.url == '/webUser')
      this.activeValidater.webUser = true
    else if (this.router.url == '/appUser')
      this.activeValidater.appUser = true
    else if (this.router.url == '/license')
      this.activeValidater.license = true
    else if (this.router.url == '/service')
      this.activeValidater.service = true
    else if (this.router.url == '/profile')
      this.activeValidater.profile = true
    else if (this.router.url == '/bm')
      this.activeValidater.bm = true
    else if (this.router.url == '/am')
     this.activeValidater.am = true
    else if (this.router.url == '/report')
     this.activeValidater.report = true
    else if (this.router.url == '/settings')
      this.activeValidater.settings = true
  }
  navigate(route: string) {
    if (this.router.url == route) return
    else this.router.navigate([route])
  }
}
