import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/api/http.service';
import { DataService } from 'src/app/services/data-share/data.service';
import { DialogResetPassword } from '../reset-password/reset_password';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  emailId: any;
  phaseEmail: boolean = true
  constructor(
    public dialogRef: MatDialogRef<DialogResetPassword>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http:HttpService,
    private dataShare:DataService,
    private toster:ToastrService
  ) { }

  ngOnInit(): void {
  }

  submit(){
    this.http.forgetPassword({"email":this.emailId}).subscribe((res:any)=>{
      if(res['status']){
        this.phaseEmail = !this.phaseEmail
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
}
