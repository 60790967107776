<div class="auditContainer">
    <div class="service_modify_header" style="justify-content: flex-start;">
        <img src="../../../assets/img/back_arrow.svg" alt="" style="cursor: pointer;" (click)="close()">
        <div style="padding-left: 2rem;">
            {{title}} Status Change report
        </div>
        <div>

        </div>
    </div>
    <div class="service_modify_pd_inp_sub_div" style="padding: 1rem;flex:1;overflow: auto;">
         <!-- Header -->
         <div class="license_modify_pd_main_div headerr" style="
         display: grid;
         grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
         gap: 20px;
         border-bottom: 2px solid #ddd;
         padding: 10px 0;">
             <div style="min-width:5rem;">User Name</div>
             <div style="min-width:5rem;">From Status</div>
             <div style="min-width:5rem;">To Status</div>
             <div style="min-width:15rem;">Status Changed on</div>
             <div style="min-width:5rem;">Remark</div>
         </div>

         <!-- Data Rows -->
         <div *ngFor="let data of auditData;let i = index" style="border-bottom: 1px solid #ddd;">
         <div class="license_modify_pd_main_div" style="
         color: #828382;
         display: grid;
         grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
         gap: 20px;
         padding: 10px 0;">
             <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
             <div style="min-width:5rem;">{{i+1 < auditData.length  ? auditData[i+1].status : 'N/A'  }}</div>
             <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
             <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
             <div style="min-width:5rem;">{{data.remarks}}</div>
         </div>
         </div>
    </div>
    <div class="service_modify_pd_btn_div" style="padding: 1rem;">
        <div></div>
        <div (click)="close()" >Close</div>
    </div> 
</div>  

<style>
    .modify_doc_header_div {
    color: #1D1E1C;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    }
    .header_main{
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
    }
</style>