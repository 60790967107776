import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogServiceRequestModify } from 'src/app/components/service-request/service-request.component';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private dialog: MatDialog
  ) { }
  secretKey: string = 'fssai_my_surya'
  roleId:any;
  prefilter:any='Retail';
  preStatusFilter:any='All'
  individualFileSize:any  = 0
  totalRetailSize:any = 0
  userStorage:any = 0
  retailLicenseCount:any = 0
  perkAvilableForThisLogin: any = {
    created_at: null,
    id: null,
    is_add_notification: null,
    is_license_update: null,
    is_license_update_status: null,
    is_license_upload_check: null,
    is_license_view: null,
    is_modify_notification: null,
    is_request_approval_or_authorize_process: null,
    is_request_modify: null,
    is_request_view: null,
    is_send_notification: null,
    is_settings_notification: null,
    is_user_add: null,
    is_user_assign_client_engagement: null,
    is_user_delete: null,
    is_user_modify: null,
    is_user_reset_password: null,
    is_user_view: null,
    role_name: null,
    updated_at: null
  }

  selectedTable:any;

  allStatesOfIndia: any = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Union Territories",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Ladakh",
    "Lakshadweep",
    "Jammu and Kashmir",
    "Puducherry"
  ]
  allTypeOfLicences: any[] = [
    { name: "Registrations" },
    { name: "State License" },
    { name: "Central License" },
  ]
  profileDtls: any

  dialogRef = (id:any,type:any) =>{
   this.dialog.open(DialogServiceRequestModify, {
    hasBackdrop: true,
    disableClose: true,
    data: {
      id,
      type,
    },
  });
  }
}

