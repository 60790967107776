import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { OutsideclickDirective } from './directives/outsideclick.directive';
import { LoginComponent } from './components/login/login.component';
import { SidenavBarComponent } from './components/sidenav-bar/sidenav-bar.component';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTabsModule} from '@angular/material/tabs';
import {FormGroup, FormControl} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { DashboardComponent, DashboardU0Component, DashboardU3Component,DashboardU2Component, DashboardU1Component} from './components/dashboard/dashboard.component';
import { DialogCESAddModify, DialogRetailModify, WebUserComponent,U2WebUserComponent, U0WebUserComponent, U1WebUserComponent, DialogAddNewUser,DialogViewLicense, DialogAssignment, DialogEnterpriseModify, DialogBusinessManagerModify } from './components/web-user/web-user.component';
import { DialogDeleteCofirm } from './components/common_dialog/delete-confirm/delete_confirm_dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {NgApexchartsModule} from 'ng-apexcharts'
import { DialogDeleteDonePopup } from './components/common_dialog/delete-sucess-info/delete_done_dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import {JsonPipe} from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { DatePipe } from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';

//Angular Matarial
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import {MatDialogModule} from '@angular/material/dialog'
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatChipsModule} from '@angular/material/chips'
import {MatFormFieldModule} from '@angular/material/form-field'
import { DialogResetPassword } from './components/common_dialog/reset-password/reset_password';
import { AppUsersComponent, DialogAppUserModify } from './components/app-users/app-users.component';
import { DialogAppLicenseDocModify, DialogAppLicenseModify, DialogAppLicenseView, LicensesComponent, U0LicensesComponent, U3LicensesComponent,U2LicensesComponent, U1LicensesComponent, DialogAddDocument, DialogueAddServiceRequest } from './components/licenses/licenses.component';
import { DialogServiceRequestModify, ServiceRequestComponent,ServiceRequestU0Component, ServiceRequestU2Component, ServiceRequestU1Component, DialogAddDocumentSR, DialogueAddRemark, DialogueAuditReport } from './components/service-request/service-request.component';
import { FssaiInterceptorInterceptor } from './interceptor/fssai-interceptor.interceptor';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ProfileComponent } from './components/profile/profile.component';
import { BussinessManagerComponent,DialogAddBM } from './components/bussiness-manager/bussiness-manager.component';
import { AccountManagerComponent,DialogAddAM } from './components/account-manager/account-manager.component';
import { ReportsComponent } from './components/reports/reports.component';
import {MatNativeDateModule} from '@angular/material/core';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ForgetPasswordComponent } from './components/common_dialog/forget-password/forget-password.component';
import { WebForgetPasswordComponent } from './components/common_dialog/web-forget-password/web-forget-password.component';
import { NotificationsComponent } from './components/common_dialog/notifications/notifications.component';

@NgModule({
  declarations: [
    AppComponent,
    OutsideclickDirective,
    LoginComponent,
    SidenavBarComponent,
    DashboardComponent,
    WebUserComponent,
    U2WebUserComponent,
    U0WebUserComponent,
    U1WebUserComponent,
    DialogCESAddModify,
    DialogResetPassword,
    DialogDeleteCofirm,
    DialogDeleteDonePopup,
    DialogRetailModify,
    DialogEnterpriseModify,
    DialogBusinessManagerModify,
    DialogAddBM,
    DialogAddAM,
    DialogViewLicense,
    AppUsersComponent,
    DialogAppUserModify,
    LicensesComponent,
    DialogAppLicenseModify,
    ServiceRequestComponent,
    DialogServiceRequestModify,
    U0LicensesComponent,
    U3LicensesComponent,
    U2LicensesComponent,
    U1LicensesComponent,
    ServiceRequestU2Component,
    ServiceRequestU0Component,
    ServiceRequestU1Component,
    DialogAppLicenseView,
    DialogAppLicenseDocModify,
    DashboardU0Component,
    DashboardU3Component,
    DashboardU2Component,
    DashboardU1Component,
    ProfileComponent,
    DialogAddNewUser,
    DialogAssignment,
    BussinessManagerComponent,
    AccountManagerComponent,
    ReportsComponent,
    DialogAddDocument,
    TermsAndConditionsComponent,
    DialogueAddServiceRequest,
    SettingsComponent,
    ForgetPasswordComponent,
    WebForgetPasswordComponent,
    DialogAddDocumentSR,
    DialogueAddRemark,
    DialogueAuditReport,
    NotificationsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    NgApexchartsModule,
    NgSelectModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    PdfViewerModule,
    MatSortModule,
    MatTabsModule,
    MatDatepickerModule, 
    JsonPipe,
    MatNativeDateModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FssaiInterceptorInterceptor,
      multi: true
    },
    DatePipe
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

  bootstrap: [AppComponent]
})
export class AppModule { }
