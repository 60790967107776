<div class="login_main_div">
    <div class="login_container_main_div">
        <div class="login_container_sub_div">
            <div class="text_center">
                <img style="width:5rem;" src="../../../assets/img/myFssai-logo-new.png" alt="">
            </div>
            <div class="text_center logIn_txt">Log in</div>
            <div class="error_msg_main_div" *ngIf="errMsgFlag">
                <span><img src="../../../assets/img/error-warning.svg" alt=""></span>
                <span>{{errMsgTxt}}</span>
            </div>
            <div class="inpt_main_div">
                <div>Email Address</div>
                <div><input type="email" name="user_id" id="" [(ngModel)]="emailId" (keyup.enter)="loginFunc()"></div>
            </div>
            <div class="inpt_main_div">
                <div>Password</div>
                <div>
                    <input [type]="passwordToggleHide?'password':'text'" name="password" id="" [(ngModel)]="password" (keyup.enter)="loginFunc()">
                    <img src="../../../assets/img/eye-close-line.svg" alt="" style="cursor: pointer;margin-right: 10px;"
                        *ngIf="passwordToggleHide" (click)="passwordToggleHide = false">
                    <img src="../../../assets/img/eye-2-line.svg" alt="" style="cursor: pointer;margin-right: 10px;"
                        *ngIf="!passwordToggleHide" (click)="passwordToggleHide = true">
                </div>
            </div>
            <div class="login_btn_main_div">
                <button (click)="loginFunc()">LOG IN</button>
            </div>
            <div class="inpt_main_div">
                <div (click)="forgetPassword()" class="forgetPassword mt-1">Forget Password</div>
            </div>
        </div>
    </div>
</div>