import { Component, ElementRef, EventEmitter, HostListener, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith, timeout } from 'rxjs';
import { DialogResetPassword } from '../common_dialog/reset-password/reset_password';
import { DialogDeleteCofirm } from '../common_dialog/delete-confirm/delete_confirm_dialog';
import { DialogDeleteDonePopup } from '../common_dialog/delete-sucess-info/delete_done_dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/api/http.service';
import { DataService } from 'src/app/services/data-share/data.service';
import { AES, enc } from 'crypto-js';
import { DialogAppLicenseModify } from '../licenses/licenses.component';
import {MatSort} from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { NotificationsComponent } from '../common_dialog/notifications/notifications.component';
import { Router } from '@angular/router';


const ELEMENT_DATA: any[] = [
  { position: 1, name: ['Hydrogen'], weight: 1.0079, symbol: 'H' },
  { position: 2, name: ['Helium'], weight: 4.0026, symbol: 'He' },
  { position: 3, name: ['Lithium'], weight: 6.941, symbol: 'Li' },
  { position: 4, name: ['Beryllium'], weight: 9.0122, symbol: 'Be' },
  { position: 5, name: ['Boron'], weight: 10.811, symbol: 'B' },
  { position: 6, name: ['Carbon'], weight: 12.0107, symbol: 'C' },
  { position: 7, name: ['Nitrogen'], weight: 14.0067, symbol: 'N' },
  { position: 8, name: ['Oxygen'], weight: 15.9994, symbol: 'O' },
  { position: 9, name: ['Fluorine'], weight: 18.9984, symbol: 'F' },
  { position: 10, name: ['Neon'], weight: 20.1797, symbol: 'Ne' },
  { position: 1, name: ['Hydrogen'], weight: 1.0079, symbol: 'H' },
  { position: 2, name: ['Helium'], weight: 4.0026, symbol: 'He' },
  { position: 3, name: ['Lithium'], weight: 6.941, symbol: 'Li' },
  { position: 4, name: ['Beryllium'], weight: 9.0122, symbol: 'Be' },
  { position: 5, name: ['Boron'], weight: 10.811, symbol: 'B' },
  { position: 6, name: ['Carbon'], weight: 12.0107, symbol: 'C' },
  { position: 7, name: ['Nitrogen'], weight: 14.0067, symbol: 'N' },
  { position: 8, name: ['Oxygen'], weight: 15.9994, symbol: 'O' },
  { position: 9, name: ['Fluorine'], weight: 18.9984, symbol: 'F' },
  { position: 10, name: ['Neon'], weight: 20.1797, symbol: 'Ne' },
  { position: 1, name: ['Hydrogen'], weight: 1.0079, symbol: 'H' },
];
const emailRegex =new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, "gm");

@Component({
  selector: 'app-u2-webuser',
  templateUrl: './u2_web-user.component.html',
  styleUrls: ['./web-user.component.css']
})
export class U2WebUserComponent implements OnInit {
  @ViewChild(MatSort) sort = new MatSort();
  RUCityDropdown: any=[];
  RUStatusDropdown: any=[];
  RUCompanyDropdown: any=[];
  RUNameDropdown: any=[];
  RUDateDropdown: any=[];
  RUNameFilter: any='';
  RuCompanyFilter: any='';
  RuCityFilter: any='';
  RuDateFilter: any='';
  RuBmFilter: any='';
  RuStatusFilter: any='';
  filterRudata: any;
  RuNameFilter: any='';
  EUCityDropdown: any=[];
  EUStatusDropdown: any=[];
  EUCompanyDropdown: any=[];
  EUNameDropdown: any=[];
  EUDateDropdown: any=[];
  EUNameFilter: any='';
  EUCompanyFilter: any='';
  EUCityFilter: any='';
  EUDateFilter: any='';
  EUBmFilter: any='';
  EUStatusFilter: any='';
  filterEUdata: any;

  constructor(
    private dataShare: DataService,
    public dialog: MatDialog,
    private httpSv: HttpService,
    private toster: ToastrService,
    private datePipe: DatePipe
  ) { }
  role: any;
  //CES varible and function
  displayedColumnsCES: string[] = ['sl_no', 'name', 'company_name', 'state','date', 'status', 'reset_password', 'modify'];
  dataCES: any[] = []
  dataSourceCES: any = new MatTableDataSource();
  cesActiveFlag: boolean = true
  selectedTable:any='enterprise'

  displayedColumnsBM: string[] = ['sl_no', 'name', 'company_name', 'date','state', 'status', 'reset_password', 'modify'];
  dataRetail: any[] = []
  dataSourceRetail: any = new MatTableDataSource();
  cesActiveFlagRetail: boolean = true

  selectedFilter: any = {
    active: false,
    expired: false,
    requested: false
  }

  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      this.role = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
      this.dataShare.roleId = this.role;
    }
    else {
      // this.toster.error('Opps! something went wrong', '', { timeOut: 2000 })
    }
    // if(this.dataShare.prefilter=='Retail'){
    //   this.selectedTable='retail'
    //   this.getAllRetailList()
    //   if (this.cesActiveFlag) this.cesActiveFlag = false
    // }
    // else
    this.getAllUsrManagementList();
  }
  getAllUsrManagementList() {
    this.httpSv.getAllUsrManagementList().subscribe((res: any) => {
      if (res['status']) {
        res['results'].map((data : any)=>{
          if(data.date.indexOf('-') != -1){
            data.date = this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
          }
        })
        this.dataCES = res['results']
        this.dataSourceCES.data = this.dataCES 
        this.dataSourceCES.sort = this.sort 
        this.filterEUdata = this.dataCES
        this.createEUDropDown(this.dataCES)
           }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      if(err.error.message=='no data found')
      this.dataSourceCES.data = [];
      if(err.error.message != 'no data found')
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  createEUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state &&!this.EUCityDropdown.includes(data.state.toLowerCase()))
        this.EUCityDropdown.push(data.state.toLowerCase())
      if(data.status && !this.EUStatusDropdown.includes(data.status))
        this.EUStatusDropdown.push(data.status)
      if(data.company_name && !this.EUCompanyDropdown.includes(data.company_name))
        this.EUCompanyDropdown.push(data.company_name)
      if(data.name && !this.EUNameDropdown.includes(data.name))
        this.EUNameDropdown.push(data.name)
      if(data.date && !this.EUDateDropdown.includes(data.date))
        this.EUDateDropdown.push(data.date)
    })
  }
   EUApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='name'){
      this.EUNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataCES;
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EUBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.name && apiData.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSourceCES.data = this.filterEUdata

    }
    if(from=='company'){
      this.EUCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataCES;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EUBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.company_name && apiData.company_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSourceCES.data = this.filterEUdata

    }
    if(from=='city'){
      this.EUCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataCES;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EUBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSourceCES.data = this.filterEUdata

    }
    if(from=='date'){
      this.EUDateFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataCES;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EUBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.date && apiData.date.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSourceCES.data = this.filterEUdata

    }
    if(from=='bm'){
      this.EUBmFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataCES;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.assign_business_manager && apiData.assign_business_manager.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSourceCES.data = this.filterEUdata

    }
    if(from=='status'){
      this.EUStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataCES;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.EUBmFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEUdata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEUdata = result
      this.dataSourceCES.data = this.filterEUdata

    }
  }
  getAllRetailList(){
    this.httpSv.getBMAllRetailUsers().subscribe((res: any) => {
      if (res['status']) {
        res['results'].map((data : any)=>{
          if(data.date.indexOf('-') != -1){
            data.date = this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
          }
        })
        this.dataRetail = res['results']
        this.dataSourceRetail.data = this.dataRetail
        this.dataSourceRetail.sort = this.sort
        this.filterRudata = this.dataRetail
        // this.dataRetail=this.dataCES
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      if(err.error.message=='no data found')
      this.dataSourceRetail.data = [];
      if(err.error.message != 'no data found')
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  createRUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state &&!this.RUCityDropdown.includes(data.state.toLowerCase()))
        this.RUCityDropdown.push(data.state.toLowerCase())
      if(data.status && !this.RUStatusDropdown.includes(data.status))
        this.RUStatusDropdown.push(data.status)
      if(data.company_name && !this.RUCompanyDropdown.includes(data.company_name))
        this.RUCompanyDropdown.push(data.company_name)
      if(data.name && !this.RUNameDropdown.includes(data.name))
        this.RUNameDropdown.push(data.name)
      if(data.date && !this.RUDateDropdown.includes(data.date))
        this.RUDateDropdown.push(data.date)
    })
  }
   RuApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='name'){
      this.RUNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.RuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RuDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.name && apiData.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceRetail.data = this.filterRudata

    }
    if(from=='company'){
      this.RuCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.RuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RuDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.company_name && apiData.company_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceRetail.data = this.filterRudata

    }
    if(from=='city'){
      this.RuCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RuDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RuStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceRetail.data = this.filterRudata

    }
    if(from=='date'){
      this.RuDateFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.date && apiData.date.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceRetail.data = this.filterRudata

    }
    if(from=='bm'){
      this.RuBmFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RuDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.assign_business_manager && apiData.assign_business_manager.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceRetail.data = this.filterRudata

    }
    if(from=='status'){
      this.RuStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RuDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.RuBmFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceRetail.data = this.filterRudata

    }
  }
  addNewBM() {
    const dialogRef = this.dialog.open(DialogCESAddModify, {
      hasBackdrop: true,
      disableClose: true,
    }).afterClosed().subscribe(()=>{
      this.getAllUsrManagementList()
      this.getAllRetailList()
    })
  }
  handelModifyBM(element:any,from:any)
  {
    this.httpSv.getRetailUserDtls(element.id,this.role,from)?.subscribe((res: any) => {
      if (res['status']) {
        const temp = res['results'][0]
        console.log(temp)
        // this.name = temp.name
        // this.email = temp.email
        // this.number = temp.mobile
        // this.compName = temp.company_name
        // this.state = temp.state
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
    const dialogRef = this.dialog.open(DialogCESAddModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        element,
        roleId:this.role,
        isEdit:true,
        from
      }
    }).afterClosed().subscribe(()=>{
      this.getAllUsrManagementList()
      // this.getAllRetailList()
    })
  }

  handleSwicthWebUser(type: any) {
    if (type === 'enterprise') {
      this.selectedTable='enterprise'
      this.getAllUsrManagementList()
      if (!this.cesActiveFlag) this.cesActiveFlag = true
    }
    else if (type === 'retail') {
      this.selectedTable='retail'
      this.getAllRetailList()
      if (this.cesActiveFlag) this.cesActiveFlag = false
    }
    this.selectedFilterDropdown = 'All'
  }
  applyFilterCES(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceCES.filter = filterValue.trim().toLowerCase();
  }
  handelDeleteBM(element:any,from:any) {
    // const dialogRef = this.dialog.open(DialogDeleteDonePopup,{
    //   hasBackdrop:true,
    //   disableClose:true,
    //   data:{
    //     heading: 'You have successfully removed the Client Specialist.'
    //   }
    // })
    var heading;
    if(from=='enterprise')
      heading='Are you sure you want to remove this ENTERPRISE USER?'
    else
      heading='Are you sure you want to remove this RETAIL USER?'
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: heading,
        element,
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res==true){
        this.httpSv.deleteUser(this.role,element,from)?.subscribe((res: any) => {
          if (res['status']) {
            this.getAllRetailList()
            this.getAllUsrManagementList()
          }
          else {
            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toster.error(err.error.message, '', { timeOut: 2000 })
        })
      }
      })
      

  }
  applyFilterRetail(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceRetail.filter = filterValue.trim().toLowerCase();
  }
  txtStatus: string = ''
  selectedFilterDropdown:any = 'All'
  filterByStatus(order: any) {
    this.selectedFilterDropdown = order
    switch (order) {
      case 'All':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: false
        }
        this.dataSourceCES = new MatTableDataSource(this.dataCES)
        
        this.txtStatus = 'All'
        break;

      case 'active':
        this.selectedFilter = {
          active: true,
          expired: false,
          requested: false
        }
        const oldUserData = this.dataCES.filter((ele:any)=> ele.status == order)
        this.dataSourceCES = new MatTableDataSource(oldUserData)
        this.txtStatus = 'Active'
        break;
      case 'inactive':
        this.selectedFilter = {
          active: false,
          expired: true,
          requested: false
        }
        const newUserData = this.dataCES.filter((ele:any)=> ele.status == order)
        this.dataSourceCES = new MatTableDataSource(newUserData)
        this.txtStatus = 'Expired'
        break;
      case 'Requested':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: true
        }
        this.txtStatus = 'Requested'
        break;
    }

  }
  resetPasswordBM(element:any,from:any) {
    this.dataShare.selectedTable=from
    const dialogRef = this.dialog.open(DialogResetPassword, {
      disableClose: true,
      hasBackdrop: true,
      data:{
        element,
        from
      }
    })
  }
  handelDeleteRetail() {
    // const dialogRef = this.dialog.open(DialogDeleteDonePopup,{
    //   hasBackdrop:true,
    //   disableClose:true,
    //   data:{
    //     heading: 'You have successfully removed the Client Specialist.'
    //   }
    // })
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to remove this Retail User?'
      }
    })
  }
  editRetailUser(id: any) {
    const dialogRef = this.dialog.open(DialogRetailModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        id,
      }
    }).afterClosed().subscribe((res:any)=>{
      this.getAllUsrManagementList()
      this.getAllRetailList()
    })

  }
}











//--------------------------------------------Account Manager------------------------------------------------------
//--------------------------------------------Account Manager------------------------------------------------------
//--------------------------------------------Account Manager------------------------------------------------------




@Component({
  selector: 'app-u1-webuser',
  templateUrl: './u1_web-user.component.html',
  styleUrls: ['./web-user.component.css']
})
export class U1WebUserComponent implements OnInit {
  @ViewChild(MatSort) sort = new MatSort();

  dataEnterprise: any;
  EuCompanyFilter: any='';
  EuCityFilter: any='';
  EuDateFilter: any='';
  EuBmFilter: any='';
  EuStatusFilter: any='';
  filterEudata: any;
  dataSourceEnterprise: any;
  EuCityDropdown: any[]=[];
  EuBMDropdown: any[]=[];
  EuStatusDropdown: any[]=[];
  EuCompanyDropdown: any[]=[];
  EuDateDropdown: any[]=[];
  RUCityDropdown: any[]=[];
  RUBMDropdown: any[]=[];
  RUStatusDropdown: any[]=[];
  RUCompanyDropdown: any[]=[];
  RUNameDropdown: any[]=[];
  RUDateDropdown: any[]=[];
  filterRudata: any;
  RUNameFilter: any='';
  RuCompanyFilter: any='';
  RuCityFilter: any='';
  RuDateFilter: any='';
  RuBmFilter: any='';
  RuStatusFilter: any='';
  dataSourceRu: any =new MatTableDataSource();
  RuNameFilter: any='';
  BMCityDropdown: any=[];
  BMStatusDropdown: any=[];
  BMCompanyDropdown: any=[];
  BMNameDropdown: any=[];
  BMDateDropdown: any=[];
  BMNameFilter: any='';
  BMCompanyFilter: any='';
  BMCityFilter: any='';
  BMDateFilter: any='';
  BMBmFilter: any='';
  BMStatusFilter: any='';
  filterBMdata: any;
  constructor(
    private dataShare: DataService,
    public dialog: MatDialog,
    private httpSv: HttpService,
    private toast:ToastrService,
    private datePipe: DatePipe
  ) { }
  role: any;

  //EU varible and function
  displayedColumnsEU: string[] = ['sl_no', 'name', 'company_name', 'state','date', 'status', 'assign_business_manager', 'reset_password', 'modify'];
  dataEU: any[] = []
  dataSourceEU: any = new MatTableDataSource();

  //CES varible and function
  displayedColumnsCES: string[] = ['sl_no', 'name', 'company_name', 'state','date', 'status', 'reset_password', 'modify'];
  dataCES: any[] = []
  dataSourceCES: any = new MatTableDataSource();


  displayedColumnsBM: string[] = ['sl_no', 'name', 'company_name', 'state', 'status', 'reset_password', 'modify'];
  dataBM: any[] = []
  dataSourceBM: any = new MatTableDataSource();


  selectedFilter: any = {
    active: false,
    expired: false,
    requested: false
  }

  selectedTable:any = 'ces';
  EuNameFilter:any=''
  EuNameDropdown:any=[]

  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      this.role = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
      this.dataShare.roleId = this.role;
    }
    else {
      // this.toster.error('Opps! something went wrong', '', { timeOut: 2000 })
    }
    this.dataShare.selectedTable = this.selectedTable;
   
    if(this.dataShare.prefilter=='Retail'){
      this.handleSwicthWebUser('ces')
      this.getCESUserList()
      // this.dataShare.prefilter='Enterprise'
    }
    else if(this.dataShare.prefilter=='Enterprise'){
      this.handleSwicthWebUser('eu')
      this.getEnterpriseUserList()
      this.dataShare.prefilter='Enterprise'
    }
    else if(this.dataShare.prefilter=='bm'){
      this.handleSwicthWebUser('bm')
      this.getBusinessManagerList()
      // this.dataShare.prefilter='Enterprise'
    }
  }
  EuApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='name'){
      this.EuNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEU;
        if(this.EuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EuDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.name && apiData.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSourceEU.data = this.filterEudata

    }
    if(from=='company'){
      this.EuCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEU;
        if(this.EuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EuDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.company_name && apiData.company_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSourceEU.data = this.filterEudata

    }
    if(from=='city'){
      this.EuCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEU;
        if(this.EuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EuDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EuStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSourceEU.data = this.filterEudata

    }
    if(from=='date'){
      this.EuDateFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEU;
        if(this.EuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.date && apiData.date.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSourceEU.data = this.filterEudata

    }
    if(from=='bm'){
      this.EuBmFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEU;
        if(this.EuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EuDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.assign_business_manager && apiData.assign_business_manager.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSourceEU.data = this.filterEudata

    }
    if(from=='status'){
      this.EuStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEU;
        if(this.EuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EuDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.EuBmFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSourceEU.data = this.filterEudata

    }
  }
  addNewUser() {
    const dialogRef = this.dialog.open(DialogAddNewUser, {
      hasBackdrop: true,
      disableClose: true,
    })
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.selectedFilterDropdown = 'all'
        if(this.selectedTable == 'eu'){
          this.getEnterpriseUserList()
        } else if(this.selectedTable == 'ces'){
          this.getCESUserList()
        } else if(this.selectedTable == 'bm')[
          this.getBusinessManagerList()
        ]
      }
    })
  }


  handleSwicthWebUser(type: any) {
    this.selectedTable = type;
    this.dataShare.selectedTable = type;

    if(this.selectedTable == 'eu'){
      this.getEnterpriseUserList()
    } else if(this.selectedTable == 'ces'){
      this.getCESUserList()
    } else if(this.selectedTable == 'bm'){
      this.getBusinessManagerList()
    }

  }

  getEnterpriseUserList(){
    this.httpSv.getAMEnterpriseUserList().subscribe((res:any) => {
      if(res['status']){
        res.results.map((data : any)=>{
          if(data.date.indexOf('-') != -1){
            data.date = this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
          }
        })
        this.dataSourceEU.data = res.results;
        this.dataSourceEU.sort = this.sort;
        this.filterEudata = res.results;
        this.dataEU = res.results;
        this.createEUDropDown(res.results)
      } else{
        this.toast.error('Oops! Someting went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      if(err.error.message=='no data found')
      this.dataSourceEU.data = [];
      this.dataEU = [];
      if(err.error.message != 'no data found')
      this.toast.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  createEUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state &&!this.EuCityDropdown.includes(data.state.toLowerCase()))
        this.EuCityDropdown.push(data.state.toLowerCase())
      if(data.assign_business_manager && !this.EuBMDropdown.includes(data.assign_business_manager.name))
        this.EuBMDropdown.push(data.assign_business_manager.name)
      if(data.status && !this.EuStatusDropdown.includes(data.status))
        this.EuStatusDropdown.push(data.status)
      if(data.company_name && !this.EuCompanyDropdown.includes(data.company_name))
        this.EuCompanyDropdown.push(data.company_name)
      if(data.name && !this.EuNameDropdown.includes(data.name))
        this.EuNameDropdown.push(data.name)
      if(data.date && !this.EuDateDropdown.includes(data.date))
        this.EuDateDropdown.push(data.date)
    })
  }
  getCESUserList(){
    this.httpSv.getAMCESUserList().subscribe((res:any) => {
      if(res['status']){
       res.results.map((data : any)=>{
          if(data.date.indexOf('-') != -1){
            data.date = this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
          }
        })
        this.dataSourceCES.data = res.results;
        this.dataSourceCES.sort = this.sort;
        this.dataCES = res.results;
        this.filterRudata = res.results;
        this.createRUDropDown(res.results)

      } else{
        this.toast.error('Oops! Someting went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      if(err.error.message=='no data found')
      this.dataSourceCES.data = [];
      this.dataCES = [];
      if(err.error.message != 'no data found')
      this.toast.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  createRUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state &&!this.RUCityDropdown.includes(data.state.toLowerCase()))
        this.RUCityDropdown.push(data.state.toLowerCase())
      if(data.status && !this.RUStatusDropdown.includes(data.status))
        this.RUStatusDropdown.push(data.status)
      if(data.company_name && !this.RUCompanyDropdown.includes(data.company_name))
        this.RUCompanyDropdown.push(data.company_name)
      if(data.name && !this.RUNameDropdown.includes(data.name))
        this.RUNameDropdown.push(data.name)
      if(data.date && !this.RUDateDropdown.includes(data.date))
        this.RUDateDropdown.push(data.date)
    })
  }
   RuApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='name'){
      this.RUNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataCES;
        if(this.RuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.RuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RuDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.name && apiData.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceCES.data = this.filterRudata

    }
    if(from=='company'){
      this.RuCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataCES;
        if(this.RuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.RuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RuDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.company_name && apiData.company_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceCES.data = this.filterRudata

    }
    if(from=='city'){
      this.RuCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataCES;
        if(this.RuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RuDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RuStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceCES.data = this.filterRudata

    }
    if(from=='date'){
      this.RuDateFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataCES;
        if(this.RuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.RuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.date && apiData.date.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceCES.data = this.filterRudata

    }
    if(from=='bm'){
      this.RuBmFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataCES;
        if(this.RuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RuDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.assign_business_manager && apiData.assign_business_manager.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceCES.data = this.filterRudata

    }
    if(from=='status'){
      this.RuStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataCES;
        if(this.RuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RuDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.RuBmFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceCES.data = this.filterRudata

    }
  }
  getBusinessManagerList(){
    this.httpSv.getBusinessManagerList().subscribe((res:any) => {
      if(res['status']){
        res.results.map((data : any)=>{
          if(data.date.indexOf('-') != -1){
            data.date = this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
          }
        })
        this.dataSourceBM.data = res.results;
        this.dataSourceBM.sort = this.sort;
        this.dataBM = res.results;
        this.createBMDropDown(res.results)
        this.filterBMdata = res.results;
      } else{
        this.toast.error('Oops! Someting went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      if(err.error.message=='no data found')
      this.dataSourceBM.data = [];
      this.dataBM = [];
      if(err.error.message != 'no data found')
      this.toast.error(err.error.message, '', { timeOut: 2000 })
    })
  }

  createBMDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state &&!this.BMCityDropdown.includes(data.state.toLowerCase()))
        this.BMCityDropdown.push(data.state.toLowerCase())
      if(data.status && !this.BMStatusDropdown.includes(data.status))
        this.BMStatusDropdown.push(data.status)
      if(data.company_name && !this.BMCompanyDropdown.includes(data.company_name))
        this.BMCompanyDropdown.push(data.company_name)
      if(data.name && !this.BMNameDropdown.includes(data.name))
        this.BMNameDropdown.push(data.name)
      if(data.date && !this.BMDateDropdown.includes(data.date))
        this.BMDateDropdown.push(data.date)
    })
  }
   BMApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='name'){
      this.BMNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataBM;
        if(this.BMCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.BMCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.BMCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.BMDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.BMBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.BMStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterBMdata.map((apiData:any)=>{
              if(apiData.name && apiData.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterBMdata = result
      this.dataSourceBM.data = this.filterBMdata

    }
    if(from=='company'){
      this.BMCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataBM;
        if(this.BMNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.BMNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.BMCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.BMDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.BMBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.BMStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterBMdata.map((apiData:any)=>{
              if(apiData.company_name && apiData.company_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterBMdata = result
      this.dataSourceBM.data = this.filterBMdata

    }
    if(from=='city'){
      this.BMCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataBM;
        if(this.BMNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.BMNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.BMCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.BMDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.BMBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.BMStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterBMdata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterBMdata = result
      this.dataSourceBM.data = this.filterBMdata

    }
    if(from=='date'){
      this.BMDateFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataBM;
        if(this.BMNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.BMNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.BMCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.BMCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.BMBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.BMStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterBMdata.map((apiData:any)=>{
              if(apiData.date && apiData.date.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterBMdata = result
      this.dataSourceBM.data = this.filterBMdata

    }
    if(from=='bm'){
      this.BMBmFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataBM;
        if(this.BMNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.BMNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.BMCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.BMCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.BMDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.BMStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterBMdata.map((apiData:any)=>{
              if(apiData.assign_business_manager && apiData.assign_business_manager.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterBMdata = result
      this.dataSourceBM.data = this.filterBMdata

    }
    if(from=='status'){
      this.BMStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataBM;
        if(this.BMNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.BMNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.BMCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.BMCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.BMDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.BMBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.BMBmFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterBMdata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterBMdata = result
      this.dataSourceBM.data = this.filterBMdata

    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if(this.selectedTable == 'eu'){
      this.dataSourceEU.filter = filterValue.trim().toLowerCase();
    } else if(this.selectedTable == 'ces'){
      this.dataSourceCES.filter = filterValue.trim().toLowerCase();
    } else if(this.selectedTable == 'bm'){
      this.dataSourceBM.filter = filterValue.trim().toLowerCase();
    }
  }

  handleDeleteUser(element:any) {
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to remove this Entriprise User?',
        element,
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res==true){
        this.httpSv.deleteUser(this.role,element, this.selectedTable)?.subscribe((res: any) => {
          if (res['status']) {
            if(this.selectedTable == 'eu'){
              this.getEnterpriseUserList()
            } else if(this.selectedTable == 'ces'){
              this.getCESUserList()
            } else if(this.selectedTable == 'bm'){
              this.getBusinessManagerList()
            }
          }
          else {
            this.toast.error('Oops! Something went wrong', '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toast.error(err.error.message, '', { timeOut: 2000 })
        })
      }
      })
      

  }
 
  txtStatus: string = ''
  filterByStatusEU(order: any) {
    let data = 'all'
    if(order == 'active')
      data = 'Active Enterprise User'
    if(order == 'inactive')
      data = 'Inactive Enterprise User'
    else if(order == 'unassigned')
      data = 'Unassigned Enterprise User'
    this.selectedFilterDropdown = data    
    switch (order) {
      case 'all':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: false
        }
        this.dataSourceEU = new MatTableDataSource(this.dataEU)
        
        this.txtStatus = 'all'
        break;

      case 'active':
        this.selectedFilter = {
          active: true,
          expired: false,
          requested: false
        }
        const oldUserData = this.dataEU.filter((ele:any)=> ele.status.toLowerCase() == order.toLowerCase())
        this.dataSourceEU = new MatTableDataSource(oldUserData)
        this.txtStatus = 'active'
        break;
      case 'inactive':
        this.selectedFilter = {
          active: false,
          expired: true,
          requested: false
        }
        const newUserData = this.dataEU.filter((ele:any)=> ele.status.toLowerCase() == order.toLowerCase())
        this.dataSourceEU = new MatTableDataSource(newUserData)
        this.txtStatus = 'inactive'
        break;
      case 'unassigned':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: true
        }
        const newUserDataa = this.dataEU.filter((ele:any)=> ele.assign_business_manager == null)
        this.dataSourceEU = new MatTableDataSource(newUserDataa)
        this.txtStatus = 'requested'
        break;
    }

  }
  filterByStatusRU(order: any) {
    let data = 'all'
    if(order == 'active')
      data = 'Active Retail User'
    if(order == 'inactive')
      data = 'Inactive Retail User'
    else if(order == 'Requested')
      data = 'Requested'
    this.selectedFilterDropdown = data
    switch (order) {
      case 'all':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: false
        }
        this.dataSourceCES = new MatTableDataSource(this.dataCES)
        
        this.txtStatus = 'all'
        break;

      case 'active':
        this.selectedFilter = {
          active: true,
          expired: false,
          requested: false
        }
        const oldUserData = this.dataCES.filter((ele:any)=> ele.status.toLowerCase() == order.toLowerCase())
        this.dataSourceCES = new MatTableDataSource(oldUserData)
        this.txtStatus = 'active'
        break;
      case 'inactive':
        this.selectedFilter = {
          active: false,
          expired: true,
          requested: false
        }
        const newUserData = this.dataCES.filter((ele:any)=> ele.status.toLowerCase() == order.toLowerCase())
        this.dataSourceCES = new MatTableDataSource(newUserData)
        this.txtStatus = 'inactive'
        break;
      case 'Requested':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: true
        }
        this.txtStatus = 'requested'
        break;
    }

  }
  selectedFilterDropdown = 'all'
  filterByStatus(order: any) {
    this.selectedFilterDropdown = order
    switch (order) {
      case 'all':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: false
        }
        this.dataSourceBM = new MatTableDataSource(this.dataBM)
        
        this.txtStatus = 'all'
        break;

      case 'active':
        this.selectedFilter = {
          active: true,
          expired: false,
          requested: false
        }
        const oldUserData = this.dataBM.filter((ele:any)=> ele.status.toLowerCase() == order.toLowerCase())
        this.dataSourceBM = new MatTableDataSource(oldUserData)
        this.txtStatus = 'active'
        break;
      case 'inactive':
        this.selectedFilter = {
          active: false,
          expired: true,
          requested: false
        }
        const newUserData = this.dataBM.filter((ele:any)=> ele.status.toLowerCase() == order.toLowerCase())
        this.dataSourceBM = new MatTableDataSource(newUserData)
        this.txtStatus = 'inactive'
        break;
      case 'Requested':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: true
        }
        this.txtStatus = 'requested'
        break;
    }

  }
  resetPasswordBM(element:any, type: any) {
    const dialogRef = this.dialog.open(DialogResetPassword, {
      disableClose: true,
      hasBackdrop: true,
      data:{
        element,
        type
      }
    })
  } 

  editEnterpriseUser(id: any) {
    const dialogRef = this.dialog.open(DialogEnterpriseModify, {
      hasBackdrop: true,
      disableClose: true,
      data:{
        id,
      }
    })
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.getEnterpriseUserList()
    })
  }

  editRetailUser(id: any) {
    const dialogRef = this.dialog.open(DialogRetailModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        id,
      }
    })
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.getCESUserList()
    })

  }

  editBusinessManager(id: any) {
    const dialogRef = this.dialog.open(DialogBusinessManagerModify, {
      hasBackdrop: true,
      disableClose: true,
      data:{
        id
      }
    })
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.getBusinessManagerList()
    })
  }


}












//--------------------------------------------User_management------------------------------------------------------
//--------------------------------------------User_management------------------------------------------------------
//--------------------------------------------User_management------------------------------------------------------

@Component({
  selector: 'app-web-user',
  templateUrl: './web-user.component.html',
  styleUrls: ['./web-user.component.css']
})
export class WebUserComponent implements OnInit {
  constructor(
    private dataShare: DataService,
    private http:HttpService,
    private toast:ToastrService,
    private dialog: MatDialog,
    private router :Router
  ) { }
  role:any;
  isNewNotifications:boolean = false
  notifications:any= []
  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      const roleId = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
      this.role = roleId
      console.log(this.role, roleId)
    }
    this.getNotifications()

    setTimeout(async ()=>{
      this.photo = await localStorage.getItem('photo')
      this.username = await localStorage.getItem('username')
    },0)
  }
  username:any = 'User'
  photo: any = '../../../assets/img/default_dp.png'
  tPhoto: any = '../../../assets/img/default_dp.png'
  navigate(route: string) {
    if (this.router.url == route) return
    else this.router.navigate([route])
  }
  getNotifications(){
    this.http.getNotifications(37).subscribe((res:any)=>{
      if(res['status']){
        this.notifications = res['result']
        const newNotf = this.notifications.filter((data:any) =>  data.is_read === false)
        console.log(this.notifications,newNotf)
        if(newNotf.length > 0)
          this.isNewNotifications = true
        else
          this.isNewNotifications = false
      }
      else{
        this.notifications = []
        this.isNewNotifications = false
      }
    },(err:any)=>{
      this.toast.error("Oops!, Somrthing went wrong Please try again",'',{timeOut:2000})
    })
  }
  @Output() messageEmitter = new EventEmitter<any>();
  moveTab(tab:any,to:any='Enterprise'){
    this.messageEmitter.emit(tab)
    this.dataShare.prefilter=to
  }
  readNotifications(element:any){
    this.moveTab('service',element.type == 'eu' ? 'Enterprise' : 'Retail')
    this.dataShare.dialogRef(element.service_request_id,element.type)
    let data = {
      id: element.id,
      is_read : 1
    }
    this.http.readNotification(data).subscribe((res:any)=>{
      if(res['status']){
        this.getNotifications()
      }
      else{
        this.toast.error(res['message'],'',{timeOut:2000})
      }
    },(err:any)=>{
      this.toast.error("Oops!, Somrthing went wrong Please try again",'',{timeOut:2000})
    })
  }
  showNotif:boolean = false
  toggleNotif(){
    this.dialog.open(NotificationsComponent,{
      data : this.notifications,
      // position: {
      //   top: `5rem`,
      //   right: `25rem`,
      // },
    }).afterClosed().subscribe((res)=>{
      if(res){
        this.getNotifications()
      }
    })
    }
}








//--------------------------------------------Super_admin_User_management------------------------------------------------------
//--------------------------------------------Super_admin_User_management------------------------------------------------------
//--------------------------------------------Super_admin_User_management------------------------------------------------------


@Component({
  selector: 'app-u0-webuser',
  templateUrl: './u0_web-user.component.html',
  styleUrls: ['./web-user.component.css']
})
export class U0WebUserComponent implements OnInit {
  @ViewChild(MatSort) sort = new MatSort();

  constructor(
    public dialog: MatDialog,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dataShare: DataService,
    private datePipe: DatePipe
  ) { }
  role: any;
  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      this.role = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
    }
    else {
      // this.toster.error('Opps! something went wrong', '', { timeOut: 2000 })
    }
    if(this.dataShare.prefilter=='Retail'){
        this.cesActiveFlag=false
        this.getAllRetailUser()
        // this.dataShare.prefilter='Enterprise'
    }
    else if(this.dataShare.prefilter=='Enterprise'){
      this.cesActiveFlag=true
      this.getAllEnterpriseUser()
      // this.dataShare.prefilter='Enterprise'
    }
    this.clearRuFilter()
    this.clearEuFilter()
  }
  //common

  handleSwicthWebUser(type: any) {
    if (type === 'ces') {
      if (!this.cesActiveFlag) this.cesActiveFlag = true
      this.getAllEnterpriseUser()
    }
    else if (type === 'bm') {
      if (this.cesActiveFlag) this.cesActiveFlag = false
      this.getAllRetailUser()
    }
  }

  //Enterprise varible and function
  displayedColumnsEnterprise: string[] = ['sl_no', 'name', 'company_name', 'state', 'date','assign_business_manager', 'assign_account_manager','downgrade', 'status', 'reset_password', 'modify'];
  dataEnterprise: any[] = []
  filterEudata:any[]=[]
  selectedFilterDropdown:any='all'
  filterRudata:any[]=[]
  dataSourceEnterprise: any = new MatTableDataSource();
  cesActiveFlag: boolean = false
  apiData:any;
  EuCityDropdown:any[]=[]
  EuNameDropdown:any[]=[]
  EuCompanyDropdown:any[]=[]
  EuDateDropdown:any[]=[]
  EuBMDropdown:any[]=[]
  EuStatusDropdown:any[]=[]
  EuAMDropdown:any[]=[]
  EuCityFilter:any='';
  EuBmFilter:any='';
  EuAmFilter:any='';
  EuStatusFilter:any='';
  EuCompanyFilter:any='';
  EuDateFilter:any='';
  EuNameFilter:any=''
  RUCityDropdown:any[]=[]
  RUNameDropdown:any[]=[]
  RUCompanyDropdown:any[]=[]
  RUDateDropdown:any[]=[]
  RUAMDropdown:any[]=[]
  RUStatusDropdown:any[]=[]
  RUCityFilter:any='';
  RUNameFilter:any='';
  RUCompanyFilter:any='';
  RUDateFilter:any='';
  RUAmFilter:any='';
  RUStatusFilter:any='';
  applyFilterCES(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceEnterprise.filter = filterValue.trim().toLowerCase();
  }
  addNewUser() {
    const dialogRef = this.dialog.open(DialogAddNewUser, {
      hasBackdrop: true,
      disableClose: true,
    })
    dialogRef.afterClosed().subscribe((data:any) => {
      if (data.status){
        if(data.from=='enterprise')
        this.getAllEnterpriseUser()
        else if(data.from=='retail')
        this.getAllRetailUser()
      }
    })
  }
  handleModifyCES(id:any)
  {
  const dialogRef = this.dialog.open(DialogEnterpriseModify, {
      hasBackdrop: true,
      disableClose: true,
      data:{
      isModify:true,
      id:id
      }
    })
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.getAllEnterpriseUser()    })
  }
  changeUserStatus(element:any,value:any,from:any){
    let data = {
      status : value
    }
      this.httpSv.changeUserStatus(element.id,data).subscribe((res:any)=>{
        if(res['status']){
          if(from == 'eu')
            this.getAllEnterpriseUser()
          else
            this.getAllRetailUser()
           }
            else {
              this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
            }
      }, (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 })
      })
    }
    
  resetPasswordCES(element:any) {
    const dialogRef = this.dialog.open(DialogResetPassword, {
      disableClose: true,
      hasBackdrop: true,
      data:{
        element
      }
    })
  }
  handelDeleteCES(element:any) {
    // const dialogRef = this.dialog.open(DialogDeleteDonePopup,{
    //   hasBackdrop:true,
    //   disableClose:true,
    //   data:{
    //     heading: 'You have successfully removed the Client Specialist.'
    //   }
    // })
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to remove this Enterprise User?'
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res==true){
      this.httpSv.deleteUser(this.role,element,'enterprise')?.subscribe((res: any) => {
        if (res['status']) {
          this.getAllEnterpriseUser()
        }
        else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 })
      })
    }
    })
  }


  // Retail user Variable and function




  displayedColumnsBM: string[] = ['sl_no', 'name', 'company_name', 'state','date', 'assign_account_manager','upgrade_access', 'status', 'resetPassword','modify'];
  dataRetail: any[] = []
  dataSourceRetail: any = new MatTableDataSource();
  cesActiveFlagRetail: boolean = true

  applyFilterRetail(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceRetail.filter = filterValue.trim().toLowerCase();
  }
  selectedFilter: any = {
    active: false,
    expired: false,
    requested: false
  }
  txtStatus: string = ''
  filterByStatus(order: any) {
    let data = 'all';
    if(order == 'active')
      data = 'Active Enterprise Users'
    else if(order == 'inactive')
      data = 'InActive Enterprise Users'
      else if(order == 'requested')
      data = 'Unassigned Enterprise Users'
    this.selectedFilterDropdown = data
    switch (order) {
      case 'all':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: false
        }
        this.dataSourceEnterprise.data = this.dataEnterprise
        this.txtStatus = 'all'
        break;

      case 'active':
        this.selectedFilter = {
          active: true,
          expired: false,
          requested: false
        }
        const oldUserData = this.dataEnterprise.filter((ele: any) => ele.status.toLowerCase() == order.toLowerCase())
        this.dataSourceEnterprise.data = oldUserData
        this.txtStatus = 'active'
        break;
      case 'inactive':
        this.selectedFilter = {
          active: false,
          expired: true,
          requested: false
        }
        const newUserData = this.dataEnterprise.filter((ele: any) => ele.status.toLowerCase() == order.toLowerCase())
        console.log(newUserData)
        this.dataSourceEnterprise.data = newUserData
        this.txtStatus = 'inactive'
        break;
      case 'requested':
        this.selectedFilter = {
          active: false,
          expired: true,
          requested: false
        }
        const newUserDataa = this.dataEnterprise.filter((ele: any) => ele.assign_business_manager==null)
        this.dataSourceEnterprise.data = newUserDataa
        this.txtStatus = 'inactive'
        break;
    }
  }
  filterByStatusRetail(order: any) {
    let data = 'all';
    if(order == 'active')
      data = 'Active Retail Users'
    else if(order == 'inactive')
      data = 'InActive Retail Users'
    else if(order == 'requested')
      data = 'Unassigned Retail Users'
    this.selectedFilterDropdown = data
    switch (order) {
      case 'all':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: false
        }
        this.dataSourceRetail.data = this.dataRetail
        this.txtStatus = 'all'
        break;

      case 'active':
        this.selectedFilter = {
          active: true,
          expired: false,
          requested: false
        }
        const oldUserData = this.dataRetail.filter((ele: any) => ele.status.toLowerCase() == order.toLowerCase())
        this.dataSourceRetail.data = oldUserData
        this.txtStatus = 'active'
        break;
      case 'inactive':
        this.selectedFilter = {
          active: false,
          expired: true,
          requested: false
        }
        const newUserData = this.dataRetail.filter((ele: any) => ele.status.toLowerCase() == order.toLowerCase())
        this.dataSourceRetail.data = newUserData
        this.txtStatus = 'inactive'
        break;
      case 'requested':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: true
        }
        console.log(this.dataRetail)
        const newUserDataa = this.dataRetail.filter((ele: any) => ele.assign_account_manager==null)
        this.dataSourceRetail.data = newUserDataa
        console.log(newUserDataa)
        this.txtStatus = 'requested'
      break;
      case 'active':
          this.selectedFilter = {
            active: true,
            expired: false,
            requested: false
          }
          this.txtStatus = 'active'
      break;
      case 'inactive':
            this.selectedFilter = {
              active: false,
              expired: true,
              requested: false
            }
            this.txtStatus = 'inctive'
      break;
    }
  }
  resetPasswordRetail(element:any) {
    const dialogRef = this.dialog.open(DialogResetPassword, {
      disableClose: true,
      hasBackdrop: true,
      data:{
        element
      }
    })
  }
  confirmUpgrade(id:any,from:any)
  {
    let header ;
    if(from == 'ru')
      header = 'Are you sure you want to Upgrade to Enterprise User? It will remove the assigned Account Manager. '
    else
      header = 'Are you sure you want to Downgrade to Retail User? It will remove the assigned Business Manager. '

    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: header
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res==true){
        this.httpSv.upgradeUser(id,'',from).subscribe((res: any) => {
          if (res['status']) {
            this.getAllRetailUser()
            this.getAllEnterpriseUser()
          }
          else {
            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
          }
      }, (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 })
      })
    }
    })
  }
  handelDeleteRetail(element:any) {
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to remove this Retail User?'
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res==true){
      this.httpSv.deleteUser(this.role,element,'retail')?.subscribe((res: any) => {
        if (res['status']) {
          const dialogRef = this.dialog.open(DialogDeleteDonePopup,{
            hasBackdrop:true,
            disableClose:true,
            data:{
              heading: 'You have successfully removed the Retail User.'
            }
          }).afterClosed().subscribe((res:any)=>
          {
            if(res)
            this.getAllRetailUser()
          })
        }
        else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 })
      })
    }
    })
  }
  editRetailUser(id: any) {
    const dialogRef = this.dialog.open(DialogRetailModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        id,
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res)
      {
        this.getAllRetailUser()
      }
    })

  }




  createRUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state && !this.RUCityDropdown.includes(data.state.toLowerCase()))
        this.RUCityDropdown.push(data.state.toLowerCase())
      if(data.assign_account_manager && !this.RUAMDropdown.includes(data.assign_account_manager.name))
        this.RUAMDropdown.push(data.assign_account_manager.name)
      if(data.status && !this.RUStatusDropdown.includes(data.status))
        this.RUStatusDropdown.push(data.status)
      if(data.date && !this.RUDateDropdown.includes(data.date))
        this.RUDateDropdown.push(data.date)
      if(data.name && !this.RUNameDropdown.includes(data.name))
        this.RUNameDropdown.push(data.name)
      if(data.company_name && !this.RUCompanyDropdown.includes(data.company_name))
        this.RUCompanyDropdown.push(data.company_name)
    })
  }
  RuApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='name'){
      this.RUNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUAmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_account_manager && data.assign_account_manager.name.slice(0,10).toLowerCase()==this.RUAmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.name && apiData.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceRetail.data = this.filterRudata

    }
    if(from=='company'){
      this.RUCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUAmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_account_manager && data.assign_account_manager.name.slice(0,10).toLowerCase()==this.RUAmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.company_name && apiData.company_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceRetail.data = this.filterRudata

    }
    if(from=='city'){
      this.RUCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.RUDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUAmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_account_manager && data.assign_account_manager.name.slice(0,10).toLowerCase()==this.RUAmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceRetail.data = this.filterRudata

    }
    if(from=='date'){
      this.RUDateFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUAmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_account_manager && data.assign_account_manager.name.slice(0,10).toLowerCase()==this.RUAmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.date && apiData.date.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceRetail.data = this.filterRudata

    }
    if(from=='bm'){
      this.RUAmFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.slice(0,10).toLowerCase()==this.RUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.RUStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.assign_account_manager && apiData.assign_account_manager.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceRetail.data = this.filterRudata

    }
    if(from=='status'){
      this.RUStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataRetail;
        if(this.RUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.RUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.RUCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.RUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.slice(0,10).toLowerCase()==this.RUDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.RUAmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_account_manager && data.assign_account_manager.name.toLowerCase()==this.RUAmFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterRudata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterRudata = result
      this.dataSourceRetail.data = this.filterRudata

    }
  }
  clearRuFilter()
  {
    this.RUStatusFilter='';
    this.RUAmFilter='';
    this.RUCityFilter='';
    this.dataSourceRetail.data = this.dataRetail

  }



  getAllRetailUser() {
    this.httpSv.getAllRetailUserList().subscribe((res: any) => {
      if (res['status']) {
        res['results'].map((data : any)=>{
          if(data.date.indexOf('-') != -1){
            data.date = this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
          }
        })
        this.dataRetail = res['results']
        this.filterRudata = res['results']
        this.dataRetail.map((res:any)=>{
          if(!res.status)
            res.status='Active'
        })
        this.createRUDropDown(this.dataRetail)
        this.dataSourceRetail.data = this.dataRetail
        this.dataSourceRetail.sort = this.sort
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      if(err.error.message=='no data found')
      this.dataSourceRetail.data = [];
      if(err.error.message != 'no data found')
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  createEUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.state &&!this.EuCityDropdown.includes(data.state.toLowerCase()))
        this.EuCityDropdown.push(data.state.toLowerCase())
      if(data.assign_business_manager && !this.EuBMDropdown.includes(data.assign_business_manager.name))
        this.EuBMDropdown.push(data.assign_business_manager.name)
      if(data.assign_account_manager && !this.EuAMDropdown.includes(data.assign_account_manager.name))
        this.EuAMDropdown.push(data.assign_account_manager.name)
      if(data.status && !this.EuStatusDropdown.includes(data.status))
        this.EuStatusDropdown.push(data.status)
      if(data.company_name && !this.EuCompanyDropdown.includes(data.company_name))
        this.EuCompanyDropdown.push(data.company_name)
      if(data.name && !this.EuNameDropdown.includes(data.name))
        this.EuNameDropdown.push(data.name)
      if(data.date && !this.EuDateDropdown.includes(data.date))
        this.EuDateDropdown.push(data.date)
    })
  }
  EuApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='name'){
      this.EuNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuAmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_account_manager && data.assign_account_manager?.name.toLowerCase()==this.EuAmFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EuDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.name && apiData.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSourceEnterprise.data = this.filterEudata

    }
    if(from=='company'){
      this.EuCompanyFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuAmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_account_manager && data.assign_account_manager?.name.toLowerCase()==this.EuAmFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.toLowerCase()==this.EuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EuDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.company_name && apiData.company_name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSourceEnterprise.data = this.filterEudata

    }
    if(from=='city'){
      this.EuCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EuDateFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuAmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_account_manager && data.assign_account_manager?.name.toLowerCase()==this.EuAmFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.state && apiData.state.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSourceEnterprise.data = this.filterEudata

    }
    if(from=='date'){
      this.EuDateFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.slice(0,10).toLowerCase()==this.EuBmFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuAmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_account_manager && data.assign_account_manager?.name.toLowerCase()==this.EuAmFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.date && apiData.date.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSourceEnterprise.data = this.filterEudata

    }
    if(from=='bm'){
      this.EuBmFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuAmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_account_manager && data.assign_account_manager?.name.toLowerCase()==this.EuAmFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EuDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.assign_business_manager && apiData.assign_business_manager?.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSourceEnterprise.data = this.filterEudata

    }
    if(from=='status'){
      this.EuStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EuDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.EuBmFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(this.EuAmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_account_manager && data.assign_account_manager?.name.toLowerCase()==this.EuAmFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSourceEnterprise.data = this.filterEudata

    }
    if(from=='am'){
      this.EuAmFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.dataEnterprise;
        if(this.EuNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EuNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCompanyFilter.length>0){
          filterData.map((data:any)=>{
                if(data.company_name && data.company_name.toLowerCase()==this.EuCompanyFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.state && data.state.slice(0,10).toLowerCase()==this.EuCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuDateFilter.length>0){
          filterData.map((data:any)=>{
                if(data.date && data.date.toLowerCase()==this.EuDateFilter)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EuBmFilter.length>0){
          filterData.map((data:any)=>{
                if(data.assign_business_manager && data.assign_business_manager.name.toLowerCase()==this.EuBmFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        if(this.EuStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EuStatusFilter.status())
                  result.push(data)    
                });
              filterData=result;
        }
        // if(this.EuAmFilter.length>0){
        //   filterData.map((data:any)=>{
        //         if(data.assign_account_manager && data.assign_account_manager.toLowerCase()==this.EuAmFilter.toLowerCase())
        //           result.push(data)    
        //         });
        //       filterData=result;
        //       result=[]
        // }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterEudata.map((apiData:any)=>{
              if(apiData.assign_account_manager && apiData.assign_account_manager?.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterEudata = result
      this.dataSourceEnterprise.data = this.filterEudata

    }
  }
  clearEuFilter()
  {
    this.EuStatusFilter='';
    this.EuBmFilter='';
    this.EuCityFilter='';
    this.dataSourceEnterprise.data = this.dataEnterprise

  }
  getAllEnterpriseUser() {
    this.httpSv.getAllEnterpriseUser().subscribe((res: any) => {
      if (res['status']) {
        res['results'].map((data : any)=>{
          if(data.date.indexOf('-') != -1){
            data.date = this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
          }
        })
        this.dataEnterprise = res['results']
        this.filterEudata = res['results']
        this.dataSourceEnterprise.data = this.dataEnterprise
        this.dataSourceEnterprise.sort = this.sort
        this.createEUDropDown(res['results'])
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      if(err.error.message=='no data found')
      this.dataSourceEnterprise.data = [];
      if(err.error.message != 'no data found')
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  handleAssignment(type: any, bmId: any, id: any,from:any=null) {
    const dialogRef = this.dialog.open(DialogAssignment, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        type,
        bmId,
        id,
        from:from
      }
    })
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result){
        if(from)
          this.getAllRetailUser()
        else
          this.getAllEnterpriseUser()
      }
    })
  }
}







//--------------------------------------------ces_add_modify_dialog------------------------------------------------------
//--------------------------------------------ces_add_modify_dialog------------------------------------------------------
//--------------------------------------------ces_add_modify_dialog------------------------------------------------------
//--------------------------------------------ces_add_modify_dialog------------------------------------------------------
//--------------------------------------------ces_add_modify_dialog------------------------------------------------------

@Component({
  selector: 'ces-add-modify-dialog',
  templateUrl: './ces_add_modify_dialog.html',
  styleUrls: ['./web-user.component.css']
})
export class DialogCESAddModify implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogCESAddModify>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toster: ToastrService,
    public dialog: MatDialog,
    private httpSv: HttpService,
    private dataShare: DataService
  ) { }
  role:any;
  type:any='beu'
  ngOnInit(): void {
    this.dataShare.allStatesOfIndia.forEach((ele: any, index: any) => {
      this.allState.push({ id: index + 1, name: ele })
    });
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
    this.role = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
    }
    if(this.role !=3)
      this.getAllBM()
    this.roleId=this.dataShare.roleId;
    if(this.data.element)
    { 
      if(this.data.from=='enterprise'){
        this.isModify=true;
        this.bmName=this.data.element.name
        this.bmEmail=this.data.element.email
        this.bmNumber=this.data.element.mobile
        this.bmComName=this.data.element.company_name
        this.bmAddress=this.data.element.address
        this.bmCity=this.data.element.city
        this.bmState= this.data.element.state
        this.bmtxtStatus=this.data.element.status
        this.bmId=this.data.element.id
        this.listOfLicense=this.data.element.fssai_lic_no ?this.data.element.fssai_lic_no  : []
      }
      else{
        this.type='retail'
        this.isModify=true;
        this.bmRetailName=this.data.element.name
        this.bmRetailEmail=this.data.element.email
        this.bmRetailNumber=this.data.element.mobile
        this.bmRetailComName=this.data.element.company_name
        this.bmRetailCity=this.data.element.address
        // this.bmRetailAddress=this.data.element.address
        this.bmRetailState= this.data.element.state
        this.bmRetailtxtStatus=this.data.element.status
        this.bmRetailId=this.data.element.id
        this.listOfLicense=this.data.element.fssai_lic_no ? this.data.element.fssai_lic_no : []
      }
    }
  }

  isModify: boolean = false;
  roleId: any;
  allState: any[] = []
  stepperFlag: any = {
    personal_dtl: true,
    assign_bm: false,
    assign_reatil: false
  }
  listOfLicense:any = [];
  ces_current_id: any;
  //--------------------------------------------------Persional Dtls Bussiness Manager
  bmName: string = ''
  bmId: string = ''
  bmEmail: string = ''
  bmNumber: string = ''
  bmNewPassword: string = ''
  bmConfirmPassword: string = ''
  bmComName: string = ''
  bmCity: string = ''
  bmAddress: string = ''
  bmState: any ;
  bmtxtStatus: String = '';
  //--------------------------------------------------Persional Dtls
  cesName: string = ''
  cesEmail: string = ''
  cesNumber: string = ''
  cesNewPassword: string = ''
  cesConfirmPassword: string = ''

  //---------------------------------------------RETAIL USER---------------------------------------

  bmRetailName: string = ''
  bmRetailId: string = ''
  bmRetailEmail: string = ''
  bmRetailNumber: string = ''
  bmRetailNewPassword: string = ''
  bmRetailConfirmPassword: string = ''
  bmRetailComName: string = ''
  bmRetailCity: string = ''
  bmRetailState: any ;
  bmRetailtxtStatus: String = '';



  //Autocomplete

  autocompleteControl: string = '';
  options: any[] = [];
  passwordToggleHide: boolean = true
  conpasswordToggleHide: boolean = true
  selectedOptions: any[] = [];
  filteredOptions: any[] = this.options;
  readonlyToggleFlag: boolean = false
  handleStatusChange(status: any) {
    if(this.type=='beu')
    this.bmtxtStatus = status
    else
    this.bmRetailtxtStatus=status
  }
  handleBmReset() {
    const dialogRef = this.dialog.open(DialogResetPassword, {
      disableClose: true,
      hasBackdrop: true,
      data:{
        element:this.bmId
      }
    })
  }
  handleOpenLicensePopup(licensesNo:any,id:any)
  {
    this.httpSv.getBMLicenseDetails(licensesNo,'enterprise').subscribe((res:any)=>{
      if(res['status'])
      {
        const dialogRef = this.dialog.open(DialogAppLicenseModify, {
          hasBackdrop: true,
          disableClose: true,
          data:
          {
            licensesNo:id,
            type:'enterprise',
            isView:true,
            licenseDetails:res['results'][0]
          }}
        ).afterClosed().subscribe((res:any)=>{
          if(res == 'close'){
            // this.close()
          }
        })
      }})
  } 
  handleOpenLicense(id:any,from:any)
  {
    const dialogRef = this.dialog.open(DialogAppLicenseModify, {
      hasBackdrop: true,
      disableClose: true,
      data:
      {
        licensesNo:id,
        type:from,
        isView:true
       }}
    ).afterClosed().subscribe((res:any)=>{
      if(res == 'close'){
        this.close()
      }
    })
  }
  handleAddNewLicense()
  {
    const dialogRef = this.dialog.open(DialogAppLicenseModify, {
      hasBackdrop: true,
      disableClose: true,
      data:
      {
        isAddNew:true,
        type:this.data.from,
        id:this.data.element.id
      }
    }
    ).afterClosed().subscribe((res:any)=>{
      if(res == 'close'){
        this.close()
      }
    })
  }
  handleViewLicense()
  {
    const dialogRef = this.dialog.open(DialogViewLicense, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        listLicenses:this.listOfLicense
      }
    })
  }
  getStateId(name:any)
  {
    let flag:any;
    this.dataShare.allStatesOfIndia.forEach((ele: any, index: any) => {
      if(name==index+1)
      {
        flag=ele
      }
    });
    return flag;
  }
  handleBmDelete() {
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to remove this Entriprise User?',
        element:this.bmId
      }
    }).afterClosed().subscribe((result:any)=>{
      if(result)
      {
        this.httpSv.deleteUser(this.role,this.bmId)?.subscribe((res:any)=>{
          if (res['status']) {
           this.dialogRef.close(true)
          }
          else {
            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
          }
    
        }, (err: any) => {
          this.toster.error(err.error.message, '', { timeOut: 2000 })
        })
      }
    })
  }
  getAllBM() {
    this.httpSv.getAllBmList(this.role).subscribe((res: any) => {
      if (res['status']) {
        this.options = res['results']
        this.filteredOptions = this.options
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }

    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }

  filter() {
    this.filteredOptions = this.options.filter(option =>
      option.name.toLowerCase().includes(this.autocompleteControl.toLowerCase())
    );
  }

  toggleSelection(option: string) {
    if (this.isSelected(option)) {
      // Deselect
      this.selectedOptions = this.selectedOptions.filter(o => o !== option);
    } else {
      // Select
      this.selectedOptions.push(option);
    }
    this.readonlyToggleFlag = true
    setTimeout(() => {
      this.readonlyToggleFlag = false
    })
    this.filteredOptions = this.options;
  }

  isSelected(option: string): boolean {
    return this.selectedOptions.includes(option);
  }

  //below box


  //---------------------------------------------Assign  Retail---------------------------------------
  //Autocomplete

  autocompleteSearch: string = '';
  allReatailUserList: string[] = ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5', 'Option 6', 'Option 7', 'Option 8'];
  selectedRetailUser: string[] = [];
  filteredReatailUser: string[] = this.allReatailUserList;
  readonlyRetailToggleFlag = false


  filterRetailUser() {
    this.filteredReatailUser = this.allReatailUserList.filter(option =>
      option.toLowerCase().includes(this.autocompleteSearch.toLowerCase())
    );
  }

  toggleRetailSelection(option: string) {
    // alert('called')
    if (this.isRetailSelected(option)) {
      // Deselect
      this.selectedRetailUser = this.selectedRetailUser.filter(o => o !== option);
    } else {
      // Select
      this.selectedRetailUser.push(option);
    }
    this.readonlyRetailToggleFlag = true
    setTimeout(() => {
      this.readonlyRetailToggleFlag = false
    }, 0)
    this.filteredReatailUser = this.allReatailUserList;
  }

  isRetailSelected(option: string): boolean {
    return this.selectedRetailUser.includes(option);
  }


  //Common
  addNewCesFunc() {
    if (this.stepperFlag.personal_dtl) {
      if (this.cesName && this.cesName != '') {
        if (this.cesEmail && this.cesEmail != '') {
          if (this.cesEmail.match(emailRegex)) {
            if (this.cesNumber && this.cesNumber != '') {
              if (this.cesNumber.toString().length === 10) {
                if (this.cesNewPassword && this.cesNewPassword != '') {
                  if (this.cesConfirmPassword && this.cesConfirmPassword != '') {
                    if (this.cesNewPassword === this.cesConfirmPassword) {
                      const data = {
                        name: this.cesName,
                        email: this.cesEmail,
                        mobile: this.cesNumber,
                        password: this.cesNewPassword
                      }
                      this.httpSv.createNewCes(data,this.role)?.subscribe((res: any) => {
                        if (res['status']) {
                          this.toster.success('Client Engagement Specialist added successfully', '', { timeOut: 2000 })
                          this.ces_current_id = res['bm_id']
                          this.stepperFlag = {
                            personal_dtl: false,
                            assign_bm: true,
                            assign_reatil: false
                          }
                        }
                        else {
                          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                        }
                      }, (err: any) => {
                        this.toster.error(err.error.message, '', { timeOut: 2000 })
                      })
                    } else {
                      this.toster.error('Password does not match', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please confirm the password', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter password', '', { timeOut: 2000 })
                }
              } else {

                this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
          }
        } else {
          this.toster.error('Please enter email id', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter name', '', { timeOut: 2000 })
      }
    }
    else if (this.stepperFlag.assign_bm) {
      if (this.selectedOptions.length > 0) {
        if (this.ces_current_id) {
          const data = {
            client_engagement_specialist_id: this.ces_current_id,
            business_manager_id: this.selectedOptions.map(ele => ele.id)
          }
        }
        else {
          this.toster.error('Oops! Something went wrong')
        }
      }
      else {
        this.stepperFlag = {
          personal_dtl: false,
          assign_bm: false,
          assign_reatil: true,
        }
      }
    }
  }
  close() {
    this.dialogRef.close(false)
  }
  // -------------- Add New Bussiness Manager -------------------------------------
  addNewBMFunc() {
    if(this.type=='beu'){
      if (this.bmComName && this.bmComName != '') {
        if (this.bmCity && this.bmCity != '') {
          if (this.bmState && this.bmState != '') {
              if (this.bmName && this.bmName != '') {
                if (this.bmEmail && this.bmEmail != '') {
                  if (this.bmEmail.match(emailRegex)) {
                    if (this.bmNumber && this.bmNumber != '') {
                      if (this.bmNumber.toString().length === 10) {
                        if (this.bmNewPassword && this.bmNewPassword != '') {
                          if (this.bmConfirmPassword && this.bmConfirmPassword != '') {
                            if (this.bmNewPassword === this.bmConfirmPassword) {
                              const data = {
                                name: this.bmName,
                                email: this.bmEmail,
                                mobile: this.bmNumber,
                                password: this.bmNewPassword,
                                address: this.bmCity,
                                company_name: this.bmComName,
                                state: this.bmState,
                              }
                              this.httpSv.createNewCes(data,this.role)?.subscribe((res: any) => {
                                if (res['status']) {
                                  this.toster.success('Entriprise User added successfully', '', { timeOut: 2000 })
                                  this.ces_current_id = res['bm_id']
                                  this.stepperFlag = {
                                    personal_dtl: false,
                                    assign_bm: true,
                                    assign_reatil: false
                                  }
                                  this.dialogRef.close(false)
                                }
                                else {
                                  this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                                }
                              }, (err: any) => {
                                this.toster.error(err.error.message, '', { timeOut: 2000 })
                              })
                            } else {
                              this.toster.error('Password does not match', '', { timeOut: 2000 })
                            }
                          } else {
                            this.toster.error('Please confirm the password', '', { timeOut: 2000 })
                          }
                        } else {
                          this.toster.error('Please enter password', '', { timeOut: 2000 })
                        }
                      } else {

                      this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter email id', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter name', '', { timeOut: 2000 })
            }
          }
          else {
            this.toster.error('Please enter State', '', { timeOut: 2000 })
          }
        }
        else {
          this.toster.error('Please enter City', '', { timeOut: 2000 })
        }
      }
      else {
        this.toster.error('Please enter Company Name', '', { timeOut: 2000 })
      }
    }
    else{
      if (this.bmRetailComName && this.bmRetailComName != '') {
        if (this.bmRetailCity && this.bmRetailCity != '') {
          if (this.bmRetailState && this.bmRetailState != '') {
              if (this.bmRetailName && this.bmRetailName != '') {
                if (this.bmRetailEmail && this.bmRetailEmail != '') {
                  if (this.bmRetailEmail.match(emailRegex)) {
                    if (this.bmRetailNumber && this.bmRetailNumber != '') {
                      if (this.bmRetailNumber.toString().length === 10) {
                        if (this.bmRetailNewPassword && this.bmRetailNewPassword != '') {
                          if (this.bmRetailConfirmPassword && this.bmRetailConfirmPassword != '') {
                            if (this.bmRetailNewPassword === this.bmRetailConfirmPassword) {
                              const data = {
                                name: this.bmRetailName,
                                email: this.bmRetailEmail,
                                mobile: this.bmRetailNumber,
                                password: this.bmRetailNewPassword,
                                address: this.bmRetailCity,
                                company_name: this.bmRetailComName,
                                state: this.bmRetailState,
                              }
                              this.httpSv.createNewRetail(data,this.role)?.subscribe((res: any) => {
                                if (res['status']) {
                                  this.toster.success('Retail User added successfully', '', { timeOut: 2000 })
                                  this.ces_current_id = res['bm_id']
                                  this.stepperFlag = {
                                    personal_dtl: false,
                                    assign_bm: true,
                                    assign_reatil: false
                                  }
                                  this.dialogRef.close(false)
                                }
                                else {
                                  this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                                }
                              }, (err: any) => {
                                this.toster.error(err.error.message, '', { timeOut: 2000 })
                              })
                            } else {
                              this.toster.error('Password does not match', '', { timeOut: 2000 })
                            }
                          } else {
                            this.toster.error('Please confirm the password', '', { timeOut: 2000 })
                          }
                        } else {
                          this.toster.error('Please enter password', '', { timeOut: 2000 })
                        }
                      } else {

                      this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter email id', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter name', '', { timeOut: 2000 })
            }
          }
          else {
            this.toster.error('Please enter State', '', { timeOut: 2000 })
          }
        }
        else {
          this.toster.error('Please enter City', '', { timeOut: 2000 })
        }
      }
      else {
        this.toster.error('Please enter Company Name', '', { timeOut: 2000 })
      }
    }
  }
  modifyBMUser()
  {
    if(this.type=='beu'){
      if (this.bmAddress && this.bmAddress != '') {
      if (this.bmComName && this.bmComName != '') {
        if (this.bmCity && this.bmCity != '') {
          if (this.bmState && this.bmState != '') {
              if (this.bmName && this.bmName != '') {
                if (this.bmEmail && this.bmEmail != '') {
                  if (this.bmEmail.match(emailRegex)) {
                    if (this.bmNumber && this.bmNumber != '') {
                      if (this.bmNumber.toString().length === 10) {
                        // if (this.bmNewPassword && this.bmNewPassword != '') {
                        //   if (this.bmConfirmPassword && this.bmConfirmPassword != '') {
                        //     if (this.bmNewPassword === this.bmConfirmPassword) {
                              const data = {
                                name: this.bmName,
                                email: this.bmEmail,
                                mobile: this.bmNumber,
                                password: this.bmNewPassword,
                                address: this.bmAddress,
                                city: this.bmCity,
                                company_name: this.bmComName,
                                state: this.bmState,
                                status:this.bmtxtStatus
                              }
                              this.httpSv.UpdateCes(data,this.bmId)?.subscribe((res: any) => {
                                if (res['status']) {
                                  this.toster.success('Enterprise User Updated successfully', '', { timeOut: 2000 })
                                  this.ces_current_id = res['bm_id']
                                  this.stepperFlag = {
                                    personal_dtl: false,
                                    assign_bm: true,
                                    assign_reatil: false
                                  }
                                  this.dialogRef.close(false)
                                }
                                else {
                                  this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                                }
                              }, (err: any) => {
                                this.toster.error(err.error.message, '', { timeOut: 2000 })
                              })
                        //     } else {
                        //       this.toster.error('Password does not match', '', { timeOut: 2000 })
                        //     }
                        //   } else {
                        //     this.toster.error('Please confirm the password', '', { timeOut: 2000 })
                        //   }
                        // } else {
                        //   this.toster.error('Please enter password', '', { timeOut: 2000 })
                        // }
                      } else {

                      this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter email id', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter name', '', { timeOut: 2000 })
            }
          }
          else {
            this.toster.error('Please enter State', '', { timeOut: 2000 })
          }
        }
        else {
          this.toster.error('Please enter City', '', { timeOut: 2000 })
        }
      }
      else {
        this.toster.error('Please enter Company Name', '', { timeOut: 2000 })
      }
     }
      else {
        this.toster.error('Please enter Address', '', { timeOut: 2000 })
      }
    }
    else{
      if (this.bmRetailComName && this.bmRetailComName != '') {
        if (this.bmRetailCity && this.bmRetailCity != '') {
          if (this.bmRetailState && this.bmRetailState != '') {
              if (this.bmRetailName && this.bmRetailName != '') {
                if (this.bmRetailEmail && this.bmRetailEmail != '') {
                  if (this.bmRetailEmail.match(emailRegex)) {
                    if (this.bmRetailNumber && this.bmRetailNumber != '') {
                      if (this.bmRetailNumber.toString().length === 10) {
                        // if (this.bmRetailNewPassword && this.bmRetailNewPassword != '') {
                        //   if (this.bmRetailConfirmPassword && this.bmRetailConfirmPassword != '') {
                        //     if (this.bmRetailNewPassword === this.bmRetailConfirmPassword) {
                              const data = {
                                name: this.bmRetailName,
                                email: this.bmRetailEmail,
                                mobile: this.bmRetailNumber,
                                password: this.bmRetailNewPassword,
                                address: this.bmRetailCity,
                                company_name: this.bmRetailComName,
                                state: this.bmRetailState,
                                status:this.bmRetailtxtStatus
                              }
                              this.httpSv.UpdateRetailBM(data,this.bmRetailId)?.subscribe((res: any) => {
                                if (res['status']) {
                                  this.toster.success('Retail User Updated successfully', '', { timeOut: 2000 })
                                  this.ces_current_id = res['bm_id']
                                  this.stepperFlag = {
                                    personal_dtl: false,
                                    assign_bm: true,
                                    assign_reatil: false
                                  }
                                  this.dialogRef.close(false)
                                }
                                else {
                                  this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                                }
                              }, (err: any) => {
                                this.toster.error(err.error.message, '', { timeOut: 2000 })
                              })
                        //     } else {
                        //       this.toster.error('Password does not match', '', { timeOut: 2000 })
                        //     }
                        //   } else {
                        //     this.toster.error('Please confirm the password', '', { timeOut: 2000 })
                        //   }
                        // } else {
                        //   this.toster.error('Please enter password', '', { timeOut: 2000 })
                        // }
                      } else {

                      this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter email id', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter name', '', { timeOut: 2000 })
            }
          }
          else {
            this.toster.error('Please enter State', '', { timeOut: 2000 })
          }
        }
        else {
          this.toster.error('Please enter City', '', { timeOut: 2000 })
        }
      }
      else {
        this.toster.error('Please enter Company Name', '', { timeOut: 2000 })
      }
    }
  }

}



//--------------------------------------------Enterprise_modify_dialog------------------------------------------------------
//--------------------------------------------Enterprise_modify_dialog------------------------------------------------------
//--------------------------------------------Enterprise_modify_dialog------------------------------------------------------
//--------------------------------------------Enterprise_modify_dialog------------------------------------------------------
@Component({
  selector: 'enterprise-modify-dialog',
  templateUrl: './enterprise_modify_dialog.html',
  styleUrls: ['./web-user.component.css']
})
export class DialogEnterpriseModify implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogEnterpriseModify>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toster: ToastrService,
    private httpSv: HttpService,
    private dataShare:DataService

  ) { }
  role:any;
  allState:any[]=[];
  ngOnInit(): void {
    this.dataShare.allStatesOfIndia.forEach((ele: any, index: any) => {
      this.allState.push({ id: index + 1, name: ele })
    });
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
    this.role = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
    }
    
  this.getUserDtls(this.data.id)

  }
  stepperFlag: any = {
    personal_dtl: true,
    assign_beu: false
  }

  id:string=''
  name: string = ''
  email: string = ''
  number: string = ''
  compName: string = ''
  address: string = ''
  state: any = ''
  city: any = ''
  assign_account_manager:any;
  assign_business_manager:any;

  selectedFilter: any = {
    active: false,
    expired: false,
    requested: false
  }
  txtStatus: string = ''
  filterByStatus(order: any) {
    switch (order) {

      case 'Active':
        this.selectedFilter = {
          active: true,
          expired: false,
          requested: false
        }
        this.txtStatus = 'Active'
        break;
      case 'Inactive':
        this.selectedFilter = {
          active: false,
          expired: true,
          requested: false
        }
        this.txtStatus = 'Inactive'
        break;
      case 'Requested':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: true
        }
        this.txtStatus = 'Requested'
        break;
    }

  }


  handleOpenLicense(id:any)
  {
    const dialogRef = this.dialog.open(DialogAppLicenseModify, {
      hasBackdrop: true,
      disableClose: true,
      data:
      {
        licensesNo:id,
        type:'enterprise',
        isView:true
       }}
    ).afterClosed().subscribe((res:any)=>{
      if(res == 'close'){
        this.close()
      }
    })
  }
  handleAddNewLicense()
  {
    const dialogRef = this.dialog.open(DialogAppLicenseModify, {
      hasBackdrop: true,
      disableClose: true,
      data:
      {
        isAddNew:true,
        type:'enterprise',
        id: this.id
      }
    }
    ).afterClosed().subscribe((res:any)=>{
      if(res == 'close'){
        this.close()
      }
    })
  }
  handleAssignment(type: any, bmId: any, id: any) {
    const dialogRef = this.dialog.open(DialogAssignment, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        type,
        bmId,
        id,
        ismodify:true
      }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.status?result.status:result) 
      {
        console.log(result,'ksksks')
        if(type == 'bm'){
          this.assign_business_manager=result.dataBM
          this.assign_account_manager=result.dataAM
        }
        else{
        this.assign_account_manager=result.data
        }

      }
    })
  }
  handleViewLicense()
  {
    const dialogRef = this.dialog.open(DialogViewLicense, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        listLicenses:this.listOfLicense
      }
    })
  }
  handleResetPassword()
  {
    const dialogRef = this.dialog.open(DialogResetPassword, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        element:this.id,
        selectedTable: this.data.selectedTable

      }
    })
  }
  handleDeleteEnterpriseUser()
  {
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
          heading: 'Are you sure you want to remove this Enterprise User?',
          element:this.id
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res==true){
      this.httpSv.deleteUser(this.role,this.id,)?.subscribe((res: any) => {
        if (res['status']) {

          this.dialogRef.close(true)
        }
        else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 })
      })
    }
    })
   
  }
  listOfLicense:any[] = []
  getUserDtls(id: any) {
    this.httpSv.getEnterpriseUserDtls(id,this.role)?.subscribe((res: any) => {
      if (res['status']) {
        const temp = res['results'][0]
        this.name = temp.name
        this.email = temp.email
        this.number = temp.mobile
        this.compName = temp.company_name
        this.state = temp.state
        this.address = temp.address
        this.city = temp.city
        this.txtStatus=temp.status;
        this.assign_account_manager=temp.assign_account_manager
        this.id=temp.id
        this.assign_business_manager=temp.assign_business_manager
        this.listOfLicense = temp.fssai_lic_no?temp.fssai_lic_no  : []
        this.dataShare.userStorage = temp.size ? temp.size : 0
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  update()
  {
    if(this.city && this.name!=''){
    if(this.name && this.name!='')
    {
    if(this.email && this.email!='')
    {
      if(this.email.match(emailRegex))
      {
      if(this.number && this.number!='')
      {
        if(this.number.toString().length==10)
        {
          if(this.compName && this.compName!='')
          {
            if(true)
            {
              if(this.state && this.state!='')
              {
                // if(this.assign_account_manager && this.assign_account_manager!='')
                // {
                  // if(this.assign_business_manager && this.assign_business_manager!='')
                  // {
                    let data={
                      name:this.name,
                      city:this.city,
                      email:this.email,
                      mobile:this.number,
                      company_name:this.compName,
                      address:this.address,
                      state:this.state,
                      business_manager_id:this.assign_business_manager?this.assign_business_manager.id:0,
                      account_manager_id:this.assign_account_manager?this.assign_account_manager.id:0,
                      status:this.txtStatus
                    }
                    this.httpSv.updateEntripriseUser(this.id,data).subscribe((res:any)=>{
                      if(res['status'])
                      {
                        this.dialogRef.close(true)
                      }
                      else{
                        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                      }
                    },(err:any)=>{
                      this.toster.error(err.error.message, '', { timeOut: 2000 })
                    })

                  // }
                  // else {
                  //   this.toster.error('Please select Bussiness Manager', '', { timeOut: 2000 })
                  // }
                // }
                // else {
                //   this.toster.error('Please select Account Manager', '', { timeOut: 2000 })
                // }
              }
              else {
                this.toster.error('Please enter State', '', { timeOut: 2000 })
              }
            }
            else {
              this.toster.error('Please enter Address', '', { timeOut: 2000 })
            }
          }
          else {
            this.toster.error('Please enter Company Name', '', { timeOut: 2000 })
          }
        }
        else {
          this.toster.error('Please enter a valid number', '', { timeOut: 2000 })
        }
        }
        else{
          this.toster.error('Please enter number', '', { timeOut: 2000 })
        }
      }
      else {
        this.toster.error('Please enter a valid Email', '', { timeOut: 2000 })
      }
    }
    else{
      this.toster.error('Please enter email', '', { timeOut: 2000 })
    }
    }
    else {
      this.toster.error('Please enter Name', '', { timeOut: 2000 })
    }
    }
    else{
      this.toster.error('Please enter City', '', { timeOut: 2000 })
    }
  }

  updateAMEnterpriseUser(){
    if(this.city && this.city!=''){
    if(this.name && this.name!=''){
      if(this.email && this.email!=''){
        if(this.email.match(emailRegex)){
          if(this.number && this.number!=''){
            if(this.number.toString().length==10){
              if(this.compName && this.compName!=''){
                if(true){
                  if(this.state && this.state!=''){
                    // if(this.assign_account_manager && this.assign_account_manager!=''){
                      // if(this.assign_business_manager && this.assign_business_manager!=''){
                        let data={
                          name:this.name,
                          city:this.city,
                          email:this.email,
                          mobile:this.number,
                          company_name:this.compName,
                          address:this.address,
                          state:this.state,
                          business_manager_id:this.assign_business_manager?this.assign_business_manager.id:0,
                          account_manager_id:this.assign_account_manager?this.assign_account_manager.id:0,
                          status:this.txtStatus
                        }
                        this.httpSv.updateAMEntripriseUser(this.id,data).subscribe((res:any)=>{
                          if(res['status'])
                          {
                            this.dialogRef.close(true)
                          }
                          else{
                            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                          }
                        },(err:any)=>{
                          this.toster.error(err.error.message, '', { timeOut: 2000 })
                        })

                      // } else{ this.toster.error('Please select Bussiness Manager', '', { timeOut: 2000 }) }
                    // } else{ this.toster.error('Please select Account Manager', '', { timeOut: 2000 }) }
                  } else{ this.toster.error('Please enter State', '', { timeOut: 2000 }) }
                } else{ this.toster.error('Please enter Address', '', { timeOut: 2000 }) }
              } else{ this.toster.error('Please enter Company Name', '', { timeOut: 2000 }) }
            } else{ this.toster.error('Please enter a valid number', '', { timeOut: 2000 }) }
          } else{ this.toster.error('Please enter number', '', { timeOut: 2000 }) }
        } else{ this.toster.error('Please enter a valid Email', '', { timeOut: 2000 }) }
      } else{ this.toster.error('Please enter email', '', { timeOut: 2000 }) }
    } else{ this.toster.error('Please enter Name', '', { timeOut: 2000 }) }
  } else{ this.toster.error('Please enter City', '', { timeOut: 2000 }) }
}


  close() {
    this.dialogRef.close(false)
  }

}


//--------------------------------------------Retail_modify_dialog------------------------------------------------------
//--------------------------------------------Retail_bm_add_modify_dialog------------------------------------------------------
//--------------------------------------------Retail_modify_dialog------------------------------------------------------
//--------------------------------------------Retail_modify_dialog------------------------------------------------------
//--------------------------------------------Retail_modify_dialog------------------------------------------------------
@Component({
  selector: 'retail-modify-dialog',
  templateUrl: './retail_modify_dialog.html',
  styleUrls: ['./web-user.component.css']
})
export class DialogRetailModify implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogRetailModify>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toster: ToastrService,
    private httpSv: HttpService,
    private dataShare:DataService

  ) { }
  role:any;
  allState:any[]=[]
  ngOnInit(): void {
    this.dataShare.allStatesOfIndia.forEach((ele: any, index: any) => {
      this.allState.push({ id: index + 1, name: ele })
    });
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
    this.role = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
  }
  this.getUserDtls(this.data.id)
  this.retailLicenseLimit = this.dataShare.retailLicenseCount
  }
  stepperFlag: any = {
    personal_dtl: true,
    assign_beu: false
  }
  listOfLicense:any[] = []
  id:any;
  name: string = ''
  email: string = ''
  city: string = ''
  size :any;
  retailLicenseLimit:any = 0
  number: string = ''
  compName: string = ''
  address: string = ''
  assignAccoutnManager:any;
  state: any = ''
  // handleStepMover(type: string) {
  //   if (type == 'p_dtl' && !this.stepperFlag['personal_dtl'])
  //     this.stepperFlag = {
  //       personal_dtl: true,
  //       assign_beu: false,
  //     }
  //   else if (type == 'ass_beu' && !this.stepperFlag['assign_bm'])
  //     this.stepperFlag = {
  //       personal_dtl: false,
  //       assign_beu: true,
  //     }

  //   else return
  // }
  selectedFilter: any = {
    active: false,
    expired: false,
    requested: false
  }
  txtStatus: string = ''
  filterByStatus(order: any) {
    switch (order) {

      case 'Active':
        this.selectedFilter = {
          active: true,
          expired: false,
          requested: false
        }
        this.txtStatus = 'Active'
        break;
      case 'Inactive':
        this.selectedFilter = {
          active: false,
          expired: true,
          requested: false
        }
        this.txtStatus = 'Inactive'
        break;
      case 'Requested':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: true
        }
        this.txtStatus = 'Requested'
        break;
    }

  }
  handleAddNewLicense()
  {
    const dialogRef = this.dialog.open(DialogAppLicenseModify, {
      hasBackdrop: true,
      disableClose: true,
      data:
      {
        size : this.size,
        isAddNew:true,
        type:'retail',
        id: this.id
      }
    }
    ).afterClosed().subscribe((res:any)=>{
      if(res == 'close'){
        this.close()
      }
    })
  }
  getUserDtls(id: any) {
    if(this.role == '1'){
      this.httpSv.getRetailUserDtls(id,this.role)?.subscribe((res: any) => {
        if (res['status']) {
          const temp = res['results'][0]
          this.id=temp.id,
          this.name = temp.name
          this.city = temp.city
          this.email = temp.email
          this.number = temp.mobile
          this.compName = temp.company_name
          this.size = temp.size? temp.size : 0
          this.dataShare.userStorage = temp.size? temp.size : 0
          this.state = temp.state
          this.address = temp.address
          this.txtStatus=temp.status
          this.assignAccoutnManager=temp.assign_account_manager
          this.listOfLicense=temp.fssai_lic_no?temp.fssai_lic_no : []
        }
        else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 })
      })
    } else if(this.role == '2'){
      this.httpSv.getAMRetailUserDtls(id)?.subscribe((res: any) => {
        if (res['status']) {
          const temp = res['results'][0]
          this.id=temp.id,
          this.name = temp.name
          this.size = temp.size? temp.size : 0
          this.dataShare.userStorage = temp.size? temp.size : 0
          this.city = temp.city
          this.email = temp.email
          this.number = temp.mobile
          this.compName = temp.company_name
          this.listOfLicense=temp.fssai_lic_no ? temp.fssai_lic_no : []
          this.state = temp.state
          this.address = temp.address
          this.txtStatus=temp.status
        }
        else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 })
      })
    }
  }
  handleOpenLicense(id:any)
  {
    const dialogRef = this.dialog.open(DialogAppLicenseModify, {
      hasBackdrop: true,
      disableClose: true,
      data:
      {
        licensesNo:id,
        type:'retail',
        isView:true
       }}
    ).afterClosed().subscribe((res:any)=>{
      if(res == 'close'){
        this.close()
      }
    })
  }
  handleChangePasswordRetailUser()
  {
    const dialogRef = this.dialog.open(DialogResetPassword, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        element:this.id
      }
    })
  }
  handleDeleteRetailUser()
  {
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
          heading: 'Are you sure you want to remove this Retail User?',
          element:this.id
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res==true){
      this.httpSv.deleteUser(this.role,this.id,'retail')?.subscribe((res: any) => {
        if (res['status']) {
          this.dialogRef.close(true)
        }
        else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 })
      })
    }
    })
  }
  close() {
    this.dialogRef.close(false)
  }
  handleAssignment(type: any, bmId: any, id: any) {
    const dialogRef = this.dialog.open(DialogAssignment, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        type,
        bmId,
        id,
        from:'retail',
        ismodify:true
      }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.status) {
          this.assignAccoutnManager=result.data
      }
    })
  }
  update()
  {
  if(this.city && this.city!='')
    {
    if(this.name && this.name!='')
    {
      if (this.email && this.email != '') {
        if (this.email.match(emailRegex)) 
        {
          if(this.number && this.number!='')
          {
          if(this.number.toString().length==10)
          {
            if(this.compName && this.compName!='')
            {
              if(true)
              {
                if(this.state && this.state!='')
                {
                  if(this.txtStatus && this.txtStatus!='')
                  {
                    // if(this.assignAccoutnManager)
                  //  {
                      var dataa={
                      name:this.name,
                      city:this.city,
                      email:this.email,
                      mobile:this.number,
                      company_name:this.compName,
                      address:this.address,
                      state:this.state,
                      assign_account_manager:this.assignAccoutnManager ? this.assignAccoutnManager.id : 0,
                      status:this.txtStatus
                    }
                    this.httpSv.updateRetailUser(this.id,this.role,dataa)?.subscribe((res:any)=>{
                      if(res['status'])
                      {
                        this.dialogRef.close(true)
                        this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                      }
                      else
                      {
                        this.toster.error('Details not updated.', '', { timeOut: 2000 })
                        this.dialogRef.close()

                      }
                    },(err:any)=>{
                      this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                      this.dialogRef.close(false)                    })
                  // }
                  // else{
                  //   this.toster.error('Please assign an Account Manager', '', { timeOut: 2000 })
                  // }
                  }
                  else{
                    this.toster.error('Please select status', '', { timeOut: 2000 })
                  }
                }
                else{
                  this.toster.error('Please select state', '', { timeOut: 2000 })
                }
              }
              else{
                this.toster.error('Please enter City', '', { timeOut: 2000 })
              }
            }
            else{
              this.toster.error('Please enter Company Name', '', { timeOut: 2000 })
            }
          }
          else{
            this.toster.error('Please enter a valid number', '', { timeOut: 2000 })
          }
        }
        else{
          this.toster.error('Please enter Number', '', { timeOut: 2000 })
        }
        }
        else{
          this.toster.error('Please enter a valid Email ID', '', { timeOut: 2000 })
        }
      }
      else{
        this.toster.error('Please enter Email Id', '', { timeOut: 2000 })
      }
    }
    else{
      this.toster.error('Please enter Name', '', { timeOut: 2000 })
    }
  }
  else{
      this.toster.error('Please enter City', '', { timeOut: 2000 })
  }
}



  updateAMRetailUser(){
    if(this.city && this.city!=''){
    if(this.name && this.name!=''){
      if (this.email && this.email != '') {
        if (this.email.match(emailRegex)){
          if(this.number && this.number!=''){
          if(this.number.toString().length==10){
            if(this.compName && this.compName!=''){
              if(true){
                if(this.state && this.state!=''){
                  if(this.txtStatus && this.txtStatus!=''){
                      var dataa={
                      name:this.name,
                      city:this.city,
                      email:this.email,
                      mobile:this.number,
                      company_name:this.compName,
                      address:this.address,
                      state:this.state,
                      status:this.txtStatus
                    }
                    this.httpSv.updateAMRetailUser(this.id,dataa)?.subscribe((res:any)=>{
                      if(res['status']){
                        this.dialogRef.close(true)
                        this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                      } else {
                        this.toster.error('Details not updated.', '', { timeOut: 2000 })
                        this.dialogRef.close()
                      }
                    },(err:any)=>{
                      this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                      this.dialogRef.close(false)                    })
                  } else{ this.toster.error('Please select status', '', { timeOut: 2000 }) }
                } else{ this.toster.error('Please select state', '', { timeOut: 2000 }) }
              } else{ this.toster.error('Please enter City', '', { timeOut: 2000 }) }
            } else{ this.toster.error('Please enter Company Name', '', { timeOut: 2000 }) }
          } else{ this.toster.error('Please enter a valid number', '', { timeOut: 2000 }) }
        } else{ this.toster.error('Please enter Number', '', { timeOut: 2000 }) }
        } else{ this.toster.error('Please enter a valid Email ID', '', { timeOut: 2000 }) }
      } else{ this.toster.error('Please enter Email Id', '', { timeOut: 2000 }) }
    } else{ this.toster.error('Please enter Name', '', { timeOut: 2000 }) }
  } else{ this.toster.error('Please enter City', '', { timeOut: 2000 }) }
}


}






// ----------- Business_Manager_modify ---------------------
// ----------- Business_Manager_modify ---------------------
// ----------- Business_Manager_modify ---------------------


@Component({
  selector: 'bm-modify-dialog',
  templateUrl: './bm_modify_dialog.html',
  styleUrls: ['./web-user.component.css']
})
export class DialogBusinessManagerModify implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogRetailModify>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toster: ToastrService,
    private httpSv: HttpService,
    private dataShare:DataService

  ) { }
  role:any;
  allState:any[]=[]
  ngOnInit(): void {
    this.dataShare.allStatesOfIndia.forEach((ele: any, index: any) => {
      this.allState.push({ id: index + 1, name: ele })
    });
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
    this.role = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
  }
  this.getBusinessManagerDtls(this.data.id)
  }
  stepperFlag: any = {
    personal_dtl: true,
    assign_beu: false
  }

  id:any;
  name: string = ''
  email: string = ''
  number: string = ''
  compName: string = ''
  city: string = ''
  address: string = ''
  assignAccoutnManager:any;
  state: any = ''
  
  selectedFilter: any = {
    active: false,
    expired: false,
    requested: false
  }
  txtStatus: string = ''
  filterByStatus(order: any) {
    switch (order) {

      case 'Active':
        this.selectedFilter = {
          active: true,
          expired: false,
          requested: false
        }
        this.txtStatus = 'Active'
        break;
      case 'Inactive':
        this.selectedFilter = {
          active: false,
          expired: true,
          requested: false
        }
        this.txtStatus = 'Inactive'
        break;
      case 'Requested':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: true
        }
        this.txtStatus = 'Requested'
        break;
    }

  }

  getBusinessManagerDtls(id: any) {
    this.httpSv.getAMBusinessManagerDtls(id)?.subscribe((res: any) => {
      if (res['status']) {
        const temp = res['results'][0]
        this.id=temp.id,
        this.name = temp.name
        this.email = temp.email
        this.number = temp.mobile
        this.compName = temp.company_name
        this.city = temp.city
        this.state = temp.state
        this.address = temp.address
        this.txtStatus=temp.status
        this.assignAccoutnManager=temp.assign_account_manager
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  handleChangePassword()
  {
    const dialogRef = this.dialog.open(DialogResetPassword, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        element:this.id
      }
    })
  }
  handleDeleteUser()
  {
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
          heading: 'Are you sure you want to remove this Retail User?',
          element:this.id
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res==true){
      this.httpSv.deleteUser(this.role,this.id,'retail')?.subscribe((res: any) => {
        if (res['status']) {
          this.dialogRef.close(true)
        }
        else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 })
      })
    }
    })
  }
  close() {
    this.dialogRef.close(false)
  }
  
  update() { 
    if(this.city && this.city!='') {
    if(this.name && this.name!='') {
      if (this.email && this.email != '') {
        if (this.email.match(emailRegex))  {
          if(this.number && this.number!='') {
          if(this.number.toString().length==10) {
            if(this.compName && this.compName!='') {
              if(true) {
                if(this.state && this.state!='') {
                  if(this.txtStatus && this.txtStatus!='') {
                      var dataa={
                      name:this.name,
                      email:this.email,
                      mobile:this.number,
                      city:this.city,
                      company_name:this.compName,
                      address:this.address,
                      state:this.state,
                      status:this.txtStatus
                    }
                    this.httpSv.updateAMBusinesseManager(this.id, dataa)?.subscribe((res:any)=>{
                      if(res['status']) {
                        this.dialogRef.close(true)
                        this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                      } else {
                        this.toster.error('Details not updated.', '', { timeOut: 2000 })
                        this.dialogRef.close()
                      }
                    },(err:any)=>{
                      this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                      this.dialogRef.close(false)                    
                    })
                  } else{ this.toster.error('Please select status', '', { timeOut: 2000 }) }
                } else{ this.toster.error('Please select state', '', { timeOut: 2000 }) }
              } else{ this.toster.error('Please enter City', '', { timeOut: 2000 }) }
            } else{ this.toster.error('Please enter Company Name', '', { timeOut: 2000 }) }
          } else{ this.toster.error('Please enter a valid number', '', { timeOut: 2000 }) }
        } else{ this.toster.error('Please enter Number', '', { timeOut: 2000 }) }
        } else{ this.toster.error('Please enter a valid Email ID', '', { timeOut: 2000 }) }
      } else{ this.toster.error('Please enter Email Id', '', { timeOut: 2000 }) }
    } else{ this.toster.error('Please enter Name', '', { timeOut: 2000 }) }
  } else{ this.toster.error('Please enter City', '', { timeOut: 2000 }) }
  }
}




//--------------------------------------------------------------------------------------------------
//--------------------------------------------Add_Users_dialog------------------------------------------------------
//--------------------------------------------Add_Users_dialog------------------------------------------------------
//--------------------------------------------Add_Users_dialog------------------------------------------------------
@Component({
  selector: 'add-newUser-dialog',
  templateUrl: './add_new_user.html',
  styleUrls: ['./web-user.component.css']
})
export class DialogAddNewUser implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogAddNewUser>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toster: ToastrService,
    private httpSv: HttpService,
    private dataShare: DataService
  ) { }
  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      this.roleId = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
      if(this.roleId==2)
      this.getAccManagerBMList();
    }
    else {
      // this.toster.error('Opps! something went wrong', '', { timeOut: 2000 })
    }

    this.allState = this.dataShare.allStatesOfIndia
    if(this.roleId == '2'){
      this.getAccManagerBMList();
    }

  }
  passwordToggleHide: boolean = true
  conpasswordToggleHide: boolean = true
  roleId: any;
  type: string = 'beu'
  allState: any[] = []
  selectedState: any
  accManagerBMList: any[] = []
  beuSelectedBusinessManager: any
  close() {
    this.dialogRef.close(false)
  }

  compareFn(item:any, selected:any) {
    return item.value === selected.name;
  }

  onBusinessManagerChange(event:any){
    this.beuSelectedBusinessManager = event;
  }

  getAccManagerBMList(){
    return this.httpSv.getBusinessManagerList().subscribe((res:any) => {
      if(res['status']){
        res.results.forEach((bm:any) => {
          this.accManagerBMList = [...this.accManagerBMList, {id: bm.id, name: bm.name}]
        })
      } else{
        this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      if(err.error.message=='no data found')
      this.accManagerBMList = [];
      if(err.error.message != 'no data found')
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }


  //           ------------------           BEU     ----------------------// 
  beuName: string = ''
  beuEmail: string = ''
  beuNumber: string = ''
  beuAddress: string = ''
  beuCompName: string = ''
  beuCity: string = ''
  beuSelectedState: any
  beuPassword: string = ''
  beuConfirmPassword: string = ''
  addBeuUser() {
    if (this.beuAddress && this.beuAddress != '') {
    if (this.beuName && this.beuName != '') {
      if (this.beuEmail && this.beuEmail != '') {
        if (this.beuEmail.match(emailRegex)) {
          if (this.beuNumber && this.beuNumber != '') {
            if (this.beuNumber.toString().length == 10) {
              if (this.beuCompName && this.beuCompName != '') {
                if (this.beuCity && this.beuCity != '') {
                  if (this.beuSelectedState) {
                    if (this.beuPassword && this.beuPassword != '') {
                      if (this.beuConfirmPassword && this.beuConfirmPassword != '') {
                        if (this.beuPassword == this.beuConfirmPassword) {
                          if(this.roleId == '1'){
                            const data = {
                              name: this.beuName,
                              email: this.beuEmail,
                              mobile: this.beuNumber,
                              company_name: this.beuCompName,
                              address: this.beuAddress,
                              city: this.beuCity,
                              state: this.beuSelectedState,
                              password: this.beuPassword
                            }
                            this.httpSv.createEnterpriseUser(data).subscribe((res: any) => {
                              if (res['status']) {
                                var data={
                                  status:true,
                                  from:'enterprise'
                                }
                                this.toster.success('You have created a new Enterprise User successfully.', '', { timeOut: 2000 })
                                this.dialogRef.close(data)
                              } else {
                                var data={
                                  status:false,
                                  from:'enterprise'
                                }
                                this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                                this.dialogRef.close(data)
                              }
                            }, (err: any) => {
                              this.toster.error(err.error.message, '', { timeOut: 2000 })
                            })
                          } else if(this.roleId == '2') {
                            if(this.beuSelectedBusinessManager) {
                              const data = {
                                name: this.beuName,
                                email: this.beuEmail,
                                mobile: this.beuNumber,
                                company_name: this.beuCompName,
                                city: this.beuCity,
                                address: this.beuAddress,
                                state: this.beuSelectedState,
                                business_manager_id: this.beuSelectedBusinessManager.id,
                                password: this.beuPassword
                              }                             
                              this.httpSv.createAMEnterpriseUser(data).subscribe((res: any) => {
                                if (res['status']) {
                                  var data={
                                    status:true,
                                    from:'enterprise'
                                  }
                                  this.toster.success('You have created a new Enterprise User successfully.', '', { timeOut: 2000 })
                                  this.dialogRef.close(data)
                                } else {
                                  var data={
                                    status:false,
                                    from:'enterprise'
                                  }
                                  this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                                  this.dialogRef.close(data)
                                }
                              }, (err: any) => {
                                this.toster.error(err.error.message, '', { timeOut: 2000 })
                              })
                            } else {
                              this.toster.error('Please assign business manager', '', { timeOut: 2000 })
                            }
                          }
                        } else {
                          this.toster.error('Password does not match', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please confirm the password', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter password', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please select state', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter city name', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter company name', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
          }
        } else {
          this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter email id', '', { timeOut: 2000 })
      }
    } else {
      this.toster.error('Please enter name', '', { timeOut: 2000 })
    }
  } else {
    this.toster.error('Please enter Address', '', { timeOut: 2000 })
  }
  }
    //           ------------------           Retail     ----------------------// 
    retailName: string = ''
    retailEmail: string = ''
    retailNumber: string = ''
    retailCompName: string = ''
    retailAddress: string = ''
    retailCity: string = ''
    retailSelectedState: any
    retailPassword: string = ''
    retailConfirmPassword: string = ''
  addRetail()
  {
    if (this.retailAddress && this.retailAddress != '') {
    if (this.retailName && this.retailName != '') {
      if (this.retailEmail && this.retailEmail != '') {
        if (this.retailEmail.match(emailRegex)) {
          if (this.retailNumber && this.retailNumber != '') {
            if (this.retailNumber.toString().length == 10) {
              if (this.retailCompName && this.retailCompName != '') {
                if (this.retailCity && this.retailCity != '') {
                  if (this.retailSelectedState) {
                    if (this.retailPassword && this.retailPassword != '') {
                      if (this.retailConfirmPassword && this.retailConfirmPassword != '') {
                        if (this.retailPassword == this.retailConfirmPassword) {
                          const data = {
                            name: this.retailName,
                            email: this.retailEmail,
                            mobile: this.retailNumber,
                            company_name: this.retailCompName,
                            address: this.retailAddress,
                            city: this.retailCity,
                            state: this.retailSelectedState,
                            password: this.retailPassword
                          }
                          if(this.roleId == '1'){
                            this.httpSv.createRetailUser(data).subscribe((res: any) => {
                              if (res['status']) {
                                var data={
                                  status:true,
                                  from:'retail'
                                }
                                this.toster.success('You have created a new Retail User successfully.', '', { timeOut: 2000 })
                                this.dialogRef.close(data)
                              } else {
                                var data={
                                  status:false,
                                  from:'retail'
                                }
                                this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                                this.dialogRef.close(data)
                              }
                            }, (err: any) => {
                              this.toster.error(err.error.message, '', { timeOut: 2000 })
                            })
                          } else if(this.roleId == '2'){
                            this.httpSv.createAMRetailUser(data).subscribe((res: any) => {
                              if (res['status']) {
                                var data={
                                  status:true,
                                  from:'retail'
                                }
                                this.toster.success('You have created a new Retail User successfully.', '', { timeOut: 2000 })
                                this.dialogRef.close(data)
                              } else {
                                var data={
                                  status:false,
                                  from:'retail'
                                }
                                this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                                this.dialogRef.close(data)
                              }
                            }, (err: any) => {
                              this.toster.error(err.error.message, '', { timeOut: 2000 })
                            })
                          }
                        } else {
                          this.toster.error('Password does not match', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please confirm the password', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter password', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please select state', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter city name', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter company name', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
          }
        } else {
          this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter email id', '', { timeOut: 2000 })
      }
    } else {
      this.toster.error('Please enter name', '', { timeOut: 2000 })
    }
  } else {
    this.toster.error('Please enter Address', '', { timeOut: 2000 })
  }
  }
      //           ------------------           BM     ----------------------// 
      bmName: string = ''
      bmEmail: string = ''
      bmNumber: string = ''
      bmCompName: string = ''
      bmCity: string = ''
      bmSelectedState: any
      bmPassword: string = ''
      bmConfirmPassword: string = ''
      addBM()
      {
        if (this.bmName && this.bmName != '') {
          if (this.bmEmail && this.bmEmail != '') {
            if (this.bmEmail.match(emailRegex)) {
              if (this.bmNumber && this.bmNumber != '') {
                if (this.bmNumber.toString().length == 10) {
                  if (this.bmCompName && this.bmCompName != '') {
                    if (this.bmCity && this.bmCity != '') {
                      if (this.bmSelectedState) {
                        if (this.bmPassword && this.bmPassword != '') {
                          if (this.bmConfirmPassword && this.bmConfirmPassword != '') {
                            if (this.bmPassword == this.bmConfirmPassword) {
                              const data = {
                                name: this.bmName,
                                email: this.bmEmail,
                                mobile: this.bmNumber,
                                company_name: this.bmCompName,
                                address: this.bmCity,
                                state: this.bmSelectedState,
                                password: this.bmPassword
                              }
                              if(this.roleId == '1'){
                                this.httpSv.createNewBM(data).subscribe((res: any) => {
                                  if (res['status']) {
                                    var data={
                                      status:true,
                                      from:'bm'
                                    }
                                    this.toster.success('You have created a new Business Manager successfully.', '', { timeOut: 2000 });
                                    this.dialogRef.close(data)
                                  } else {
                                    var data={
                                      status:false,
                                      from:'bm'
                                    }
                                    this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                                    this.dialogRef.close(data)
                                  }
                                }, (err: any) => {
                                  this.toster.error(err.error.message, '', { timeOut: 2000 })
                                })
                              } else if(this.roleId == '2'){
                                this.httpSv.createAMNewBM(data).subscribe((res: any) => {
                                  if (res['status']) {
                                    var data={
                                      status:true,
                                      from:'bm'
                                    }
                                    this.toster.success('You have created a new Business Manager successfully.', '', { timeOut: 2000 });
                                    this.dialogRef.close(data)
                                  } else {
                                    var data={
                                      status:false,
                                      from:'bm'
                                    }
                                    this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                                    this.dialogRef.close(data)
                                  }
                                }, (err: any) => {
                                  this.toster.error(err.error.message, '', { timeOut: 2000 })
                                })
                              }
                            } else {
                              this.toster.error('Password does not match', '', { timeOut: 2000 })
                            }
                          } else {
                            this.toster.error('Please confirm the password', '', { timeOut: 2000 })
                          }
                        } else {
                          this.toster.error('Please enter password', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please select state', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter city name', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please enter company name', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter email id', '', { timeOut: 2000 })
          }
        } else {
          this.toster.error('Please enter name', '', { timeOut: 2000 })
        }
      }
  //           ------------------           AM     ----------------------// 
  amName: string = ''
  amEmail: string = ''
  amNumber: string = ''
  amCity: string = ''
  amSelectedState: any
  amPassword: string = ''
  amConfirmPassword: string = ''
  addAm(){
    if (this.amName && this.amName != '') {
      if (this.amEmail && this.amEmail != '') {
        if (this.amEmail.match(emailRegex)) {
          if (this.amNumber && this.amNumber != '') {
            if (this.amNumber.toString().length == 10) {
              // if (this.amCompName && this.amCompName != '') {
                if (this.amCity && this.amCity != '') {
                  if (this.amSelectedState) {
                    if (this.amPassword && this.amPassword != '') {
                      if (this.amConfirmPassword && this.amConfirmPassword != '') {
                        if (this.amPassword == this.amConfirmPassword) {
                          const data = {
                            name: this.amName,
                            email: this.amEmail,
                            mobile: this.amNumber,
                            address: this.amCity,
                            state: this.amSelectedState,
                            password: this.amPassword
                          }
                          this.httpSv.CreateNewAmUser(data).subscribe((res: any) => {
                            if (res['status']) {
                              var data={
                                status:true,
                                from:'am'
                              }
                              this.dialogRef.close(data)
                            } else {
                              var data={
                                status:false,
                                from:'am'
                              }
                              this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                              this.dialogRef.close(data)
                            }
                          }, (err: any) => {
                            this.toster.error(err.error.message, '', { timeOut: 2000 })
                          })
                        } else {
                          this.toster.error('Password does not match', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please confirm the password', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter password', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please select state', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter city name', '', { timeOut: 2000 })
                }
              // } else {
              //   this.toster.error('Please enter company name', '', { timeOut: 2000 })
              // }
            } else {
              this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
          }
        } else {
          this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter email id', '', { timeOut: 2000 })
      }
    } else {
      this.toster.error('Please enter name', '', { timeOut: 2000 })
    }
  }
  onMaterialGroupChange(event: any) {
    // console.log(event);
  }
  addNewUser() {
    switch (this.type) {
      case 'beu':
        this.addBeuUser()
        break
      case 'retail':
        this.addRetail()
        break
      case 'BM':
        this.addBM()
        break
      case 'AM':
        this.addAm()
        break

    }
  }
}





//--------------------------------------------assgiment_dialog------------------------------------------------------
//--------------------------------------------assgiment_dialog------------------------------------------------------
//--------------------------------------------assgiment_dialog------------------------------------------------------
//--------------------------------------------assgiment_dialog------------------------------------------------------
@Component({
  selector: 'assignment-dialog',
  templateUrl: './assignment_dialog.html',
  styleUrls: ['./web-user.component.css']
})
export class DialogAssignment implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogAssignment>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toster: ToastrService,
    private httpSv: HttpService,
    private dataShare: DataService,
  ) { }
  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      this.roleId = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
    }
    console.log("tss",this.roleId)
    this.type = this.data.type
    this.id = this.data.bmId
    if (this.type == 'bm')
      this.getAllBM()
    else if (this.type == 'am')
      this.getAllAm()
  }
  type: any
  id: any
  apiData: any[] = []
  options: any[] = []
  roleId:any;
  noneData = { id: 0, name :'None'}
  getAllBM() {
    this.httpSv.getAllBmList(this.roleId).subscribe((res: any) => {
      if (res['status']) {
        this.options = res['results']
        this.options.unshift(this.noneData)
        this.apiData = JSON.parse(JSON.stringify(res['results']))
        // this.filteredOptions = this.options
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }

    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  getAllAm(){
    this.httpSv.getAllAmList().subscribe((res: any) => {
      if (res['status']) {
        this.options = res['results']
        this.options.unshift(this.noneData)
        this.apiData = JSON.parse(JSON.stringify(res['results']))
        // for (let i = 0; i < 20; i++) {
        //   this.options.push(res['results'][0])
        // }
        // this.filteredOptions = this.options
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }

    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  applyFilter(event: any) {
    if (event.target.value)
      this.options = this.apiData.filter((ele: any) => ele.name.toLowerCase().includes(event.target.value.toLowerCase()))
    else
      this.options = this.apiData
  }
  changeAssignment(id:any ,name:any) {
    // FOR EDIT EU USER ASSIGN AM DIRECTLY IN DIALOGUE OPEN
    if(this.data.ismodify && this.type == 'am')
    {
      let closure={
        status:true,
        data:{
          id:id ? id : 0,
          name:name
        }
      }
      this.dialogRef.close(closure)
      return;
    }
    if (id == this.id) this.close()
    else {
      if (this.type == 'bm') {
        const data = {
          business_manager_id: id,
          enterprise_user_id: this.data.id
        }
        this.httpSv.assignBMToEU(data).subscribe((res: any) => {
          if (res['status']) {
            // FOR EDIT EU USER ASSIGN BM DIRECTLY IN DIALOGUE OPEN
            if(this.data.ismodify)
              {
                let closure={
                  status:true,
                  dataBM:{
                    id:id,
                    name:name
                  },
                  dataAM:{
                    id:res['results'].id ? res['results'].id : 0,
                    name:res['results'].name ? res['results'].name : 'None'
                  }
                }
                this.dialogRef.close(closure)
                return;
              }
            this.dialogRef.close(true)
          } else {
            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
            this.close()
          }
        }, (err: any) => {
          this.toster.error(err.error.message, '', { timeOut: 2000 })
          this.close()
        })
      }else if(this.type == 'am'){
        if(this.data.from=='sa')
        {
          const data = {
            account_manager_id: id,
            business_manager_id: this.data.id
          }
          this.httpSv.assignBMToAM(data).subscribe((res: any) => {
            if (res['status']) {
              this.dialogRef.close(true)
            } else {
              this.toster.error('Oops! Something went weong', '', { timeOut: 2000 })
              this.close()
            }
          }, (err: any) => {
            this.toster.error(err.error.message, '', { timeOut: 2000 })
            this.close()
          })
        }
        else if(this.data.from=='retail')
        {
          const data = {
            am_user_id: id,
            retail_user_id: this.data.id
          }
          this.httpSv.assignBMToRU(data).subscribe((res: any) => {
            if (res['status']) {
              this.dialogRef.close(true)
            } else {
              this.toster.error('Oops! Something went weong', '', { timeOut: 2000 })
              this.close()
            }
          }, (err: any) => {
            this.toster.error(err.error.message, '', { timeOut: 2000 })
            this.close()
          })
        }
        else{
          const data = {
            account_manager_id: id,
            enterprise_user_id: this.data.id
          }
          this.httpSv.assignAMToEU(data).subscribe((res: any) => {
            if (res['status']) {
              this.dialogRef.close(true)
            } else {
              this.toster.error('Oops! Something went weong', '', { timeOut: 2000 })
              this.close()
            }
          }, (err: any) => {
            this.toster.error(err.error.message, '', { timeOut: 2000 })
            this.close()
          })
        }
      }
    }
  }
  close() {
    this.dialogRef.close(false)
  }
}
















//--------------------------------------------List the Licences Super Admin------------------------------------------------------
//--------------------------------------------List the Licences Super Admin------------------------------------------------------
//--------------------------------------------List the Licences Super Admin------------------------------------------------------
//--------------------------------------------List the Licences Super Admin------------------------------------------------------
@Component({
  selector: 'assignment-dialog',
  templateUrl: './list_license_dialog.html',
  styleUrls: ['./web-user.component.css']
})
export class DialogViewLicense implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogViewLicense>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toster: ToastrService,
    private httpSv: HttpService,
    private dataShare :DataService
  ) { }
  ngOnInit(): void {
    this.type = this.data.type
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
    this.role = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
    }
    this.id = this.data.bmId
    if(this.data.listLicenses)
      this.listLicenses=this.data.listLicenses;
    if (this.type == 'bm')
      this.getAllBM()
    else if (this.type == 'am')
      this.getAllAm()
  }
  type: any
  id: any
  role:any;
  apiData: any[] = []
  options: any[] = []
  listLicenses:any=[];
  getAllBM() {
    this.httpSv.getAllBmList(this.role).subscribe((res: any) => {
      if (res['status']) {
        this.options = res['results']
        this.apiData = JSON.parse(JSON.stringify(res['results']))
        // this.filteredOptions = this.options
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }

    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  getAllAm(){
    this.httpSv.getAllAmList().subscribe((res: any) => {
      if (res['status']) {
        this.options = res['results']
        this.apiData = JSON.parse(JSON.stringify(res['results']))
        for (let i = 0; i < 20; i++) {
          this.options.push(res['results'][0])
        }
        // this.filteredOptions = this.options
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }

    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  applyFilter(event: any) {
    if (event.target.value)
      this.options = this.apiData.filter((ele: any) => ele.name.toLowerCase().includes(event.target.value.toLowerCase()))
    else
      this.options = this.apiData
  }
  changeAssignment(id: any) {
    if (id == this.id) this.close()
    else {
      if (this.type == 'bm') {
        const data = {
          business_manager_id: id,
          enterprise_user_id: this.data.id
        }
        this.httpSv.assignBMToEU(data).subscribe((res: any) => {
          if (res['status']) {
            this.dialogRef.close(true)
          } else {
            this.toster.error('Oops! Something went weong', '', { timeOut: 2000 })
            this.close()
          }
        }, (err: any) => {
          this.toster.error(err.error.message, '', { timeOut: 2000 })
          this.close()
        })
      }else if(this.type == 'am'){
        const data = {
          account_manager_id: id,
          enterprise_user_id: this.data.id
        }
        this.httpSv.assignAMToEU(data).subscribe((res: any) => {
          if (res['status']) {
            this.dialogRef.close(true)
          } else {
            this.toster.error('Oops! Something went weong', '', { timeOut: 2000 })
            this.close()
          }
        }, (err: any) => {
          this.toster.error(err.error.message, '', { timeOut: 2000 })
          this.close()
        })
      }
    }
  }
  close() {
    this.dialogRef.close(false)
  }
}
