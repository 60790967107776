import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
    selector: 'delete-confirm-dialog',
    templateUrl: './delete_done_dialog.html',
    styleUrls: ['./delete_done_dialog.css']
  })
  export class DialogDeleteDonePopup implements OnInit {
    constructor(
      public dialogRef: MatDialogRef<DialogDeleteDonePopup>,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ){}
    ngOnInit(): void {
      setTimeout(() => {
        this.close()
      },3000)
    }
    close(){
        this.dialogRef.close(true)
    }
  }