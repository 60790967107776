import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DialogResetPassword } from '../common_dialog/reset-password/reset_password';
import { DialogDeleteCofirm } from '../common_dialog/delete-confirm/delete_confirm_dialog';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/api/http.service';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data-share/data.service';
import { DialogAssignment } from '../web-user/web-user.component';
import { DialogDeleteDonePopup } from '../common_dialog/delete-sucess-info/delete_done_dialog';
import {MatSort} from '@angular/material/sort';
import { NotificationsComponent } from '../common_dialog/notifications/notifications.component';
import { Router } from '@angular/router';
import { AES, enc } from 'crypto-js';



@Component({
  selector: 'app-account-manager',
  templateUrl: './account-manager.component.html',
  styleUrls: ['./account-manager.component.css']
})
export class AccountManagerComponent implements OnInit {
@ViewChild(MatSort) sort = new MatSort();

displayedColumns: string[] = ['sl_no', 'name', 'address','email','status','reset_password' ,'modify'];
data: any ;

dataSource: any = new MatTableDataSource();
apiData:any;
filterData:any;
notifications:any;
isNewNotifications:boolean = false
selectedFilter: any = {
  all: true,
  active: false,
  expired: false,
  requested: false
}
  constructor(
    private http:HttpService,
    private toaster:ToastrService,
    private dataShare : DataService,
    private router:Router,
    private dialog:MatDialog) { }

  ngOnInit(): void {
    this.getAm()
    this.getNotifications()
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      const roleId = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(enc.Utf8);
      this.role = roleId
      console.log(this.role, roleId)
    }
    setTimeout(async ()=>{
      this.photo = await localStorage.getItem('photo')
      this.username = await localStorage.getItem('username')
    },0)
  }
  role:any;
  username:any = 'user';
  photo: any = '../../../assets/img/default_dp.png'
  tPhoto: any = '../../../assets/img/default_dp.png'
  navigate(route: string) {
    if (this.router.url == route) return
    else this.router.navigate([route])
  }
  getNotifications(){
    this.http.getNotifications(37).subscribe((res:any)=>{
      if(res['status']){
        this.notifications = res['result']
        const newNotf = this.notifications.filter((data:any) =>  data.is_read === false)
        console.log(this.notifications,newNotf)
        if(newNotf.length > 0)
          this.isNewNotifications = true
        else
          this.isNewNotifications = false
      }
      else{
        this.notifications = []
        this.isNewNotifications = false
      }
    },(err:any)=>{
      this.toaster.error("Oops!, Somrthing went wrong Please try again",'',{timeOut:2000})
    })
  }
  @Output() messageEmitter = new EventEmitter<any>();
  moveTab(tab:any,to:any='Enterprise'){
    this.messageEmitter.emit(tab)
    this.dataShare.prefilter=to
  }
  readNotifications(element:any){
    this.moveTab('service',element.type == 'eu' ? 'Enterprise' : 'Retail')
    this.dataShare.dialogRef(element.service_request_id,element.type)
    let data = {
      id: element.id,
      is_read : 1
    }
    this.http.readNotification(data).subscribe((res:any)=>{
      if(res['status']){
        this.getNotifications()
      }
      else{
        this.toaster.error(res['message'],'',{timeOut:2000})
      }
    },(err:any)=>{
      this.toaster.error("Oops!, Somrthing went wrong Please try again",'',{timeOut:2000})
    })
  }
  showNotif:boolean = false
  toggleNotif(){
    this.dialog.open(NotificationsComponent,{
      data : this.notifications,
      position: {
        top: `5rem`,
        right: `25rem`,
      },
    })
  }
  getAm()
  {
    this.http.getAllAm().subscribe((res:any)=>{
      if(res['status'])
      {
        res['results'].map((data:any)=>{
          // if(data.status)
            // data.status=data.status.toLowerCase()
        })
        this.dataSource.data=res['results'];
        this.apiData=res['results'];
        this.filterData=res['results'];
        this.dataSource.sort = this.sort
        this.createEUDropDown(this.apiData)

      }
      else {
        this.toaster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      if(err.error.message=="no data found")
        this.dataSource.data=[]
      this.toaster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  EUEmailDropdown:any[]= []
  EUNameDropdown:any[] = []
  EUCityDropdown:any[] = []
  EUStatusDropdown:any[] = []
  EUNameFilter:any = ''
  EUCityFilter:any = ''
  EUEmailFilter:any = ''
  EUStatusFilter:any = ''
  createEUDropDown(tableData:any)
  {
    tableData.forEach((data:any)=>{
      if(data.name && !this.EUNameDropdown.includes(data.name.toLowerCase()))
        this.EUNameDropdown.push(data.name.toLowerCase())
      if(data.city && !this.EUCityDropdown.includes(data.city))
        this.EUCityDropdown.push(data.city)
      if(data.status && !this.EUStatusDropdown.includes(data.status))
        this.EUStatusDropdown.push(data.status)
      if(data.email && !this.EUEmailDropdown.includes(data.email))
        this.EUEmailDropdown.push(data.email)
    
    })
  }
  EuApplyFilter(data:any,from:any,remove:any=null)
  {
    if(from=='name'){
      this.EUNameFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.apiData;
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.city && data.city.toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUEmailFilter.length>0){
          filterData.map((data:any)=>{
                if(data.email && data.email.toLowerCase()==this.EUEmailDropdown)
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterData.map((apiData:any)=>{
              if(apiData.name && apiData.name.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterData = result
      this.dataSource.data = this.filterData

    }
    if(from=='city'){
      this.EUCityFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.apiData;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUEmailFilter.length>0){
          filterData.map((data:any)=>{
                if(data.email && data.email.toLowerCase()==this.EUEmailFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase()())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterData.map((apiData:any)=>{
              if(apiData.city && apiData.city.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterData = result
      this.dataSource.data = this.filterData

    }
    if(from=='email'){
      this.EUEmailFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.apiData;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.city && data.city.toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUStatusFilter.length>0){
          filterData.map((data:any)=>{
                if(data.status && data.status.toLowerCase()==this.EUStatusFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
        }
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterData.map((apiData:any)=>{
              if(apiData.email && apiData.email.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterData = result
      this.dataSource.data = this.filterData

    }
    if(from=='status'){
      this.EUStatusFilter=data
      let result:any=[];
      if(remove==1){
        let filterData=this.apiData;
        if(this.EUNameFilter.length>0){
          filterData.map((data:any)=>{
                if(data.name && data.name.toLowerCase()==this.EUNameFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUCityFilter.length>0){
          filterData.map((data:any)=>{
                if(data.city && data.city.toLowerCase()==this.EUCityFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
        if(this.EUEmailFilter.length>0){
          filterData.map((data:any)=>{
                if(data.email && data.email.slice(0,10).toLowerCase()==this.EUEmailFilter.toLowerCase())
                  result.push(data)    
                });
              filterData=result;
              result=[]
        }
 
        if(result.length==0)
          result=filterData
      }
      else{
        this.filterData.map((apiData:any)=>{
              if(apiData.status && apiData.status.toLowerCase()==data.toLowerCase())
                result.push(apiData)    
              });
      }
      this.filterData = result
      this.dataSource.data = this.filterData

    }
  }
  clearEUFilter()
  {
    this.EUStatusFilter='';
    this.EUNameFilter='';
    this.EUCityFilter='';
    this.EUEmailFilter='';
    this.dataSource.data = this.apiData

  }
  handelDeleteAM(id:any) {
    let dialogue=this.dialog.open(DialogDeleteCofirm,{
      disableClose: true,
      hasBackdrop: true,
      data:{
        heading:'Are you sure you want to remove this Account Manager?',
        element:id
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res){
        if(res){
          this.http.deleteAM(id).subscribe((res:any)=>{
            if(res['status'])
          {
          const dialogRef = this.dialog.open(DialogDeleteDonePopup, {
            hasBackdrop: true,
            disableClose: true,
            data: {
              heading: 'Account Manager Deleted'
            }
          })
          dialogRef.afterClosed().subscribe((result)=>{
            this.ngOnInit()
          })      
        }
        else {
          this.toaster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toaster.error(err.error.message, '', { timeOut: 2000 })
      })
        }
      }
    })
  }
  handleModifyAM(id:any)
  {
    let dialogue=this.dialog.open(DialogAddAM,{
      hasBackdrop: true,
      disableClose: true,
      data:{
        id
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res)
      this.getAm()
    })
  }
  resetPassword(element:any) {
    const dialogRef = this.dialog.open(DialogResetPassword, {
      disableClose: true,
      hasBackdrop: true,
      data:{
        element
      }
    })
  }
  selectedFilterDropdown :any ='All'
  filterByStatus(order:any){
    this.selectedFilterDropdown= order
    if (order == 'All')
      this.dataSource.data = this.apiData
    else if(order=='unassigned')
    {
      const tempData = this.apiData.filter((ele: any) => ele.assign == 'no')
      this.dataSource.data = tempData
    }
    else {
      const tempData = this.apiData.filter((ele: any) => ele.status.toLowerCase() == order.toLowerCase())
      this.dataSource.data = tempData
    }
    }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  addNewUser() {
    let dialogue=this.dialog.open(DialogAddAM,{
      hasBackdrop: true,
      disableClose: true,
    }).afterClosed().subscribe((res:any)=>{
      if(res)
      this.getAm()
    })
  } 

}














@Component({
  selector: 'app-account-manager',
  templateUrl: './add-new-am_component.html',
  styleUrls: ['./account-manager.component.css']
})
export class DialogAddAM implements OnInit {

constructor(
  public dialogRef: MatDialogRef<DialogAddAM>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private httpSv:HttpService,
  private toster:ToastrService,
  public dialog: MatDialog,
  private dataShare:DataService
  ) { }

  displayedColumns: string[] = ['sl_no', 'name', 'mobile_no', 'city','assign_am','status','reset_password' ,'modify'];
  emailRegex =new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, "gm");
  dataSource: any = new MatTableDataSource();
  isModify=false;
  passwordToggleHide:boolean=true
  conpasswordToggleHide:boolean=true
  selectedFilter: any = {
    all: true,
    active: false,
    expired: false,
    requested: false
  }
  ngOnInit(): void {
    this.allState=this.dataShare.allStatesOfIndia;
    if(this.data.id)
    {
      this.httpSv.getAMDetails(this.data.id).subscribe((res:any)=>{
        if(res['status'])
        {
          this.isModify=true
          let data=res['results'][0];
          this.bmName=data.name;
          this.bmCity=data.city;
          this.bmAddress=data.address;
          this.bmSelectedState=data.state;
          this.bmEmail=data.email;
          this.bmNumber=data.mobile;
          this.bmtxtStatus=data.status;
        }
        else {
          this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 })
      })
    }
  }
  bmName: string = ''
  bmEmail: string = ''
  bmNumber: string = ''
  bmCompName: string = ''
  bmAddress: string = ''
  bmCity: string = ''
  bmSelectedState: any
  bmPassword: string = ''
  bmConfirmPassword: string = ''
  allState:any;
  bmtxtStatus: String = '';
  assign_account_manager:any;


  addNewUser()
  {
    if (this.bmAddress && this.bmAddress != '') {
    if (this.bmName && this.bmName != '') {
      if (this.bmEmail && this.bmEmail != '') {
        if (this.bmEmail.match(this.emailRegex)) {
          if (this.bmNumber && this.bmNumber != '') {
            if (this.bmNumber.toString().length == 10) {
              // if (this.bmCompName && this.bmCompName != '') {
                if (this.bmCity && this.bmCity != '') {
                  if (this.bmSelectedState) {
                    if (this.bmPassword && this.bmPassword != '') {
                      if (this.bmConfirmPassword && this.bmConfirmPassword != '') {
                        if (this.bmPassword == this.bmConfirmPassword) {
                          const data = {
                            name: this.bmName,
                            email: this.bmEmail,
                            mobile: this.bmNumber,
                            // company_name: this.bmCompName,
                            city: this.bmCity,
                            address: this.bmAddress,
                            state: this.bmSelectedState,
                            password: this.bmPassword
                          }
                          this.httpSv.CreateNewAmUser(data).subscribe((res: any) => {
                            if (res['status']) {
                              this.dialogRef.close(true)
                            } else {
                              this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                            }
                          }, (err: any) => {
                            this.toster.error(err.error.message, '', { timeOut: 2000 })
                          })
                        } else {
                          this.toster.error('Password does not match', '', { timeOut: 2000 })
                        }
                      } else {
                        this.toster.error('Please confirm the password', '', { timeOut: 2000 })
                      }
                    } else {
                      this.toster.error('Please enter password', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.error('Please select state', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.error('Please enter city name', '', { timeOut: 2000 })
                }
              // } else {
              //   this.toster.error('Please enter company name', '', { timeOut: 2000 })
              // }
            } else {
              this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
          }
        } else {
          this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter email id', '', { timeOut: 2000 })
      }
    } else {
      this.toster.error('Please enter name', '', { timeOut: 2000 })
    }
    } else {
      this.toster.error('Please enter Address', '', { timeOut: 2000 })
    }
  }
  modifyNewUser(){
    if (this.bmAddress && this.bmAddress != '') {
    if (this.bmName && this.bmName != '') {
      if (this.bmEmail && this.bmEmail != '') {
        if (this.emailRegex.test(this.bmEmail)) {
          if (this.bmNumber && this.bmNumber != '') {
            if (this.bmNumber.toString().length == 10) {
              if (this.bmSelectedState && this.bmSelectedState != '') {
                if (this.bmCity && this.bmCity != '') {
                  // if (this.assign_account_manager) {
                  //   if (this.bmPassword && this.bmPassword != '') {
                  //     if (this.bmConfirmPassword && this.bmConfirmPassword != '') {
                  //       if (this.bmPassword == this.bmConfirmPassword) {
                          const data = {
                            name: this.bmName,
                            email: this.bmEmail,
                            mobile: this.bmNumber,
                            // company_name: this.bmCompName,
                            city: this.bmCity,
                            address: this.bmAddress,
                            state: this.bmSelectedState,
                            status: this.bmtxtStatus,
                          }
                          this.httpSv.updateAMDetails(this.data.id,data).subscribe((res: any) => {
                            if (res['status']) {
                              this.dialogRef.close(true)
                            } else {
                              this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                            }
                          }, (err: any) => {
                            this.toster.error(err.error.message, '', { timeOut: 2000 })
                          })
                        // } else {
                        //   this.toster.error('Please select Accoutn manager', '', { timeOut: 2000 })
                        // }
                  //     } else {
                  //       this.toster.error('Please confirm the password', '', { timeOut: 2000 })
                  //     }
                  //   } else {
                  //     this.toster.error('Please enter password', '', { timeOut: 2000 })
                  //   }
                  // } else {
                  //   this.toster.error('Please select state', '', { timeOut: 2000 })
                  // }
                } else {
                  this.toster.error('Please enter city name', '', { timeOut: 2000 })
                }
              } else {
                this.toster.error('Please enter State', '', { timeOut: 2000 })
              }
            } else {
              this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Please enter mobile number', '', { timeOut: 2000 })
          }
        } else {
          this.toster.error('Please enter a valid email id', '', { timeOut: 2000 })
        }
      } else {
        this.toster.error('Please enter email id', '', { timeOut: 2000 })
      }
    } else {
      this.toster.error('Please enter name', '', { timeOut: 2000 })
    }
    } else {
    this.toster.error('Please enter Address', '', { timeOut: 2000 })
    }
  }
  handleAssignment(type: any, bmId: any, id: any) {
    const dialogRef = this.dialog.open(DialogAssignment, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        type,
        bmId,
        id,
        from:'sa'
      }
    })
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.ngOnInit()
    })
  }
  handleStatusChange(status: any) {
    this.bmtxtStatus = status
  }
  onMaterialGroupChange(event: any) {
    // console.log(event);
  }
  close()
  {
    this.dialogRef.close(false)
  }
  handleChangePasswordBMUser()
  {
    const dialogRef = this.dialog.open(DialogResetPassword, {
      disableClose: true,
      hasBackdrop: true,
      data:{
        element:this.data.id
      }
    })
  }
  handleDeleteBMUser(){
    let dialogue=this.dialog.open(DialogDeleteCofirm,{
      disableClose: true,
      hasBackdrop: true,
      data:{
        heading:'Are you sure you want to remove this Account Manager?',
        element:this.data.id
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res){
        this.httpSv.deleteAM(this.data.id).subscribe((res:any)=>{
          if(res['status'])
        {
        const dialogRef = this.dialog.open(DialogDeleteDonePopup, {
          hasBackdrop: true,
          disableClose: true,
          data: {
            heading: 'Account Manager Deleted'
          }
        })
        dialogRef.afterClosed().subscribe((result)=>{
          this.dialogRef.close(true)
        })      
      }
      else {
        this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
      }
    })
  }
  }