import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/api/http.service';
import { DataService } from 'src/app/services/data-share/data.service';
import * as CryptoJS from 'crypto-js';
import { MatDialog } from '@angular/material/dialog';
import { DialogResetPassword } from '../common_dialog/reset-password/reset_password';
import { ForgetPasswordComponent } from '../common_dialog/forget-password/forget-password.component';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private httpSv : HttpService,
    public toster : ToastrService,
    private dataShare : DataService,
    private router: Router,
    private dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    localStorage.clear()
    this.dataShare.profileDtls = null
  }
  passwordToggleHide: boolean = true
  errMsgFlag: boolean = false
  errMsgTxt: string = 'The email address or password you entered is incorrect.'
  emailId: string = ''
  password: string = ''
  encryptedValue: any;
  loginFunc() {
    this.errMsgFlag = false
    if (this.emailId != '' && this.emailId) {
      if (this.emailId.includes('@') && this.emailId.includes('.')) {
        if (this.password != '' && this.password) {
          const data = {
            email: this.emailId,
            password: this.password
          }
          this.httpSv.login(data).subscribe((res:any) => {
            if(res['status']){
              if(res.role_data.id==4)
              {
                this.router.navigate(['/'])
                this.toster.warning('Oops! You dont have the access','',{timeOut:2000})
                return;
              }
              localStorage.setItem('token', res.token)
              const secretKey = this.dataShare.secretKey;
              const numberToEncrypt = res.role_data.id;
              this.dataShare.roleId=res.role_data.id;
              // Encrypt the number using AES encryption with the secret key
              const encrypted = CryptoJS.AES.encrypt(
                numberToEncrypt.toString(), // Convert the number to a string before encryption
                secretKey
              );
          
              // Store the encrypted value
              this.encryptedValue = encrypted.toString();
              localStorage.setItem('role', this.encryptedValue)
              
              this.dataShare.perkAvilableForThisLogin = res.role_data
              this.router.navigate(['/dashboard'])
            }
            else{
              this.toster.error('Oops! Something went wrong','',{timeOut:2000})
            }
          },(err:any)=>{
            this.errMsgTxt = err.error.message
            this.errMsgFlag = true
          })

        } else {
          this.errMsgTxt = 'Please enter the password'
          this.errMsgFlag = true
        }
      } else {
        this.errMsgTxt = 'Please enter a valid email id'
        this.errMsgFlag = true
      }
    }
    else {
      this.errMsgTxt = 'Please enter the email id'
      this.errMsgFlag = true
    }
  }
  forgetPassword(){
    this.dialog.open(ForgetPasswordComponent,{})
  }
}
