<div class="modify_doc_main_div">
    <div style="display: contents;">
        <div class="header_main">
            <img src="../../../assets/img/back_arrow.svg" alt="" style="cursor: pointer;" (click)="close()">
            <span class="modify_doc_header_div" *ngIf="!data.addNew">MODIFY {{typeTitle}}</span>
            <span class="modify_doc_header_div" *ngIf="data.addNew">CREATE {{typeTitle}}</span>

        </div>
        <!-- Application -->
        <div *ngIf="type.application" style="overflow: auto;">
            <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;padding:2rem;padding-bottom: 1rem;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="name">Document Name</label>
                    <input type="text" name="name" [(ngModel)]="docName">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Description</label>
                    <input type="email" name="description" [(ngModel)]="docDes">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">Sample Document</label>
                    <ng-select class="multi-select" [items]="allTypeOfDocs" bindLabel="name" bindValue="id"
                        [multiple]="false" groupBy="selectedAllGroup" [selectableGroup]="true" name="typeOfLicence"
                        placeholder="Select Sample Document" 
                        [(ngModel)]="selectedDoc">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">Status</label>
                    <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                        [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                        placeholder="Select Document Status" 
                        [(ngModel)]="selectedStatus">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="upload">Upload Documents</label>
                    <div class="license_upload_sub_div">
                        <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                            #fileInput_image hidden id="file_inp_doc" />
                        <div (click)="fileInput_image.click()">
                            Choose file
                        </div>
                        <div>
                            <span *ngIf="fileName == ''">No file chosen</span>
                            <span *ngIf="fileName != ''">{{fileName}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Remarks</label>
                    <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
                </div> -->
                <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                    <span class="view_license_span" (click)="viewLicence()">View Document</span>
                    <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

                </div>
                 
            </div>
            <div style="width:100%;padding:2rem;padding-top: 0;" *ngIf="!data.addNew">
                <div class="d-flex justify-content-between" style="padding-bottom: 1rem;">
                    <label for="state">Recent Status Changes</label>
                    <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
                </div>
                
                <div class="license_modify_pd_main_divq headerr" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                border-bottom: 2px solid #ddd;
                padding: 10px 0;">
                    <div style="min-width:5rem;">User Name</div>
                    <div style="min-width:5rem;">From Status</div>
                    <div style="min-width:5rem;">To Status</div>
                    <div style="min-width:15rem;">Status Changed on</div>
                </div>

                <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
                <div class="license_modify_pd_main_divq" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                padding: 10px 0;">
                    <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                    <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                    <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                    <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
                </div>
                </div>
            </div> 
        </div>
        <!-- Testing -->
        <div *ngIf="type.testing" style="overflow: auto;">
            <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;;padding:2rem;padding-bottom: 1rem;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="name">Product Name</label>
                    <input type="text" name="name" [(ngModel)]="productName">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Product ID</label>
                    <input type="email" name="id" [(ngModel)]="productId">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="date">Product Date</label>
                    <input type="date" name="date" [(ngModel)]="testingDate">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">Status</label>
                    <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                        [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                        placeholder="Select Document Status" 
                        [(ngModel)]="selectedStatus">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="upload">Upload Documents</label>
                    <div class="license_upload_sub_div">
                        <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                            #fileInput_image hidden id="file_inp_doc" />
                        <div (click)="fileInput_image.click()">
                            Choose file
                        </div>
                        <div>
                            <span *ngIf="fileName == ''">No file chosen</span>
                            <span *ngIf="fileName != ''">{{fileName}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Remarks</label>
                    <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
                </div> -->
                <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                    <span class="view_license_span" (click)="viewLicence()">View Document</span>
                    <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

                </div>
            </div>
            <div style="width:100%;padding:2rem;padding-top: 0;" *ngIf="!data.addNew">
                <div class="d-flex justify-content-between"  style="padding-bottom: 1rem;">
                    <label for="state">Recent Status Changes</label>
                    <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
                </div>
                
                <div class="license_modify_pd_main_divq headerr" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                border-bottom: 2px solid #ddd;
                padding: 10px 0;">
                    <div style="min-width:5rem;">User Name</div>
                    <div style="min-width:5rem;">From Status</div>
                    <div style="min-width:5rem;">To Status</div>
                    <div style="min-width:15rem;">Status Changed on</div>
                </div>

                <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
                <div class="license_modify_pd_main_divq" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                padding: 10px 0;">
                    <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                    <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                    <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                    <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
                </div>
                </div>
            </div> 
        </div>
         <!-- Training -->
         <div *ngIf="type.training" style="overflow: auto;">
            <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;;padding:2rem;padding-bottom: 1rem;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="name">Employee Name</label>
                    <input type="text" name="name" [(ngModel)]="trainingName" >
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Emloyee ID</label>
                    <input type="email" name="id" [(ngModel)]="trainingId" >
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Training Date</label>
                    <input type="date" name="date" [(ngModel)]="trainingDate" >
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">Status</label>
                    <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                        [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                        placeholder="Select Document Status" 
                        [(ngModel)]="selectedStatus">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="upload">Upload Documents</label>
                    <div class="license_upload_sub_div">
                        <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                            #fileInput_image hidden id="file_inp_doc" />
                        <div (click)="fileInput_image.click()">
                            Choose file
                        </div>
                        <div>
                            <span *ngIf="fileName == ''">No file chosen</span>
                            <span *ngIf="fileName != ''">{{fileName}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Remarks</label>
                    <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
                </div> -->
                <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                    <span class="view_license_span" (click)="viewLicence()">View Document</span>
                    <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

                </div>
            </div>
            <div style="width:100%;padding:2rem;padding-top: 0;" *ngIf="!data.addNew">
                <div class="d-flex justify-content-between"  style="padding-bottom: 1rem;">
                    <label for="state">Recent Status Changes</label>
                    <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
                </div>
                
                <div class="license_modify_pd_main_divq headerr" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                border-bottom: 2px solid #ddd;
                padding: 10px 0;">
                    <div style="min-width:5rem;">User Name</div>
                    <div style="min-width:5rem;">From Status</div>
                    <div style="min-width:5rem;">To Status</div>
                    <div style="min-width:15rem;">Status Changed on</div>
                </div>

                <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
                <div class="license_modify_pd_main_divq" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                padding: 10px 0;">
                    <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                    <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                    <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                    <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
                </div>
                </div>
            </div> 
        </div>
         <!-- Health -->
         <div *ngIf="type.health" style="overflow: auto;">
            <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;padding:2rem;padding-bottom: 1rem;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="name">Employee Name</label>
                    <input type="text" name="name" [(ngModel)]="healthName">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Emloyee ID</label>
                    <input type="email" name="id" [(ngModel)]="healthId">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Report Date</label>
                    <input type="date" name="date" [(ngModel)]="healthDate">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">Status</label>
                    <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                        [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                        placeholder="Select Document Status" 
                        [(ngModel)]="selectedStatus">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="upload">Upload Documents</label>
                    <div class="license_upload_sub_div">
                        <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                            #fileInput_image hidden id="file_inp_doc" />
                        <div (click)="fileInput_image.click()">
                            Choose file
                        </div>
                        <div>
                            <span *ngIf="fileName == ''">No file chosen</span>
                            <span *ngIf="fileName != ''">{{fileName}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Remarks</label>
                    <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
                </div> -->
                <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                    <span class="view_license_span" (click)="viewLicence()">View Document</span>
                    <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

                </div>
            </div>
            <div style="width:100%;padding:2rem;padding-top: 0;" *ngIf="!data.addNew">
                <div class="d-flex justify-content-between"  style="padding-bottom: 1rem;">
                    <label for="state">Recent Status Changes</label>
                    <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
                </div>
                
                <div class="license_modify_pd_main_divq headerr" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                border-bottom: 2px solid #ddd;
                padding: 10px 0;">
                    <div style="min-width:5rem;">User Name</div>
                    <div style="min-width:5rem;">From Status</div>
                    <div style="min-width:5rem;">To Status</div>
                    <div style="min-width:15rem;">Status Changed on</div>
                </div>

                <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
                <div class="license_modify_pd_main_divq" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                padding: 10px 0;">
                    <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                    <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                    <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                    <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
                </div>
                </div>
            </div> 
        </div>
        <!-- Calibration -->
        <div *ngIf="type.calibiration" style="overflow: auto;">
            <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;padding:2rem;padding-bottom: 1rem;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="name">Name of Instrument</label>
                    <input type="text" name="name" [(ngModel)]="calibirationName">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Equipment ID</label>
                    <input type="email" name="id" [(ngModel)]="calibirationId">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Calibration Date</label>
                    <input type="date" name="date" [(ngModel)]="calibirationDate">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">Status</label>
                    <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                        [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                        placeholder="Select Document Status" 
                        [(ngModel)]="selectedStatus">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="upload">Upload Documents</label>
                    <div class="license_upload_sub_div">
                        <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                            #fileInput_image hidden id="file_inp_doc" />
                        <div (click)="fileInput_image.click()">
                            Choose file
                        </div>
                        <div>
                            <span *ngIf="fileName == ''">No file chosen</span>
                            <span *ngIf="fileName != ''">{{fileName}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Remarks</label>
                    <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
                </div> -->
                <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                    <span class="view_license_span" (click)="viewLicence()">View Document</span>
                    <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

                </div>
            </div>
            <div style="width:100%;padding:2rem;padding-top: 0" *ngIf="!data.addNew">
                <div class="d-flex justify-content-between"  style="padding-bottom: 1rem;">
                    <label for="state">Recent Status Changes</label>
                    <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
                </div>
                
                <div class="license_modify_pd_main_divq headerr" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                border-bottom: 2px solid #ddd;
                padding: 10px 0;">
                    <div style="min-width:5rem;">User Name</div>
                    <div style="min-width:5rem;">From Status</div>
                    <div style="min-width:5rem;">To Status</div>
                    <div style="min-width:15rem;">Status Changed on</div>
                </div>

                <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
                <div class="license_modify_pd_main_divq" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                padding: 10px 0;">
                    <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                    <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                    <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                    <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
                </div>
                </div>
            </div> 
        </div>
        <!-- Others -->
        <div *ngIf="type.other" style="overflow: auto;">
            <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;padding:2rem;padding-bottom: 1rem;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="name">Document Name</label>
                    <input type="text" name="name" [(ngModel)]="othersName">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Description</label>
                    <input type="email" name="id"  [(ngModel)]="othersDes">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Expiration Date</label>
                    <input type="date" name="date" [(ngModel)]="othersDate">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">Status</label>
                    <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                        [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                        placeholder="Select Document Status" 
                        [(ngModel)]="selectedStatus">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="upload">Upload Documents</label>
                    <div class="license_upload_sub_div">
                        <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                            #fileInput_image hidden id="file_inp_doc" />
                        <div (click)="fileInput_image.click()">
                            Choose file
                        </div>
                        <div>
                            <span *ngIf="fileName == ''">No file chosen</span>
                            <span *ngIf="fileName != ''">{{fileName}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Remarks</label>
                    <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
                </div> -->
                <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                    <span class="view_license_span" (click)="viewLicence()">View Document</span>
                    <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->
                </div>
            </div>
            <div style="width:100%;padding:2rem;padding-top: 0;padding-bottom: 1rem;" *ngIf="!data.addNew">
                <div class="d-flex justify-content-between"  style="padding-bottom: 1rem;">
                    <label for="state">Recent Status Changes</label>
                    <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
                </div>
                
                <div class="license_modify_pd_main_divq headerr" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                border-bottom: 2px solid #ddd;
                padding: 10px 0;">
                    <div style="min-width:5rem;">User Name</div>
                    <div style="min-width:5rem;">From Status</div>
                    <div style="min-width:5rem;">To Status</div>
                    <div style="min-width:15rem;">Status Changed on</div>
                </div>

                <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
                <div class="license_modify_pd_main_divq" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                padding: 10px 0;">
                    <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                    <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                    <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                    <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
                </div>
                </div>
            </div> 
        </div>
        <div *ngIf="type.audits" style="overflow: auto;">
            <div class="license_modify_pd_main_div" style="display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;padding:2rem;padding-bottom: 1rem;">
                <div class="license_modify_pd_inp_sub_div">
                    <label for="name">Document Name</label>
                    <input type="text" name="name" [(ngModel)]="docName">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Description</label>
                    <input type="email" name="description" [(ngModel)]="docDes">
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">Sample Document</label>
                    <ng-select class="multi-select" [items]="allTypeOfDocs" bindLabel="name" bindValue="id"
                        [multiple]="false" groupBy="selectedAllGroup" [selectableGroup]="true" name="typeOfLicence"
                        placeholder="Select Sample Document" 
                        [(ngModel)]="selectedDoc">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="state">Status</label>
                    <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                        [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                        placeholder="Select Document Status" 
                        [(ngModel)]="selectedStatus">

                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                        </ng-template>
                    </ng-select>
                </div>
                <div class="license_modify_pd_inp_sub_div">
                    <label for="upload">Upload Documents</label>
                    <div class="license_upload_sub_div">
                        <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                            #fileInput_image hidden id="file_inp_doc" />
                        <div (click)="fileInput_image.click()">
                            Choose file
                        </div>
                        <div>
                            <span *ngIf="fileName == ''">No file chosen</span>
                            <span *ngIf="fileName != ''">{{fileName}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="license_modify_pd_inp_sub_div">
                    <label for="email">Remarks</label>
                    <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
                </div> -->
                <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                    <span class="view_license_span" (click)="viewLicence()">View Document</span>
                    <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

                </div>
                 
            </div>
            <div style="width:100%;padding:2rem;padding-top: 0;" *ngIf="!data.addNew">
                <div class="d-flex justify-content-between" style="padding-bottom: 1rem;">
                    <label for="state">Recent Status Changes</label>
                    <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
                </div>
                
                <div class="license_modify_pd_main_divq headerr" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                border-bottom: 2px solid #ddd;
                padding: 10px 0;">
                    <div style="min-width:5rem;">User Name</div>
                    <div style="min-width:5rem;">From Status</div>
                    <div style="min-width:5rem;">To Status</div>
                    <div style="min-width:15rem;">Status Changed on</div>
                </div>

                <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
                <div class="license_modify_pd_main_divq" style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr ;
                gap: 20px;
                padding: 10px 0;">
                    <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                    <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                    <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                    <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
                </div>
                </div>
            </div> 
        </div>
        <div style="flex: 1;">&nbsp;</div>
        <div class="license_modify_btn_main_div"
            style="padding: 0 0rem 10px 0rem;gap: 10px;display: flex;flex-direction: column;;padding:2rem">
            <!-- <div>
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="tcFlag">
                <label class="form-check-label license_term_condition" for="">
                    I agree to the <span (click)="openTerms()">terms of services</span>
                </label>
            </div> -->
            <div class="ces_pd_btn_div" *ngIf="addNew">
                <div (click)="close()">CANCEL</div>
                <div (click)="addNewDtls()">CREATE</div>
            </div>
            <div class="ces_pd_btn_div"  *ngIf="!addNew">
                <div (click)="close()">CANCEL</div>
                <div (click)="updateDtls()">UPDATE</div>
            </div>
        </div>
    </div>
</div>

<style>
    .modify_doc_main_div {
        position: relative;
        min-width: 75vw;
        min-height: 75vh;
        border-radius: 16px;
        background: #F2F2F2;
        box-shadow: 0px 2px 10px 2px rgba(236, 236, 236, 0.25);
        padding-top: 0;
        display: flex;
        flex-direction: column;
        max-height: 95vh;
        overflow-y: hidden;
        /* padding: 2rem; */
    }

    .modify_doc_header_div {
        color: #1D1E1C;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .header_main{
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
        padding: 1.5rem 2rem;
        margin-bottom: -1rem;
        align-items: center;
        background: #E3EBD8;
        z-index: 10;
    }
</style>