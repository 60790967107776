<div class="ces_main_div addRequest" style="min-width:55vw;max-width:60vw;">
    <div class="ces_header">
        <span >Add Service Request</span>
        <div class="header_quik_functions">
            <img src="../../../assets/img/close.svg" alt="" (click)="close()" >
        </div>
    </div>
    <div class="ces_content_main_div">
        <div class="ces_pd_inp_sub_div">
            <label for="text">Service Request Name</label>
            <ng-select class="multi-select" [items]="allTypeOfService" bindLabel="name" bindValue="id"
                [multiple]="false" groupBy="selectedAllGroup" [selectableGroup]="true"
                name="typeOfLicence" placeholder="Select Service Name"
                [(ngModel)]="serviceReqName"
                    >

                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index"
                    >

                </ng-template>
            </ng-select>
        </div>
        <div class="ces_pd_inp_sub_div">
            <label for="text">License Number</label>
            <input type="text" disabled class="input_disable" [(ngModel)]="licenseNumber">
        </div>
        <div class="ces_pd_inp_sub_div">
            <label for="number">Company Name</label>
            <input type="text" disabled class="input_disable" [(ngModel)]="companyName">
       </div>
       <div class="service_modify_pd_inp_sub_div " *ngIf="role!=3 && role!=5"  >
        <label for="state">Notes</label>
        <textarea [(ngModel)]="notes"></textarea>
        <!-- <input type="text" [(ngModel)]="selectedState" [disabled]="isBlock?true:false"
        class="disabled" [ngClass]="isBlock?'input_disable':''"> -->
    </div>   
    </div>
    <div style="flex: 1;">&nbsp;</div>
    <div class="ces_btn_main_div" style="margin-bottom: 2rem;">
        <div class="ces_pd_btn_div">
            <div (click)="close()">CANCEL</div>
            <div (click)="save()">ADD</div>
        </div>
    </div>
</div>